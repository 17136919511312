import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Skeleton } from "primereact/skeleton";
import Gifting_cover from "./../../Images/Gifting_cover.png";

import { Slider } from 'primereact/slider';

import React, { useEffect, useState } from "react";
import "./product.css";
import { useParams, useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";

import ProductLists from "../../components/ProductLists";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddOns from "../../components/Modals/AddOns";
import { useSearchProductQuery } from "../../redux/service/productSlice";
import { useGetAllBrandQuery } from "../../redux/service/brandSlice";
import { useGetAllDepartmentQuery } from "../../redux/service/departmentSlice";

const sizes = [
  { label: "Small (Under 50ml)", value: "small" },
  { label: "Medium (50ml - 500ml)", value: "medium" },
  { label: "Large (500ml - 750ml)", value: "large" },
  { label: "Extra-Large (Above 750ml)", value: "extra_large" },
];

const brands = [
  "Pol Roger",
  "Mignon Grande",
  "Pommery",
  "Santa Margherita",
  "Torresella",
  "Charles De Fère",
  "A Nos Amours",
  "Zonin",
  "Cremant De Bordeaux",
  "Bouchard Aine & Fils",
];

const pageHeader = [
  {
    departmertId: 1,
    title: "Wines",
    subTitle: " Browse through the largest portfolio of wines",
    description:
      "Whether you’re looking for a Red Wine or a White Wine, dry Merlotfrom Chile or a crisp Chardonnay from Italy, our online wine store in Sri Lanka has some of the best wine brands for you to choosefrom. Buy your favorite wine from our store and quench your thirstwithout stepping out!",
  },
  {
    departmertId: 2,
    title: "Spirits",
    subTitle: " Browse through the largest portfolio of Sprits",
    description:
      "The diverse range of spirits available at our online store in Sri Lanka, whether its Arrack, Tequila, Rum or Whisky, allows you to choose and buy spirits online without standing in long bar queues! Set your spirit free as we deliver the best alcohol brands to you.",
  },
  {
    departmertId: 3,
    title: "Mixers",
    subTitle: "Browse through the largest portfolio of Beers & Mixers",
    description:
      "Complement the beverage of your choice with the right mixer! Choose from syrups, bitters, tonic or soda that will perfectly blend with your spirit while still retaining the real taste of your alcohol.",
  },
];

const Products = ({ toggleTheme, isDarkTheme }) => {
  const { department, category, brandId } = useParams();

  // console.log("department", department);
  const data = pageHeader?.find(
    (item) => item.departmertId == department?.toLowerCase()
  );

  const { data: dep } = useGetAllDepartmentQuery();

  const [sortBy, setSortBy] = useState(null);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [isAddOneVisible, setisAddOneVisible] = useState(false);
  const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);
  const currency = useSelector((state) => state?.meta?.currency);
  const [size, setSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([0, 100]);


  const [searchParams] = useSearchParams();
  const queryDep = searchParams.get("dep");
  const cat = searchParams.get("cat");





  useEffect(() => {
    setSelectedBrands([]);
    setSelectedBrands([]);
    setSelectedSizes([]);
  }, [department, category]);


  const { data: brand, isLoading } = useGetAllBrandQuery({ size });

  const filteredBrands = dep
    ?.filter((item) => item.departmertId.toString() === department)
    .flatMap((item) =>
      category
        ? item.categories.filter(
          (cat) => cat.categoryId.toString() === category
        )
        : item.categories
    )
    .flatMap((cat) => cat.brands);

  const maxMinValue = dep?.find(
    (item) => item?.departmertId?.toString() === department
  );


  const minPrice = currency === "LKR" ? maxMinValue?.minPriceInRupee : maxMinValue?.minPriceInDollar
  const maxPrice = currency === "LKR" ? maxMinValue?.maxPriceInRupee : maxMinValue?.maxPriceInDollar



  const onePercentValue = (maxPrice - minPrice) / 100




  useEffect(() => {
    if (brandId) {
      const matchingBrand = filteredBrands?.find(
        (brand) => brand.brandId.toString() === brandId
      );
      if (matchingBrand) {
        setSelectedBrands([matchingBrand.brand]);
      }
    }
  }, [brandId]);


  const cityId = localStorage.getItem("city");

  const [debouncedMinPrice, setDebouncedMinPrice] = useState(
    minPrice + value[0] * onePercentValue
  );
  const [debouncedMaxPrice, setDebouncedMaxPrice] = useState(
    minPrice + value[1] * onePercentValue
  );
  const {
    data: prodcuts,
    isLoading: prodcutsLoading,
    refetch,
  } = useSearchProductQuery(
    {
      page: 0,
      size: 1000,
      sizeCategories: selectedSizes,
      brands: selectedBrands,
      sortBy: sortBy,
      categoryId: category,
      departmentId: department,
      cityId,
      currency,
      minPrice: debouncedMinPrice,
      maxPrice: debouncedMaxPrice,
    },
    { refetchOnMountOrArgChange: true }
  );


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedMinPrice(minPrice + value[0] * onePercentValue);
      setDebouncedMaxPrice(minPrice + value[1] * onePercentValue);
    }, 1000); // 2 seconds debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [value, minPrice, onePercentValue]);


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [department, category, selectedBrands, sortBy, selectedSizes, debouncedMinPrice, debouncedMaxPrice]);


  useEffect(() => {
    refetch();
  }, [selectedSizes, selectedBrands, sortBy]);

  const handleSizeChange = (e) => {
    let selected = [...selectedSizes];
    if (e.checked) selected.push(e.value);
    else selected = selected.filter((size) => size !== e.value);
    setSelectedSizes(selected);
  };

  const handleBrandChange = (e) => {
    let selected = [...selectedBrands];
    if (e.checked) selected.push(e.value);
    else selected = selected.filter((brand) => brand !== e.value);
    setSelectedBrands(selected);
  };

  const toggleAddOneModal = () => {
    setisAddOneVisible((prev) => !prev);
  };

  const toggleFilterDialog = () => {
    setIsFilterDialogVisible((prev) => !prev);
  };

  const applyFilters = () => {
    toggleFilterDialog();
  };


  const uniqueBrands = filteredBrands?.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => t.brandId === value.brandId && t.brand === value.brand
      )
  );

  return (
    <div className="px-2 md:px-[8%] mt-4 container" >

      <AddOns
        isModalVisible={isAddOneVisible}
        toggleModal={toggleAddOneModal}
        isDarkTheme={isDarkTheme}
      />

      <div className="md:flex justify-center items-center px-2 hidden">
        {data && (
          <div className="pb-8 rounded-lg text-center">
            <h1 className="text-[3rem] prodTypeTitle font-newsreader uppercase">
              {data?.title}
            </h1>
            <h2 className="text-1xl text-[#da813d]">{data?.subTitle}</h2>
            <p className="text-1xl text-[#333] text-[var(--color)]">
              {data?.description}
            </p>
          </div>
        )}
        {department == 4 && (
          <div style={{ paddingLeft: "2%", marginRight: "2%" }}>
            <div className="parth">
              {/* Home {">"} <div className="parth-current"> Gifting</div> */}
            </div>

            <img
              className="w-full h-[125px] md:h-[350px] object-cover"
              src={Gifting_cover}
              alt="Gifting Cover"
            />
          </div>
        )}
      </div>
      {/* Price Filter By Section */}


      <div className="flex-1 flex p-4">
        <div className="hide-on-small">
          <div className="font-sans text-gray-800 max-w-sm ml- p-3">


            <div className="mb-5 md:w-3/4 ">
              <h3 className="text-lg mb-5 mt-8 text-[var(--color)] font-semibold">
                Price Range
              </h3>
              <div>
                {/* <p className="text-lg mb-5 mt-5 text-[var(--color)] font-normal">{Math.round(minPrice + value[0] * onePercentValue)} {currency} - {Math.round(minPrice + value[1] * onePercentValue)} {currency}</p> */}
                <p className="text-lg mb-5 mt-5 text-[var(--color)] font-normal">
                  {Math.round(minPrice + value[0] * onePercentValue).toLocaleString()} {currency} - {Math.round(minPrice + value[1] * onePercentValue).toLocaleString()} {currency}
                </p>
                <Slider className="" value={value} onChange={(e) => setValue(e.value)} range />
              </div>
            </div>


            {/* Sort By Section */}
            <div className="mb-5">
              <h3 className="text-lg mb-5 mt-8 text-[var(--color)] font-semibold">
                Sort by
              </h3>
              <div className="flex items-center filtergap">
                <RadioButton
                  inputId="price_low_high"
                  name="sort"
                  value="price_low_high"
                  onChange={(e) => setSortBy(e.value)}
                  checked={sortBy === "price_low_high"}
                />
                <label
                  htmlFor="lowToHigh"
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  Price : Low to High
                </label>
              </div>
              <div className="flex items-center filtergap">
                <RadioButton
                  inputId="price_high_low"
                  name="sort"
                  value="price_high_low"
                  onChange={(e) => setSortBy(e.value)}
                  checked={sortBy === "price_high_low"}
                />
                <label
                  htmlFor="highToLow"
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  Price : High to Low
                </label>
              </div>
              <div className="flex items-center filtergap">
                <RadioButton
                  inputId="fast_moving"
                  name="sort"
                  value="fast_moving"
                  onChange={(e) => setSortBy(e.value)}
                  checked={sortBy === "fast_moving"}
                />
                <label
                  htmlFor="fastMoving"
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  Fast moving
                </label>
              </div>
            </div>

            {/* Filter by Size */}
            {department != 4 && (
              <div className="mb-5">
                <h3 className="text-lg mb-5 mt-8 text-[var(--color)] font-semibold">
                  Filter by size
                </h3>
                {sizes.map((size, index) => (
                  <div key={index} className="flex items-center filtergap">
                    <Checkbox
                      inputId={`size${index}`}
                      value={size.value}
                      onChange={handleSizeChange}
                      checked={selectedSizes.includes(size.value)}
                    />
                    <label
                      htmlFor={`size${index}`}
                      className="ml-2 text-base font-medium text-[var(--color)]"
                    >
                      {size.label}
                    </label>
                  </div>
                ))}
              </div>
            )}

            {/* Filter by Brands */}
            {department != 4 && (
              <div className="mb-8">
                <h3 className="text-lg mb-5 mt-8 text-[var(--color)] font-semibold">
                  Filter by brand
                </h3>
                {(uniqueBrands || []).slice(0, size).map((info, index) => {
                  return (
                    <div className="flex items-center filtergap">
                      <Checkbox
                        inputId={`brand${index}`}
                        value={info?.brand}
                        onChange={handleBrandChange}
                        checked={selectedBrands.includes(info?.brand)}
                      />
                      <label
                        htmlFor={`brand${index}`}
                        className="ml-2 text-base font-medium text-[var(--color)]"
                      >
                        {info?.brand}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}

            {/* Load More Button */}
            {filteredBrands?.length > size && (
              <Button
                label={`${isLoading ? "Loading..." : "Load More"}`}
                icon="pi pi-chevron-down"
                className="text-[#007ad9] ml-3"
                onClick={() => setSize(size + 10)}
              />
            )}
          </div>
        </div>
        <div className="flex-1 mt-8">
          <div className="text-center  flex md:hidden justify-end pb-5">
            <Button icon="pi pi-filter" onClick={toggleFilterDialog} />
          </div>

          {prodcutsLoading || loading ? (
            <ProductSkeleton />
          ) : prodcuts?.content?.length > 0 ? (
            <ProductLists productData={prodcuts} visibleCount={6} />
          ) : (
            <h2 className=" text-2xl text-[var(--color)]">
              No Products available yet.
            </h2>
          )}
          {/* <ProductLists productData={prodcuts} visibleCount={6} /> */}
        </div>
      </div>


      {/* Filter Dialog -mobile */}

      <Dialog
        header="Filters"
        visible={isFilterDialogVisible}
        onHide={toggleFilterDialog}
        className="w-full h-screen"
        footer={
          <Button
            label="Apply Filters"
            icon="pi pi-check"
            onClick={applyFilters}
            autoFocus
          />
        }
      >

        <div className="font-sans max-w-sm ml- p-3">
          <div className="mb-5 md:w-3/4 ">
            <h3 className="text-lg mb-5 mt-8 text-[var(--color)] font-semibold">
              Price Range
            </h3>
            <div>
              {/* <p className="text-lg mb-5 mt-5 text-[var(--color)] font-normal">{Math.round(minPrice + value[0] * onePercentValue)} {currency} - {Math.round(minPrice + value[1] * onePercentValue)} {currency}</p> */}
              <p className="text-lg mb-5 mt-5 text-[var(--color)] font-normal">
                {Math.round(minPrice + value[0] * onePercentValue).toLocaleString()} {currency} - {Math.round(minPrice + value[1] * onePercentValue).toLocaleString()} {currency}
              </p>
              <Slider className="" value={value} onChange={(e) => setValue(e.value)} range />
            </div>
          </div>

          <div className="mb-5">
            <h3 className="text-lg mb-5 mt-8 text-[var(--color)] font-semibold">
              Sort by
            </h3>
            <div className="flex items-center filtergap">
              <RadioButton

                inputId="price_low_high"
                name="sort"
                value="price_low_high"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "price_low_high"}
              />
              <label
                htmlFor="lowToHigh"
                className="ml-2 text-base font-medium text-[var(--color)]"

              >
                Price : Low to High
              </label>
            </div>
            <div className="flex items-center filtergap">
              <RadioButton

                inputId="price_high_low"
                name="sort"
                value="price_high_low"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "price_high_low"}
              />
              <label
                htmlFor="highToLow"
                className="ml-2 text-base font-medium text-[var(--color)]"

              >
                Price : High to Low
              </label>
            </div>
            <div className="flex items-center filtergap">
              <RadioButton

                inputId="fast_moving"
                name="sort"
                value="fast_moving"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "fast_moving"}
              />
              <label
                htmlFor="fastMoving"
                className="ml-2 text-base font-medium text-[var(--color)]"
              >
                Fast moving
              </label>
            </div>
          </div>

          {/* Filter by Size */}
          {department != 4 &&

            <div className="mb-5">
              <h3 className="text-lg mb-5 mt-8 text-[var(--color)] font-semibold">
                Filter by size
              </h3>
              {sizes.map((size, index) => (
                <div key={index} className="flex items-center filtergap">
                  <Checkbox
                    inputId={`size${index}`}
                    value={size.value}
                    onChange={handleSizeChange}
                    checked={selectedSizes.includes(size.value)}
                  />
                  <label
                    htmlFor={`size${index}`}
                    className="ml-2 text-base font-medium text-[var(--color)]"
                  >
                    {size.label}
                  </label>
                </div>
              ))}
            </div>
          }

          {/* Filter by Brands */}
          {department != 4 &&
            <div className="mb-8">
              <h3 className="text-lg mb-5 mt-8 text-[var(--color)] font-semibold">
                Filter by brand
              </h3>
              {(uniqueBrands || []).slice(0, size).map((info, index) => {
                return (
                  <div className="flex items-center filtergap">
                    <Checkbox
                      inputId={`brand${index}`}
                      value={info?.brand}

                      onChange={handleBrandChange}
                      checked={selectedBrands.includes(info?.brand)}
                    />
                    <label
                      htmlFor={`brand${index}`}
                      className="ml-2 text-base font-medium text-[var(--color)]"
                    >
                      {info?.brand}
                    </label>
                  </div>
                )
              })}
            </div>
          }

          {/* Load More Button */}
          {filteredBrands?.length > size && (
            <Button
              label={`${isLoading ? "Loading..." : "Load More"}`}
              icon="pi pi-chevron-down"
              className="text-[#007ad9] ml-3"
              onClick={() => setSize(size + 10)}
            />

          )}
        </div>
      </Dialog>
    </div>
  );
};

export default Products;

const ProductSkeleton = () => {
  return (

    <div className="w-full grid md:grid-cols-3 gap-4">
      <div className="px-5 mb-5">
        <Skeleton height="300px" className="mb-2" borderRadius="16px"></Skeleton>
        <Skeleton height="60px" className="mb-2 mt-5" borderRadius="16px"></Skeleton>
      </div>
      <div className="px-5 mb-5">
        <Skeleton height="300px" className="mb-2" borderRadius="16px"></Skeleton>
        <Skeleton height="60px" className="mb-2 mt-5" borderRadius="16px"></Skeleton>
      </div>
      <div className="px-5 mb-5">
        <Skeleton height="300px" className="mb-2" borderRadius="16px"></Skeleton>
        <Skeleton height="60px" className="mb-2 mt-5" borderRadius="16px"></Skeleton>
      </div>

      <div className="px-5 mb-5">
        <Skeleton height="300px" className="mb-2" borderRadius="16px"></Skeleton>
        <Skeleton height="60px" className="mb-2 mt-5" borderRadius="16px"></Skeleton>
      </div>
      <div className="px-5 mb-5">
        <Skeleton height="300px" className="mb-2" borderRadius="16px"></Skeleton>
        <Skeleton height="60px" className="mb-2 mt-5" borderRadius="16px"></Skeleton>
      </div>
      <div className="px-5 mb-5">
        <Skeleton height="300px" className="mb-2" borderRadius="16px"></Skeleton>
        <Skeleton height="60px" className="mb-2 mt-5" borderRadius="16px"></Skeleton>
      </div>


    </div>

  );
};
