import { apiSlice } from "./apiSlice";

export const departmentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllDepartment: builder.query({
      query: () => ({
        url: "products/filter-values",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllDepartmentQuery } = departmentSlice;
