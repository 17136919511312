import React, { useEffect, useState } from "react";
import "./WishListItem.css";
import { Button } from "primereact/button";
import Monkey_ShoulderBottle from "./../Images/Monkey_Shoulder.png";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { FaHeart } from 'react-icons/fa';
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import { useAddCartItemMutation } from "../redux/service/cartSlice";
// import { Tooltip } from "react-tooltip";
// import "react-tooltip/dist/react-tooltip.css"; // Import the styles
import { useAddfavItemMutation, useDeleteFavoriteItemMutation, useGetFavoriteItemsQuery } from "../redux/service/favouriteSlice";

const WishListItem = ({ productName, onClick, productImageUrl, productId, regularPriceInRupee, regularPriceInDollar, isFavorite }) => {
  const navigate = useNavigate(); // Initialize the navigate hook
  const user = useSelector((state) => state?.auth?.userInfo);
  const userId = useSelector((state) => state?.auth?.userInfo?.id);
  const sessionID = useSelector((state) => state?.auth?.sessionId);
  const [addToCart, { isLoading }] = useAddCartItemMutation();
  const currency = useSelector((state) => state?.meta?.currency);
  const [addfavItem, { isLoading: addFavLoading }] = useAddfavItemMutation();
  const [deletefavItem, { isLoading: deleteFavLoading }] = useDeleteFavoriteItemMutation();
  const { favdata, isLoading: favproductLoading, refetch: refetchfav } = useGetFavoriteItemsQuery(userId, {
    skip: !userId, // Skip the query if userId is not available
  });

  const toggleFavourite = async () => {





    if (isFavorite == true) {
      try {
        // Trigger the mutation to add to favorites
        await deletefavItem({
          userId,
          productId,
        }).unwrap();
        console.log("Item removed from favorites.");
        refetchfav();
      } catch (error) {
        console.error("Error adding to favorites:", error);
      }

    } else {
      try {
        await addfavItem({
          userId,
          productId,
        }).unwrap();
        refetchfav();
      } catch (error) {
        console.error("Error adding to favorites:", error);
      }
    }


  };

  const handleAddToCart = async () => {
    // console.log("user", user);
    if (!user) {
      return toast.error("Please login to the system before adding to cart");
    }
    try {
      await addToCart({
        userId: userId || null,
        itemId: productId,
        quantity: 1,
        sessionId: sessionID,
        cityId: localStorage.getItem("city"),
      }).unwrap();
      toast.success("Product added to cart");
    } catch (err) {
      if (err.status === 404) {
        console.log(err);
        toast.error(err.data.message || "Error adding product to cart");
      } else {
        toast.error("Error adding product to cart");
      }
    }
  };

  const id = productId;

  return (
    <div className="cursor-pointer" onClick={() => {
      navigate(`/product/${id}`);

      window.scrollTo(0, 0);
    }}>


      <div className="border border-gray-500 flex rounded-lg gap-2 p-2 w-full mb-3">
        <img
          src={productImageUrl}
          alt="Monkey Shoulder"
          className="h-28 w-16 md:w-24 object-contain"
        />
        <div className="flex flex-col w-full mb-4">
          <div className="flex justify-between w-full mb-1 mobile-padding-s">
            <div className="md:pt-3">
              <p className="text-black font-bold mb-0">{productName}</p>
              <p className="text-[#2BAE66FF] text-lg font-bold mb-0">
                {currency}{" "}
                {currency === "LKR"
                  ? regularPriceInRupee.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : regularPriceInDollar.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              {/* <p className="text-[#FF6F61] mb-0 text-sm">Blended</p> */}
            </div>
            {deleteFavLoading ? (
              <span className="text-sm text-gray-500">Removing...</span>
            ) : (
              <>

                <Button tooltip={isFavorite ? "Remove from favorites" : "Add to favorites"}
                  tooltipOptions={{ autoHide: false }}>
                  <FaHeart
                    id="favorite-icon"
                    onClick={(e) => {
                      toggleFavourite();
                      e.stopPropagation();
                    }}
                    color={isFavorite ? "red" : "gray"}
                    size={20}
                  />
                </Button>
                {/* <Tooltip
                  anchorId="favorite-icon"
                  content={isFavorite ? "Remove from favorites" : "Add to favorites"}
                  place="top"
                /> */}
              </>
            )}

          </div>
          <div className="flex md:justify-end">
            <Button
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from reaching the parent div
                handleAddToCart();
              }}
              label="Add to cart"
              size="small"
              className="cartButton bg-emerald-800 border-none rounded-md text-sm w-44 py-2 hover:bg-[#006837] text-[#ffffff] !important"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishListItem;
