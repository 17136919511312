// src/components/Header.js
import React from "react";
import NewArrivalImage from "./../icons/NewArrival.png";
import "./ProductsCard.css"; // Use a CSS file for styles
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { useSelector } from "react-redux";
import { useAddCartItemMutation } from "../redux/service/cartSlice";
import { toast } from "material-react-toastify";
import { useCreateOrderMutation } from "../redux/service/orderSlice";
import { useCreatePaymentIntentMutation } from "../redux/service/paymentSlice";

const ProductsCard = ({ productData, onClick }) => {
  const navigate = useNavigate();
  const product = productData;

  const sessionID = useSelector((state) => state?.auth?.sessionId);
  const userId = useSelector((state) => state?.auth?.userInfo?.id);
  const currency = useSelector((state) => state?.meta?.currency);

  const [addToCart, { isLoading }] = useAddCartItemMutation();
  const [createOrder] = useCreateOrderMutation();
  const [createPaymentIntent] = useCreatePaymentIntentMutation();
  const user = useSelector((state) => state?.auth?.userInfo);

  const handleAddToCart = async () => {
    // console.log("user", user);
    if (!user) {
      return toast.error("Please login to the system before adding to cart");
    }
    try {
      await addToCart({
        userId: userId || null,
        itemId: product.id,
        quantity: 1,
        sessionId: sessionID,
        cityId: localStorage.getItem("city"),
      }).unwrap();
      toast.success("Product added to cart");
    } catch (err) {
      if (err.status === 404) {
        console.log(err);
        toast.error(err.data.message || "Error adding product to cart");
      } else {
        toast.error("Error adding product to cart");
      }
    }
  };

  const paymentHandler = async () => {
    try {
      await addToCart({
        userId: userId || null,
        itemId: product.id,
        quantity: 1,
        sessionId: sessionID,
        cityId: localStorage.getItem("cityId")
          ? localStorage.getItem("cityId")
          : undefined,
      })
        .unwrap()
        .then(async (cartRes) => {
          await createOrder({
            id: cartRes.data.Id,
            userId: user.id,

            sessionId: localStorage.getItem("sessionId")
              ? localStorage.getItem("sessionId")
              : undefined,
            cityId: localStorage.getItem("city")
              ? localStorage.getItem("city")
              : undefined,
          })
            .unwrap()
            .then(async (res) => {
              await createPaymentIntent({
                amount: res.amount,
                currency,
                orderId: res.id,
              })
                .unwrap()
                .then((res) => {
                  window.location.href = res.url;
                })
                .catch((err) => {
                  console.log(err);
                });
            });
        });
    } catch (error) { }
  };

  return (
    <div
      // data-aos="fade-up"
      //  data-aos-anchor-placement="top-bottom"
      onClick={() => {
        navigate(`/product/${product.id}`);
        window.scrollTo(0, 0);
      }}
      className="flex flex-col hover:shadow-3xl  hover:scale-[98%] transition-all cursor-pointer items-center justify-between border border-[#13382B] rounded-lg py-5 px-3 w-full sm:w-[300px] md:w-[275px]  h-[350px] md:h-[440px] relative bg-white"
    >
      {product?.isNewArrival === true && (
        <img
          src={NewArrivalImage}
          className="absolute top-0 left-0 object-contain w-1/2"
          alt="New Arrival"
        />
      )}
      <img
        className="w-auto mt-2 h-[130px] md:h-[180px] md:my-0"
        src={product.imageUrl}
        alt={product.correctName}
      />
      <h3
        className="text-black mb-0 md:mb-0 text-sm leading-tight text-center line-clamp-2 overflow-hidden"
        style={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          overflow: "hidden",
        }}
      >
        {product.correctName}
      </h3>
      <p className="text-[var(--green)] text-sm mb-2 md:mb-0 font-bold leading-tight">
        {product.categoryName}
      </p>
      <p className="text-[#13382B] text-sm font-bold leading-tight">
        {currency}{" "}
        {currency === "LKR"
          ? product.regularPriceInRupee?.toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : product.regularPriceInDollar?.toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </p>
      <div className="w-full">
        <button
          // disabled={!user}
          onClick={(e) => {
            e.stopPropagation(); // Prevent event from reaching the parent div
            handleAddToCart();
          }}
          // className={`${!user
          //   ? "bg-gray-400 cursor-not-allowed" // Disabled state styles
          //   : "bg-[#13382B] hover:bg-opacity-80 transition-opacity duration-300"
          //   } text-white font-bold rounded-lg w-full h-[38px]`}
          className={`bg-[#13382B] hover:bg-opacity-80 transition-opacity duration-300 ${isLoading && " cursor-wait"} text-white font-bold rounded-lg w-full h-[38px]`}
        >
          {isLoading ? "Adding..." : "Add to cart"}
        </button>
      </div>
    </div>
  );
};

export default ProductsCard;
