import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";


export const profileSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: (id) => `/profile/${id}`,
            providesTags: ["Profile"],
        }),
        getGuest: builder.query({
            query: (id) => `/profile/${id}`,
            providesTags: ["Profile"],
        }),
        updateProfile: builder.mutation({
            query: (data) => ({
                url: data.id ? `profile/${data.id}` : 'profile',
                method: "PUT",
                body: data.formData,
            }),
            invalidatesTags: ["Profile"],
        }),

        changePassword: builder.mutation({
            query: (data) => ({
                url: `profile/${data.id}/change-password`, // Construct URL dynamically
                method: 'PUT',
                body:
                {
                    oldPassword: data.oldPassword,
                    newPassword: data.newPassword
                },
            }),
            invalidatesTags: ['Profile'], // Invalidate cache if necessary
        }),

        // updateProfileAddress: builder.mutation({
        //     query: (data) => ({
        //         url: data.id ? `profile/${data.id}/update-addresses` : `profile/update-addresses`,
        //         method: "PUT",
        //         body: data.formData,
        //     }),
        //     invalidatesTags: ["Profile"],
        // }),
        updateProfileAddress: builder.mutation({
            query: (data) => {
                const baseUrl = 'profile/update-addresses';
                const urlWithId = `profile/${data.id}/update-addresses`;

                return {
                    url: data.id ? urlWithId : baseUrl, // Use the correct URL
                    method: "PUT",
                    body: data.formData,
                };
            },
            invalidatesTags: ["Profile"],
        }),
        updateCartAddress: builder.mutation({
            query: (data) => {
                const baseUrl = `carts/${data?.cartId}/update-addresses`;
                const urlWithId = `carts/${data?.cartId}/update-addresses`;

                return {
                    url: data.id ? urlWithId : baseUrl, // Use the correct URL
                    method: "PUT",
                    body: data.formData,
                };
            },
            invalidatesTags: ["Profile"],
        }),
        updateProfileImage: builder.mutation({
            query: (data) => ({
                url: `profile/${data.id}/uploadProfilePic`,
                method: "POST",
                body: data.formData, 
            }),
            invalidatesTags: ["Profile"],
        }),
    }),
});

export const { useGetProfileQuery, useChangePasswordMutation, useUpdateProfileMutation, useUpdateProfileAddressMutation, useUpdateCartAddressMutation, useUpdateProfileImageMutation } = profileSlice;