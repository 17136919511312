// src/components/Header.js
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import OtpInput from "../OtpInput";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { useGetAddOnsQuery } from "../../redux/service/addonsSlice";
import { useSelector } from "react-redux";
import {
  useGetCartItemsQuery,
  useSubmitAddonsMutation,
} from "../../redux/service/cartSlice";
import { toast } from "material-react-toastify";

const AddOns = ({ isModalVisible, onClick, toggleModal, data }) => {
  const [ingredients, setIngredients] = useState([]);
  const { data: addOns } = useGetAddOnsQuery();

  useEffect(() => {
    if (data && addOns) {
      // Filter addOns based on matching addonName
      const matchingAddOns = addOns.filter((addOn) =>
        data.some((item) => item.addonName === addOn.name)
      );

      // Update state with matching addOns
      setIngredients(
        matchingAddOns.map((addOn) => ({
          name: addOn.name,
          quantity: data.find((item) => item.addonName === addOn.name)?.quantity || 0,
          ...addOn,
        }))
      );
    }
  }, [data, addOns]);



  const userInfo = useSelector((state) => state?.auth?.userInfo);
  const [submitAddons, { isLoading }] = useSubmitAddonsMutation();

  const { data: cartData } = useGetCartItemsQuery({
    cartType: "normal",
    userId: userInfo?.id,
  });

console.log("data",data)
console.log(ingredients)
console.log("addOns",addOns)

  const currency = useSelector((state) => state?.meta?.currency);



 
  const onIngredientsChange = (e) => {
    let _ingredients = [...ingredients];
    if (e.checked) {
      _ingredients.push({ ...e.value, quantity: 1 }); 
    } else {
      _ingredients = _ingredients.filter((item) => item.name !== e.value.name); 
    }
    setIngredients(_ingredients);
  };

 
  const handleQuantityChange = (addOn, change) => {
    const updatedIngredients = ingredients.map((item) =>
      item.name === addOn.name
        ? { ...item, quantity: Math.max(1, (item.quantity || 1) + change) } 
        : item
    );
    setIngredients(updatedIngredients);
  };

  
  const handleSubmit = async () => {
    await submitAddons({
      cartId: cartData?.data?.id,
      addons: {
        addons: ingredients.map((data) => ({
          addonId: data.id,
          quantity: data.quantity || 1,
        })),
      },
    }).then(() => {
      toast.success("Addons added successfully");
    });
    toggleModal(false);
  };

  return (
    <Dialog
      visible={isModalVisible}
      footer={true}
      // header="Verify OTP" // Added a header title here
      closable={true}
      className="custom-dialog"
      onHide={toggleModal}
      breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
      style={{ width: "32vw" }}
    >
      <div className="address-modal-inputs">
        <div className="address-topics">
          <h2 className="address-modal-title">Add-ons</h2>
          <div className="modal-devider"></div>
          {/* <span className='address-modal-description'>All fields are required to complete the delivery information. Please ensure all sections are filled out before submitting.</span> */}
        </div>



        <div className="w-full flex flex-col gap-3">
          {addOns?.map((addOn, index) => (
            <div className="flex sm:px-10 md:px-20 px-5 w-full justify-between  " key={index}>
              {/* Checkbox for add-on */}
              <div className="flex items-center">
                <Checkbox
                  inputId={addOn.name}
                  name={addOn.name}
                  value={addOn}
                  size={"small"}
                  onChange={onIngredientsChange}
                  checked={ingredients.some((item) => item.name === addOn.name)}
                />
                <label htmlFor={addOn.name} className="ml-2 w-[80px] md:w-[120px] text-[12px] md:text-[15px]">
                  {addOn.name}
                </label>
              </div>

              {/* Price and Quantity Controls in one line */}
              <div className="flex items-center w-full justify-between">
                <label className="price-label text-[12px] md:text-[15px]">
                  {currency}{" "}
                  {currency === "LKR"
                    ? new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      addOn.priceInRupee * (ingredients.find((item) => item.name === addOn.name)?.quantity || 1)
                    )
                    : new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      addOn.priceInDollar * (ingredients.find((item) => item.name === addOn.name)?.quantity || 1)
                    )}
                </label>
                
                <div className="quantity-control pl-0 ml-0">
                  <button
                    className="quantity-btn decrease-btn  md:mr-1 text-[12px] md:text-[15px]"
                    onClick={() => handleQuantityChange(addOn, -1)}
                    disabled={!ingredients.some((item) => item.name === addOn.name)}
                  >
                    -
                  </button>
                  <label className="border px-2 justify-center flex items-center text-[12px] md:text-[15px]">
                   
                    {ingredients.find((item) => item.name === addOn.name)?.quantity || 1}
                  </label>
                  <button
                    className="quantity-btn increase-btn md:ml-1 text-[12px] md:text-[15px]"
                    onClick={() => handleQuantityChange(addOn, 1)}
                    disabled={!ingredients.some((item) => item.name === addOn.name)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-10 flex">
          <Button
            label="Save"
            loading={isLoading}
            className="bill-address-save-btn py-1"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Dialog >
  );
};

export default AddOns;
