// src/pages/AboutPage.js
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React from 'react';

const Return = () => {
    return (
        <div className="p-4 md:mx-12 md:px-12">
            <Card title={<span className="text-3xl uppercase text-[var(--color)] bg-transparent" >Return</span>} className="mb-4 md:mx-12 md:px-12 font-10 border-none shadow-none">

                <p> Return </p>
                <p> Our website offers an exclusive product portfolio extending from Wines, to Whiskeys, to Brandies, to Arracks and many other genres of spirits sourced from all over the world, which could now be received by you right on to your door step in 2 to 7 days, anywhere in the island! </p>
                <h2 className="text-lg font-bold mt-4">Find out more on Concierge services</h2>
                <p>The services we offer include;</p>
                <ul className="list-disc ml-6">
                    <li> Concierge Services for premium wines and spirits – WineWorld.lk does not in any way have legal ownership of the items sold on its digital platforms and all payments made through online purchases are directly paid to the vendor of the stocks. We are a mere concierge service and the only income recognized by us is the charge for the concierge service. </li>
                    <li> Bar Services – We provide a full bar service for corporate and social functions, offering expert advice and creative solutions to diverse requirements. Over the years we have been privileged to partner with events hosted by RedBull, HSBC, Colombo Fashion Week, and many more esteemed organizations. </li>
                    <li> We curate premium spirits and wine evenings conducted by local and international sommeliers. </li>
                    <li> Hampers & Gifting Solutions – Leave it to our team to customize your order to suit your requirement. We provide tailor made corporate hampers for all occasions with no hidden charges. </li>
                </ul>
                {/* <p className="mt-4"> To read more about our special services
                    <a href="#" className="text-blue-500">CLICK HERE.</a> </p> */}
                {/* <Button label="Start Shopping Now!" className="mt-4 bg-green-500 border-none text-white rounded cursor-pointer hover:bg-green-600" /> */}
            </Card>
        </div>
    );
};

export default Return;
