import { apiSlice } from "./apiSlice";

export const complainSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // createOrder: builder.mutation({
    //   query: (query) => {
    //     const { id, userId, sessionId, cityId } = query;

    //     console.log("User ID:", userId);
    //     console.log("Session ID:", sessionId);
    //     console.log("City ID:", cityId);
    //     // Construct the query string parameters
    //     const params = new URLSearchParams();
    //     if (userId) params.append("userId", userId);
    //     if (sessionId) params.append("sessionId", sessionId);
    //     if (cityId) params.append("cityId", cityId);

    //     return {
    //       url: `/orders/create/${id}?${params.toString()}`,
    //       method: "POST",
    //       body: query,
    //     };
    //   },
    // }),
    // getAllOrder: builder.query({
    //   query: (query) => ({
    //     url: `/orders/user?${query.userId && `userId=${query.userId}`}${query.sessionId && `&sessionId=${query.sessionId}`}}`,
    //     method: "GET",
    //   }),
    // }),
    getAllComplains: builder.query({
      query: (query) => {
        const queryString = [
          `page=${query.page || 0}`,
          query.size && `size=${query.size}`,
          query.userId && `userId=${query.userId}`
          
        ]
          .filter(Boolean) // Remove any undefined or null values
          .join("&"); // Join the query parameters with "&"

        return {
          url: `complaints/allComplaints?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Complains"]
    }),
    closeTicket: builder.mutation({
      query: (query) => {
        const queryString = [
          `status= 'CLOSED'}`, // Add status as query parameter
          query.ticketId && `ticketId=${query.ticketId}`, // You can optionally pass a ticketId
        ]
          .filter(Boolean) // Remove any undefined or null values
          .join("&"); // Join the query parameters with "&"

        return {
          url: `complaints/${query.ticketId}/closeTicket?status=CLOSED`, // Use ticketId dynamically
          method: "POST",
        };
      },
      invalidatesTags: ["Complains"],
    }),

  }),
});

export const { useGetAllComplainsQuery, useCloseTicketMutation } = complainSlice;
