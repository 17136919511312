// src/components/Header.js
import React, { useState } from 'react';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import logo from './../icons/logo.png';
import whiteLogo from './../icons/whiteLogo.png';
import { InputText } from 'primereact/inputtext';

const ResetPassword = ({ isModalVisible, onClick, isDarkTheme, toggleModal }) => {


    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');


    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError(''); 
    };


    return (
        <Dialog
            visible={isModalVisible}
            footer={true}
            
            closable={true}
            className="custom-dialog"
            onHide={toggleModal}
            breakpoints={{ '960px': '70vw', '640px': '100vw', '576px': '90vw'}}
            style={{ width: '30vw' }}
        >
            <div className="verify-modal-inputs">
                <img src={isDarkTheme ? whiteLogo : logo} alt="Wine World Logo" className="login-logo" />

                <span className='verify-modal-description m-0'>Please enter your new password.</span>


                <div className="input-group mt-0">
                    <label className="ml-0 mb-0 mt-1 text-left">Password</label>

                    <InputText
                        id="Password"
                        type="password"
                        placeholder="Please enter your password"
                        className="login-modal-inputs"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    {passwordError && <small className="error-text">{passwordError}</small>}
                </div>

                <div className="input-group">
                    <label className="ml-0 mb-0 mt-1 text-left">Confirm Password</label>
                    <InputText
                        id="Password"
                        type="password"
                        placeholder="Please confirm your password"
                        className="login-modal-inputs"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    {passwordError && <small className="error-text">{passwordError}</small>}
                </div>
                <div className="modal-footer mt-5">
                    <Button label="Next" className="login-confirm-btn py-2" onClick={toggleModal} />
                </div>
            </div>
        </Dialog>
    );
};

export default ResetPassword;
