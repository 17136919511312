// src/components/Header.js
import React, { useMemo, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import logo from "./../icons/logo.png";
import CartDelete from "./../icons/DeleteCart.png";
import whiteLogo from "./../icons/whiteLogo.png";
import Empty_cart from "./../Images/Empty_cart.png";
import { Checkbox } from "primereact/checkbox";

import {
  logout,
  removeCredentials,
  setCredentials,
  setUser,
  setUserSession,
} from "../features/authSlice";
import { Badge } from "primereact/badge";
import { Sidebar } from "primereact/sidebar";

import { useLocation, useNavigate } from "react-router-dom";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import VerifyOtp from "./verifyOtp";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { FaAngleDown } from "react-icons/fa";
import { ProgressSpinner } from "primereact/progressspinner";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { IoMdArrowDropright } from "react-icons/io";

import {
  useLoginMutation,
  useRegisterMutation,
  useReSendOtpMutation,
} from "../redux/service/authSlice";
import { toast } from "material-react-toastify";

import ClipLoader from "react-spinners/ClipLoader";
import { removeCity, setCurrency } from "../features/metaSlice";
import {
  useDeleteCartItemMutation,
  useDeleteCartMutation,
  useGetCartItemsQuery,
  useUpdateCartItemMutation,
} from "../redux/service/cartSlice";

import { useGetAllDepartmentQuery } from "../redux/service/departmentSlice";
import * as Yup from "yup";
import { BASE_URL } from "../redux/service/apiSlice";
import axios from "axios";

import VerifyPasswordOtp from "./verifyPasswordOtp";
import Search from "./Search";
import { useGetProductHierarchyQuery } from "../redux/service/productSlice";

export const countries = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia (Czech Republic)",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (North)",
  "Korea (South)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao PDR",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "North Macedonia",
  "Romania",
  "Russia",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic (Syria)",
  "Taiwan, Republic of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const Header = ({ toggleTheme, isDarkTheme }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showSignPassword, setShowSignPassword] = useState(false); // State to toggle password visibility
  const [showSignConfirmPassword, setShowSignConfirmPassword] = useState(false); // State to toggle password visibility
  const [selectedCountry, SetSelectedCountry] = useState("Sri Lanka");
  const user = useSelector((state) => state?.auth?.userInfo);
  // const [cartCount, setCartCount] = useState(0);
  const [remember, setRemember] = useState(false);
  const cityId = useSelector((state) => state?.meta?.city);
  const currency = useSelector((state) => state?.meta?.currency);

  const location = useLocation();
  const dateFormat = "mm/dd/yy";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSignModalVisible, setIsSignModalVisible] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isVerifyVisible, setIsVerifyVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isResetVisible, setIsResetVisible] = useState(false);
  const [isForgotVisible, setIsForogtVisible] = useState(false);
  const [isVisibleSignOut, setIsVisibleSignOut] = useState(false);
  const [termsAC, setTermsAC] = useState(false);
  const [isResetPasswordVisible, setIsResetPasswordVisible] = useState(false);

  const newAdditions = useSelector((state) => state.HomePage.NewAdditions);
  const profileMenu = useRef(null);
  const [logedin, setLogedin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nic, setNic] = useState("");
  const [birthday, setBirthday] = useState("");
  const [birthdayError, setBirthdayError] = useState("");

  const [locationError, setLocationError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [gender, setGender] = useState("");
  const [maleBirthDay, setMaleBirthday] = useState(false);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [errors, setErrors] = useState({});
  const userInfo = useSelector((state) => state?.auth?.userInfo);
  const [timer, setTimer] = useState(300); // 2 minutes in seconds
  const [formData, setFormData] = useState({});
  const theme = useSelector((state) => state.theme.theme);

  const { data: department } = useGetAllDepartmentQuery();
  const [register, { isLoading: registerLoading }] = useRegisterMutation();
  const { data: cartData, refetch: cartReFetch } = useGetCartItemsQuery({
    cartType: "normal",
    userId: userInfo?.id,
  });


  const [reSendOtp] = useReSendOtpMutation();
  useEffect(() => {
    setErrors({});
  }, [isSignModalVisible, isModalVisible]);

  useEffect(() => {
    // console.log("location.pathname", location.pathname);

    if (
      location.pathname === "/my-account/" ||
      location.pathname === "/cart/" ||
      location.pathname === "/WishList" ||
      location.pathname === "/OrderHistory" ||
      location.pathname === "/Complaint" ||
      location.pathname === "/OrderDetails/:id" ||
      location.pathname === "/payment/return"
    ) {
      if (userInfo?.id) {
        const fetchProfile = async () => {
          try {
            const response = await axios.get(
              BASE_URL + "/profile/" + userInfo?.id
            );
            // setProfile(response.data); // Assuming the response contains the profile data
          } catch (error) {
            if (error.response && error.response.status === 404) {
              // console.log("Not logged in");
              toast.error(
                "Your session has expired. Please log in again to continue."
              );
              navigate("/");
              setIsModalVisible(true);
              dispatch(logout());
            } else {
              console.error("Error fetching the profile:", error);
            }
          }
        };

        fetchProfile();
      } else {
        // console.log("Not logged in");
        toast.error(
          "Your session has expired. Please log in again to continue."
        );
        navigate("/");
        setIsModalVisible(true);
        dispatch(logout());
      }
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (nic.length === 10 || nic.length === 12) {
  //     let year, dayOfYear;

  //     if (nic.length === 10) {
  //       year = parseInt("19" + nic.slice(0, 2));
  //       dayOfYear = parseInt(nic.slice(2, 5));
  //     } else if (nic.length === 12) {
  //       year = parseInt(nic.slice(0, 4));
  //       dayOfYear = parseInt(nic.slice(4, 7));
  //     }

  //     if (dayOfYear > 500) {
  //       setGender("female");
  //       dayOfYear = 500;
  //     } else {
  //       setGender("male");
  //     }

  //     const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  //     console.log("isLeapYear", isLeapYear);
  //     console.log("dayOfYear", dayOfYear);
  //     if (isLeapYear && dayOfYear < 59) {
  //       dayOfYear += 1;
  //       console.log("dayOfYear1", dayOfYear);

  //     }

  //     const birthDate = new Date(year, 0, dayOfYear);
  //     const formattedDate = birthDate.toISOString().split("T")[0];
  //     const today = new Date();
  //     const age = today.getFullYear() - birthDate.getFullYear();
  //     const monthDifference = today.getMonth() - birthDate.getMonth();
  //     const dayDifference = today.getDate() - birthDate.getDate();

  //     if (age < 21 || (age === 21 && (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)))) {
  //       toast.error("You must be at least 21 years old for registration.");
  //       setMaleBirthday(false);
  //     } else {
  //       setMaleBirthday(true);
  //       setBirthday(formattedDate);
  //     }

  //     if (formattedDate) {
  //       setMaleBirthday(true);
  //     }
  //   } else {
  //     setBirthday("");
  //     setGender("");
  //   }
  // }, [nic]);

  const credentials = useSelector((state) => state.auth);

  useEffect(() => {
    if (isModalVisible) {
      setEmail(credentials.email || email);
      setPassword(credentials.password || password);

      if (credentials.email && credentials.password) {
        setRemember(true);
      }
    } else {

      setPassword("");
      setName("");
      setNic("");
      setBirthday("");
      setAddress("");
      setGender("");
      setConfirmPassword("");
    }
  }, [isModalVisible, isSignModalVisible]);

  const [deleteCart, { isLoading: deleteCartLoading }] =
    useDeleteCartMutation();

  const deleteAll = async () => {
    try {
      await deleteCart({
        userId: user?.id,
        cartType: "normal",
      }).unwrap();


      cartReFetch();
      toast.success("Successfully cleared the cart.");
    } catch (err) {
      toast.error(err.data?.message || "Failed to delete item");
      console.error(err);
    }
  };

  useEffect(() => {
    if (nic.length === 10 || nic.length === 12) {
      let year, dayOfYear;

      if (nic.length === 10) {
        year = parseInt("19" + nic.slice(0, 2));
        dayOfYear = parseInt(nic.slice(2, 5));
      } else if (nic.length === 12) {
        year = parseInt(nic.slice(0, 4));
        dayOfYear = parseInt(nic.slice(4, 7));
      }

      if (dayOfYear > 500) {
        setGender("female");
        dayOfYear -= 500;
      } else {
        setGender("male");
      }
      const isLeapYear =
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

      if (isLeapYear) {
        dayOfYear += 1;
        console.log("dayOfYear1", dayOfYear);
      }
      const birthDate = new Date(year, 0, dayOfYear);
      const formattedDate = birthDate.toISOString().split("T")[0];
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      const dayDifference = today.getDate() - birthDate.getDate();

      if (
        age < 21 ||
        (age === 21 &&
          (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)))
      ) {
        toast.error("You must be at least 21 years old for registration.");
        setMaleBirthday(false); // Resetting as underage
      } else {
        setMaleBirthday(true); // Valid as 18+

        // console.log("isLeapYear", isLeapYear);
        // console.log("dayOfYear", dayOfYear);

        setBirthday(formattedDate);
      }

      if (formattedDate) {
        setMaleBirthday(true);
      }
    } else {
      setBirthday("");
      setGender("");
    }
  }, [nic]);

  // console.log(gender, birthday);

  const today = new Date();
  const maxSelectableDate = new Date(
    today.getFullYear() - 21,
    today.getMonth(),
    today.getDate()
  );

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowSignPassword = () => {
    setShowSignPassword(!showSignPassword);
  };
  console.log(birthday);
  const validationSchema = Yup.object().shape({
    ...(isSignModalVisible && {
      name: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces")
        .max(50, "Name must not exceed 50 characters")
        .min(3, "Name must be at least 3 characters")
        .required("Name is required"),

      email: Yup.string()
        .email("Invalid email address")
        .max(254, "Email must not exceed 254 characters")
        .min(3, "Email must be at least 3 characters")
        .required("Email is required"),
      nic: Yup.string()
        .nullable()
        .notRequired()
        .test(
          "len",
          "Invalid NIC. Please enter a valid NIC",
          (val) =>
            !val || // Allow empty/null values
            (val.trim() !== "" && (val.length === 10 || val.length === 12)) // Check length
        )
        .matches(
          /^[0-9]{9}[VXvx]?|^[0-9]{12}$/,
          "Invalid NIC. Please enter a valid NIC"
        ),
      birthday: Yup.string().required("Birthday is required"),
      address: Yup.string().required("Address is required"),
      gender: Yup.string().required("Gender is required"),
      // selectedCountry: Yup.string().required("Country is required"),
      number: Yup.string()
        .required("Phone Number is required")
        .matches(
          /^\+?\d+$/,
          "Phone Number must contain only numbers and may start with a '+'"
        )
        .min(10, "Phone Number must be at least 10 digits")
        .max(15, "Phone Number must not exceed 15 digits"),
      password: Yup.string()
        .matches(
          /[@$!%*?&#]/,
          "Password must contain at least one special character"
        )
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .max(50, "Password must not exceed 50 characters")
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
      termsAC: Yup.bool().oneOf(
        [true],
        "Please accept the terms and conditions"
      ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords do not match")
        .min(8, "Password must be at least 8 characters")
        .max(50, "Password must not exceed 50 characters")
        .required("Confirm Password is required"),
    }),
    ...(isModalVisible && {
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
  });

  const sidebarDark = [
    {
      label: (
        <span onClick={toggleTheme}>
          {!isDarkTheme ? "DARK MODE" : "LIGHT MODE"}
        </span>
      ),
      icon: `pi ${!isDarkTheme ? "pi-moon" : "pi-sun"}`,
      link: "/",
      command: toggleTheme, // Trigger the toggle on click
    },
  ];

  const registerHandler = async () => {
    console.log("testvalid", selectedCountry);

    if (selectedCountry === "Sri Lanka") {
      setLocationError("Location is mandatory");
    } else {
      setLocationError("");
    }

    try {
      await validationSchema.validate(
        {
          email,
          password,
          birthday,
          address,
          nic: nic || undefined,
          gender,
          confirmPassword,
          termsAC,
          name,
          number,
        },
        { abortEarly: false }
      );

      function validateGenderAgainstNIC(nic, gender) {
        let julianDay;
        if (nic.length === 10) {
          julianDay = parseInt(nic.slice(2, 5));
        } else if (nic.length === 12) {
          julianDay = parseInt(nic.slice(4, 7));
        } else {
          return true;
        }

        const inferredGender = julianDay > 500 ? "female" : "male";

        if (inferredGender !== gender) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            gender: "Gender does not match NIC",
          }));
          return true;
        }

        return false;
      }

      function validateBirthdayAgainstNIC(nic, birthday) {
        let julianDay;

        if (nic.length === 10) {
          julianDay = parseInt(nic.slice(2, 5));
        } else if (nic.length === 12) {
          julianDay = parseInt(nic.slice(4, 7));
        } else {
          return true;
        }

        if (julianDay > 500) {
          julianDay -= 500;
        }

        const birthYear =
          nic.length === 10
            ? 1900 + parseInt(nic.slice(0, 2))
            : parseInt(nic.slice(0, 4));

        const inferredDate = new Date(birthYear, 0, julianDay);
        const providedDate = new Date(birthday);

        if (inferredDate.getFullYear() !== providedDate.getFullYear()) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            birthday: "Birthday does not match NIC",
          }));
          return true;
        }

        return false;
      }

      const genderError = validateGenderAgainstNIC(nic, gender);
      const birthdayError = validateBirthdayAgainstNIC(nic, birthday);

      if ((!genderError && !birthdayError) || !nic) {
        setErrors({});
        await register({
          email,
          password,
          birthday,
          address,
          nic,
          gender,
          name: name,
          username: name,
          contactNumbers: number?.replace("+", ""),
        })
          .unwrap()
          .then((res) => {
            // toast.success("Registration successful");
            // setEmail("");
            setPassword("");
            toggleSignupModal();
            setIsVerifyVisible(true);
          })
          .catch((err) => {
            toast.error(err?.data?.message || "Something went wrong");
            console.log(err);
          });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
        console.log(validationErrors);
      }
    }
  };

  const [login, { isLoading }] = useLoginMutation();
  const [deleteItem, { isLoading: deleteItemLoading }] =
    useDeleteCartItemMutation();
  // const cityId = localStorage.getItem("city");
  // 

  const [updateCartItem, { isLoading: updateCartItemLoading }] =
    useUpdateCartItemMutation();

  const { refetch } = useGetCartItemsQuery({
    userId: userInfo?.id,
    cartType: "normal",
  });

  const updateHandler = async (data) => {
    // console.log(data.type, data.id, data.quantity);

    await updateCartItem({
      id: data?.id,
      userId: user?.id,
      sessionId: localStorage.getItem("sessionId")
        ? localStorage.getItem("sessionId")
        : undefined,
      cityId: cityId
        ? cityId
        : undefined,
      quantity:
        data.type === "plus"
          ? data.quantity + 1
          : data.quantity <= 1
            ? 1
            : data.quantity - 1,
    })
      .unwrap()
      .then((res) => {
        // console.log("Successfully updated item");
        refetch();
      })
      .catch((err) => {
        toast.error(`${err.data.message}`);
        console.log(err);
      });
  };

  const deleteItems = async ({ itemId }) => {
    // console.log("Attempting to delete item", itemId);

    await deleteItem({ itemId, cityId, userId: userInfo?.id })
      .unwrap()
      .then((res) => {
        // console.log("Successfully deleted item");
        refetch();
      })
      .catch((err) => {
        toast.error(`${err.data.message}`);
        console.log(err);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission behavior if applicable
      loginLogic(); // Call the login function
    }
  };

  const toggleMenuVisible = (visible) => {
    setIsMenuVisible(visible);
  };

  const departmentItems = department?.map((item) => {
    if (!item.categories || item.categories.length === 0) {
      return {
        label: (
          <span
            className={`headerTitleType2 uppercase ${location.pathname.split("/").pop() === `${item.departmertId}`
              ? "active"
              : ""
              }`}
          >
            {item.department}
          </span>
        ),
        command: () => {
          navigate(`product-category/${item.departmertId}`);
          window.scrollTo(0, 0);
        },
      };
    }

    return {
      label: (
        <span
          className={`headerTitleType2 uppercase  ${location.pathname.split("/").pop() === `${item.departmertId}`
            ? "active"
            : ""
            }`}
        >
          {item.department}
        </span>
      ),
      command: () => {
        navigate(`product-category/${item.departmertId}`);
        window.scrollTo(0, 0);
      },
      items: item.categories.map((child) => ({
        label: (
          <span
            className={`headerTitleType3 ${location.pathname ===
              `product-category/${item.departmertId}/${child.categoryId}`
              ? "active"
              : ""
              }`}
          >
            {child.catrgory}
          </span>
        ),
        command: () => {
          navigate(`product-category/${item.departmertId}/${child.categoryId}`);
          window.scrollTo(0, 0);
        },
        items: child?.brands?.map((brands) => ({
          label: (
            <span
              className={`headerTitleType4 ${location.pathname ===
                `product-category/${item.departmertId}/${child.categoryId}/${brands.brandId}`
                ? "active"
                : ""
                }`}
            >
              {brands.brand}
            </span>
          ),
          command: () => {
            navigate(
              `product-category/${item.departmertId}/${child.categoryId}/${brands.brandId}`
            );
            window.scrollTo(0, 0);
          },
        })),
      })),
    };
  });

  const items = [
    {
      label: (
        <span
          className={
            location.pathname === "/for-weddings/"
              ? "headerTitleType3 active"
              : "headerTitleType3"
          }
        >
          WEDDINGS
        </span>
      ),
      command: () => {
        navigate("/for-weddings/");
        window.scrollTo(0, 0);
      },
    },
    {
      label: (
        <span
          className={
            location.pathname === "/for-corporates/"
              ? "headerTitleType3 active"
              : "headerTitleType3"
          }
        >
          CORPORATES
        </span>
      ),
      command: () => {
        navigate("/for-corporates/");
        window.scrollTo(0, 0);
      },
    },
    {
      label: (
        <span
          className={
            location.pathname === "/event-and-services/"
              ? "headerTitleType3 active"
              : "headerTitleType3"
          }
        >
          SERVICES
        </span>
      ),
      command: () => {
        navigate("/event-and-services/");
        window.scrollTo(0, 0);
      },
    },
    {
      label: (
        <span
          className={
            location.pathname === "/blog"
              ? "headerTitleType3 active"
              : "headerTitleType3"
          }
        >
          BLOG
        </span>
      ),
      command: () => {
        navigate("/blog");
        window.scrollTo(0, 0);
      },
    },
  ];
  const depitems = [
    {
      label: (
        <span
          className={`headerTitleType3 ${location.pathname === "/" ? "active" : ""
            }`}
        >
          HOME
        </span>
      ),
      command: () => {
        navigate("/");
        window.scrollTo(0, 0);
      },
    },

    ...(departmentItems || []),
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sidebarOptions = [
    {
      label: "Profile",
      icon: "pi pi-user",
      link: "/profile",
      command: () => {
        navigate("/my-account/");
        window.scrollTo(0, 0);
      },
    },
    {
      label: "Cart",
      icon: "pi pi-shopping-cart",
      link: "#",
      command: () => {
        navigate("/cart");
        window.scrollTo(0, 0);
      },
    },
    {
      label: "Favorites",
      icon: "pi pi-heart",
      link: "#",
      command: () => {
        navigate("#");
        window.scrollTo(0, 0);
      },
    },
    {
      label: "Complaint Management",
      icon: "pi pi-comments",
      link: "#",
      command: () => {
        navigate("/Complaint");
        window.scrollTo(0, 0);
      },
    },
    {
      label: "Order History",
      icon: "pi pi-clock",
      link: "#",
      command: () => {
        navigate("/OrderHistory");
        window.scrollTo(0, 0);
      },
    },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      link: "#",
      command: () => {
        setLogedin(false);

        setIsVisibleSignOut(true);
      },
    },
  ];

  const cartItems = cartData?.data?.cartItems || [];

  const cartCount = cartItems.length;

  const CartItem = ({ item, minus, plus, loading, deleteItemLoading }) => {
    return (
      <div className="side-cart-item">
        {/* Image and Details Section */}
        <div className="side-item-left">
          <img
            src={item?.product?.imageUrl}
            alt={item?.name}
            className="item-image mr-2"
          />
        </div>

        <div className="side-item-rght">
          <div className="side-item-details">
            <div className="side-item-details-left">
              <div className="side-item-name">{item?.product.correctName}</div>
              <div className="side-item-price">
                {currency}{" "}
                {currency === "LKR"
                  ? item?.product.regularPriceInRupee
                    ?.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : item?.product.regularPriceInDollar
                    ?.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
              <div className="item-type">{item.type}</div>
            </div>
            <button
              onClick={() => deleteItems({ itemId: item?.itemId })}
              className={` me-0 text-red-400 item-center justify-center flex ${deleteItemLoading && "opacity-50 cursor-progress"
                }`}
              disabled={deleteItemLoading}
            >
              <img className="w-6" src={CartDelete} alt="" />
            </button>
          </div>

          <div className="side-item-controls">
            <button
              onClick={minus}
              disabled={loading}
              className={`quantity-btn cursor-pointer ${loading && "opacity-50 cursor-progress"
                }`}
            >
              -
            </button>
            <input
              type="text"
              value={item?.quantity}
              readOnly
              className="quantity-input"
            />
            <button
              onClick={plus}
              disabled={loading}
              className={`quantity-btn cursor-pointer ${loading && "opacity-50 cursor-progress"
                }`}
            >
              +
            </button>
          </div>
        </div>

        {/* Quantity Selector and Delete Icon */}
      </div>
    );
  };

  const profileItems = [
    {
      label: "Profile Details",
      items: [
        {
          label: (
            <div className="profile-menu-text">
              <i
                className="pi pi-user "
                style={{ fontSize: "1rem", marginRight: "20px" }}
              ></i>
              <span>Profile</span>
            </div>
          ),
          command: () => {
            navigate("/my-account/");
            window.scrollTo(0, 0);
          },
        },
        // {
        //   label: (
        //     <div className="profile-menu-text">
        //       <i
        //         className="pi pi-shopping-cart "
        //         style={{ fontSize: "1rem", marginRight: "20px" }}
        //       ></i>
        //       <span>Cart</span>
        //     </div>
        //   ),
        //   command: () => {
        //     navigate("/cart/");
        //     window.scrollTo(0, 0);
        //   },
        // },
        ...(cartData?.data?.cartItems?.length > 0
          ? [
            {
              label: (
                <div className="profile-menu-text">
                  <i
                    className="pi pi-shopping-cart "
                    style={{ fontSize: "1rem", marginRight: "20px" }}
                  ></i>
                  <span>Cart</span>
                </div>
              ),
              command: () => {
                navigate("/cart/");
                window.scrollTo(0, 0);
              },
            },
          ]
          : []),
        // {
        //   label: (
        //     <div className="profile-menu-text">
        //       <i
        //         className="pi pi-bookmark "
        //         style={{ fontSize: "1rem", marginRight: "20px" }}
        //       ></i>
        //       <span>Wish List</span>
        //     </div>
        //   ),
        //   command: () => {
        //     navigate("/Favourite");
        //     window.scrollTo(0, 0);
        //   },
        // },
        {
          label: (
            <div className="profile-menu-text">
              <i
                className="pi pi-heart "
                style={{ fontSize: "1rem", marginRight: "20px" }}
              ></i>
              <span>Favourite</span>
            </div>
          ),
          command: () => {
            navigate("/Favourite");
            window.scrollTo(0, 0);
          },
        },
        {
          label: (
            <div className="profile-menu-text">
              <i
                className="pi pi-comments"
                style={{ fontSize: "1rem", marginRight: "20px" }}
              ></i>
              Complaint Management
            </div>
          ),
          command: () => {
            navigate("/Complaint");
            window.scrollTo(0, 0);
          },
        },
      ],
    },
    {
      label: "Order Details",
      items: [
        {
          label: (
            <div className="profile-menu-text">
              <i
                className="pi pi-history"
                style={{ fontSize: "1rem", marginRight: "20px" }}
              ></i>
              Order History
            </div>
          ),
          command: () => {
            navigate("/OrderHistory");
            window.scrollTo(0, 0);
          },
        },
        // {
        //   label: (
        //     <div className="profile-menu-text">
        //       <i
        //         className="pi pi-truck"
        //         style={{ fontSize: "1rem", marginRight: "20px" }}
        //       ></i>
        //       Delivery
        //     </div>
        //   ),
        //   command: () => {
        //     navigate("/delivery");
        //     window.scrollTo(0, 0);
        //   },
        // },
        {
          label: (
            <div
              className="profile-menu-text w-[220px] h-[20px]"
              onClick={() => {
                setIsVisibleSignOut(true);
              }}
            >
              <i
                className="pi pi-sign-out"
                style={{ fontSize: "1rem", marginRight: "20px" }}
              ></i>
              Logout
            </div>
          ),
          command: () => setLogedin(false),
        },
      ],
    },
  ];

  const toggleModal = () => {
    setIsModalVisible(false);
    setEmail("");
    setPassword("");
    setEmailError("");
    setPasswordError("");
  };

  const toggleVerifyModal = () => {
    setIsVerifyVisible((prev) => !prev);
    setIsResetVisible(true);
    // console.log("working");
  };

  const toggleSearchModal = () => {
    setIsSearchVisible((prev) => !prev);

    console.log("working");
  };

  const toggleResetModal = () => {
    setIsResetVisible((prev) => !prev);
  };

  const toggleForgotModal = () => {
    setIsModalVisible(false);
    setIsForogtVisible((prev) => !prev);
  };

  const forgotNext = () => {
    setIsForogtVisible(false);
    setIsVerifyVisible(true);
  };
  const VerifyNext = () => {
    setIsVerifyVisible(false);
    setIsResetVisible(true);
  };

  const toggleSignupModal = () => {
    setIsSignModalVisible(false);
    // setEmail("");
    setPassword("");
    setEmailError("");
    setPasswordError("");
    setName("");
    setBirthday("");
    setBirthdayError("");
    setAddressError("");
    setAddress("");
    setGender("");
    setNic("");
  };

  const toggleCartModal = () => {
    setIsCartVisible(false);
  };

  const handleBirthdayChange = (e) => {
    setBirthday(e.value);
    setBirthdayError("");
    setErrors((prev) => ({ ...prev, birthday: "" }));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear error when user starts typing
    setErrors((prev) => ({ ...prev, email: "" }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(""); // Clear error when user starts typing
    setErrors((prev) => ({ ...prev, password: "" }));
  };

  const reSendOtpHandler = async () => {
    setIsModalVisible(false);

    setIsVerifyVisible(true);

    await reSendOtp({ email, contact: number })
      .unwrap()
      .then((res) => {
        setTimer(300);
        toast.success("OTP sent successfully");
        setFormData("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message || "Something went wrong");
      });
  };

  async function loginLogic() {
    try {
      await validationSchema.validate(
        {
          email,
          password,
        },
        { abortEarly: false }
      );
      setErrors({});
      await login({ email, password })
        .unwrap()
        .then((res) => {
          if (res?.data?.verified == false) {
            reSendOtpHandler();

            // setIsVerifyVisible(true);
          } else {
            toast.success("Login successful");

            if (remember) {
              dispatch(setCredentials({ email, password }));
            } else {
              dispatch(removeCredentials({ email, password }));
            }

            dispatch(setUserSession(res?.data));

            setEmail("");
            setPassword("");
            toggleModal();
          }
        })
        .catch((err) => {
          toast.error(`${err?.data?.message || "Something went wrong"}`);
          console.log(err);
        });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      }
    }
  }

  const setCartVisible = () => {
    if (!user) {
      return toast.error("Please login to the system before adding to cart");
    }
    setIsCartVisible(true);
  };

  return (
    <div className="sticky-header shadow-sm md:shadow-md">
      {isVisible && (
        <div className="delivery-message-header">
          <div className="overflow-hidden relative w-3/4">
            <span className="whitespace-nowrap text-xs md:text-md sliding-text">
              Deliveries within Colombo will be fulfilled within 1 working day,
              and deliveries outside Colombo will be fulfilled within 1-2
              working days.
            </span>

          </div>

          <i
            className="pi pi-times"
            onClick={() => {
              setIsVisible(false);
            }}
          ></i>
        </div>
      )}
      <div className="header-content  ">
        {/* Login Modal */}
        <Dialog
          visible={isModalVisible}
          footer={true}
          header={true}
          closable={true}
          className="custom-dialog" // Add custom className here
          onHide={toggleModal}
          breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
          style={{ width: "30vw" }}
          onKeyDown={(e) => handleKeyPress(e)}
        >
          <div className="login-modal-wrap">
            <img
              src={isDarkTheme ? whiteLogo : logo}
              alt="Wine World Logo"
              className="login-logo"
            />

            <div className="input-group">
              <label className="ml-0 mb-0">Email</label>

              <InputText
                id="Email"
                placeholder="Please enter your email"
                className="login-modal-inputs"
                value={email}
                onChange={async (e) => {
                  const newValue = e.target.value;
                  setEmail(newValue);
                  setErrors((prev) => ({ ...prev, email: "" }));
                  try {
                    await validationSchema.validateAt("email", {
                      email: newValue,
                    });
                    setErrors((prev) => ({ ...prev, email: "" }));
                  } catch (err) {
                    setErrors((prev) => ({
                      ...prev,
                      email: err.message,
                    }));
                  }
                }}
              />
              {errors.email && (
                <small className="error-text">{errors.email}</small>
              )}
            </div>
            <div className="input-group">
              <label className="ml-0 mb-0">Password</label>
              <div className="relative w-full ">
                <InputText
                  id="Password"
                  type={showPassword ? "text" : "password"} // Toggle input type between "text" and "password"
                  placeholder="Please enter your password"
                  className="login-modal-inputs w-full" // Add padding-right for space for the icon
                  value={password}
                  onChange={handlePasswordChange}
                />
                {/* Eye icon for show/hide password */}
                <span
                  onClick={toggleShowPassword}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer item-center justify-center"
                >
                  {!showPassword ? (
                    <i
                      className="pi pi-eye-slash"
                      style={{ fontSize: "1.3rem" }}
                    />
                  ) : (
                    <i className="pi pi-eye" style={{ fontSize: "1.3rem" }} />
                  )}
                </span>
              </div>

              {errors.password && (
                <small className="error-text">{errors.password}</small>
              )}
              <div className="flex justify-between">
                <div className="flex h-5 gap-3  justify-center mt-[10px]">
                  <Checkbox
                    checked={remember}
                    onChange={(e) => setRemember(!remember)}
                    className="mt-[2px]"
                  ></Checkbox>
                  <p>Remember me?</p>
                </div>

                <span
                  onClick={toggleForgotModal}
                  style={{
                    textAlign: "end",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  className="hover:underline"
                >
                  Forgot password?
                </span>
              </div>
            </div>
            <div className="modal-footer">
              {/* <Button label="Order History" className="cancel-btn" onClick={orderHistory} /> */}
              {/* <Button
                label={`${isLoading ? "Loading..." : "Sign In"}`}
                className="login-confirm-btn py-2 mt-5 mb-2"
                onClick={loginLogic}
              /> */}
              <Button
                className="login-confirm-btn py-2 mt-5 mb-2 flex items-center justify-center"
                onClick={loginLogic}
                disabled={isLoading} // Disable button while loading (optional)
              // label={"Sign In"}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center ">
                    <ProgressSpinner
                      style={{ width: "1rem", height: "1rem" }}
                      className="mr-3"
                      strokeWidth="8"
                    />
                    <span>Signing in...</span>
                  </div>
                ) : (
                  "Sign In"
                )}
              </Button>
            </div>
            <p className="-mt-2">
              Don't have an account?{" "}
              <span
                onClick={() => {
                  setIsSignModalVisible(true);
                  setIsModalVisible(false);
                }}
                className="cursor-pointer font-bold text-[#42866E] hover:underline"
              >
                Sign Up
              </span>
            </p>
          </div>
        </Dialog>

        <Dialog
          visible={isVisibleSignOut}
          footer={true}
          header={true}
          closable={true}
          className="custom-dialog md:mx-0 mx-2"
          onHide={() => setIsVisibleSignOut(false)}
          breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
          style={{ width: "35vw" }}
        >
          <div className="login-modal-wrap ">
            <img
              src={isDarkTheme ? whiteLogo : logo}
              alt="Wine World Logo"
              className="login-logo"
            />

            <h1 className="text-center font-Mulish ">
              Are you sure you want to <br /> sign out?
            </h1>

            <div className="flex mt-10 gap-4">
              <Button
                label={`Cancel`}
                className="login-confirm-btn py-2 "
                onClick={() => {
                  // dispatch(logout());
                  setIsVisibleSignOut(false);
                  // navigate("/");
                }}
              />
              <Button
                label={`Logout`}
                className="login-confirm-btn py-2"
                onClick={() => {
                  dispatch(logout());
                  dispatch(removeCity())
                  setIsVisibleSignOut(false);
                  navigate("/");
                }}
              />
            </div>
          </div>
        </Dialog>

        {/* Sign in Modal */}
        <Dialog
          visible={isSignModalVisible}
          footer={true}
          header={true}
          closable={true}
          className="custom-dialog md:mx-0 mx-2 "
          onHide={toggleSignupModal}
          breakpoints={{ "960px": "70vw", "640px": "100vw" }}
          style={{ width: "35vw" }}
        >
          <div className="login-modal-wrap ">
            <img
              src={isDarkTheme ? whiteLogo : logo}
              alt="Wine World Logo"
              className="login-logo"
            />
            <div className="input-group">
              <label className="ml-0 mb-0 mt-1">
                Name <span className="text-red-500">*</span>
              </label>

              <InputText
                id="name"
                placeholder="Please enter your name"
                className="login-modal-inputs"
                value={name}
                onChange={async (e) => {
                  const newValue = e.target.value;
                  setName(newValue);

                  setErrors((prev) => ({ ...prev, name: "" }));

                  try {
                    await validationSchema.validateAt("name", {
                      name: newValue,
                    });
                    setErrors((prev) => ({ ...prev, name: "" }));
                  } catch (err) {
                    setErrors((prev) => ({
                      ...prev,
                      name: err.message,
                    }));
                  }
                }}
              />

              {errors.name && (
                <small className="error-text">{errors.name}</small>
              )}
            </div>

            {/* Email Input */}
            <div className="input-group">
              <label className="ml-0 mb-0 mt-1">
                Email <span className="text-red-500">*</span>
              </label>
              <InputText
                id="Email"
                placeholder="Please enter your email"
                className="login-modal-inputs"
                value={email}
                onChange={async (e) => {
                  const newValue = e.target.value;
                  setEmail(newValue);
                  setErrors((prev) => ({ ...prev, email: "" }));
                  try {
                    await validationSchema.validateAt("email", {
                      email: newValue,
                    });
                    setErrors((prev) => ({ ...prev, email: "" }));
                  } catch (err) {
                    setErrors((prev) => ({
                      ...prev,
                      email: err.message,
                    }));
                  }
                }}
              />
              {errors.email && (
                <small className="error-text">{errors.email}</small>
              )}
            </div>

            <div className="input-group">
              <label className="ml-0 mb-0 mt-1">
                Password <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <InputText
                  id="Password"
                  type={showSignPassword ? "text" : "password"} // Toggle input type between "text" and "password"
                  placeholder="Please enter your password"
                  className="login-modal-inputs pr-10 w-full" // Add padding-right for space for the icon
                  value={password}
                  onChange={async (e) => {
                    const newValue = e.target.value;
                    setPassword(newValue);
                    setErrors((prev) => ({ ...prev, password: "" }));
                    try {
                      await validationSchema.validateAt("password", {
                        password: newValue,
                      });
                      setErrors((prev) => ({ ...prev, password: "" }));
                    } catch (err) {
                      setErrors((prev) => ({
                        ...prev,
                        password: err.message,
                      }));
                    }
                  }}
                />
                <span
                  onClick={toggleShowSignPassword}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                >
                  {!showSignPassword ? (
                    <i
                      className="pi pi-eye-slash"
                      style={{ fontSize: "1.5rem" }}
                    />
                  ) : (
                    <i className="pi pi-eye" style={{ fontSize: "1.5rem" }} />
                  )}
                </span>
              </div>
              {errors.password && (
                <small className="error-text">{errors.password}</small>
              )}
            </div>

            <div className="input-group">
              <label className="ml-0 mb-0 mt-1">
                Confirm Password <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <InputText
                  id="Password"
                  type={showSignConfirmPassword ? "text" : "password"}
                  placeholder="Please confirm your password"
                  className="login-modal-inputs pr-10 w-full"
                  value={confirmPassword}
                  onChange={async (e) => {
                    const newConfirmPassword = e.target.value;
                    setConfirmPassword(newConfirmPassword);
                    try {
                      await validationSchema.validateAt("confirmPassword", {
                        password,
                        confirmPassword: newConfirmPassword,
                      });
                      setErrors((prev) => ({ ...prev, confirmPassword: "" }));
                    } catch (err) {
                      setErrors((prev) => ({
                        ...prev,
                        confirmPassword: err.message,
                      }));
                    }
                  }}
                />

                <span
                  onClick={() => {
                    setShowSignConfirmPassword(!showSignConfirmPassword);
                  }}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                >
                  {!showSignConfirmPassword ? (
                    <i
                      className="pi pi-eye-slash"
                      style={{ fontSize: "1.5rem" }}
                    />
                  ) : (
                    <i className="pi pi-eye" style={{ fontSize: "1.5rem" }} />
                  )}
                </span>
              </div>
              {errors.confirmPassword && (
                <small className="error-text">{errors.confirmPassword}</small>
              )}
            </div>

            {/* NIC Input */}
            <div className="input-group">
              <label className="ml-0 mb-0 mt-1">NIC</label>

              <InputText
                id="NIC"
                placeholder="Please enter your NIC"
                className="login-modal-inputs"
                value={nic}
                // style={{ backgroundColor: "var(--inputBackground)" }}
                onChange={async (e) => {
                  const newValue = e.target.value;
                  setNic(newValue);
                  setErrors((prev) => ({ ...prev, nic: "" }));
                  try {
                    await validationSchema.validateAt("nic", {
                      nic: newValue,
                    });
                    setErrors((prev) => ({ ...prev, nic: "" }));
                  } catch (err) {
                    setErrors((prev) => ({ ...prev, nic: err.message }));
                  }
                }}
              />
              {errors.nic && <small className="error-text">{errors.nic}</small>}
            </div>

            {/* <div className="input-group">
              <label className="ml-0 mb-0 mt-1">Country</label>

              <select
                className=" w-full py-2 px-1 border border-gray-500 bg-transparent rounded-lg outline-none"
                onChange={(e) => {
                  SetSelectedCountry(e.target.value);
                  setErrors((prev) => ({ ...prev, country: "" }));
                  setLocationError("");
                }}
                defaultValue={selectedCountry}
              >
                {countries.map((country) => (
                  <option
                    className={`${localStorage.getItem("theme") === "dark"
                        ? "bg-[#1F2D40]"
                        : ""
                      }`}
                    key={country}
                    value={country}
                  >
                    {country}
                  </option>
                ))}
              </select>
              {errors.selectedCountry && (
                <small className="error-text">{errors.country}</small>
              )}
            </div> */}

            <div className="input-group ">
              <label className="ml-0 mb-0 mt-1">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <PhoneInput
                id="phone"
                value={number}
                onChange={async (e) => {
                  setNumber(e);
                  try {
                    await validationSchema.validateAt("number", {
                      number: e,
                    });
                    setErrors((prev) => ({ ...prev, number: "" }));
                  } catch (err) {
                    setErrors((prev) => ({ ...prev, number: err.message }));
                  }
                }}
                defaultCountry="LK"
                className=" border bg-red-300 border-gray-300 rounded-md  p-2 w-full outline-none focus:ring-2 focus:ring-blue-500"
              />
              {errors.number && (
                <small className="error-text">{errors.number}</small>
              )}
            </div>
            <div className="flex gap-3 justify-start w-[80%]">
              {/* <div className="input-group">
                <label className="ml-0 mb-0 mt-1">Location</label>

                <select
                  className=" w-full py-2 px-1 border  border-gray-500 bg-transparent rounded-lg outline-none"
                  onChange={(e) => {
                    setCountry(e.target.value);
                    setErrors((prev) => ({ ...prev, country: "" }));
                  }}
                >
                  <option
                    className={`${localStorage.getItem("theme") === "dark"
                        ? "bg-[#1F2D40]"
                        : ""
                      }`}
                    value=""
                  >
                    Select City
                  </option>
                  {cities?.data?.content.map((city) => (
                    <option
                      className={`${localStorage.getItem("theme") === "dark"
                          ? "bg-[#1F2D40]"
                          : ""
                        }`}
                      value={city.cityName}
                    >
                      {city.cityName}
                    </option>
                  ))}
                </select>
                {locationError && (
                  <small className="error-text">{locationError}</small>
                )}
              </div> */}
            </div>

            <div className="flex gap-3 justify-start w-[80%]">
              <div className="input-group mb-3">
                <label className="ml-0 mb-0 mt-1">
                  Gender <span className="text-red-500">*</span>
                </label>

                <Dropdown
                  id="Gender"
                  placeholder="Select gender"
                  className="login-modal-inputs-dropdown"
                  value={gender}
                  disabled={Boolean(nic)}
                  options={[
                    { label: "Male", value: "male" },
                    { label: "Female", value: "female" },
                  ]}
                  onChange={(e) => {
                    setGender(e.value);
                    setErrors((prev) => ({ ...prev, gender: "" }));
                  }}
                />
                {errors.gender && (
                  <small className="error-text">{errors.gender}</small>
                )}
              </div>

              <div className="input-group">
                <label className="ml-0 mb-0 mt-1">
                  Birthday <span className="text-red-500">*</span>
                </label>

                <Calendar
                  id="Birthday"
                  placeholder={`Select your birthday`} // Placeholder with date format
                  value={birthday ? new Date(birthday) : null} // Handle null/undefined
                  className=" border-gray-500 bg-transparent rounded-lg border py-2 px-1 "
                  onChange={handleBirthdayChange}
                  dateFormat={dateFormat}
                  disabled={Boolean(nic)}
                  maxDate={maxSelectableDate} // Disable dates after this
                  viewDate={maxSelectableDate} // Open the calendar at the max date
                  // viewDate={new Date("01/01/2001")} // Use a specific date format
                  showIcon // Optional: Shows an icon for the calendar
                />
                {errors.birthday && (
                  <small className="error-text">{errors.birthday}</small>
                )}
              </div>
            </div>
            <div className="input-group">
              <label className="ml-0 mb-0 mt-1">
                Address <span className="text-red-500">*</span>
              </label>

              <InputText
                id="Address"
                placeholder="Enter your address"
                className="login-modal-inputs"
                value={address}
                onChange={async (e) => {
                  setAddress(e.target.value);
                  try {
                    await validationSchema.validateAt("address", {
                      address: e.target.value,
                    });
                    setErrors((prev) => ({ ...prev, address: "" }));
                  } catch (err) {
                    setErrors((prev) => ({ ...prev, address: err.message }));
                  }
                }}
              />
              {errors.address && (
                <small className="error-text">{errors.address}</small>
              )}
            </div>

            <div className="flex  gap-3 input-group  mt-[10px] ">
              <div className="flex gap-3 ">
                <Checkbox
                  checked={termsAC}
                  onChange={(e) => {
                    setTermsAC(!termsAC);
                    setErrors((prev) => ({ ...prev, termsAC: "" }));
                  }}
                  className={`mt-[3px] ${errors.termsAC && "border-red-600"}`}
                ></Checkbox>

                <p
                  className={`mt-[3px] ${errors.termsAC && "text-red-600"
                    } group`}
                >
                  I agree with the{" "}
                  <Link
                    onClick={() => {
                      setIsSignModalVisible(false)
                    }}
                    to={"/terms-and-conditions"}
                    className="group-hover:underline"
                  >
                    terms and conditions
                  </Link>{" "}
                  in the platform
                </p>
              </div>
            </div>

            <div className="modal-footer ">
              <Button
                label={`Create Account`}
                className="login-confirm-btn py-2"
                loading={registerLoading}
                onClick={registerHandler}
              />
            </div>
            <p className="modal-text ">
              Have an account?{" "}
              <span
                className="cursor-pointer font-bold text-[#42866E] hover:underline"
                onClick={() => {
                  setIsSignModalVisible(false); // First function
                  setIsModalVisible(true); // Second function
                }}
              >
                Sign In
              </span>
            </p>
          </div>
        </Dialog>

        {/* Cart Modal */}
        <Sidebar
          visible={isCartVisible}
          position="right"
          header={true}
          padding="0px"
          closable={true}
          className="side-cart  overflow-hidden hidden-scrollbar"
          style={{ padding: "0px", minWidth: "350px", display: "overflow-hidden" }}
          onHide={toggleCartModal}
          breakpoints={{ "960px": "90vw", "640px": "350px" }}
        >
          <div className="">
            <div className="mx-3 h-6 pl-0 z-10  ">
              {cartItems?.length > 0 && (
                <div className="flex justify-end  z-50">
                  <button onClick={() => deleteAll()}>
                    <p className="text-red-500 hover:underline">
                      {deleteCartLoading ? "Removing..." : "Remove all"}{" "}
                    </p>
                  </button>
                </div>
              )}
              {(updateCartItemLoading || deleteItemLoading) && (
                <ClipLoader
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </div>
            {!cartItems?.length > 0 ? (

              <div style={{ height: '85vh' }} className=" flex flex-col justify-center items-center  px-[60px] overflow-hidden text-center">
                <div className="md:px-20  px-10">
                  <img src={Empty_cart} alt="empty cart" />
                </div>
                <h2 className="text-xl font-bold mt-4 text-[#42866e]">Your cart is empty</h2>
                <span
                  onClick={toggleCartModal}
                  className="mt-2 text-sm text-blue-600 text-[#ed7242] underline cursor-pointer"
                >
                  Add items to your cart to checkout
                </span>
              </div>
            ) : (
              <div className="flex flex-col   h-[90vh]  justify-between z-0">
                <div className="w-full  overflow-hidden flex-1 overflow-y-scroll  hidden-scrollbar px-5">
                  {cartItems?.map((item, index) => (
                    <React.Fragment key={index}>
                      <CartItem
                        item={item}
                        loading={updateCartItemLoading}
                        deleteItemLoading={deleteItemLoading}
                        plus={() =>
                          updateHandler({
                            type: "plus",
                            id: item.itemId,
                            quantity: item.quantity,
                          })
                        }
                        minus={() =>
                          updateHandler({
                            type: "minus",
                            id: item.itemId,
                            quantity: item.quantity,
                          })
                        }
                      />
                      {index < cartItems.length - 1 && <hr className="divider" />}
                    </React.Fragment>
                  ))}
                </div>

                <div
                  style={{
                    boxShadow:
                      "0px -10px 15px -3px rgba(0, 0, 0, 0.1), 0px -4px 6px -2px rgba(0, 0, 0, 0.05)",
                  }}
                  className=" z-50 w-full flex flex-col py-6  bg-[ver-(--heroButton)] justify-center items-center   drop-shadow-2xl drop-shadow-top"
                >
                  <div className="side-cart-list "></div>

                  <div className="cart-modal-footer ">
                    <div className="side-cart-total">
                      <div>Total</div>
                      <div>
                        {currency}{" "}
                        {currency === "LKR"
                          ? (
                            cartData?.data?.totalPriceInRupee -
                            cartData?.data?.deliveryChargesInRupee
                          )
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : (
                            cartData?.data?.totalPriceInDollar -
                            cartData?.data?.deliveryChargesInDollar
                          )
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                    </div>
                    <Button
                      label="Buy now"
                      className="cart-confirm-btn py-2 "
                      onClick={() => {
                        setIsCartVisible(false);
                        navigate("/cart/");
                        window.scrollTo(0, 0);
                      }}
                    />
                    <small>
                      Upon clicking 'Buy now', I confirm I have read and
                      <br /> acknowledge all
                      <Link
                        to="/terms-and-conditions"
                        onClick={toggleCartModal}
                        className="ml-1 text-[var(--otpOrange)]"
                      >
                        terms and policies.
                      </Link>
                    </small>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Sidebar>

        <div>
          <ForgotPassword
            isModalVisible={isForgotVisible}
            emailSet={setEmail}
            setIsVerifyVisible={setIsVerifyVisible}
            setIsResetPasswordVisible={setIsResetPasswordVisible}
            isDarkTheme={isDarkTheme}
            onClick={forgotNext}
            toggleModal={toggleForgotModal}
          />
        </div>

        <div>
          <VerifyOtp
            setIsVerifyVisible={setIsVerifyVisible}
            isModalVisible={isVerifyVisible}
            isDarkTheme={isDarkTheme}
            onClick={VerifyNext}
            toggleModal={toggleVerifyModal}
            email={email}
            number={number}
          />
        </div>
        <div>
          <Search
            setIsSearchVisible={setIsSearchVisible}
            isModalVisible={isSearchVisible}
            isDarkTheme={isDarkTheme}
            onClick={VerifyNext}
            toggleModal={toggleSearchModal}
          />
        </div>

        <div>
          <VerifyPasswordOtp
            isResetPasswordVisible={isResetPasswordVisible}
            setIsResetPasswordVisible={setIsResetPasswordVisible}
            email={email}
          />
        </div>

        <div>
          <ResetPassword
            isModalVisible={isResetVisible}
            forgotNext={forgotNext}
            isDarkTheme={isDarkTheme}
            onClick={() => console.log("Sign In Clicked")}
            toggleModal={toggleResetModal}
          />
        </div>

        <div className="header-top hidden md:flex">
          <div className="header-right">
            <i
              className="pi pi-heart"
              onClick={() => {
                navigate("/Favourite");
                window.scrollTo(0, 0);
              }}
            />
            <div
              onClick={() => {
                navigate("/Favourite");
                window.scrollTo(0, 0);
              }}
              className="headertext cursor-pointer"
            >
              Favorites
            </div>
            {user && (
              <div className="flex flex-row items-center justify-center space-x-2">
                <i
                  onClick={() => setCartVisible()}
                  className="pi pi-shopping-cart p-overlay-badge cursor-pointer"
                >
                  {cartCount > 0 && (
                    <Badge
                      value={cartCount}
                      className="badgeColor"
                      style={{ background: "var(--badgeColor)" }}
                    />
                  )}
                </i>
                <div
                  onClick={() => setCartVisible()}
                  className="headertext cursor-pointer"
                >
                  Cart
                </div>
              </div>
            )}
            {!user && (
              <div className="flex flex-row items-center justify-center space-x-2 w-7 mx-5"></div>
            )}

            {/* <button className="contact-button">Contact Us</button> */}
          </div>
          <div className="header-left">
            <Link to={"/"}>
              <img
                src={isDarkTheme ? whiteLogo : logo}
                alt="Wine World Logo"
                className="logo"
              />
            </Link>
          </div>
          <div className="flex gap-3">

            {/* Search */}
            <div onClick={() => setIsSearchVisible(true)} className="items-center justify-center flex ">
              <i className="pi pi-search text-xl font-bold text-[var(--color)]"></i>
            </div>

            <select
              name="cars"
              id="cars"
              value={currency}
              className="outline-none text-[var(--color)] bg-[var(--backg-color)] "
              onChange={(e) => dispatch(setCurrency(e.target.value))}
            >
              <option value="LKR">LKR</option>
              <option value="USD">USD</option>
            </select>
            <button
              onClick={toggleTheme}
              style={{
                backgroundColor: isDarkTheme ? "#333" : "#f4f4f4",
                color: isDarkTheme ? "#fff" : "#000",
                border: "none",
                padding: "10px 15px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <i className={`pi ${isDarkTheme ? "pi-moon" : "pi-sun"}`} />
              {/* {isDarkTheme ? '' : 'Switch to Dark Theme'} */}
            </button>

            <Button
              className="contactButton px-3"
              label="Contact Us"
              onClick={() => {
                navigate("/contact-us/");
                window.scrollTo(0, 0);
              }}
            />

            {user ? (
              <Button
                className="profileButton"
                icon="pi pi-user"
                onClick={(event) => {
                  profileMenu.current.toggle(event);
                }}
                aria-controls="profile_menu"
                aria-haspopup
              />
            ) : (
              <Button
                className="px-3 bg-[#13382B] signIn"
                label="Sign in"
                onClick={() => {
                  setIsModalVisible(true);
                }}
              />
            )}

            {/* Profile Dropdown Menu */}
            <Menu
              model={profileItems}
              popup
              ref={profileMenu}
              id="profile_menu"
              style={{ width: "250px" }}
            />
          </div>
        </div>

        <div className="header-bottom hidden md:flex bg-opacity-90">
          <nav className="nav-links flex flex-col ">
            {/* <Menubar className="menuBar" model={items} /> */}
            <MenuOptions />
          </nav>
        </div>

        {/* header mobile */}
        <div className="bg-[var(--backg-color)] block md:hidden">
          <div className="flex justify-between items-center w-full">
            <div>
              <i
                onClick={() => toggleMenuVisible(true)}
                className="pi pi-bars hamburger-icon"
              />
              <div
                onClick={() => toggleMenuVisible(true)}
                className="headertext"
              ></div>
            </div>
            <img
              src={isDarkTheme ? whiteLogo : logo}
              alt="Wine World Logo"
              className="mobile-logo"
            />
            <div className="flex flex-row">
              <div onClick={() => setIsSearchVisible(true)} className="items-center justify-center flex ">
                <i className="pi pi-search text-lg mr-3 font-bold text-[var(--color)]"></i>
              </div>

              {user && (
                <i
                  onClick={() => setIsCartVisible(true)}
                  className="pi pi-shopping-cart relative mt-1"
                  style={{ fontSize: '19px' }}
                >
                  {cartCount > 0 && (
                    <Badge
                      value={cartCount}
                      className="badgeColor"
                      // style={{ background: "var(--badgeColor)" }}
                      style={{
                        background: "var(--badgeColor)",
                        // minHeight: "20px",
                        // minWidth: "20px",
                        position: "absolute", // Position badge absolutely
                        top: "-10px", // Adjust position of the badge
                        right: "-12px", // Adjust position of the badge
                        borderRadius: "50%", // Round the badge
                      }}
                    />
                  )}
                </i>
              )}
              <div
                onClick={() => setIsCartVisible(true)}
                className="headertext "
              ></div>
            </div>
          </div>

          {/* Full-screen Dialog */}
          <Dialog
            visible={isMenuVisible}
            // visible={isMenuVisible}
            onHide={() => toggleMenuVisible(false)}
            className="p-dialog-fullscreen h-screen w-96"
            header={
              <div className="flex justify-center items-center">
                <img
                  src={isDarkTheme ? whiteLogo : logo}
                  alt="Wine World Logo"
                  className="w-36 mt-4 ms-7"
                />
              </div>
            }
          >
            <div className="flex flex-col justify-center items-center px-5">
              <div className="border-t border-gray-200 w-full ">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-3">
                  <div>
                    {depitems?.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col  transition duration-200 cursor-pointer text-sm mt-4"
                      >
                        <div
                          onClick={() => handleDropdown(index)}
                          className="flex items-center justify-between  w-full"
                        >
                          <span
                            onClick={() => {
                              item.command(); // Execute the command
                              toggleMenuVisible(false); // Close the dialog
                            }}
                            className="text-start"
                          >
                            {item.label}
                          </span>
                          {item.items && <i className="pi pi-chevron-down" />}
                        </div>

                        {item.items && activeIndex === index && (
                          <div>
                            {item.items?.map((subItem, subIndex) => (
                              <div key={subIndex} className="pl-2 py-1">
                                <span
                                  onClick={() => {
                                    subItem.command(); // Execute the command
                                    toggleMenuVisible(false); // Close the dialog
                                  }}
                                  className="text-green-700"
                                >
                                  {subItem.label}
                                </span>

                                {subItem.items && (
                                  <div>
                                    {subItem.items?.map(
                                      (nestedItem, nestedIndex) => (
                                        <div
                                          key={nestedIndex}
                                          className="pl-4 py-1"
                                        >
                                          <span
                                            onClick={() => {
                                              nestedItem.command(); // Execute the command
                                              toggleMenuVisible(false); // Close the dialog
                                            }}
                                            className=""
                                          >
                                            {nestedItem.label}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div>
                    {items?.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col  transition duration-200 cursor-pointer text-sm mt-4"
                      >
                        <div
                          onClick={() => handleDropdown(index)}
                          className="flex items-center justify-between  w-full"
                        >
                          <span
                            onClick={() => {
                              item.command(); // Execute the command
                              toggleMenuVisible(false); // Close the dialog
                            }}
                            className="text-start"
                          >
                            {item.label}
                          </span>
                          {item.items && <i className="pi pi-chevron-down" />}
                        </div>

                        {item.items && activeIndex === index && (
                          <div>
                            {item.items?.map((subItem, subIndex) => (
                              <div key={subIndex} className="pl-2 py-1">
                                <span
                                  onClick={() => {
                                    subItem.command(); // Execute the command
                                    toggleMenuVisible(false); // Close the dialog
                                  }}
                                  className="text-green-700"
                                >
                                  {subItem.label}
                                </span>

                                {subItem.items && (
                                  <div>
                                    {subItem.items?.map(
                                      (nestedItem, nestedIndex) => (
                                        <div
                                          key={nestedIndex}
                                          className="pl-4 py-1"
                                        >
                                          <span
                                            // onClick={() => {
                                            //   nestedItem.command(); // Execute the command
                                            //   toggleMenuVisible(false); // Close the dialog
                                            // }}
                                            className=""
                                          >
                                            {nestedItem.label}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="border-t  w-full pt-2 ">
                <>
                  {sidebarDark?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-row   my-2 w-full text-sm text-[var(--color)] cursor-pointer hover:text-green-700"
                      onClick={() => {
                        item.command(); // Execute the command
                        toggleMenuVisible(false); // Close the dialog
                      }}
                    >
                      <div>
                        <i
                          className={`${item.icon} text-sm mr-3 mt-1 text-[var(--green)] `}
                        />
                      </div>
                      <div
                        onClick={() => {
                          item.command(); // Execute the command
                          toggleMenuVisible(false); // Close the dialog
                        }}
                      >
                        <p className="text-[var(--green)] w-full ">
                          {item.label}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              </div>

              <div className="border-t  w-full pt-2 ">
                {userInfo ? (
                  <>
                    {sidebarOptions?.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-row   my-2 w-full text-sm text-[var(--color)] cursor-pointer hover:text-green-700"
                        onClick={() => {
                          item.command(); // Execute the command
                          toggleMenuVisible(false); // Close the dialog
                        }}
                      >
                        <div>
                          <i
                            className={`${item.icon} text-sm mr-3 mt-1 text-[var(--green)] `}
                          />
                        </div>
                        <div
                          onClick={() => {
                            item.command(); // Execute the command
                            toggleMenuVisible(false); // Close the dialog
                          }}
                        >
                          <p className="text-[var(--green)] w-full ">
                            {item.label.toUpperCase()}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div>
                      <Button
                        className="login-confirm-btn py-2 mt-5 mb-2 flex items-center justify-center"
                        onClick={() => {
                          toggleMenuVisible(false);
                          setIsModalVisible(true);
                        }}
                      >
                        Sign In
                      </Button>

                      {/* <Button
                        className="login-confirm-btn py-2 mt-5 mb-2 flex items-center justify-center"
                        onClick={() => {
                          setIsSignModalVisible(true); toggleMenuVisible(false);
                        }}
                      >
                        Sign Up
                      </Button> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div >
  );
};

export default Header;
const MenuOptions = () => {
  const [open, setOpen] = useState(false);
  const { data: department } = useGetAllDepartmentQuery();

  // console.log("pathname", pathname);

  const { pathname } = useLocation();
  const depatmentID = pathname.split("/")[2];

  const metadata = department?.map((item) => {
    return {
      name: item.department,
      path: `/product-category/${item.departmertId}`,
      children: item?.categories?.map((child) => ({
        name: child.catrgory,
        path: `/product-category/${item.departmertId}/${child.categoryId}`,
        children: child?.brands?.map((subChild) => ({
          name: subChild.brand,
          path: `/product-category/${item.departmertId}/${child.categoryId}/${subChild.brandId}`,
        })),
      })),
    };
  });

  const menuItems = [
    {
      name: "Home",
      path: "/",
    },
    ...(metadata || []),
    {
      name: "Weddings",
      path: "/for-weddings",
    },
    {
      name: "corporates",
      path: "/for-corporates",
    },
    {
      name: "services",
      path: "/event-and-services",
    },
    {
      name: "Blog",
      path: "/blog",
    },
  ];

  return (
    <>
      <div
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className=" w-full  flex gap-3"
      >
        {menuItems.map((item, index) => (
          <div
            className={`relative group  hover:bg-[var(--blog-bg-color)] bg-opacity-70 transition-all duration-150 `}
          >
            <div className=" uppercase px-2  font-bold   ">
              <Link
                to={item.path}
                className={`flex ${pathname === item.path
                  ? "text-[#da813d]"
                  : item.children
                    ? `${depatmentID === item.path.split("/")[2]
                      ? " text-[#da813d]"
                      : "text-[#42866e]"
                    }`
                    : "text-[var(--color)]"
                  } justify-between items-center  `}
              >
                <p className="m-0 py-3 text-[16px]  ">{item.name}</p>
                {item.children && item.children.length > 0 && (
                  <FaAngleDown
                    className={`group-hover:rotate-180 ml-3   opacity-50 transition-all duration-150`}
                  />
                )}
              </Link>
            </div>

            {item.children && (
              <div className="absolute  overflow-hidden  group-hover:overflow-visible h-0 group-hover:h-auto left-0 gap shadow-md bg-[var(--blog-bg-color)]  opacity-0 group-hover:opacity-100  flex justify-center items-center group flex-col bg-white  rounded-xl ">
                {item.children.map((child, index) => (
                  <ToggleMenu data={child} key={index} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

const ToggleMenu = (data) => {
  const { pathname } = useLocation();
  const categoryId = pathname.split("/")[3];
  const brandId = pathname.split("/")[4];

  const [open, setOpen] = useState(false);
  return (
    <Link
      to={data.data.path}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className={` text-[15px] hover:text-[#da813d] font-Mulish px-4 py-2.5 text-center flex justify-between font-light  w-[200px] items-center  ${categoryId === data.data.path.split("/")[3]
        ? "text-[#da813d]"
        : "text-[#686868]"
        }`}
    >
      {data.data.name}
      <IoMdArrowDropright
        size={20}
        className={`${open ? "-translate-x-1 " : ""
          } transition-all duration-150 `}
      />
      {data.data.children && (
        <div
          className={`absolute ${open ? "min-w-[200px]" : "w-0 hidden"
            } transition-all duration-150  rounded-xl text-start overflow-y-scroll right-0 top-0 translate-x-[100%]  px-4 py-2.5  bg-white font-medium text-[var(--softText)]  h-[100%] hidden-scrollbar`}
        >
          {data.data.children.map((child, index) => (
            <Link
              to={child.path}
              className={` ${brandId === child.path.split("/")[4]
                ? "text-[#da813d]"
                : "text-black"
                }  px-2 text-[16px]  py-2 flex min-w-[200px]  transition-all duration-150`}
            >
              <p className="m-0  text-[14px] font-normal hover:text-[#da813d]">
                {child.name}
              </p>
            </Link>
          ))}
        </div>
      )}
    </Link>
  );
};
