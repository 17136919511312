// src/pages/Profile.js
import React, { useEffect, useRef, useState } from "react";
import "./Complaint.css"; // Use a CSS file for styles
import avatar from "./../../Images/avatar.png";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { Message } from "primereact/message";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  useGetAllOrderQuery,
  useGetAOrderQuery,
} from "../../redux/service/orderSlice";
import {
  useCloseTicketMutation,
  useGetAllComplainsQuery,
} from "../../redux/service/complainSlice";
import { toast } from "material-react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import { BASE_URL } from "../../redux/service/apiSlice";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";

// const validationSchema = Yup.object({
//   subject: Yup.string().required("Subject is required"),
//   message: Yup.string().required("Message is required"),
//   attachments: Yup.mixed().required("Attachment is required"),
//   inquiry: Yup.string().required("Inquiry or Complaint is required"),
//   // orderId: Yup.string().required("Order ID is required"),
//   orderId: Yup.string().when("inquiry", {
//     is: "Inquiry", // Condition: if inquiry is "Inquiry"
//     then: Yup.string().notRequired(), // Make orderId not required
//     otherwise: Yup.string().required("Order ID is required"), // Default behavior
//   }),
// });

const Complaint = () => {



  // Simulating user information

  const [activeTab, setActiveTab] = useState("awaiting"); // Default to 'awaiting'
  const [itemList, SetItemList] = useState();
  const [loading, setLoading] = useState(false);
  const [createNew, SetCreateNew] = useState(false);
  const [visible, setVisible] = useState(true);
  const [inquiry, setInquiry] = useState(null);
  const [subject, setSubject] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [complaints, setComplaints] = useState([]);
  const user = useSelector((state) => state?.auth?.userInfo);
  const { data: Orders } = useGetAOrderQuery();
  const [avaitingComplainList, setAwaitingComplainList] = useState([]);
  const [resolvedComplainList, setResolvedComplainList] = useState([]);
  const [reopenedComplainList, setReopenedComplainList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [Closing, setClosing] = useState(false);
  const [errors, setErrors] = useState({}); // for tracking error messages
  const [message, setMessage] = useState("");
  const inquiryOptions = [
    { label: "Inquiry", value: "Inquiry" },
    { label: "Complaint", value: "Complaint" },
  ];

  const OrderOptions = [
    { label: "9289424324v23t4v234t34", value: "product" },
    { label: "3535u35345345k435v56456", value: "order" },
    { label: "242i35u53n64432425346346n36", value: "others" },
  ];

  const location = useLocation();
  const { ReportorderId } = location.state || {};
  console.log("ReportorderId", ReportorderId);
  const {
    data: ComplainsList,
    isLoading: isComLoading,
    refetch: refetchComplains,
  } = useGetAllComplainsQuery({
    page: 0,
    userId: user?.id,
  });

  useEffect(() => {
    if (ReportorderId) {
      setOrderId(ReportorderId);
      SetCreateNew(true);
    }
  }, []);


  const validationSchema = Yup.object().shape({
    ...(inquiry === "Complaint" && {
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message is required"),
      attachments: Yup.mixed().required("Attachment is required"),
      inquiry: Yup.string().required("Inquiry or Complaint is required"),
      orderId: Yup.string().required("Order ID is required"),
    }),
    ...(inquiry !== "Complaint" && {
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message is required"),
      attachments: Yup.mixed().required("Attachment is required"),
      inquiry: Yup.string().required("Inquiry or Complaint is required"),
    }),
  })

  const [closeTicket] = useCloseTicketMutation({
    onSuccess: () => {
      // console.log("working here");
      setClosing(false);
      refetchComplains(); // Refetch complaints after successfully closing the ticket
    },
  });
  const CloseTicketApi = async () => {
    setClosing(true);
    // console.log("test", selectedItems[0]?.id)
    try {
      await closeTicket({
        ticketId: selectedItems[0]?.id,
        status: "CLOSED",
      });
      setClosing(false);
      setSelectedItems([]);
      // unwrap if using RTK Query to ensure the promise resolves
    } catch (error) {
      setClosing(false);

      console.error("Error closing ticket:", error);
    }
  };

  const { data: OrderList, isLoading: isOrderLoading } = useGetAllOrderQuery({
    // page: 0,
    sessionId: localStorage.getItem("sessionId")
      ? localStorage.getItem("sessionId")
      : undefined,
    userId: user?.id,
  });

  // console.log("OrderList", OrderList?.data);

  const [attachments, setAttachments] = useState([]);
  const fileUploadRef = useRef(null);

  // Validation function
  // const validateForm = () => {
  //   const newErrors = {};
  //   if (!inquiry) newErrors.inquiry = "Inquiry or Complaint is required.";
  //   if (!subject) newErrors.subject = "Subject is required.";
  //   if (!orderId) newErrors.orderId = "Order ID is required.";
  //   if (!message) newErrors.message = "Message is required.";
  //   if (attachments.length === 0)
  //     newErrors.attachment = "Attachment is required.";
  //   setErrors(newErrors);

  //   return Object.keys(newErrors).length === 0; // Returns true if no errors
  // };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(
        {
          inquiry,
          subject,
          orderId,
          message,
          attachments: attachments[0]?.file,
        },
        { abortEarly: false }
      );
      sendComplaint();
    } catch (error) {
      const errors = {};
      error.inner?.forEach((err) => {
        errors[err.path] = err.message;
      });
      console.log(errors);
      setErrors(errors);
    }
    // if (validateForm()) {
    //   // Submit the form data
    //   sendComplaint();

    //   // console.log('Form submitted successfully!');
    // } else {
    //   // console.log('Please fill out all required fields.');
    //   toast.error("Please fill out all required fields.");
    // }
  };

  // Handle file upload
  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.files).map((file) => ({
      file,
      preview: URL.createObjectURL(file), // Generate preview URL
    }));

    setAttachments((prev) => [...prev, ...uploadedFiles]);
    setErrors((prev) => ({ ...prev, attachment: "" }));

    // Clear the file input to allow new selections
    fileUploadRef.current?.clear();
  };

  // Handle file removal
  const handleRemoveFile = (index) => {
    setAttachments((prev) => {
      // Revoke preview URL to release memory
      URL.revokeObjectURL(prev[index].preview);
      return prev.filter((_, i) => i !== index);
    });
  };

  useEffect(() => {
    if (ComplainsList?.data?.content) {
      const complaints = ComplainsList?.data?.content;
      const reversedComplaints = [...complaints]?.reverse();

      const awaiting = reversedComplaints?.filter(
        (complaint) => complaint.status === "AWAITING"
      );
      const resolved = reversedComplaints?.filter(
        (complaint) => complaint.status === "CLOSED"
      );
      const reopened = reversedComplaints?.filter(
        (complaint) => complaint.status === "REOPENED"
      );
      if (awaiting.length > 0) {
        // console.log("awaiting", awaiting);
        setSelectedItems([awaiting[0]]);
      }
      setAwaitingComplainList(awaiting);
      setResolvedComplainList(resolved);
      setReopenedComplainList(reopened);
    }
  }, [ComplainsList]);

  const handleCardClick = (item) => {
    handleReset();
    window.scrollTo(0, 0);
    // Check if the item is already in the selectedItems array
    if (!selectedItems?.some((selected) => selected.id === item.id)) {
      setSelectedItems((prev) => [item]);
    }
  };

  const [position, setPosition] = useState({ x: "50%", y: "50%" });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [imageVisible, setImageVisible] = useState(false);

  const handleMouseMove = (event) => {
    const image = event.target;
    const { left, top, width, height } = image.getBoundingClientRect();

    // Get the mouse position relative to the image
    const x = ((event.clientX - left) / width) * 100;
    const y = ((event.clientY - top) / height) * 100;

    setMousePosition({ x, y });
  };

  const handleReset = () => {
    setInquiry("");
    setSubject("");
    setOrderId("");
    setMessage("");
    setAttachments([]);
    setErrors({});
    SetCreateNew(false);
    window.scrollTo(0, 0);
  };

  const sendComplaint = async () => {
    setLoading(true);
    // const url = 'https://uuy8atyhe6.execute-api.ap-south-1.amazonaws.com/api/complaints/createComplaint';
    const url = `${BASE_URL}/complaints/createComplaint`;

    // Prepare FormData for multipart request
    const formData = new FormData();

    const complaintDto = {
      inquiryType: inquiry,
      subject: subject,
      message: message,
      status: "AWAITING",
      userId: user?.id,
      orderId: orderId,
    };

    Object.entries(complaintDto)?.forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (attachments?.length > 0) {
      attachments?.forEach((attachment) => {
        formData.append("attachment", attachment.file);
      });
    } else {
      formData.append("attachment", null); // or formData.append('attachment', ''); if null causes issues
    }
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log('Response:', response.data);
      setInquiry(null);
      setSubject(null);
      setOrderId(null);
      setMessage("");
      setAttachments([]);
      handleReset();
      SetCreateNew(false);
      refetchComplains();
      setLoading(false);
    } catch (error) {
      // SetCreateNew(false);
      setLoading(false);

      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    // console.log("Orders", Orders);
    // fetchComplaints(); // Ensure the function is invoked
  }, []);

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };

  useEffect(() => {
    if (activeTab === "awaiting") {
      SetItemList(avaitingComplainList);
    } else if (activeTab === "resolved") {
      SetItemList(resolvedComplainList);
    } else if (activeTab === "reopened") {
      SetItemList(reopenedComplainList);
    }
  }, [activeTab, ComplainsList, avaitingComplainList]);

  return (
    <div className="px-2  md:px-[8%] container">
      <div className="flex">
        <p className="cursor-pointer text-[var(--color)] text-[12px] md:text-[16px] pt-[60px] md:pt-0">
          <Link to="/" className="mr-2">
            {" "}
            Home{" "}
          </Link>{" "}


          {">"}{" "}
          <span className="text-[#DA813D] mx-2"> Complaint Management </span>
        </p>
      </div>

      {/* <div className='parth ml-5 md:ml-0'>Home {">"} Profile {">"}  <div className='parth-current'> Complaint Management</div></div> */}
      <div className=" flex flex-col md:flex-row justify-between p-2 md:p-5 flex-1 font-sans">
        {/* Main Ticket Section */}

        <div className=" flex-[0.65] md:p-2.5">
          {createNew === false ? (
            <div>
              {selectedItems[0]?.id && (
                <div className="text-2xl font-bold text-[var(--softText)] mb-5">
                  {selectedItems[0]?.inquiryType} No -  {selectedItems[0]?.id}
                </div>
              )}

              {selectedItems?.length > 0 ? (
                <div>
                  {selectedItems?.map((complaint, index) => (
                    <div>
                      <div key={index} className="ticket mb:2">
                        <div className="ticket-header flex flex-col  md:flex-row justify-between mb-2.5">
                          <div className="ticket-user-info">
                            <div className="avatar">
                              <span className="avatar-icon">&#128100;</span>
                            </div>
                            <div className="user-details">
                              <div>
                                <h4 className="ticket-name ">
                                  {complaint?.userName || "Anonymous User"}
                                </h4>
                              </div>
                              <div
                                className={
                                  complaint?.status === "CLOSED"
                                    ? "tag closing-tag"
                                    : "tag awaiting-tag"
                                }
                              >
                                {complaint?.status === "CLOSED" ? "Closed" : complaint?.status === "REOPENED" ? "Reopened" : "Awaiting"}
                              </div>
                            </div>
                          </div>
                          <div className="ticket-date">
                            {new Date(complaint?.createdAt).toLocaleString()}
                          </div>
                        </div>
                        <div className="mb-5 text-[var(--softText)] font-mulish md:ml-12 pt-3">
                          <p className="ticket-heading">{complaint?.subject}</p>
                          <div>
                            <p className="ticket-description mt-0">
                              {complaint?.message}
                            </p>
                          </div>
                        </div>

                        <div className="text-[var(--softText)] font-mulish md:ml-12">
                          {complaint?.attachmentPath && (
                            <div className="ticket-attachment-viewer">
                              <h5 className="ticket-heading text-[var(--softText)] font-mulish mb-2">
                                Attachment
                              </h5>
                              {complaint.attachmentPath.endsWith(".jpg") ||
                                complaint.attachmentPath.endsWith(".jpeg") ||
                                complaint.attachmentPath.endsWith(".png") ? (
                                <img
                                  onClick={() => setImageVisible(true)}
                                  src={complaint.temporaryLink}
                                  alt="Attachment Preview"
                                  className="w-24 h-24 object-cover rounded shadow-md cursor-pointer"
                                />
                              ) : (
                                <a
                                  href={complaint.temporaryLink}
                                  download
                                  className="createNew-btn mt-5"
                                >
                                  Download File
                                </a>
                              )}
                              {imageVisible && (
                                <div
                                  className="image-viewer-backdrop"
                                  onClick={() => setImageVisible(false)}
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: 1000,
                                  }}
                                >
                                  <img
                                    src={complaint.temporaryLink}
                                    alt="Product"
                                    className="transition-transform duration-300 ease-in-out"
                                    style={{
                                      maxWidth: "90%",
                                      maxHeight: "90%",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Complete ticket */}
                      {complaint?.adminComment != null && (
                        <div className="ticketComplete">
                          <div className="ticket-header  flex flex-col md:flex-row">
                            <div className="ticket-user-info">
                              <div className="avatar">
                                <span className="avatar-icon">&#128100;</span>
                              </div>
                              <div className="user-details">
                                <div>
                                  <h4 className="tiket-name">Admin</h4>
                                </div>
                                <div className="resolved-tag">Resolved</div>
                              </div>
                            </div>
                            {/* <div className="ticket-date">Tue, 27th Feb 2024 10:31am</div> */}
                          </div>
                          <div className=" mb-5 text-[var(--lightCardText)] font-mulish md:ml-12 py-3.5">
                            <p className="ticket-heading">
                              {complaint.subject}
                            </p>
                            <div style={{ marginTop: "10px" }}>
                              <p className="ticket-description">
                                {complaint.adminComment}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center text-xl font-bold text-[var(--softText)] my-32">
                  No values to display..!
                </div>
              )}

              <div className="ticket-resolve-button">
                <button
                  onClick={() => SetCreateNew(true)}
                  className="createNew-btn"
                >
                  Create New Ticket
                </button>
                {selectedItems?.length > 0 && (
                  <div>
                    {selectedItems[0]?.status !== "CLOSED" && (
                      <button
                        onClick={() => CloseTicketApi()}
                        className="closeTicket-btn"
                      >
                        {Closing ? (
                          <div className="flex items-center justify-center">
                            <ProgressSpinner
                              style={{ width: "1rem", height: "1rem" }}
                              className="mr-3 text-[var(--deleteColor)]"
                              strokeWidth="8"
                            />
                            <span className="text-[var(--deleteColor)]">
                              Closing...
                            </span>
                          </div>
                        ) : (
                          "Close Ticket"
                        )}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className=" mx-auto p-3 md:p-5 flex flex-col">
              <div className="form-card">
                <div className="py-1 px-3 bg-[#42866e] flex items-center mb-5 border-black rounded-t-lg">
                  <h2 className="text-white mt-1 ml-3 text-lg font-bold">
                    Complaint Form
                  </h2>
                </div>

                <p className="form-description">
                  Please complete this form and one of our sellers will reply to
                  you by email as soon as possible.
                </p>

                {/* Inquiry or Complaint */}
                <div className="form-field">
                  <label htmlFor="inquiry">
                    Inquiry or Complaint
                    <span className="text-red-500">*</span>
                  </label>
                  <Dropdown
                    id="inquiry"
                    value={inquiry}
                    className="input-border"
                    options={inquiryOptions}
                    onChange={(e) => {
                      setInquiry(e.value);
                      setErrors((prev) => ({ ...prev, inquiry: "", orderId: "" }));
                    }}
                    placeholder="Select Inquiry or Complaint"
                  />
                  {errors.inquiry && (
                    <p className="error text-red-500">{errors.inquiry}</p>
                  )}
                </div>

                {/* Subject */}
                <div className="form-field ">
                  <label htmlFor="Subject">
                    Subject<span className="text-red-500">*</span>
                  </label>
                  <InputTextarea
                    id="subject"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                      setErrors((prev) => ({ ...prev, subject: "" }));
                    }}
                    className="input-border pl-2 pt-2"
                    placeholder="Enter your subject here"
                  />
                  {errors.subject && (
                    <p className="error text-red-500">{errors.subject}</p>
                  )}
                </div>

                {/* Order ID */}
                <div className="form-field">
                  <label htmlFor="orderId">
                    Order ID
                    {inquiry === "Complaint" && (
                      <span className="text-red-500">*</span>
                    )}
                  </label>
                  <Dropdown
                    id="orderId"
                    value={orderId}
                    className="input-border"
                    // options={
                    //   OrderList?.data?.length > 0
                    //     ? OrderList.data.map((order) => ({
                    //       label: order?.id, // Adjust this to the correct property from your data
                    //       value: order?.id, // The value to be set on selection
                    //     }))
                    //     : [{ label: "No Orders Available", value: null }] // Fallback option
                    // }
                    options={
                      OrderList?.data?.length > 0
                        ? OrderList.data.map((order) => {
                          const formattedDate = new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(new Date(order?.orderDate));

                          return {
                            label: `${order?.id} - ${formattedDate}`, // Show ID and formatted date
                            value: order?.id, // The value to be set on selection
                          };
                        })
                        : [{ label: "No Orders Available", value: null }]// Fallback option
                    }
                    onChange={(e) => {
                      setOrderId(e.value);
                      setErrors((prev) => ({ ...prev, orderId: "" }));
                    }}
                    placeholder="Select Order ID"
                  />
                  {errors.orderId && (
                    <p className="error text-red-500">{errors.orderId}</p>
                  )}
                </div>

                {/* Message */}
                <div className="form-field">
                  <label htmlFor="message">
                    Message<span className="text-red-500">*</span>
                  </label>
                  <InputTextarea
                    id="message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setErrors((prev) => ({ ...prev, message: "" }));
                    }}
                    rows={5}
                    autoResize
                    className="input-border pl-2 pt-2"
                    placeholder="Enter your message here"
                  />
                  {errors.message && (
                    <p className="error text-red-500">{errors.message}</p>
                  )}
                </div>

                {/* File Upload */}
                <div className="form-field">
                  <label htmlFor="attachment">
                    Add Attachment<span className="text-red-500">*</span>
                  </label>
                  {attachments.length === 0 && (
                    <FileUpload
                      mode="basic"
                      name="demo[]"
                      className="uploadButton"
                      onChange={(e) => {
                        setErrors((prev) => ({ ...prev, attachment: "" }));
                      }}
                      customUpload
                      chooseLabel="Attach Files"
                      uploadHandler={handleFileUpload}
                      auto
                    />
                  )}
                  <div
                    className="attachment-viewer"
                    style={{ marginTop: "10px" }}
                  >
                    {attachments.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {attachments.map((attachment, index) => (
                          <div
                            key={index}
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              padding: "5px",
                              position: "relative",
                              width: "150px",
                            }}
                          >
                            {/* Preview */}
                            {attachment.file.type.startsWith("image/") ? (
                              <img
                                src={attachment.preview}
                                alt={attachment.file.name}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  background: "#f5f5f5",
                                  fontSize: "12px",
                                  textAlign: "center",
                                }}
                              >
                                {attachment.file.name}
                              </div>
                            )}

                            {/* Remove Button */}
                            <button
                              onClick={() => handleRemoveFile(index)}
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {errors.attachments && (
                    <p className="error text-red-500">{errors.attachments}</p>
                  )}
                </div>

                {/* Submit and Reset buttons */}
                <div className="form-actions">
                  <Button
                    className="bg-[#42866e] text-white font-bold rounded-md border-none py-2 px-7 cursor-pointer"
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <div className="flex  items-center justify-center">
                        <ProgressSpinner
                          style={{ width: "1rem", height: "1rem" }}
                          className="mr-3"
                          strokeWidth="8"
                        />
                        <span className="text-white">Sending...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </Button>

                  <Button
                    label="Close"
                    className="p-button-secondary px-7"
                    onClick={handleReset}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Sidebar with Ticket Status */}
        <div className="ticket-sidebar">
          <div className="pt-5 md:p-2.5">
            <div className=" flex justify-between mb-3.5 md:px-3.5">
              <button
                className={
                  activeTab === "awaiting" ? "active-tab" : "sidebar-tab"
                }
                onClick={() => handleTabClick("awaiting")}
              >
                Awaiting Tickets
              </button>
              <button
                className={
                  activeTab === "resolved" ? "active-tab" : "sidebar-tab"
                }
                onClick={() => handleTabClick("resolved")}
              >
                Resolved Tickets
              </button>
              <button
                className={
                  activeTab === "reopened" ? "active-tab" : "sidebar-tab"
                }
                onClick={() => handleTabClick("reopened")}
              >
                Reopened Tickets
              </button>
            </div>

            <div className="rounded-lg md:p-3.5 max-h-[65vh] overflow-y-auto">
              {itemList?.length !== 0 ? (
                <div>
                  {itemList?.map((item, index) => (
                    <div
                      onClick={() => handleCardClick(item)} // Add click handler
                      className="ticket-item cursor-pointer"
                      key={index}
                    >
                      <div className="avatar">
                        <span className="avatar-icon">&#128100;</span>
                      </div>
                      <div className="ticket-details">
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p className="complain-heading">{item.userName}</p>
                          <div
                            className={
                              activeTab == "resolved"
                                ? "tag closing-tag"
                                : "tag awaiting-tag"
                            }
                          >
                            {activeTab == "resolved" ? "Closed" : activeTab == "reopened" ? "Reopened" : "Awaiting"}
                          </div>
                        </div>
                        <span className="reference-number">{item.orderId}</span>
                        <div className="item-set">
                          <p className="complain-heading">Created</p>
                          <p className="ticket-info">
                            {new Date(item?.createdAt).toLocaleString()}
                          </p>
                        </div>
                        <div className="item-set">
                          <p className="complain-heading">Reason</p>
                          <p className="ticket-info font-bold">
                            {item.subject}
                          </p>
                          <p className="ticket-info">{item.message}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="item-center flex justify-center mt-12 font-bold text-[var(--softText)]">
                  No {activeTab} tickets available..!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complaint;
