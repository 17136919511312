//  CorporatesPage

import React, { useEffect, useState } from "react";
import "./ContactusPage.css"; // Use a CSS file for styles
// import 'primeflex/primeflex.css';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import ContactUs from "./../../Images/ContactUs.png";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { toast } from "material-react-toastify";
import { useGetContactMutation } from "../../redux/service/contactSlice";

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces")
    .max(50, "Name must not exceed 50 characters")
    .min(3, "Name must be at least 3 characters")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .max(254, "Email must not exceed 254 characters")
    .min(3, "Email must be at least 3 characters")
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone Number is required").min(10, "Phone Number must be at least 10 characters")
    .test("is-valid-length", "Invalid phone number", (value) => {
      if (!value) return false; // Required field check
      const countryCode = value.slice(0, 3); // First 3 characters for country code
      if (countryCode === "+94") {
        
        return value.length === 12;
      }
      // Other countries (default to max 15 characters)
      return value.length <= 15;
    }),
  date: Yup.date().required("Date is required"),
  category: Yup.string().required("Category is required"),
  message: Yup.string().required("Message is required"),
});

const ContactusPage = () => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [createContact, { isLoading }] = useGetContactMutation();

  const contactHandler = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      await createContact(formData)
        .unwrap()
        .then((res) => {
          setFormData({
            name: "",
            email: "",
            phone: "",
            date: "",
            message: "",
          });
          toast.success("Message sent successfully");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.data.message || "Something went wrong");
        });
    } catch (err) {
      if (err.inner) {
        const errorMessages = {};
        err.inner.forEach((validationError) => {
          errorMessages[validationError.path] = validationError.message;
        });
        setErrors(errorMessages);
      }
    }
  };

  const categories = [
    { label: "Birthday", value: "Birthday" },
    { label: "Wedding", value: "Wedding" },
    { label: "Corporate", value: "Corporate" },
  ];
console.log(formData.phone)
  return (
    <div className="px-2 md:px-[8%]  container">
      <div className="grid md:grid-cols-2 gap-5 p-5 rounded-lg border border-gray-300 m-8 mx-auto">
        <div className="md:flex-[0.5] p-7.5 order-2 md:order-1">
          <div className="text-center md:text-start">
            <h1>Planning a special event?</h1>
            <p className="text-[var(--color)]">
              Our team is ready to help you out. Please fill out the form below
              to connect with our event specialist.
            </p>
          </div>
          <form>
            <div className="mb-3">
              <label
                className="block text-sm mb-2 ms-0 font-bold text-[var(--softText)]"
                htmlFor="name"
              >
                Name
              </label>
              <InputText
                id="name"
                placeholder="Name"
                className="p-inputtext-lg border border-gray-300 rounded h-10 md:h-12 px-2 text-[var(--color)] "
                onChange={async(e) => {
                  setFormData({ ...formData, name: e.target.value });
                  try {
                    await validationSchema.validateAt("name", {
                      name: e.target.value,
                    })

                    setErrors({ ...errors, name: "" });
                  } catch (error) {
                    setErrors({ ...errors, name: error.message });
                  }
                }}
                value={formData.name}
              />
              {errors.name && <p className="text-red-500">{errors.name}</p>}
            </div>
            <div className="mb-3">
              <label
                className="block text-sm mb-2 ms-0 font-bold  text-[var(--softText)]"
                htmlFor="email"
              >
                Email Address
              </label>
              <InputText
                id="email"
                placeholder="Email Address"
                className="p-inputtext-lg border border-gray-300 rounded h-10 md:h-12 pl-2 text-[var(--color)] "
                onChange={async(e) => {
                  setFormData({ ...formData, email: e.target.value });
                  try {
                    await validationSchema.validateAt("email", {
                      email: e.target.value,
                    })
                    setErrors({ ...errors, email: "" });
                  } catch (error) {
                    setErrors({ ...errors, email: error.message });
                  }
                }}
                value={formData.email}
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </div>
            <div className="mb-3">
              <label
                className="block text-sm mb-2 ms-0 font-bold  text-[var(--softText)]"
                htmlFor="phone"
              >
                Phone Number
              </label>
              <PhoneInput
                id="phone"
                value={formData.number}
                onChange={async(e) => {
                  setFormData({ ...formData, phone: e });
                  try {
                    await validationSchema.validateAt("phone", { phone: e });
                    setErrors({ ...errors, phone: "" });
                  } catch (error) {
                    setErrors({ ...errors, phone: error.message });
                  }
                }}
                // international={false}
                // disableCountrySelect
                defaultCountry="LK"
                country="LK"
                className="border  border-gray-300 rounded-md  p-2 w-full outline-none focus:ring-2 focus:ring-blue-500"
              />
              
              {errors.phone && <p className="text-red-500">{errors.phone}</p>}
            </div>
            <div className="mb-3">
              <label
                className="block text-sm mb-2 ms-0 font-bold  text-[var(--softText)]"
                htmlFor="category"
              >
                Category
              </label>
              <Dropdown
                id="category"
                options={categories}
                placeholder="Select a category"
                className="p-inputtext-lg border border-gray-300 rounded h-10 md:h-12 text-[var(--color)] "
                onChange={(e) =>{

                  setFormData({ ...formData, category: e.value })
                  setErrors({ ...errors, category: "" })
                }
                }
                value={formData.category}
              />
              {errors.category && (
                <p className="text-red-500">{errors.category}</p>
              )}
            </div>
            <div className="mb-3">
              <label
                className="block text-sm mb-2 ms-0 font-bold  text-[var(--softText)]"
                htmlFor="date"
              >
                Date
              </label>
              <Calendar
                id="date"
                placeholder="DD/MM/YYYY"
                minDate={new Date()}
                className="p-inputtext-lg border border-gray-300 rounded h-10 md:h-12 pl-2 text-[var(--color)] "
                onChange={(e) => {
                  setFormData({ ...formData, date: e.value });
                  setErrors({ ...errors, date: "" });
                }}
                value={formData.date}
              />
              {errors.date && <p className="text-red-500">{errors.date}</p>}
            </div>
            <div className="mb-3">
              <label
                className="block text-sm mb-2 ms-0 font-bold  text-[var(--softText)]"
                htmlFor="message"
              >
                Message
              </label>
              <InputTextarea
                id="message"
                rows={4}
                placeholder="Write a message"
                className="p-inputtext-lg border border-gray-300 rounded h-auto pl-2 pt-2 text-[var(--color)] "
                onChange={(e) => {
                  setFormData({ ...formData, message: e.target.value });
                  setErrors({ ...errors, message: "" });
                }}
                value={formData.message}
              />
              {errors.message && (
                <p className="text-red-500">{errors.message}</p>
              )}
            </div>
            <Button
              label="Submit"
              loading={isLoading}
              onClick={contactHandler}
              className="contactusSubmit-button mt-5 w-full bg-[var(--green)] border-none text-white py-2 px-6 text-base rounded cursor-pointer hover:bg-green-700"
            />
          </form>
        </div>
        <div className="flex justify-center mb-10 md:mb-0 md:flex-[0.1] order-1">
          <img
            className="flex-1 max-w-full rounded-lg max-h-[200px] md:max-h-[750px] object-cover "
            src={ContactUs}
            alt="Event"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactusPage;
