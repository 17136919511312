import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: "LKR",
  city: localStorage.getItem("city") || "",
};

const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
      localStorage.setItem("city", action.payload);
    },
    removeCity: (state) => {
      state.city = "";
      localStorage.removeItem("city");
    },
  },
});

export const { setCurrency, setCity, removeCity } = metaSlice.actions;

export default metaSlice.reducer;
