// Import white images
import white1 from './../../Images/White/1white.png';
import white2 from './../../Images/White/2white.png';
import white3 from './../../Images/White/3white.png';
import white4 from './../../Images/White/4white.png';
import white5 from './../../Images/White/5white.png';
import white6 from './../../Images/White/6white.png';
import white7 from './../../Images/White/7white.png';
import white8 from './../../Images/White/8white.png';
import white9 from './../../Images/White/9white.png';
import white10 from './../../Images/White/10white.png';
import white11 from './../../Images/White/11white.png';
import white12 from './../../Images/White/12white.png';
import white13 from './../../Images/White/13white.png';
import white14 from './../../Images/White/14white.png';

import dark1 from './../../Images/dark/1dark.png'
import dark2 from './../../Images/dark/2dark.png'
import dark3 from './../../Images/dark/3dark.png'
import dark4 from './../../Images/dark/4dark.png'
import dark5 from './../../Images/dark/5dark.png'
import dark6 from './../../Images/dark/6dark.png'
import dark7 from './../../Images/dark/7dark.png'
import dark8 from './../../Images/dark/8dark.png'
import dark9 from './../../Images/dark/9dark.png'
import dark10 from './../../Images/dark/10dark.png'
import dark11 from './../../Images/dark/11dark.png'
import dark12 from './../../Images/dark/12dark.png'
import dark13 from './../../Images/dark/13dark.png'
import dark14 from './../../Images/dark/14dark.png'



const whiteImages = [
    { src: white1, alt: 'Ceylon Arrack 1' },
    { src: white2, alt: 'Ceylon Arrack 2' },
    { src: white3, alt: 'Ceylon Arrack 3' },
    { src: white4, alt: 'Ceylon Arrack 4' },
    { src: white5, alt: 'Ceylon Arrack 5' },
    { src: white6, alt: 'Ceylon Arrack 6' },
    { src: white7, alt: 'Ceylon Arrack 7' },
    { src: white8, alt: 'Ceylon Arrack 8' },
    { src: white9, alt: 'Ceylon Arrack 9' },
    { src: white10, alt: 'Ceylon Arrack 10' },
    { src: white11, alt: 'Ceylon Arrack 11' },
    { src: white12, alt: 'Ceylon Arrack 12' },
    { src: white13, alt: 'Ceylon Arrack 13' },
    { src: white14, alt: 'Ceylon Arrack 14' },
];


const darkImages = [
    { src: dark1, alt: 'Ceylon Arrack 1' },
    { src: dark2, alt: 'Ceylon Arrack 2' },
    { src: dark3, alt: 'Ceylon Arrack 3' },
    { src: dark4, alt: 'Ceylon Arrack 4' },
    { src: dark5, alt: 'Ceylon Arrack 5' },
    { src: dark6, alt: 'Ceylon Arrack 6' },
    { src: dark7, alt: 'Ceylon Arrack 7' },
    { src: dark8, alt: 'Ceylon Arrack 8' },
    { src: dark9, alt: 'Ceylon Arrack 9' },
    { src: dark10, alt: 'Ceylon Arrack 10' },
    { src: dark11, alt: 'Ceylon Arrack 11' },
    { src: dark12, alt: 'Ceylon Arrack 12' },
    { src: dark13, alt: 'Ceylon Arrack 13' },
    { src: dark14, alt: 'Ceylon Arrack 14' },
];

export { whiteImages , darkImages};
