// src/components/Header.js
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
  useGetProfileQuery,
  useUpdateCartAddressMutation,
  useUpdateProfileAddressMutation,
} from "../../redux/service/profileSlice";
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useGetAllCityQuery, useGetAllProvinceAndCityQuery } from "../../redux/service/citySlice";
import {
  useCartAddressUpdateMutation,
  useGetCartItemsQuery,
} from "../../redux/service/cartSlice";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { countries } from "../Header";

const provinces = [
  "Central",
  "Eastern",
  "North Central",
  "Northern",
  "North Western",
  "Sabaragamuwa",
  "Southern",
  "Uva",
  "Western",
];

const AddBillingInfo = ({ isModalVisible, toggleModal, cartId }) => {
  const [updateAddress, { isLoading }] = useUpdateProfileAddressMutation();
  const [cartAddress] = useUpdateCartAddressMutation();

  const user = useSelector((state) => state?.auth?.userInfo);
  const { data: profile } = useGetProfileQuery(user?.id, {
    skip: !user?.id,
  });
  const { data: cities } = useGetAllCityQuery();
  const [setCartAddress] = useCartAddressUpdateMutation();

  const { data: cartData } = useGetCartItemsQuery({
    cartType: "normal",
    userId: user?.id,
  });

  const [formData, setFormData] = useState({
    country: "Sri Lanka",
    city: localStorage.getItem("city") || "",
  });
  const [formErrors, setFormErrors] = useState({});

  const cityName = cities?.data?.content?.filter(
    (city) => city.id === Number(localStorage.getItem("city"))
  )[0]?.cityName;

  const { data: ProvinceAndCity } = useGetAllProvinceAndCityQuery()

  const provinces = ProvinceAndCity?.data?.content.map((city) => city.province);
  const Citys = ProvinceAndCity?.data?.content.map((city) => ({
    province: city.province,
    cityName: city.cityName,
  }));


  function getCitiesByProvince(selectedProvince) {
    return Citys?.filter((city) => city.province === selectedProvince).map(
      (city) => city?.cityName
    );
  }
  const filteredCitys = getCitiesByProvince(formData?.province);
  const uniqueProvinces = [...new Set(provinces)];


  console.log(formData)

  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     city: cityName || undefined,
  //   }));
  // }, [cityName]);

  useEffect(() => {
    //console.log("check");
    // Find the city by ID
    const selectedCity = cities?.data?.content.find(
      (city) => city.cityName === formData.city
    );

    // console.log("selectedCity", formData.city);

    if (selectedCity) {
      setFormData((prev) => ({ ...prev, province: selectedCity.province }));
    }
  }, [formData.city, cities]);

  const validationSchema = Yup.object(
    Object.assign(
      {},
      !user ? { name: Yup.string().required("Name is required") } : {},
      !user
        ? { number: Yup.string().required("Phone Number is required") }
        : {},
      {
        addressLine1: Yup.string().required("Address Line 1 is required"),
        addressLine2: Yup.string().required("Address Line 2 is required"),

        postalCode: Yup.string().length(
          5,
          "Postal Code must be exactly 5 characters"
        ),
        // .required("Postal Code is required"),
        country: Yup.string().required("Country is required"),
        number: Yup.string()
          .required("Phone Number is required")
          .matches(
            /^\+?\d+$/,
            "Phone Number must contain only numbers and may start with a '+'"
          )
          .min(10, "Phone Number must be at least 10 digits")
          .max(15, "Phone Number must not exceed 15 digits"),
      },
      formData.country === "Sri Lanka" && {
        province: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
      }
    )
  );

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setFormErrors({});

      if (user) {
        const updatedBillingAddresses = [
          ...(profile?.data?.billingAddresses || []),
          {
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            city: formData.country === "Sri Lanka" ? formData.city : "",
            province: formData.country === "Sri Lanka" ? formData.province : "",
            postalCode: formData.postalCode,
            country: formData.country,
            contactNumber: formData.number,
          },
        ];

        const billingAddressesWithoutId = updatedBillingAddresses.map(
          ({ id, ...rest }) => rest
        );

        const deliveryAddressesWithoutId = profile?.data?.deliveryAddresses?.map(
          ({ id, ...rest }) => rest
        );

        await updateAddress({
          id: user.id,
          formData: {
            billingAddresses: billingAddressesWithoutId,
            deliveryAddresses: deliveryAddressesWithoutId,
          },
        })
          .unwrap()
          .then(() => {
            toast.success("Billing information saved successfully!");
            setFormData({
              ...formData,
              name: "",
              number: "",
              addressLine1: "",
              addressLine2: "",
              postalCode: "",
              // country: "",
            });
          });
      } else {
        await setCartAddress({
          cartId: cartData?.data?.id,
          formData: {
            billingAddress: `${formData.name}, ${formData.addressLine1}, ${formData.addressLine2}, ${formData.city}, ${formData.province}, ${formData.postalCode}, ${formData.country} ${formData.number}`,
          },
        }).unwrap();
      }

      toggleModal();
    } catch (error) {
      if (error.inner) {
        const errorMessages = {};
        error.inner.forEach((validationError) => {
          errorMessages[validationError.path] = validationError.message;
        });
        setFormErrors(errorMessages);
        console.log(errorMessages)
      } else {
        toast.error(error.message || "Something went wrong");
      }
    }
  };

  return (
    <Dialog
      visible={isModalVisible}
      closable={true}
      className="custom-dialog "
      onHide={() => {
        toggleModal()
        setFormData({
          ...formData,
          name: "",
          number: "",
          addressLine1: "",
          addressLine2: "",
          postalCode: "",
          // country: "",
        });
        setFormErrors({});
      }}
      breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
      style={{ width: "30vw" }}
    >
      <div className="address-modal-inputs ">
        <div className=" px-10">
          <h2 className="address-modal-title ">Add New Billing Information</h2>
          <div className="modal-divider my-2"></div>
          <span className="address-modal-description ">
            All fields are required to complete the billing information. Please
            ensure all sections are filled out before submitting.
          </span>
        </div>

        {/* <div className="address-input-group">
              <label className="modal-label">Name</label>
              <InputText
                value={formData.name}
                onChange={(e) =>{

                  setFormData({ ...formData, name: e.target.value })
                  setFormErrors((prev) => ({ ...prev, name: "" }));
                }
                }
                placeholder="Please enter your name"
                className="login-modal-inputs"
              />
              {formErrors.name && (
                <small className="error-text">{formErrors.name}</small>
              )}
            </div> */}

        <div className="address-input-group">
          <label className="modal-label">Address Line 1</label>
          <InputText
            value={formData.addressLine1}
            onChange={(e) => {
              setFormData({ ...formData, addressLine1: e.target.value });
              setFormErrors((prev) => ({ ...prev, addressLine1: "" }));
            }}
            placeholder="Please enter your address line 1"
            className="login-modal-inputs"
          />
          {formErrors.addressLine1 && (
            <small className="error-text">{formErrors.addressLine1}</small>
          )}
        </div>

        <div className="address-input-group">
          <label className="modal-label">Address Line 2</label>
          <InputText
            value={formData.addressLine2}
            onChange={(e) => {
              setFormData({ ...formData, addressLine2: e.target.value });
              setFormErrors((prev) => ({ ...prev, addressLine2: "" }));
            }}
            placeholder="Please enter your address line 2"
            className="login-modal-inputs"
          />
          {formErrors.addressLine2 && (
            <small className="error-text">{formErrors.addressLine2}</small>
          )}
        </div>

        <div className="address-input-group">
          <label className="modal-label">Phone Number</label>
          <PhoneInput
            id="phone"
            value={formData.number}
            onChange={(e) => {
              setFormData((prev) => ({ ...prev, number: e }));
              setFormErrors((prev) => ({ ...prev, number: "" }));
            }}
            defaultCountry="LK"
            className="border border-gray-300 rounded-md  p-2 w-full outline-none focus:ring-2 focus:ring-blue-500"
          />
          {formErrors.number && (
            <small className="error-text">{formErrors.number}</small>
          )}
        </div>

        <div className="address-input-group">
          <label className="modal-label">Country</label>
          <select
            className=" w-full py-2 px-1 border border-gray-300 bg-transparent rounded-lg outline-none"
            onChange={(e) => {
              setFormData({ ...formData, country: e.target.value });
              setFormErrors((prev) => ({ ...prev, country: "" }));
            }}
            defaultValue={formData.country}
          >
            {countries.map((country) => (
              <option
                className={`${localStorage.getItem("theme") === "dark" ? "bg-[#1F2D40]" : ""
                  }`}
                key={country}
                value={country}
              >
                {country}
              </option>
            ))}
          </select>
          {formErrors.country && (
            <small className="error-text">{formErrors.country}</small>
          )}
        </div>

        {formData.country === "Sri Lanka" && (
          <>
            <div className="flex gap-5 w-[78%]">
              <div className="address-input-group">
                <label className="modal-label">Province</label>
                <select
                  className=" w-full py-2 px-1 border border-gray-300 bg-transparent rounded-lg outline-none"
                  onChange={(e) => {
                    setFormData({ ...formData, province: e.target.value });
                    setFormErrors((prev) => ({ ...prev, province: "" }));
                  }}
                  value={formData.province}
                >
                  <option
                    value=""
                    className={`${localStorage.getItem("theme") === "dark"
                        ? "bg-[#1F2D40]"
                        : ""
                      }`}
                  >
                    Select Province
                  </option>
                  {uniqueProvinces.map((province) => (
                    <option
                      className={`${localStorage.getItem("theme") === "dark"
                          ? "bg-[#1F2D40]"
                          : ""
                        }`}
                      key={province}
                      value={province}
                    >
                      {province}
                    </option>
                  ))}
                </select>
                {formErrors.province && (
                  <small className="error-text">{formErrors.province}</small>
                )}
              </div>

              <div className="address-input-group-custom">
                <label className="modal-label">City</label>
                <select
                  className="p-2 border w-4/5 md:w-full border-gray-300 rounded-md"
                  onChange={(e) => {
                    setFormData({ ...formData, city: e.target.value });
                    setFormErrors((prev) => ({ ...prev, city: "" }));
                  }}
                  defaultValue={formData.city}
                >
                  <option
                    value=""
                    className={`${localStorage.getItem("theme") === "dark"
                        ? "bg-[#1F2D40]"
                        : ""
                      }`}
                  >
                    Select City
                  </option>
                  {filteredCitys?.map((city, index) => (
                    <option
                      key={city.id}
                      className={`${localStorage.getItem("theme") === "dark"
                          ? "bg-[#1F2D40]"
                          : ""
                        }`}
                      value={city}
                    >
                      {city}
                    </option>
                  ))}
                </select>
                {formErrors.city && (
                  <small className="error-text">{formErrors.city}</small>
                )}
              </div>
            </div>
          </>
        )}
        {formData.country === "Sri Lanka" && (
          <div className="address-input-group">
            <label className="modal-label">Postal Code</label>
            <InputText
              value={formData.postalCode}
              type="number"
              onChange={(e) => {
                setFormData({ ...formData, postalCode: e.target.value });
                setFormErrors((prev) => ({ ...prev, postalCode: "" }));
              }}
              placeholder="Please enter your Postal Code"
              className="login-modal-inputs"
            />
            {formErrors.postalCode && (
              <small className="error-text">{formErrors.postalCode}</small>
            )}
          </div>
        )}
        <div className="add-delivery-modal-footer">
          <Button
            label="Reset"
            className="bill-address-save-btn py-1"
            onClick={() => {
              setFormData({
                ...formData,
                name: "",
                number: "",
                addressLine1: "",
                addressLine2: "",
                postalCode: "",
                // country: "",
              });
              setFormErrors({});
            }}
          />
          <Button
            label="Save"
            className="bill-address-save-btn py-1"
            loading={isLoading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Dialog>
  );
};
export default AddBillingInfo;
