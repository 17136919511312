// src/components/Header.js
import React from 'react';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import finestwinePosterImage1 from './../Images/finestwinePosterImage1.png';
import wineOfTheMonth from './../Images/wineOfTheMonth.png';
import art1 from './../icons/art1.png';
import art2 from './../icons/art2.png';

const SelectionOfWineCard = () => {

    return (
        // <div className="wine-section-container">
        <div className="flex flex-col lg:flex-row justify-between items-stretch my-5 mx-4 mb-4 selection-wine">
            <div className="h-full flex-1 p-2.5 rounded-lg ">

                {/* First Image */}

                <img
                    src={finestwinePosterImage1}
                    alt="Wine being poured"
                    // width="100%"
                    // height='100%'
                    className="h-80 md:h-5/6 w-full object-cover rounded-lg" />
            </div>

            <div className="flex-1 pt-5 pb-5 flex flex-col items-center justify-between">

                {/* Middle Card */}
                <div className="flex flex-[0.2] items-center">
                    <img src={art1} style={{ width: "193px", }} />
                </div>
                <div className="flex-[0.6]"> <Card className="mx-5 shadow-none text-center bg-transparent"> <h3 className="text-2xl font-bold text-[var(--color)]">Experience The Finest<br /> Selection Of Wines</h3> <p className="text-lg text-[var(--lightCardText)] mt-2 leading-6"> As Sri Lanka's premier destination for premium wines and spirits, WineWorld invites you to indulge in our exquisite collection. With a passion for exceptional beverages, our extraordinary selection caters to discerning enthusiasts, delivering unrivaled value, quality, and unmatchable prices. Discover the perfect wine or spirit that matches your unique taste and occasion. </p> </Card> </div>

                <div className="flex flex-[0.2] items-center">
                    <img src={art2} style={{ width: "193px", }} />
                </div>
            </div>

            < div className="h-full flex-1 p-2.5 rounded-lg">
                {/* First Image */}
                <img
                    src={wineOfTheMonth}
                    alt="Wine being poured"
                    // width="100%"
                    // height='100%'
                    // className="wine-image  h-80 md:h-5/6 w-full  object-cover"
                    className="h-80 md:h-5/6 w-full object-cover rounded-lg"

                />
            </div>
        </div>
    );
};

export default SelectionOfWineCard;
