// src/components/Header.js
import React from "react";
import whiteLogo from "./../icons/whiteLogo.png";
import PaymentLogos from "./../icons/PaymentLogos.png";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import scrolldown from "../../src/icons/scrolldown.gif";
import { useSubscribeMutation } from "../redux/service/subscriberSlice";
import { toast } from "material-react-toastify";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const Footer = (productData) => {
  const navigate = useNavigate();
  const [subscribe , { isLoading}] = useSubscribeMutation();
  const [formData, setFormData] = React.useState({
    email: "",
  });
  const [formErrors, setFormErrors] = React.useState({});

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSubscribe = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      await subscribe(formData)
        .unwrap()
        .then((res) => {
          toast.success("Subscribed successfully");
          setFormData({
            email: "",
          });
          setFormErrors({});
        }).catch((err) => {
          toast.error(err?.data?.message || "Something went wrong");
        });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setFormErrors(validationErrors);
      }
    }
  };

  return (
    <div className="px-3  md:px-8 mt-10">
      <div className="bg-[#1b1f28] text-white flex flex-col md:flex-row rounded-lg p-5 md:p-8">
        <div className="flex justify-end md:hidden">
          <div className="bg-orange-500 p-2 sm:p-3 md:p-4 rounded-lg cursor-pointer absolute">
            <img
              className="h-6 w-6 sm:h-7 sm:w-7 md:h-10 md:w-10 rotate-180"
              src={scrolldown}
              alt="Scroll down"
              onClick={handleScrollToTop} // Scroll to top on click
            />
          </div>
        </div>
        <div className="flex-[0.3] flex flex-row mb-4 md:mb-0 justify-center md:justify-start">
          <img
            className="w-48 sm:w-64 md:w-80 object-contain "
            src={whiteLogo}
            alt="Footer Icon"
          />
        </div>

        <div className="flex-[0.4] flex flex-col mb-4 md:mb-0 text-center md:text-left">
          <p className="my-1">
            Wine World, No 102 and 104, <br />
            Kumaran Ratnam Road, <br />
            Colombo 2, <br />
            Sri Lanka | +94 71 234 5234
          </p>

          <div className="opacity-70 my-4">
            <a href="mailto:wwinfo@wineworld.lk">wwinfo@wineworld.lk</a>
          </div>

          <div className="my-0">
            <h5 className="font-bold text-[18px] font-Mulish">
              Customer Service
            </h5>
            <p>(9.00AM – 9.00PM)</p>
          </div>

          <div className="flex items-center justify-center md:justify-start md:mt-4">
            <a
              href="https://maps.app.goo.gl/9hNhsFyiRDmC5fzk6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="pi pi-map-marker text-white text-[1.3em]"></i>
            </a>
            <span className="ml-2.5 text-white">Reach Us</span>
          </div>
          <div className="flex items-center md:mt-4 flex-col md:flex-row">
            <div className=" mb-2 md:mb-0">
              <span className="text-white">Follow Us :</span>
            </div>
            <div className="flex space-x-3 text-white ml-2.5">
              <a
                href="https://web.facebook.com/wineworldlk?_rdc=1&_rdr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="pi pi-facebook text-[1.3em]"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCXGLx8t1A7gfTGwe6fXOt7g"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="pi pi-youtube text-[1.3em]"></i>
              </a>
              <a
                href="https://www.instagram.com/wineworldsrilanka/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="pi pi-instagram text-[1.3em]"></i>
              </a>
              <a
                href="https://www.tiktok.com/@wineworld.lk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="pi pi-tiktok text-[1.3em]"></i>
              </a>
            </div>
          </div>
          {/* <div className="w-[85%] border-t border-[#ced4da] my-5"></div> */}
          <div className="flex flex-col mt-4 items-center md:hidden">
            <span className="text-white">Payment methods :</span>
            <img src={PaymentLogos} className="w-3/5 mt-2" />
            <div className="w-[85%] border-t border-[#ced4da] mt-3"></div>
          </div>
        </div>

        {/* <!-- Right Section --> */}
        <div className="flex-[0.3] flex flex-col items-center md:items-end  md:pt-4 pt-0 justify-center">
          <div className="flex flex-col items-center md:items-end mb-4    md:p-4 p-0  md:justify-end">
            <div className="bg-orange-500 hidden md:block p-1 rounded-lg cursor-pointer mb-3 ">
              <img
                className="h-7 w-7 rotate-180 cursor-pointer"
                src={scrolldown}
                alt="Scroll down"
                onClick={handleScrollToTop} // Scroll to bottom on click
              />
            </div>
            <div className="flex items-center justify-center space-x-4 mb-3">
              <span className="text-white text-lg sm:text-xl md:text-2xl mb-2 text-center">
                SIGN UP FOR OUR NEWSLETTER
              </span>
            </div>

            <div className="flex flex-row items-center justify-center w-full max-w-lg">
              <input
                type="text"
                value={formData.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                  setFormErrors({ ...formErrors, email: "" });
                }}
                placeholder="Enter your email address"
                className="px-2 py-2 text-[var(--softText)] border-none rounded-l w-full sm:flex-1 text-sm sm:text-base md:text-lg"
              />
              <button  onClick={handleSubscribe} className={`bg-[#42866E]  text-white py-2 px-4 rounded-r text-sm sm:text-base md:text-lg w-auto ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}>
                {isLoading ? "Subscribing..." : "Subscribe"}
              </button>
            </div>
            <div className="w-full ">

              {formErrors.email && (
                <span className="text-red-500 my-3">{formErrors.email}</span>
              )}
              </div>
          </div>

          <div className="hidden md:block">
            <div className=" border-t border-[#ced4da] my-5"></div>
            <div className="flex flex-col items-end">
              <span className="text-white">Payment methods :</span>
              <img src={PaymentLogos} className="w-3/5  mt-2 " alt="Payment Logos"/>
            </div>
          </div>
        </div>
      </div>

      <div className="py-8 md:px-16 px-3 ">
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4 footer-row">
          <div className="footer-column">
            <h4 className="footer-heading">ABOUT US</h4>
            <ul className="footer-list">
              <li
                onClick={() => {
                  navigate("/about");
                  window.scrollTo(0, 0);
                }}
              >
                About Us
              </li>
              <li
                onClick={() => {
                  navigate("/contact-us/");
                  window.scrollTo(0, 0);
                }}
              >
                Contact Us
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4 className="footer-heading">PRODUCT RANGE</h4>
            <ul className="footer-list">
              <li
                onClick={() => {
                  navigate("/product-category/1");
                  window.scrollTo(0, 0);
                }}
              >
                Wines
              </li>
              {/* <li onClick={() => { navigate("/Champagne"); window.scrollTo(0, 0); }}>Champagne</li> */}
              <li
                onClick={() => {
                  navigate("/product-category/2");
                  window.scrollTo(0, 0);
                }}
              >
                Spirits
              </li>
              <li
                onClick={() => {
                  navigate("/product-category/3");
                  window.scrollTo(0, 0);
                }}
              >
                Mixers
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4 className="footer-heading">INFORMATION PAGES</h4>
            <ul className="footer-list">
              <li
                onClick={() => {
                  navigate("/place-an-order");
                  window.scrollTo(0, 0);
                }}
              >
                Place an Order
              </li>
              <li
                onClick={() => {
                  navigate("/returns-and-refunds");
                  window.scrollTo(0, 0);
                }}
              >
                Returns and Refunds
              </li>
              {/* <li>Privacy Policy</li> */}
              <li
                onClick={() => {
                  navigate("/privacy-policy");
                  window.scrollTo(0, 0);
                }}
              >
                Privacy Policy
              </li>

              <li
                onClick={() => {
                  navigate("/terms-and-conditions");
                  window.scrollTo(0, 0);
                }}
              >
                Terms and Conditions
              </li>
              <li
                onClick={() => {
                  navigate("/concierge-policy");
                  window.scrollTo(0, 0);
                }}
              >
                Concierge Policy
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4 className="footer-heading">NAVIGATION</h4>
            <ul className="footer-list">
              <li
                onClick={() => {
                  navigate("/for-weddings/");
                  window.scrollTo(0, 0);
                }}
              >
                Wedding
              </li>
              <li
                onClick={() => {
                  navigate("/for-corporates/");
                  window.scrollTo(0, 0);
                }}
              >
                Corporate
              </li>
              <li
                onClick={() => {
                  navigate("/event-and-services/");
                  window.scrollTo(0, 0);
                }}
              >
                Services
              </li>
              <li
                onClick={() => {
                  navigate("/blog/");
                  window.scrollTo(0, 0);
                }}
              >
                Blog
              </li>
            </ul>
          </div>
          {/* <div className="footer-column">
            <h4 className="footer-heading">BEERS & MIXERS</h4>
            <ul className="footer-list">
              <li onClick={() => { navigate("/for-weddings/"); window.scrollTo(0, 0); }}>Beers</li>
              <li>Toddy</li>
              <li>Bitters</li>
              <li>Puree</li>
            </ul>
          </div> */}
        </div>

        <div
          className={`w-full h-[1px] ${
            localStorage.getItem("theme") === "dark"
              ? "bg-gradient-to-r from-transparent via-[#13382B] to-transparent"
              : "bg-[#13382B] "
          }  my-8`}
        ></div>

        <div className="flex justify-center mt-4">
          <small className="text-center text-[var(--softText)]">
            © Copyright 2024. All Rights Reserved. Designed by
            <a href="https://elysiancrest.com/" className="footer-link">
              {" "}
              Elysian Crest
            </a>
          </small>
        </div>
      </div>
    </div>
  );
};

export default Footer;
