//  CorporatesPage

// src/pages/HomePage.js
import React, { useEffect, useState } from "react";
import "./WishList.css"; // Use a CSS file for styles
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
// import 'primeflex/primeflex.css';
import { useDispatch, useSelector } from "react-redux";
import Monkey_ShoulderBottle from "./../../Images/Monkey_Shoulder.png";

import { Card } from "primereact/card";
import ProductLists from "../../components/ProductLists";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import WishListItem from "../../components/WishListItem";
import { useAddfavItemMutation, useDeleteFavoriteItemMutation, useGetFavoriteItemsQuery } from "../../redux/service/favouriteSlice";

const WishList = () => {
  const navigate = useNavigate(); // Initialize the navigate hook
  const [selectedOrderType, setSelectedOrderType] = useState("favourites");
  const userId = useSelector((state) => state?.auth?.userInfo?.id);
  console.log("userId", userId);
  // Fetch favorites using the API
  const { data, isLoading: productLoading, refetch: refetchProduct } = useGetFavoriteItemsQuery(userId, {
    skip: !userId, // Skip the query if userId is not available
  });

  const [addfavItem, { isLoading: addFavLoading }] = useAddfavItemMutation();
  const [deletefavItem, { isLoading: deleteFavLoading }] = useDeleteFavoriteItemMutation();

  const handleOrderTypeChange = (type) => {
    setSelectedOrderType(type);
  };

  // Log data for debugging
  useEffect(() => {
    console.log("Fetched Favorites Data:", data);
  }, [data]);

  const toggleFavourite = async ({ productId, isFavorite }) => {

    console.log("isFavorite", isFavorite);
    console.log("productId", productId);
    if (isFavorite == true) {
      try {
        // Trigger the mutation to add to favorites
        await deletefavItem({
          userId,
          productId,
        }).unwrap();
        console.log("Item removed from favorites.");
        refetchProduct();
      } catch (error) {
        console.error("Error adding to favorites:", error);
      }

    } else {
      try {
        await addfavItem({
          userId,
          productId,
        }).unwrap();
        refetchProduct();
      } catch (error) {
        console.error("Error adding to favorites:", error);
      }
    }


  };

  return (
    <div className="main container">

      <div className="flex">
        <p className="cursor-pointer text-[var(--color)] text-[12px] md:text-[16px] pt-[60px] md:pt-0">
          <Link to="/" className="mr-2"> Home </Link>   {">"}<span className="text-[#DA813D] mx-2"> Favourites </span>
        </p>
      </div>
      {/* <div className="flex gap-2.5 mb-5">

        <button
          className={`${selectedOrderType === "favourites"
            ? "bg-black text-white border-black"
            : "bg-white text-black"
            } py-2 px-4 text-base cursor-pointer border border-gray-300 rounded transition duration-300`}
          onClick={() => handleOrderTypeChange("favourites")}
        >
          Favourites
        </button>
        <button
          className={`${selectedOrderType === "wishlist"
            ? "bg-black text-white border-black"
            : "bg-white text-black"
            } py-2 px-4 text-base cursor-pointer border border-gray-300 rounded transition duration-300`}
          onClick={() => handleOrderTypeChange("wishlist")}
        >
          Wish List
        </button>
      </div> */}

      {productLoading ? (
        <p>Loading...</p>
      ) : data && data.length > 0 ? (
        data.map((item) => (
          <WishListItem
            productId={item.productId}
            productName={item.correctName}
            productImageUrl={item.productImageUrl}
            regularPriceInRupee={item.regularPriceInRupee}
            regularPriceInDollar={item.regularPriceInDollar}
            isFavorite={item.isFavorite}
            onClick={() => toggleFavourite({ productId: item.productId, isFavorite: item.isFavorite })} />
        ))
      ) : (
        <p>No favorites found.</p>
      )}
    </div>
  );
};
export default WishList;
