
import "primereact/resources/themes/saga-blue/theme.css"; /* or any other theme */
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import React, {  useState } from "react";
import "./WinesPage.css"; 
import { useNavigate } from "react-router-dom"; 
import {  useSelector } from "react-redux";
import ProductLists from "../../components/ProductLists";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddOns from "../../components/Modals/AddOns";



const sizes = [
  { label: "Small (Under 50ml)", value: "Small" },
  { label: "Medium (50ml - 500ml)", value: "Medium" },
  { label: "Large (500ml - 750ml)", value: "Large" },
  { label: "Extra-Large (Above 750ml)", value: "Extra-Large" },
];

const brands = [
  "Pol Roger",
  "Mignon Grande",
  "Pommery",
  "Santa Margherita",
  "Torresella",
  "Charles De Fère",
  "A Nos Amours",
  "Zonin",
  "Cremant De Bordeaux",
  "Bouchard Aine & Fils",
];

const WinesPage = () => {
  
  const bestsellers = useSelector((state) => state.HomePage.Bestsellers);
  const [sortBy, setSortBy] = useState(null);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [isAddOneVisible, setisAddOneVisible] = useState(false);
  const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);



  const handleSizeChange = (e) => {
    let selected = [...selectedSizes];
    if (e.checked) selected.push(e.value);
    else selected = selected.filter((size) => size !== e.value);
    setSelectedSizes(selected);
  };

  const handleBrandChange = (e) => {
    let selected = [...selectedBrands];
    if (e.checked) selected.push(e.value);
    else selected = selected.filter((brand) => brand !== e.value);
    setSelectedBrands(selected);
  };

  const toggleAddOneModal = () => {
    setisAddOneVisible((prev) => !prev);
  };

  const toggleFilterDialog = () => {
    setIsFilterDialogVisible((prev) => !prev); // Toggle the dialog
  };

  const applyFilters = () => {
    // You can apply the selected filters here if needed
    toggleFilterDialog(); // Close the dialog after applying filters
  };
  return (
    <div className="px-2 md:px-[8%]">
      <AddOns
        isModalVisible={isAddOneVisible}
        toggleModal={toggleAddOneModal}
      />
      <div className="md:flex justify-center items-center px-2 hidden">
        <div className="pb-8 rounded-lg text-center">
          <h1 className="text-[3rem] text-[#2b5d45] font-newsreader">WINES</h1>
          <h2 className="text-[1.5rem] text-[#a55b3e]">
            Browse through the largest portfolio of wines
          </h2>
          <p className="text-base text-[#333] text-[var(--color)]">
            Whether you’re looking for a Red Wine or a White Wine, dry Merlot
            from Chile or a crisp Chardonnay from Italy, our online wine store
            in Sri Lanka has some of the best wine brands for you to choose
            from. Buy your favorite wine from our store and quench your thirst
            without stepping out!
          </p>
        </div>
      </div>

      <div className="flex-1 flex p-4">
        <div className="hide-on-small">
          <div className="font-sans text-gray-800 max-w-sm ml-12 p-3">
            {/* Sort By Section */}
            <div className="mb-8">
              <h3 className="text-lg mb-8 mt-8 text-[var(--color)] font-semibold">
                Sort by
              </h3>
              <div className="flex items-center mb-5">
                <RadioButton
                  inputId="lowToHigh"
                  name="sort"
                  value="lowToHigh"
                  onChange={(e) => setSortBy(e.value)}
                  checked={sortBy === "lowToHigh"}
                />
                <label
                  htmlFor="lowToHigh"
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  Sort by price: low to high
                </label>
              </div>
              <div className="flex items-center mb-5">
                <RadioButton
                  inputId="highToLow"
                  name="sort"
                  value="highToLow"
                  onChange={(e) => setSortBy(e.value)}
                  checked={sortBy === "highToLow"}
                />
                <label
                  htmlFor="highToLow"
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  Sort by price: high to low
                </label>
              </div>
              <div className="flex items-center mb-5">
                <RadioButton
                  inputId="fastMoving"
                  name="sort"
                  value="fastMoving"
                  onChange={(e) => setSortBy(e.value)}
                  checked={sortBy === "fastMoving"}
                />
                <label
                  htmlFor="fastMoving"
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  Sort by Fast moving
                </label>
              </div>
            </div>

            {/* Filter by Size */}
            <div className="mb-8">
              <h3 className="text-lg mb-8 mt-8 text-[var(--color)] font-semibold">
                Filter by size
              </h3>
              {sizes.map((size, index) => (
                <div key={index} className="flex items-center mb-5">
                  <Checkbox
                    inputId={`size${index}`}
                    value={size.value}
                    onChange={handleSizeChange}
                    checked={selectedSizes.includes(size.value)}
                  />
                  <label
                    htmlFor={`size${index}`}
                    className="ml-2 text-base font-medium text-[var(--color)]"
                  >
                    {size.label}
                  </label>
                </div>
              ))}
            </div>

            {/* Filter by Brands */}
            <div className="mb-8">
              <h3 className="text-lg mb-8 mt-8 text-[var(--color)] font-semibold">
                Filter by Brands
              </h3>
              {brands.slice(0, 10).map((brand, index) => (
                <div key={index} className="flex items-center mb-5">
                  <Checkbox
                    inputId={`brand${index}`}
                    value={brand}
                    onChange={handleBrandChange}
                    checked={selectedBrands.includes(brand)}
                  />
                  <label
                    htmlFor={`brand${index}`}
                    className="ml-2 text-base font-medium text-[var(--color)]"
                  >
                    {brand}
                  </label>
                </div>
              ))}
            </div>

            {/* Load More Button */}
            <Button
              label="Load More"
              icon="pi pi-clock"
              className="text-[#007ad9] ml-3"
            />
          </div>
        </div>
        <div className="md:flex-[0.65] mt-8">
          <div className="text-center flex justify-end pb-5">
            <Button
              icon="pi pi-filter"
              onClick={toggleFilterDialog} // Open the filter dialog
            />
          </div>
          <ProductLists productData={bestsellers} visibleCount={6} />
        </div>
      </div>
      {/* Filter Dialog */}
      <Dialog
        header="Filters"
        visible={isFilterDialogVisible}
        onHide={toggleFilterDialog}
        className="w-full h-screen"
        footer={
          <Button
            label="Apply Filters"
            icon="pi pi-check"
            onClick={applyFilters}
            autoFocus
          />
        }
      >
        {/* Place all filters inside the dialog */}
        <div className="font-sans  px-5">
          {/* Sort By Section */}
          <div className="mb-8">
            <h3 className="text-lg mb-3 mt-8 text-[var(--color)] font-semibold">
              Sort by
            </h3>
            <div className="flex items-center mb-5">
              <RadioButton
                inputId="lowToHigh"
                name="sort"
                value="lowToHigh"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "lowToHigh"}
              />
              <label
                htmlFor="lowToHigh"
                className="ml-2 text-base font-medium text-[var(--color)]"
              >
                Sort by price: low to high
              </label>
            </div>
            <div className="flex items-center mb-5">
              <RadioButton
                inputId="highToLow"
                name="sort"
                value="highToLow"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "highToLow"}
              />
              <label
                htmlFor="highToLow"
                className="ml-2 text-base font-medium text-[var(--color)]"
              >
                Sort by price: high to low
              </label>
            </div>
            <div className="flex items-center mb-5">
              <RadioButton
                inputId="fastMoving"
                name="sort"
                value="fastMoving"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "fastMoving"}
              />
              <label
                htmlFor="fastMoving"
                className="ml-2 text-base font-medium text-[var(--color)]"
              >
                Sort by Fast moving
              </label>
            </div>
          </div>

          {/* Filter by Size */}
          <div className="mb-10">
            <h3 className="text-lg mb-3 mt-8 text-[var(--color)] font-semibold">
              Filter by size
            </h3>
            {sizes.map((size, index) => (
              <div key={index} className="flex items-center mb-3">
                <Checkbox
                  inputId={`size${index}`}
                  value={size.value}
                  onChange={handleSizeChange}
                  checked={selectedSizes.includes(size.value)}
                />
                <label
                  htmlFor={`size${index}`}
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  {size.label}
                </label>
              </div>
            ))}
          </div>

          {/* Filter by Brands */}
          <div className="mb-8">
            <h3 className="text-lg mb-3 mt-8 text-[var(--color)] font-semibold">
              Filter by Brands
            </h3>
            {brands.slice(0, 10).map((brand, index) => (
              <div key={index} className="flex items-center mb-3">
                <Checkbox
                  inputId={`brand${index}`}
                  value={brand}
                  onChange={handleBrandChange}
                  checked={selectedBrands.includes(brand)}
                />
                <label
                  htmlFor={`brand${index}`}
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  {brand}
                </label>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default WinesPage;
