import AboutPage from "../pages/AboutPage";
import Blog from "../pages/Blog";
import BlogView from "../pages/BlogView";
import Checkout from "../pages/Checkout/Checkout";
import Complaint from "../pages/Complaint/Complaint";
import ContactusPage from "../pages/ContactusPage/ContactusPage";
import CorporatesPage from "../pages/CorporatesPage/CorporatesPage";
import GiftingPage from "../pages/Gifting/GiftingPage";
import HomePage from "../pages/HomePage/HomePage";
import OrderDetails from "../pages/OrderDetails/OrderDetails";
import OrderHistory from "../pages/OrderHistory/OrderHistory";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Products from "../pages/products";
import ProductView from "../pages/ProductView/ProductView";
import Profile from "../pages/Profile/Profile";
import ServicesPage from "../pages/ServicesPage/ServicesPage";
import WeddingPage from "../pages/WeddingPage/WeddingPage";
import WinesPage from "../pages/WinesPage/WinesPage";
import WishList from "../pages/WishList/WishList";
import PlaceAnOrder from "../pages/PlaceAnOrder";
import ReturnsAndRefunds from "../pages/ReturnsAndRefunds";
import TermsAndConditions from "../pages/TermsAndConditions";
import ConciergePolicy from "../pages/ConciergePolicy";
import CheckoutForm from "../components/Modals/PaymentForm";
import ResetPassword from "../pages/Auth/ResetPassword";
import Return from "../pages/Return";



const publicRoutes = [
  { path: "/", element: <HomePage />, name: "Home" }, //done
  { path: "/for-weddings/", element: <WeddingPage />, name: "Weddings" }, // done
  { path: "/for-corporates/", element: <CorporatesPage />, name: "Corporates" }, //done
  { path: "/contact-us/", element: <ContactusPage />, name: "Contact Us" }, //done
  { path: "/product", element: <WinesPage />, name: "Wines" }, //not using
  { path: "/product-category/:department/:category?/:brandId?", element: <Products />, name: "Products", }, //done

  { path: "/about", element: <AboutPage />, name: "About" }, //done
  { path: "/privacy-policy/", element: <PrivacyPolicy />, name: "Privacy Policy", }, //done
  { path: "/blog/", element: <Blog />, name: "Blog" }, //done
  { path: "/product/:id", element: <ProductView />, name: "Product View" }, //done
  { path: "/BlogView", element: <BlogView />, name: "Blog View" }, //done
  { path: "/event-and-services", element: <ServicesPage />, name: "Services" }, //done
  { path: "/place-an-order", element: <PlaceAnOrder />, name: "Place An Order" }, //done
  { path: "/returns-and-refunds", element: <ReturnsAndRefunds />, name: "Returns And Refunds" }, //done
  { path: "/terms-and-conditions", element: <TermsAndConditions />, name: "Terms And Conditions" }, //done
  { path: "/concierge-policy", element: <ConciergePolicy />, name: "Concierge Policy" }, //done
  { path: "/payment-form", element: <CheckoutForm /> }, //done
  { path: "/cart/", element: <Checkout />, name: "Checkout" }, //done
  { path: "/GiftingPage", element: <GiftingPage />, name: "Gifting" }, //done
  { path: "/payment/return", element: <Return />, name: "Return" }, //done


];

const privateRoutes = [
  { path: "/Favourite", element: <WishList />, name: "Wish List" }, //done

  { path: "/OrderHistory", element: <OrderHistory />, name: "Order History" }, //done
  { path: "/my-account/", element: <Profile />, name: "My Account" },
  { path: "/Complaint", element: <Complaint />, name: "Complaint" },
  { path: "/OrderDetails/:id", element: <OrderDetails />, name: "Order Details" },
  { path: "/payment/return", element: <Return />, name: "Return" }, //done
];

const authRoutes = [
  { path: "/reset-password", element: <ResetPassword />, name: "reset-password" }, //done
]

export { publicRoutes, privateRoutes, authRoutes };
