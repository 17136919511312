import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";


export const subscribeSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        subscribe: builder.mutation({
            query: (data) => ({
                url: "subscribe",
                method: "POST",
                body: data,
            }),
        })
       
    }),
});

export const { useSubscribeMutation } = subscribeSlice;