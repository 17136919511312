import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import logo from "./../icons/logo.png";
import whiteLogo from "./../icons/whiteLogo.png";
import OtpInput from "./OtpInput";
import {
  usePasswordResetOtpMutation,
  usePasswordResetRequestMutation,
  useReSendOtpMutation,
  useVerifyOtpMutation,
} from "../redux/service/authSlice";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../features/authSlice";
import { InputText } from "primereact/inputtext";

import { Password } from "primereact/password";

const validationSchema = Yup.object().shape({
  otp: Yup.string()
  .required("OTP is required")
  .matches(/^\d+$/, "OTP must be numbers only") // Numbers only
  .length(5, "OTP must be exactly 5 digits"), // Exactly 5 digits

  password: Yup.string()
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    )
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .max(50, "Password must not exceed 50 characters")
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .min(8, "Password must be at least 8 characters")
    .max(50, "Password must not exceed 50 characters")
    .required("Confirm Password is required"),
});

const VerifyPasswordOtp = ({
  isModalVisible,
  isDarkTheme,
  email,
  number,
  isResetPasswordVisible,
  setIsResetPasswordVisible,
}) => {
  const [PasswordReset, { isLoading }] = usePasswordResetOtpMutation();
  const [reSendOtp] = usePasswordResetRequestMutation();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [timer, setTimer] = useState(300); // 2 minutes in seconds
  const [clearOtp, setClearOtp] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  useEffect(() => {
    if (isResetPasswordVisible) {
      setTimer(300);
    }
  }, [isResetPasswordVisible]);
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const reSendOtpHandler = async () => {
    await reSendOtp(email)
      .unwrap()
      .then((res) => {
        setClearOtp((prev) => !prev);
        setTimer(300);
        toast.success("OTP sent successfully");
        
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message || "Something went wrong");
      });
  };

  const handleSubmit = async () => {
    if (timer <= 0) return;
    try {

      await validationSchema.validate(formData, { abortEarly: false });
      setFormErrors({});
      await PasswordReset({
        email,
        otpCode: formData.otp,
        newPassword: String(formData.password),
      })
        .unwrap()
        .then((res) => {
          setFormData({
            otp: "",
            password: "",
            confirmPassword: "",
          });
          setIsResetPasswordVisible(false);
          toast.success("Password reset successfully");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.data?.message || "Something went wrong");
        });

    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setFormErrors(validationErrors);
      }
    }
  };

  return (
    <Dialog
      visible={isResetPasswordVisible}
      footer={true}
      closable={true}
      className="custom-dialog"
      onHide={() => setIsResetPasswordVisible(false)}
      breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
      style={{ width: "30vw" }}
    >
      <div className="verify-modal-inputs">
        <img
          src={isDarkTheme ? whiteLogo : logo}
          alt="Wine World Logo"
          className="login-logo"
        />

        <span className="verify-modal-description">
          An OTP will be sent to the provided email address
          <br />
          to ensure the security of your account.
        </span>

        <span
          className={`verify-modal-description ${
            timer === 0 ? "text-red-500" : ""
          }`}
        >
          {formatTime(timer)}
        </span>

        <div className="verify-modal-description">
          <OtpInput
            length={5}
            clearOtp={clearOtp}
            onChange={(otp) => {
              setFormData({ ...formData, otp });
              setFormErrors({ ...formErrors, otp: "" });
            }}
          />
        </div>

        {formErrors.otp && (
          <span className="text-red-500">{formErrors.otp}</span>
        )}

        <span className="verify-modal-description group/otp">
          Didn't receive OTP?{" "}
          <button
            disabled={timer > 0}
            // className="group-hover/otp:underline text-[#ED7242] cursor-pointer "
            className={`group-hover/otp:underline ${
              timer > 0
                ? "text-gray-400 cursor-not-allowed"
                : "text-[#ED7242] cursor-pointer"
            }`}
            onClick={reSendOtpHandler}
          >
            Resend
          </button>
        </span>

        <div className="w-full max-w-[300px] flex flex-col ">
          <div className="flex flex-col  items-start relative">
            <label className="ml-0 mb-0">Password</label>
            <InputText
              id="Password"
              type={showPassword.password ? "text" : "password"}
              value={formData?.password}
              onChange={async (e) => {
                setFormData({ ...formData, password: e.target.value });
                try {
                  await validationSchema.validateAt("password", {
                    password: e.target.value,
                  });

                  setFormErrors({ ...formErrors, password: "" });
                } catch (err) {
                  setFormErrors({
                    ...formErrors,
                    password: err.errors[0],
                  });
                }
              }}
              placeholder="Please enter your password"
              className="login-modal-inputs w-full"
            />
            <span
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  password: !showPassword.password,
                })
              }
              className="absolute right-3 -bottom-3 transform -translate-y-1/2 cursor-pointer"
            >
              {!showPassword.password ? (
                <i className="pi pi-eye-slash" style={{ fontSize: "1.5rem" }} />
              ) : (
                <i className="pi pi-eye" style={{ fontSize: "1.5rem" }} />
              )}
            </span>
          </div>
          {formErrors.password && (
            <span className="text-red-500 text-start">
              {formErrors.password}
            </span>
          )}
          <div className="flex flex-col items-start relative mt-4">
            <label className="ml-0 mb-0">Confirm Password</label>
            <InputText
              id="Password"
              type={showPassword.confirmPassword ? "text" : "password"}
              value={formData?.confirmPassword}
              onChange={async (e) => {
                setFormData({ ...formData, confirmPassword: e.target.value });
                setFormErrors({ ...formErrors, confirmPassword: "" });
              }}
              placeholder="Please confirm your password"
              className="login-modal-inputs w-full"
              toggleMask
            />
            <span
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  confirmPassword: !showPassword.confirmPassword,
                })
              }
              className="absolute right-3 -bottom-3 transform -translate-y-1/2 cursor-pointer"
            >
              {!showPassword.confirmPassword ? (
                <i className="pi pi-eye-slash" style={{ fontSize: "1.5rem" }} />
              ) : (
                <i className="pi pi-eye" style={{ fontSize: "1.5rem" }} />
              )}
            </span>
          </div>
          {formErrors.confirmPassword && (
            <span className="text-red-500 text-start">
              {formErrors.confirmPassword}
            </span>
          )}
        </div>

        <div className="modal-footer mt-8">
          <Button
            label="Submit"
            className="login-confirm-btn py-2"
            onClick={handleSubmit}
            loading={isLoading}
            disabled={
              !formData?.otp ||
              formData?.otp?.length < 5 ||
              isLoading ||
              timer === 0
            }
          />
        </div>
      </div>
    </Dialog>
  );
};

export default VerifyPasswordOtp;
