import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { authRoutes, privateRoutes, publicRoutes } from ".";
import Default from "../layout/Default";
import { useSelector } from "react-redux";

const AllRoutes = () => {
  const user = useSelector((state) => state?.auth?.userInfo);
  const city = useSelector((state) => state?.meta?.city);
  const verified = localStorage.getItem("verified");


  const filteredPublicRoutes = publicRoutes.filter(route => 
    !city || !verified ? route.path === "/" : true
  );

  return (
    <Routes>

      {(!city || !verified) && <Route path="*" element={<Navigate to="/" />} />}

      {filteredPublicRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}

      {user && 
        privateRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<Default>{route.element}</Default>}
          />
        ))
      }
      
      {authRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={route.element}
        />
      ))}
    </Routes>
  );
};

export default AllRoutes;