// src/pages/AboutPage.js

import { Card } from 'primereact/card';
import React from 'react';
import blog1 from './../Images/Blog/1.jpeg';



const BlogView = () => {

    return (
        <div className="mt-6">
            <Card title={<span className="text-3xl uppercase text-[var(--color)] bg-transparent">A Toast to Love: Valentine’s Day Stories with a Splash of Wine</span>}
                  className="mb-4 md:mx-12 md:px-12 font-10 border-none shadow-none">


                <img className="mb-10 mx-auto block" src={blog1} alt="Blog Image"/>


                <p>Welcome to our romantic corner of the web, where love stories intertwine with the exquisite flavors
                    of some of the finest wines. As Valentine’s Day approaches, we invite you to join us on a journey
                    through tales of love, passion, and connection, all accompanied by the enchanting notes of Sparkling
                    Rosé, Tequila Rose, Zonin Sparkling, Santa Julia Organic, Tall Horse Pinotage, and Penfolds Koonunga
                    Hill Chardonnay. Grab your favorite glass, settle into a cozy spot, and let the stories unfold.</p>

                <h2 className="text-lg font-bold mt-4">Zonin Sparkling: A Toast to Forever</h2>

                <p>Zonin Sparkling, with its timeless elegance, mirrors the enduring nature of true love. Take a step
                    back in time as we recount a love story that transcends the years, proving that like a fine
                    sparkling wine, some bonds only get better with age. This tale is a testament to the beauty of a
                    love that stands the test of time.</p>


                <h2 className="text-lg font-bold mt-4">Tequila Rose: A Spicy Love Affair</h2>

                <p>Tequila Rose, with its enticing blend of tequila and strawberry cream, sets the stage for a spicy and
                    sweet love affair. Join our characters as they navigate the complexities of passion, discovering
                    that love is sometimes as unexpected and delightful as the unique combination of flavors in a
                    Tequila Rose cocktail.</p>


                <h2 className="text-lg font-bold mt-4">Santa Julia Organic: Love in Full Bloom</h2>

                <p>In the vineyards of Santa Julia, where organic wines flourish, our protagonists discover the
                    importance of nurturing their love like a delicate grapevine. As they navigate the challenges that
                    life throws their way, they learn that, much like the organic cultivation of grapes, love requires
                    patience, care, and a commitment to allowing it to blossom naturally.</p>


                <h2 className="text-lg font-bold mt-4">Tall Horse Pinotage: Love Under the Stars</h2>

                <p>Under the vast, starry skies, a love story unfolds, guided by the rich and bold notes of Tall Horse
                    Pinotage. Join our characters as they embark on a journey filled with passion, adventure, and the
                    deep, robust flavors that mirror the intensity of their connection. This is a tale of love that
                    stands tall against the backdrop of the universe.

                </p>


            </Card>
        </div>);
};

export default BlogView;
