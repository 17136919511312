import { apiSlice } from "./apiSlice";



export const brandSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllBrand: builder.query({
            query: (query) => ({
                url: `brands?${query.size && `size=${query.size}`}`,
                method: "GET",
            }),
        })
    }),
});

export const { useGetAllBrandQuery } = brandSlice