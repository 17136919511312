// src/pages/HomePage.js
import React, { useEffect, useState, useRef } from "react";
import "./HomePage.css"; // Use a CSS file for styles
import { useDispatch, useSelector } from "react-redux";
import logo from "./../../icons/logo.png";
import whiteSmallLogo from "./../../icons/wLogo.png";

import whiteLogo from "./../../icons/whiteLogo.png";
import { Carousel } from "primereact/carousel";
import ProductLists from "../../components/ProductLists";
import SelectionOfWineCard from "../../components/SelectionOfWineCard";
import HeroCarosal from "../../components/HeroCarosal";
import Blog1 from "./../../Images/Blog1.png";
import Blog2 from "./../../Images/Blog2.png";
import Blog3 from "./../../Images/Blog3.png";
import popup from "./../../Images/popup.png";
import { Card } from "primereact/card";
import { Rating } from "primereact/rating";
import { Dialog } from "primereact/dialog";
import { ScrollTop } from "primereact/scrolltop";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { useGetAllCityQuery } from "../../redux/service/citySlice";
import {
  useFeaturedProductQuery,
  useSearchProductQuery,
} from "../../redux/service/productSlice";
import { darkImages, whiteImages } from "./data";
import scrolldown from "./../../icons/scrolldown.gif";
import { setCity } from "../../features/metaSlice";

const HomePage = () => {
  const navigate = useNavigate();
  // const BrandImages = useSelector((state) => state.HomePage.BrandImages);
  const CollectionList = useSelector((state) => state.HomePage.CollectionList);
  const HeroImageList = useSelector((state) => state.HomePage.HeroImageList);
  const bestsellers = useSelector((state) => state.HomePage.Bestsellers);
  const recommendedProducts = useSelector(
    (state) => state.HomePage.RecommendedProducts
  );
  const newAdditions = useSelector((state) => state.HomePage.NewAdditions);
  const [selectedTab, setSelectedTab] = useState(0); // 0 = Bestsellers, 1 = Recommended, 2 = New Additions
  const [products, setProducts] = useState(bestsellers); // Default to bestsellers
  const [visible, setVisible] = useState(false);
  const [ageVerifyVisible, setAgeVerifyVisible] = useState(false);
  const [selectCityError, setSelectCityError] = useState(false);
  const [underAge, setisUnderAge] = useState(false);
  const cityId = localStorage.getItem("city");
  const theme = useSelector((state) => state.theme.theme);
  const { data: cities } = useGetAllCityQuery();

  const verify = localStorage.getItem("verified");
  const city = localStorage.getItem("city");
  const lastVerified = localStorage.getItem("verifyTimestamp");
  const currentTime = new Date().getTime();
  const oneDay = 24 * 60 * 60 * 1000;
  const threeHours = 3 * 60 * 60 * 1000;
  const lastVerifiedDate = new Date(parseInt(lastVerified)).toDateString();
  const todayDate = new Date().toDateString();
  // const theme = localStorage.getItem("theme");
  // const oneDay = 24 * 60 * 60 * 1000;
  // const verify = localStorage.getItem("verified");
  // const city = localStorage.getItem("city");
  // const lastVerified = localStorage.getItem("verifyTimestamp");
  const sessionStarted = sessionStorage.getItem("sessionStarted");

  // const currentTime = new Date().getTime();

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!cityId) {
      setAgeVerifyVisible(true)
    }
  }, [cityId]);


  const { data: prodcuts, isLoading: prodcutsLoading } =
    useFeaturedProductQuery({
      page: 0,
      cityId,
      isFeatured: selectedTab == 1 ? true : false,
      isBestSeller: selectedTab == 0 ? true : false,
      isNewArrival: selectedTab == 0 ? false : selectedTab == 1 ? false : true,
    });

  const { data: peopleAlsoTried, isLoading: peopleAlsoTriedLoading } =
    useSearchProductQuery(
      {
        page: 0,
        cityId,
        isFeatured: true,
        isBestSeller: false,
        isNewArrival: false
      },
      { refetchOnMountOrArgChange: true }
    );

  const blogPosts = [
    {
      id: 1,
      image: Blog1, // Replace with your image path
      title: "A Toast to Love: Valentine's Day Stories with a Splash of Wine",
      date: "TUESDAY, JANUARY 30TH, 2024",
    },
    {
      id: 2,
      image: Blog2, // Replace with your image path
      title: "Wedding Cocktails Ideas",
      date: "THURSDAY, APRIL 6TH, 2023",
    },
    {
      id: 3,
      image: Blog3, // Replace with your image path
      title:
        "Celebrate National Mojito Day With An Easy To Make Mount Gay Rum Mojito Recipe",
      date: "THURSDAY, APRIL 6TH, 2023",
    },
  ];

  const testimonials = [
    {
      name: "Sarah M.",
      text: "I have been ordering from Wine World for over a year now, and I always impressed by their selection and service. The wines are top-notch, and the customer service is exceptional. They always go above and beyond to ensure my orders are correct and delivered on time. Highly recommend!",
      rating: 5,
    },
    {
      name: "Jessica L.",
      text: "I recently discovered Wine World and I am blown away by the selection and the convenience of shopping online. The delivery is quick, and the packaging ensures that everything arrives safely.",
      rating: 4,
    },
    {
      name: "John D.",
      text: "As a cocktail enthusiast, I am always on the lookout for unique spirits. Wine World has an incredible variety of liquors that I can’t find anywhere else. Their detailed descriptions make it easy to choose the perfect bottle every time.",
      rating: 4,
    },
    {
      name: "Emily R.",
      text: "I hosted a wine tasting event for my friends and decided to use Wine World to supply the wines. The experience was fantastic! Beautifully packaged, the quality was outstanding.",
      rating: 5,
    },
    {
      name: "Sarah M.",
      text: "I have been ordering from Wine World for over a year now, and I always impressed by their selection and service. The wines are top-notch, and the customer service is exceptional. They always go above and beyond to ensure my orders are correct and delivered on time. Highly recommend!",
      rating: 5,
    },
  ];

  const getInitialCount = () => {
    return window.innerWidth >= 1440 ? 4 : 2;
  };
  const [visibleCount, setVisibleCount] = useState(getInitialCount());

  const dispatch = useDispatch();

  useEffect(() => {
    const updateVisibleCount = () => {
      const width = window.innerWidth;
      if (width >= 1440) {
        // console.log("large");
        setVisibleCount(4);
      } else {
        setVisibleCount(2);
      }
    };
    updateVisibleCount();
    window.addEventListener("resize", updateVisibleCount);
    return () => window.removeEventListener("resize", updateVisibleCount);
  }, []);

  useEffect(() => {
    // console.log("test render");
    if (selectedTab === 0) {
      setProducts(bestsellers);
    } else if (selectedTab === 1) {
      setProducts(recommendedProducts);
    } else {
      setProducts(newAdditions);
    }
  }, [selectedTab, bestsellers, recommendedProducts, newAdditions]);

  useEffect(() => {
    // Constants for time calculations

    if (!sessionStarted) {
      try {
        sessionStorage.setItem("sessionStarted", "true");
      } catch (error) {
        console.error("Failed to set sessionStarted in sessionStorage:", error);
      }
      localStorage.removeItem("city");
      setAgeVerifyVisible(true);
      sessionStorage.setItem("cityCleared", "true");
    }

    // Show age verification if necessary
    if (
      !verify ||
      !city ||
      !lastVerified ||
      currentTime - lastVerified > oneDay
    ) {
      setAgeVerifyVisible(true);
      localStorage.setItem("verifyTimestamp", currentTime);
    }
  }, []);

  useEffect(() => {
    // if (currentTime - lastVerified > threeHours) {
    //   // console.log("check hours");
    // }
    // if (lastVerifiedDate !== todayDate) {
    //   // localStorage.removeItem("city");
    // }

    if (
      !verify ||
      !city ||
      !lastVerified ||
      currentTime - lastVerified > oneDay
    ) {
      setAgeVerifyVisible(true);
      localStorage.setItem("verifyTimestamp", currentTime);
    }
  }, []);

  const hideDialog = () => {
    setVisible(false);
  };

  const hideAgeDialog = () => {
    setAgeVerifyVisible(false);
  };

  return (
    <>
      <div className=" mt-7 md:mt-0">
        <div className="home-page mt-6 container">
          {/* <ScrollTop className="bg-[var(--contactBtn)]" /> */}
          {/* Header */}
          <header className="header" />

          <Dialog
            visible={ageVerifyVisible}
            onHide={hideAgeDialog}
            footer={false}
            header={false}
            className={`${theme === "light" ? "age-popup" : "age-popup-dark"
              } h-[440px] flex items-center justify-center border-none mx-0 relative flex-col `}
            closable={false}
          >
            <div className="hidden md:flex">
              <div className="hidden   logo-section bg-transparent">
                <img
                  src={theme == "light" ? logo : whiteSmallLogo}
                  alt="Vodka Tropical Twist"
                  className="age-popup-image "
                />
              </div>
            </div>

            <div className="flex md:hidden border-non logo-section bg-[var(--greenRawPrimary)]">
              <img
                src={whiteLogo}
                alt="Vodka Tropical Twist"
                className="age-popup-image max-w-1/4"
              />
            </div>

            <div className="text-center mt-4 w-full  mb-4">
              <div>
                <span className="text-lg font-medium md:font-semibold mb-2 block text-[var(--otpOrange)]">
                  Please provide your delivery location
                </span>
              </div>
              <select
                className={`${theme === "light" ? "cityDropdown" : "cityDropdown-dark"
                  } p-2 border w-4/5 md:w-3/4 border-gray-300 pr-3 rounded-md bg-transparent text-greenRawPrimary`}
                defaultValue=""
                onChange={(e) => {
                  setSelectCityError(false);
                  dispatch(setCity(e.target.value));
                  sessionStorage.setItem("sessionStarted", "true");
                }}
              >
                <option value="" disabled>
                  Select your city
                </option>
                {cities?.data?.content.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityName}
                  </option>
                ))}
              </select>{" "}
              {selectCityError && (
                <p className="mt-1 text-red-500">
                  Please select your delivery location
                </p>
              )}
            </div>

            <div className="age-opup-content">
              <div className=" text-center">
                <span className="text-lg font-medium md:font-semibold mb-2 block text-[var(--otpOrange)]">
                  Verify your age
                </span>
              </div>
              <div>
                <span className="age-verify-text">
                  Are you over 21 years of age?
                </span>
              </div>
              <div className="age-button-wrap">
                <button
                  onClick={() => {
                    setisUnderAge(true);
                    localStorage.setItem("verified", false);
                  }}
                  className="age-verify-button"
                >
                  No
                </button>
                <button
                  onClick={() => {
                    const city = localStorage.getItem("city");
                    if (city) {
                      setAgeVerifyVisible(false);
                      setVisible(true);
                      localStorage.setItem("verified", true);
                    } else {
                      setSelectCityError(true);
                      setisUnderAge(false);
                    }
                  }}
                  className="age-verify-button"
                >
                  Yes
                </button>
              </div>
            </div>

            {underAge === true ? (
              <div className="under-age-text">
                You are not old enough to view this content
              </div>
            ) : (
              <div className="under-age-text"></div>
            )}
          </Dialog>

          <Dialog
            visible={visible}
            onHide={hideDialog}
            header={false}
            className="vodka-popup w-[85%] md:w-[21%]  h-auto"
            closable={false}
          >
            <div className="popup-content">
              <img
                src={popup}
                alt="Vodka Tropical Twist"
                className="popup-image"
              />
              <button className="close-button" onClick={hideDialog}>
                <i className="pi pi-times"></i>
              </button>
            </div>
          </Dialog>

          {/* Hero Section */}
          <div className="relative">
            <Carousel
              // className="hero-section"
              value={HeroImageList}
              autoplayInterval={5000}
              numScroll={1}
              numVisible={1}
              itemTemplate={(product) => <HeroCarosal product={product} />}
            />
            <img
              className={
                "h-10 hidden md:block w-10 absolute cursor-pointer right-20 bottom-20 "
              }
              src={scrolldown}
              alt="Scroll down"
              onClick={handleScrollToBottom} // Scroll to bottom on click
            />
          </div>

          {/* Brand Logos Section */}
          <div className="logo-container">
            <div className="brand-logos">
              {(theme === "dark" ? whiteImages : darkImages).map(
                (logo, index) => (
                  <div key={index}>
                    <img
                      className="max-w-[90px] md:max-w-[150px] mx-0 "
                      src={logo.src}
                      alt={logo.alt}
                    />
                  </div>
                )
              )}
              {(theme === "dark" ? whiteImages : darkImages).map(
                (logo, index) => (
                  <div key={index}>
                    <img
                      className="max-w-[90px] md:max-w-[150px] mx-0 "
                      src={logo.src}
                      alt={logo.alt}
                    />
                  </div>
                )
              )}
              {(theme === "dark" ? whiteImages : darkImages).map(
                (logo, index) => (
                  <div key={index}>
                    <img
                      className="max-w-[90px] md:max-w-[150px] mx-0 "
                      src={logo.src}
                      alt={logo.alt}
                    />
                  </div>
                )
              )}
            </div>
          </div>

          {/* Product Collections */}
          <div className="grid grid-cols-2 gap-4 m-4 md:grid-cols-4  md:m-12 md:gap-12">
            {CollectionList.map((Image, index) => (
              <div className="relative group">
                <div
                  onClick={() => {
                    navigate(Image.navigation);
                    window.scrollTo(0, 0);
                  }}
                  className="w-full absolute bottom-2 px-2 py-1 opacity-0 group-hover:opacity-100 group-hover:backdrop-blur-sm transition-all duration-300"
                >
                  <div className="rounded-lg flex items-center justify-between text-left p-2 md:p-3 bg-black bg-opacity-40 text-white font-semibold">
                    <span className="text-white">Shop Now</span>
                    <i className="pi pi-arrow-right ml-2" />{" "}
                    {/* PrimeReact arrow icon */}
                  </div>
                </div>

                <img src={Image.src} alt="Spirits Collection" />
              </div>
            ))}
          </div>

          <div className="productTitle font-bold text-center mb-5 mt-8 text-2xl">
            Featured Products
          </div>

          <div className="flex justify-center ">
            <div className="  mb-10 mt-3 mobile-padding flex flex-wrap justify-center gap-3 md:gap-4">
              <div className="order-1 sm:order-1">
                <button
                  className={`tab-button  w-full  text-xs md:text-sm px-8 py-2  border rounded text-gray-400 cursor-pointer bg-white font-mulish font-semibold shadow-sm transition-shadow duration-400 ease-in-out ${selectedTab === 0
                    ? "active border-green-600 text-green-600"
                    : "border-gray-400 "
                    }`}
                  onClick={() => setSelectedTab(0)}
                >
                  Bestsellers
                </button>
              </div>

              <div className="order-3 sm:order-2">
                <button
                  className={`tab-button  text-xs md:text-sm px-10 py-2  border rounded text-gray-400 cursor-pointer bg-white font-mulish font-semibold shadow-sm transition-shadow duration-400 ease-in-out ${selectedTab === 1
                    ? "active border-green-600 text-green-600"
                    : "border-gray-400"
                    }`}
                  onClick={() => setSelectedTab(1)}
                >
                  Recommended Products
                </button>
              </div>

              {/* Center the third button in mobile view */}
              <div className="order-2 sm:order-3">
                <button
                  className={`tab-button w-full text-xs md:text-sm px-8 py-2  border rounded text-gray-400 cursor-pointer bg-white font-mulish font-semibold shadow-sm transition-shadow duration-400 ease-in-out ${selectedTab === 2
                    ? "active border-green-600 text-green-600"
                    : "border-gray-400"
                    }`}
                  onClick={() => setSelectedTab(2)}
                >New Additions
                </button>
              </div>
            </div>

          </div>
          {/* Featured Products Section */}
          <ProductLists productData={prodcuts} visibleCount={visibleCount} />
          <SelectionOfWineCard />
        </div>

        <div
          className="text-center py-10 mt-0"
          style={{ backgroundColor: "var(--blog-bg-color)" }}
        >
          <h2 className=" blog-titleCapital text-green-500 font-extrabold text-[25px] ">
            BLOG
          </h2>

          <p className="text-base text-gray-700 max-w-xl mx-auto mb-5 px-10 md:px-0">
            Explore the exotic world of French Wines and the finest Single
            Malts. Visit our blog to learn more about your favourite spirits,
            mixing tips and more.
          </p>

          <button
            onClick={() => {
              navigate("/blog/");
              window.scrollTo(0, 0);
            }}
            className="bg-[#336e56] text-white px-5 py-2 rounded-md text-base cursor-pointer hover:opacity-80"
          >
            Read More
          </button>
        </div>

        <div
          className="flex   justify-center  "
          style={{ backgroundColor: "var(--blog-bg-color)" }}
        >
          <div className="flex flex-col md:flex-row  justify-between gap-5 p-10 pb-5 px-[5%]">


            {blogPosts.map((post, index) => (

              <div
                className={` ${index > 0 && "hidden sm:block"
                  } bg-white rounded-lg overflow-hidden relative shadow-md w-full md:w-1/3 flex flex-col`}
                key={post.id}
              >
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-64 object-cover"
                />
                <div className="p-5 flex flex-col relative h-24">
                  <h3 className="title-blog-home text-[18px] font-bold text-[#1e3d30] font-3 m-0 ">
                    {post.title}
                  </h3>
                </div>
                <div className="px-5 flex flex-col relative">
                  <p className="text-gray-500 text-xs mb-5">{post.date}</p>
                  <p
                    onClick={() => {
                      navigate("/blogView");
                      window.scrollTo(0, 0);
                    }}
                    className="text-[#ff7043] font-normal text-[13px] text-end cursor-pointer bottom-1"
                  >
                    READ MORE
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {peopleAlsoTried?.content?.length > 0 &&

        <div>


          <div className="productTitle font-bold  text-center mb-5 mt-8 text-2xl">
            People Also Tried
          </div>
          <div className="mx-5">
            <div className="mt-3">
              <ProductLists productData={peopleAlsoTried} visibleCount={visibleCount} />
            </div>
          </div>
        </div>
      }


      <div
        className="flex flex-col md:flex-row  justify-between gap-5 p-10 mb-5 px-[5%]"
        style={{ backgroundColor: "var(--blog-bg-color)" }}
      >
        <div className="container">


          {/* Testimonials */}
          <div className="testimonials-container hidden md:hidden lg:block">
            <div className="testimonials-header grid grid-cols-3 gap-5">
              <div className="ComentTitle">
                <h2>
                  WHAT OUR
                  <br />
                  CUSTOMERS
                  <br />
                  SAY
                </h2>
              </div>

              <div className="col-span-2 grid grid-cols-2 gap-5">
                {testimonials.slice(0, 2).map((testimonial, index) => (
                  <div key={index} className="testimonial-card">
                    <p>{testimonial.text}</p>
                    <p>
                      <strong>{testimonial.name}</strong>
                    </p>
                    <div className="rating">
                      <Rating
                        value={testimonial.rating}
                        readOnly
                        stars={5}
                        cancel={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5">
              {testimonials
                .slice(2, testimonials?.length)
                .map((testimonial, index) => (
                  <div key={index} className="testimonial-card">
                    <p>{testimonial.text}</p>
                    <p>
                      <strong>{testimonial.name}</strong>
                    </p>
                    <div className="rating">
                      <Rating
                        value={testimonial.rating}
                        readOnly
                        stars={5}
                        cancel={false}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* Testimonials Mobile */}
        <div className="lg:hidden md:w-3/4 md:m-auto px-5 mb-5">
          <div className="mb-3">
            <h2 className="text-center font-4 text-green-900 text-2xl">
              wine world
            </h2>
            <h1 className="text-center font-1 text-black">
              WHAT OUR CUSTOMERS SAY
            </h1>
          </div>
          <Swiper
            pagination={true}
            modules={[Pagination]}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            className="mySwiper"
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="border rounded-lg p-10">
                  <p>{testimonial.text}</p>
                  <h4 className="font-serif">{testimonial.name}</h4>
                  <div className="flex justify-center w-full mt-5">
                    <Rating
                      value={testimonial.rating}
                      readOnly
                      cancel={false}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

    </>
  );
};

export default HomePage;
