import { Card } from 'primereact/card';
import React from 'react';

const ReturnsAndRefunds = () => {
  return (
      <div className="mt-6 container">
          <Card
              title={
                  <span className="text-3xl uppercase text-[var(--color)] bg-transparent">
              Place an Order
            </span>
              }
              className="mb-4 md:mx-12 md:px-12 font-10 border-none shadow-none"
          >
              <div className='grid gap-3'>
                  <p>Product returns due to any reason whatsoever shall not be entertained provided any manufacturing
                      defect and/or physical damage shall be inquired into and verified at the sole discretion of the
                      vendor.</p>
                  <p>A customer will only be eligible for a Return or Refund if Wine World is unable to fulfill the
                      placed order or based on below scenarios</p>
                  <h1 className="text-2xl text-black font-sans font-semibold">
                      Returns
                  </h1>
                  <p>1. Replacement may be possible if product is damaged upon receipt. In this unique situation, the
                      damaged product will be replaced one for one with no additional charge as soon as possible.</p>
                  <p>2. To avoid such situations, we urge our customers to take inventory of every item at the point of
                      receipt. The concierge should be notified at the door, if any item is damaged and is
                      in-consumable. No replacement will be made once the concierge acknowledgement is signed as “Items
                      received in “sealed condition with no damages”.</p>
                  <h1 className="text-2xl text-black font-sans font-semibold">
                      Refunds/ Replacements For Missing Item(s)
                  </h1>
                  <p>1. At the point of receipt please ensure you check that the items received are in order in the
                      presence of the concierge and raise it to them if there are any missing item(s).</p>
                  <p>2. If there are missing item(s) – Note down the number of bottles actually received on the invoice
                      and get the concierge to place a signature with the name and contact number confirming the
                      quantity so that the Wine World team can further investigate. If this process is not followed,
                      complaints of missing items via phone call/ email after the concierge has left the premises will
                      be considered unviable.</p>
                  <p>3. If this process is followed and an image/scanned copy of the concierge signed document is
                      emailed to wwinfo@wineworld.lk with a complaint, the missing item(s) will be replaced as soon as
                      possible to the customer or a refund will be arranged – as per preference.</p>
              </div>
          </Card>
      </div>
  );
};

export default ReturnsAndRefunds;
