import { apiSlice } from "./apiSlice";


export const contactSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getContact: builder.mutation({

            query: (data) => ({
                url: "/contactus",
                method: "POST",
                body: data

            }),
        }),
    }),
})

export const { useGetContactMutation } = contactSlice