import { apiSlice } from "./apiSlice";

export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (credentials) => ({
        url: "users/register",
        method: "POST",
        body: credentials,
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "users/login",
        method: "POST",
        body: credentials,
      }),
    }),
    passwordReset: builder.mutation({
      query: (credentials) => ({
        url: `pwdResetController/reset?${
          credentials.token && `token=${credentials.token}`
        }${
          credentials.newPassword && `&newPassword=${credentials.newPassword}`
        }`,
        method: "POST",
        body: credentials,
      }),
    }),
    passwordResetRequest: builder.mutation({
      query: (email) => ({
        url: "pwdResetController/resetRequest?email=" + email,
        method: "POST",
      }),
    }),
    verifyOtp: builder.mutation({
      query: (query) => {
        const queryString = [
          query.email && `email=${query.email}`,
          query.otp && `otp=${query.otp}`,
          query.contact && `contact=${query.contact}`,
        ]
          .filter(Boolean)
          .join("&");

        return {
          url: `users/verify-otp?${queryString}`,
          method: "POST",
        };
      },
    }),
    reSendOtp: builder.mutation({
      query: (query) => ({
        url: `users/resendOtp?email=${query.email}&contact=${query.contact}`,
        method: "POST",
      }),
    }),
    passwordResetOtp: builder.mutation({
      query: (query) => ({
        url: `pwdResetController/reset`,
        method: "POST",
        body:query,
      }),
    }),

  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  usePasswordResetMutation,
  usePasswordResetRequestMutation,
  useVerifyOtpMutation,
  useReSendOtpMutation,
  usePasswordResetOtpMutation,
} = authSlice;
