import React, { useEffect, useState } from "react";
import "./GiftingPage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import ProductLists from "../../components/ProductLists";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog"; // Import Dialog
import Gifting_cover from "./../../Images/Gifting_cover.png";
import AddOns from "../../components/Modals/AddOns";
import { useSearchProductQuery } from "../../redux/service/productSlice";
import { useParams } from "react-router-dom";

const GiftingPage = () => {
  const navigate = useNavigate();
  const bestsellers = useSelector((state) => state.HomePage.Bestsellers);
  const [sortBy, setSortBy] = useState(null);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [isAddOneVisible, setisAddOneVisible] = useState(false);
  const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false); // State to control dialog visibility
  const cityId = localStorage.getItem("city");
  const { department, category, brandId } = useParams();

  const { data: prodcuts, isLoading: prodcutsLoading, refetch } = useSearchProductQuery(
    {
      page: 0,
      size: 1000,
      sizeCategories: selectedSizes,
      // brands: selectedBrands,
      sortBy: sortBy,
      categoryId: category,
      departmentId: 4,
      cityId
      // isFeatured: true,
      // isBestSeller: true,
    },
    { refetchOnMountOrArgChange: true }
  );

  // console.log("prodcuts gifts", prodcuts?.content);

  const sizes = [
    { label: "Small (Under 50ml)", value: "Small" },
    { label: "Medium (50ml - 500ml)", value: "Medium" },
    { label: "Large (500ml - 750ml)", value: "Large" },
    { label: "Extra-Large (Above 750ml)", value: "Extra-Large" },
  ];

  const brands = [
    "Pol Roger",
    "Mignon Grande",
    "Pommery",
    "Santa Margherita",
    "Torresella",
    "Charles De Fère",
    "A Nos Amours",
    "Zonin",
    "Cremant De Bordeaux",
    "Bouchard Aine & Fils",
  ];

  const handleSizeChange = (e) => {
    let selected = [...selectedSizes];
    if (e.checked) selected.push(e.value);
    else selected = selected.filter((size) => size !== e.value);
    setSelectedSizes(selected);
  };

  const handleBrandChange = (e) => {
    let selected = [...selectedBrands];
    if (e.checked) selected.push(e.value);
    else selected = selected.filter((brand) => brand !== e.value);
    setSelectedBrands(selected);
  };

  const toggleAddOneModal = () => {
    setisAddOneVisible((prev) => !prev);
  };

  const toggleFilterDialog = () => {
    setIsFilterDialogVisible((prev) => !prev); // Toggle the dialog
  };

  const applyFilters = () => {
    // You can apply the selected filters here if needed
    toggleFilterDialog(); // Close the dialog after applying filters
  };

  return (
    <div className="px-3 md:px-8 ">
      <AddOns
        isModalVisible={isAddOneVisible}
        toggleModal={toggleAddOneModal}
      />

      <div className="mb-5">
        <div className="parth">
          {/* Home {">"} <div className="parth-current"> Gifting</div> */}
        </div>

        <img
          className="rounded-lg w-full h-full object-cover"
          src={Gifting_cover}
          alt="Gifting Cover"
        />
      </div>

      {/* <div className="flex-1 flex p-5"> */}
      {/* <div className="w-full mt-8"> */}
      {/* {prodcuts?.content.length > 0 ? ( */}
      <ProductLists productData={prodcuts?.content} visibleCount={6} />
      {/* ) : ( */}
      {/* <div className="text-center text-gray-500 text-2xl font-bold">
          No items available..!
        </div> */}
      {/* )} */}
      {/* </div> */}
      {/* </div> */}

      {/* Filter Dialog */}
      <Dialog
        header="Filters"
        visible={isFilterDialogVisible}
        onHide={toggleFilterDialog}
        className="w-full h-screen"
        footer={
          <Button
            label="Apply Filters"
            icon="pi pi-check"
            onClick={applyFilters}
            autoFocus
          />
        }
      >
        {/* Place all filters inside the dialog */}
        <div className="font-sans  px-5">
          {/* Sort By Section */}
          <div className="mb-8">
            <h3 className="text-lg mb-3 mt-8 text-[var(--color)] font-semibold">
              Sort by
            </h3>
            <div className="flex items-center mb-5">
              <RadioButton
                inputId="lowToHigh"
                name="sort"
                value="lowToHigh"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "lowToHigh"}
              />
              <label
                htmlFor="lowToHigh"
                className="ml-2 text-base font-medium text-[var(--color)]"
              >
                Sort by price: low to high
              </label>
            </div>
            <div className="flex items-center mb-5">
              <RadioButton
                inputId="highToLow"
                name="sort"
                value="highToLow"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "highToLow"}
              />
              <label
                htmlFor="highToLow"
                className="ml-2 text-base font-medium text-[var(--color)]"
              >
                Sort by price: high to low
              </label>
            </div>
            <div className="flex items-center mb-5">
              <RadioButton
                inputId="fastMoving"
                name="sort"
                value="fastMoving"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "fastMoving"}
              />
              <label
                htmlFor="fastMoving"
                className="ml-2 text-base font-medium text-[var(--color)]"
              >
                Sort by Fast moving
              </label>
            </div>
          </div>

          {/* Filter by Size */}
          <div className="mb-10">
            <h3 className="text-lg mb-3 mt-8 text-[var(--color)] font-semibold">
              Filter by size
            </h3>
            {sizes.map((size, index) => (
              <div key={index} className="flex items-center mb-3">
                <Checkbox
                  inputId={`size${index}`}
                  value={size.value}
                  onChange={handleSizeChange}
                  checked={selectedSizes.includes(size.value)}
                />
                <label
                  htmlFor={`size${index}`}
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  {size.label}
                </label>
              </div>
            ))}
          </div>

          {/* Filter by Brands */}
          <div className="mb-8">
            <h3 className="text-lg mb-3 mt-8 text-[var(--color)] font-semibold">
              Filter by Brands
            </h3>
            {brands.slice(0, 10).map((brand, index) => (
              <div key={index} className="flex items-center mb-3">
                <Checkbox
                  inputId={`brand${index}`}
                  value={brand}
                  onChange={handleBrandChange}
                  checked={selectedBrands.includes(brand)}
                />
                <label
                  htmlFor={`brand${index}`}
                  className="ml-2 text-base font-medium text-[var(--color)]"
                >
                  {brand}
                </label>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default GiftingPage;
