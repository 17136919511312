import React, { useEffect, useRef, useState } from 'react';
import './CorporatesPage.css'; // Use a CSS file for styles
import { Button } from 'primereact/button';
import copImg1 from './../../Images/copImg1.png';
import copImg2 from './../../Images/copImg2.png';
import copImg3 from './../../Images/copImg3.png';
import seperaatorGold from './../../icons/seperatorGold.png'
import purplecards1 from './../../Images/purple-cards1.png';
import purplecards2 from './../../Images/purple-cards2.png';
import purplecards3 from './../../Images/purple-cards3.png';
import BottleCalculator from '../../components/BottleCalculator';
import ContactForm from '../../components/ContactForm';
import { useNavigate } from 'react-router-dom';
import marbleBackground from './../../Images/marble_background.png';
import VerticalDemo from "../../components/Carousel/CoustomCarousel";

const CorporatesPage = () => {

    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        const slider = sliderRef.current;
        let animationFrame;
        // return;
        const startAnimation = () => {
            slider.scrollLeft += 0.5; // Adjust scroll increment for slower speed
            if (slider.scrollLeft >= slider.scrollWidth / 2) {
                slider.scrollLeft = 0; // Reset scroll to create seamless effect
            }
            animationFrame = requestAnimationFrame(startAnimation);
        };

        startAnimation();

        return () => cancelAnimationFrame(animationFrame);
    }, []);

    const sliderRef = useRef(null);

    const [images, setImages] = useState([
        { id: 1, src: copImg1 },
        { id: 2, src: copImg2 },
        { id: 3, src: copImg3 },
    ]);

    const imageList = [...images, ...images];

    return (

        <div className="px-1 md:px-[8%]  overflow-hidden mt-10 container">
            <div className="mx-[1.5%] ">
                <div className="px-5 flex flex-col items-center">
                    {/* <div className="mb-8 rounded-lg flex flex-row">

                        <div className="p-col-12 p-md-4">
                            <img
                                src={copImg1} // Replace with actual image link
                                alt="Bottle and glasses"
                                className="w-[500px] h-auto rounded-l-lg"
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <img
                                src={copImg2} // Replace with actual image link
                                alt="Table with wine"
                                className="w-[500px] h-auto"
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <img
                                src={copImg3} // Replace with actual image link
                                alt="Cheers with bottles"
                                className="w-[500px] h-auto rounded-r-lg"
                            />
                        </div>
                    </div> */}


                    <div className="slider-container mt-0 mb-5 md:mx-[1%]" ref={sliderRef}>
                        <div className="slider-track px-5">
                            {imageList.map((image) => (
                                <img
                                    key={image.id + Math.random()}
                                    src={image.src}
                                    alt={`Slide ${image.id}`}
                                    className="w-[160px] h-auto flex-shrink-0 sm:w-[200px] md:w-[300px] lg:w-[436px]" />
                            ))}
                            {imageList.map((image) => (
                                <img
                                    key={image.id + Math.random()}
                                    src={image.src}
                                    alt={`Slide ${image.id}`}
                                    className="w-[160px] h-auto flex-shrink-0 sm:w-[200px] md:w-[300px] lg:w-[436px]" />
                            ))}
                            {imageList.map((image) => (
                                <img
                                    key={image.id + Math.random()}
                                    src={image.src}
                                    alt={`Slide ${image.id}`}
                                    className="w-[160px] h-auto flex-shrink-0 sm:w-[200px] md:w-[300px] lg:w-[436px]" />
                            ))}
                        </div>
                    </div>

                    <div className="text-center">
                        <h2 className="section-heading text-[2rem] md:text-[2.5rem] text-[#42866E]  mb-4">Elevate Your
                            Corporate Events</h2>
                        <h3 className="text-[1.1rem] md:text-[1.5rem] mx-12 md:mx-0 text-[#13382B] text-center mb-8">Welcome to WineWorld's exclusive platform for
                            <br /> Sri Lanka corporates</h3>
                    </div>
                    <img className="w-[290px]" src={seperaatorGold}></img>
                    <div className="p-text-center separator">
                        {/* <i className="pi pi-star"></i> */}
                    </div>

                    <div className="description">
                        <p className={`mx-auto text-lg text-[var(--softText)] text-[1rem] md:text-[1.2rem] text-center leading-7 px-[6%] ${isExpanded ? 'max-h-full' : 'max-h-40'} overflow-hidden transition-all duration-500 ease-in-out`}>
                            Welcome to WineWorld's exclusive platform for Sri Lanka corporates, where we offer a
                            seamless and personalized service
                            for ordering and managing liquor (wine and spirits) for your corporate events. Our mission
                            is to provide you with a
                            one-stop solution, ensuring a smooth and effortless experience in curating the perfect
                            selection for your event!
                            Our team of experts is dedicated to assisting you at every step, offering valuable insights
                            on how to structure the
                            ideal beverage selection based on the nature of your event, the preferences of your
                            audience, and your budget requirements.
                            With our online platform, you can conveniently complete the entire transaction from the
                            comfort of your office, eliminating any unnecessary hassles.
                        </p>
                        <button className="block md:hidden mx-auto mt-4 text-blue-500"
                            onClick={handleToggle}> {isExpanded ? 'See Less' : 'See More'} </button>
                    </div>
                </div>


                {/* Bottle Calculator */}

                <BottleCalculator />

                <div className="hidden sm:flex flex-row md:mt-5 justify-between">
                    <div className="imageBox w-1/2 rounded-lg">
                        <img src={purplecards1} className="imagerow_image rounded-lg" ></img>
                    </div>
                    <div className="imageBox w-1/2 rounded-lg">
                        <img src={purplecards2} className="imagerow_image rounded-lg" ></img>
                    </div>
                    <div className="imageBox w-1/2 rounded-lg">
                        <img src={purplecards3} className="imagerow_image rounded-lg" ></img>
                    </div>
                </div>

                <VerticalDemo className="flex sm:hidden rounded-lg" Images={[purplecards1, purplecards2, purplecards3]}
                    showIndicators={true} />

                {/* Contact form */}
                <div className="mobile-padding mobile-margin">
                    <ContactForm  type="corporate"/>
                </div>
                <div className="mobile-margin">
                    <div
                        className="flex justify-center items-center mt-11 min-h-[60vh] mx-5 md:mx-0 bg-cover bg-center md:p-10  rounded-lg "
                        style={{ backgroundImage: `url(${marbleBackground})` }}>
                        <div className="p-5 text-center rounded-lg max-w-5xl">
                            <h1 className="text-[1.8rem] md:text-[2.2rem] text-[#b08d57] mb-5 font-semibold">FILL IN
                                YOUR
                                DETAILS AND WE WILL<br />REACH OUT TO YOU!</h1>
                            <p className="md:text-lg text-[1rem] text-gray-800 leading-loose mb-5 ">
                                Flexibility is at the core of our service, ensuring that all your
                                organization's procurement and purchasing
                                requirements are met with meticulous precision. Your corporate event's
                                success is our priority, and we will go above and beyond to prioritize your
                                convenience and exceed your expectations.
                            </p>
                            <Button onClick={() => {
                                navigate('/contact-us/');
                                window.scrollTo(0, 0);
                            }}
                                className="text-base py-2 px-5 rounded bg-transparent border border-[var(--green)]">
                                <span className="font-bold text-[var(--green)]">Contact Us</span> </Button></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CorporatesPage;