// src/pages/AboutPage.js
import { Card } from 'primereact/card';
import React from 'react';
import blog1 from './../Images/Blog/1.jpeg';
import blog2 from './../Images/Blog/2.png';
import blog3 from './../Images/Blog/3.png';

import {  useNavigate } from "react-router-dom";


const Blog = () => {
    const articles = [
        {
            title: "A Toast to Love: Valentine’s Day Stories with a Splash of Wine",
            date: "Tuesday, January 30th, 2024",
            description: "Welcome to our romantic corner of the web, where love stories intertwine with the exquisite flavors of some of the finest wines. As Valentine’s Day approaches, we invite you to join us on a journey through tales of love, passion, and connection, all accompanied by the enchanting notes of Sparkling Rosé, Tequila Rose, Zonin Sparkling.",
            image: blog1
        }
        ,
        {
            title: "Unveiling the Versatility of Rosé: Beyond Misconceptions",
            date: "Friday, July 21st, 2023",
            description: "Rosé wine has experienced a surge in popularity over recent years, captivating wine enthusiasts with its alluring pink hue and refreshing character. However, there are several misconceptions surrounding this delightful libation that deserve to be debunked. In this article, we will delve into the world of Rosé, dispel common myths, and celebrate its versatility.",
            image: blog2
        }
        , {
            title: "Unlocking Wedding Bliss: The Perfect Selection of Wines and Spirits",
            date: "Friday, July 21st, 2023",
            description: "Your wedding day is a once-in-a-lifetime event, and every detail should be crafted to perfection. From the stunning decor to the mouthwatering cuisine, every element contributes to creating a memorable celebration. Among these, the selection of wines and spirits holds a special place. At WineWorld, we understand the significance of choosing the perfect beverages to.",
            image: blog3
        }
    ];

    const navigate = useNavigate(); // Initialize the navigate hook


    return (
        <div className="mobile-padding mobile-margin" style={{backgroundColor: "var(--blog-bg-color)"}}>
        <div className="container mx-auto p-4 mt-6 w-full"  >
            <h2 className="text-4xl font-bold mb-8 text-[var(--color)]">BLOG</h2>
            {articles.map((article, index) => (
                <Card key={index} className="mb-8 w-full rounded-lg">
                    <div className="flex flex-col md:flex-row">
                        <img src={article.image} alt={article.title} className="w-full md:w-1/3 object-cover" />
                        <div className="p-4">
                            <h2 className="text-2xl font-bold mb-2">{article.title}</h2>
                            <p className=" mb-4 text-[var(--conditions)]">{article.date}</p>
                            <p className="text-[var(--softText)]">{article.description}</p>
                            <div onClick={() => { navigate("/BlogView"); window.scrollTo(0, 0); }} className="text-blue-500 mt-4 inline-block cursor-pointer">Read More</div>
                        </div>
                    </div>
                </Card>
            ))}
        </div>
            </div>
    );
};
 
export default Blog;
