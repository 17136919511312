import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useGetAllProductQuery, useSearchProductQuery } from "../redux/service/productSlice";
import { useNavigate } from "react-router-dom";

const BottleCalculator = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [bottles, setBottles] = useState(0);
  const [guests, setGuests] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  // const { data } = useGetAllProductQuery({ page: 0, size: 1000 });
  const [volumePerPerson, setVolumePerPerson] = useState(0); // New state to store volumePerPerson
  const cityId = localStorage.getItem("city");

  const { data, isLoading: prodcutsLoading, refetch } = useSearchProductQuery(
    {
      page: 0,
      size: 1000,
      cityId
    },
    { refetchOnMountOrArgChange: true }
  );

  const calculateBottles = (value) => {
    const guests = value;
    // console.log("guests1", guests);

    // console.log({ guests })
    if (!guests || guests < 1) {
      setBottles(0); // Set to 0 if guests is invalid or less than or equal to 0
      return;
    }
    const bottleCount = Math.ceil(guests / volumePerPerson);
    setBottles(bottleCount);
  };

  const handleDropdownChange = (e) => {
    const selected = e.value;

    if (!selected?.volume) {
      return;
    }
    if (!selected.volumePerPerson) {
      return;
    }
    setSelectedProducts(selected);
    const personsPerBottle = selected.volume / selected.volumePerPerson;
    setVolumePerPerson(personsPerBottle); // Update volumePerPerson state


    setGuests(0);
    setBottles(0);


  };
  // useEffect(() => {
  // }, [selected]);

  useEffect(() => {
    if (data?.content) {
      data?.content?.map((info) => {
        setProduct((prev) => [
          ...prev,
          {
            id: info.id,
            name: info.correctName,
            price: info.regularPriceInRupee,
            img: info.imageUrl,
            volume: info.volume || 0,
            volumePerPerson: info.volumePerPerson || 0,
          },
        ]);
        return null;
      });
    }
  }, [data]);

  const truncateOptions = (options) => {
    return options.map((option) => ({
      ...option,
      name:
        option.name.length > 25
          ? `${option.name.slice(0, 20)}...`
          : option.name,
    }));
  };

  const truncatedProducts = truncateOptions(product);

  const products = [
    { label: "Wine", value: "wine" },
    { label: "Champagne", value: "champagne" },
    { label: "Spirits", value: "spirits" },
  ];

  return (
    <div className="mobile-padding mobile-margin">
      <Card className="bottle-calculator-card bg-[var(--blog-bg-color)]">
        <h2 className="bottle-calculator-heading">
          <span className="bottle-calculator-heading hidden md:flex">
            Bottle
          </span>{" "}
          Calculator
        </h2>
        <div className="flex flex-col md:flex-row gap-3 justify-between">
          <div className="w-full flex flex-col gap-4">
            <div className="flex flex-col md:flex-row justify-between">
              <label className="w-1/3 text-nowrap" htmlFor="product">
                Product Name:
              </label>
              {/* <Dropdown
                value={selectedProducts}
                options={truncatedProducts}
                onChange={(e) => {
                  setSelectedProducts(e.value);
                  setPerPerson()
                }

                }
                optionLabel="name"
                placeholder="Select products"
                className="border border-gray-300 rounded h-12"
                filter
              /> */}
              <Dropdown
                value={selectedProducts}
                options={truncatedProducts}
                onChange={handleDropdownChange} // Use the new handler
                optionLabel="name"
                placeholder="Select products"
                className="border border-gray-300 rounded h-12"
                filter
              />
            </div>

            <div className="flex flex-col md:flex-row justify-between">
              <label className="w-1/3 text-nowrap" htmlFor="guests">
                Number of Guests:
              </label>
              <InputNumber
                id="guests"
                value={guests}
                disabled={selectedProducts?.length < 1}
                onChange={(e) => {
                  setGuests(e.value);
                  calculateBottles(e.value);
                }}
                min={0}
                showButtons
                className="w-full border-2 ps-2"
              />
            </div>
          </div>

          <div className="w-full md:w-1/3">
            <div className="flex border-2 text-center w-full">
              <div className="flex justify-center w-full">
                <h3 className="text-[20px] text-orange-600 font-semibold">
                  {bottles} Bottles
                </h3>
              </div>
            </div>
            <p className="result-label text-end hidden md:flex">
              Result (Number of bottles)
            </p>
          </div>
        </div>

        <div className="p-text-center">
          <p className="note-text">
            <strong className="note-highlight">Please Note:</strong> The results
            provided by this calculator are based on assumptions and are
            intended for illustrative purposes only. For more accurate and
            personalized information, please{" "}
            <span
              onClick={() => {
                navigate('/contact-us/');
                window.scrollTo(0, 0);
              }}
              className="contact-link cursor-pointer"
              role="link" // Add role to maintain accessibility
              tabIndex={0} // Make it focusable for keyboard users
            >
              Contact Us
            </span>

            .
          </p>
        </div>
      </Card>
    </div>
  );
};

export default BottleCalculator;
