import { Dialog } from "primereact/dialog";
import useToggle from "../../hooks/useToggle";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useChangePasswordMutation, useUpdateProfileMutation } from "../../redux/service/profileSlice";
import { toast } from "material-react-toastify";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[@$!%*?&#]/, "Password must contain at least one special character"),

  currentPassword: Yup.string().required("Current Password is required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ChangePasswordModel = ({ children }) => {
  const [isVisible, toggleVisibility] = useToggle();
  const [showCurrentPassword, setshowCurrentPassword] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const user = useSelector((state) => state?.auth?.userInfo);
  const [updatePassword] = useChangePasswordMutation();


  const toggleShowSignPassword = () => {
    setshowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const toggleShowconfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
    currentPassword: ""
  });
  const [formErrors, setFormErrors] = useState({});

  const handlePassword = async (e) => {
    // Validate form fields using Yup schema
    try {
      console.log(user.id)
      await validationSchema.validate(form, { abortEarly: false }); // Validate form
      setFormErrors({}); // Clear previous errors if validation succeeds
      console.log("form.currentPassword", form.currentPassword);
      console.log("form.password", form.password);
      await updatePassword({
        id: user.id,
        oldPassword: form.currentPassword,
        newPassword: form.password
      }).then((response) => {
        if (response.data) {
          toast.success("Password updated successfully");
          setForm({
            currentPassword: "",
            password: "",
            confirmPassword: ""
          });
          toggleVisibility();
          console.log("Response:", response);
          // Perform other success actions
        }
      }).catch((error) => {
        toast.error(error.data.message || "Something went wrong");
      });

    } catch (err) {
      // Handle validation errors
      const errors = {};
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
      setFormErrors(errors); // Update errors in state
    }
  };


  return (
    <div className="">
      <button onClick={toggleVisibility}>
        <span>{children}</span>
      </button>

      <Dialog
        header="Change Password"
        visible={isVisible}
        className="md:min-w-[500px] "
        // style={{   width: window.innerWidth < 768 ? "98vw" : "40vw", }}
        onHide={toggleVisibility}
      >
        <div className="w-[300px] md:min-w-[500px] ">
          <div className="flex justify-center h-[400px] md:min-w-[500px]">
            <div className="flex flex-col my-10 gap-10 px-1 md:w-full">

              <div className=" md:w-full  px-10">
                <label className="ml-0 mb-0 mt-2 ">Current Password</label>

                <div className="relative ">
                  <InputText
                    id="Password"
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="Please enter your current password"
                    className="login-modal-inputs pr-10 w-full"
                    value={form.currentPassword}
                    onChange={(e) =>
                      setForm({ ...form, currentPassword: e.target.value })
                    } />
                  <span
                    onClick={toggleShowSignPassword}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  >
                    {!showCurrentPassword ? (
                      <i
                        className="pi pi-eye-slash"
                        style={{ fontSize: "1.5rem" }}
                      />
                    ) : (
                      <i className="pi pi-eye" style={{ fontSize: "1.5rem" }} />
                    )}
                  </span>
                </div>


                {formErrors.currentPassword && (
                  <p className="text-red-500">{formErrors.currentPassword}</p>
                )}
              </div>

              <div className=" md:w-full  px-10">
                <label className="ml-0 mb-0 mt-2 ">Password</label>

                <div className="relative ">
                  <InputText
                    // id="Password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Please enter your password"
                    className="login-modal-inputs pr-10 w-full"
                    value={form.password}
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    } />
                  <span
                    onClick={toggleShowPassword}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  >
                    {!showPassword ? (
                      <i
                        className="pi pi-eye-slash"
                        style={{ fontSize: "1.5rem" }}
                      />
                    ) : (
                      <i className="pi pi-eye" style={{ fontSize: "1.5rem" }} />
                    )}
                  </span>
                </div>


                {formErrors.password && (
                  <p className="text-red-500">{formErrors.password}</p>
                )}
              </div>

              {/* <div className="input-group mt-2">
                <label className="ml-0 mb-0 mt-1">Password</label>
                <InputText
                  id="Password"
                  placeholder="Please enter your password"
                  className="password-reset-modal-inputs w-[290px] mt-2"
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                />
                {formErrors.password && (
                  <p className="text-red-500">{formErrors.password}</p>
                )}
              </div> */}


              <div className=" md:w-full  px-10">
                <label className="ml-0 mb-0 mt-2 ">Confirm Password</label>

                <div className="relative ">
                  <InputText
                    // id="Password"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Please confirm your password"
                    className="login-modal-inputs pr-10 w-full"
                    value={form.confirmPassword}
                    onChange={(e) =>
                      setForm({ ...form, confirmPassword: e.target.value })
                    } />
                  <span
                    onClick={toggleShowconfirmPassword}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  >
                    {!showConfirmPassword ? (
                      <i
                        className="pi pi-eye-slash"
                        style={{ fontSize: "1.5rem" }}
                      />
                    ) : (
                      <i className="pi pi-eye" style={{ fontSize: "1.5rem" }} />
                    )}
                  </span>
                </div>


                {formErrors.confirmPassword && (
                  <p className="text-red-500">{formErrors.confirmPassword}</p>
                )}
              </div>

              {/* <div className="input-group mt-2">
                <label className="ml-0 mb-0 mt-1">Confirm Password</label>
                <InputText
                  id="ConfirmPassword"
                  placeholder="Please confirm your password"
                  className="password-reset-modal-inputs w-[290px]   mt-2"
                  value={form.confirmPassword}
                  onChange={(e) =>
                    setForm({ ...form, confirmPassword: e.target.value })
                  }
                />
                {formErrors.confirmPassword && (
                  <p className="text-red-500">{formErrors.confirmPassword}</p>
                )}
              </div> */}

              <div className="w-full justify-center flex mt-5">
                <div className="modal-footer justify-center flex">
                  <Button
                    label="Submit"
                    className="login-confirm-btn py-2 px-2 text-[var(--color)]"
                    onClick={handlePassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ChangePasswordModel;
