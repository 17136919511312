import React, { useEffect, useState } from 'react';
import './TypeTwoEventCard.css';

const TypeTwoEventCard = ({ Title, Description, onClick, image }) => {


    return (

        <div className="event-card-container ">
            <img
                src={image}
                className="event-card-image object-cover"
            />

            <div className="event-card-content">
                <h3 className="event-card-title">{Title}</h3>
                <span className="event-card-description">{Description}</span>
                <button className="event-card-button  text-[var(--color)]" onClick={onClick}>Contact us</button>
            </div>
        </div>


    );
};

export default TypeTwoEventCard;
