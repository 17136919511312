import { apiSlice } from "./apiSlice";

export const orderSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (query) => {
        const { id, userId, sessionId, cityId } = query;
        const params = new URLSearchParams();
        if (userId) params.append("userId", userId);
        if (sessionId) params.append("sessionId", sessionId);
        if (cityId) params.append("cityId", cityId);

        return {
          url: `/orders/create/${id}?${params.toString()}`,
          method: "POST",
          body: query,
        };
      },
      invalidatesTags: ["Order"],
    }),
    getAllOrder: builder.query({
      query: (query) => ({
        url: `/orders/user?${query.userId && `userId=${query.userId}`}${query.sessionId && `&sessionId=${query.sessionId}`}`,
        method: "GET",
      }),

    }),
    getSingleOrder: builder.query({
      query: (query) => ({
        url: `/orders/${query.orderId}`,  // Using orderId instead
        method: "GET",
      }),
    }),
    getOrderStatus: builder.query({
      query: (query) => ({
        url: `/orders/${query.orderId}`,  // Using orderId instead
        method: "GET",
      }),
    }),
    getAOrder: builder.query({
      query: (id) => ({
        url: `/orders?page=0&size=10`,
        method: "GET",
      }),
    }),
    convertOrder: builder.mutation({
      query: (orderId) => ({
        url: `/orders/convert-order?orderId=${orderId}`,
        method: "POST",
        headers: {
          accept: "*/*",
        },
        body: "", // Empty body for the POST request
      }),
      invalidatesTags: ["Cart"]
    }),
  }),
});

export const { useCreateOrderMutation, useConvertOrderMutation, useGetAllOrderQuery, useGetSingleOrderQuery, useGetAOrderQuery, useGetOrderStatusQuery } = orderSlice;
