import React, { useState } from "react";

const OtpInput = ({ length = 5, onChange, clearOtp }) => {
  const [otp, setOtp] = useState(Array(length).fill(""));

  React.useEffect(() => {
    setOtp(Array(length).fill(""));
  }, [clearOtp]);

  console.log(clearOtp);

  console.log(otp);

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(-1); // Limit input to 1 character
    setOtp(newOtp);
    onChange(newOtp.join(""));

    // Move to next field if there is a next input and value is not empty
    if (value && index < length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  return (
    <div className="otp-container">
      {otp.map((digit, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          value={digit}
          maxLength={1}
          onChange={(e) => handleChange(e.target.value, index)}
          className={`otp-input ${otp.every((digit) => digit) ? "filled" : ""}`}
        />
      ))}
    </div>
  );
};

export default OtpInput;
