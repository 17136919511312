// src/components/Header.js
import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import logo from "./../icons/logo.png";
import whiteLogo from "./../icons/whiteLogo.png";
import { InputText } from "primereact/inputtext";
import { usePasswordResetRequestMutation } from "../redux/service/authSlice";
import { toast } from "material-react-toastify";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ResetPassword = ({
  isModalVisible,
  isDarkTheme,
  toggleModal,
  setIsResetPasswordVisible,
  emailSet
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [ResetPassword , { isLoading }] = usePasswordResetRequestMutation();

  const ResetPasswordHandler = async () => {
    // setIsVerifyVisible(true);
    try {
      await validationSchema.validate({ email }, { abortEarly: false });
      if (email === "") {
        setEmailError("Please enter your email");
        return;
      }
      await ResetPassword(email)
        .unwrap()
        .then((res) => {
          // onClick();
          emailSet(email)
          toggleModal();
          setIsResetPasswordVisible(true);
          toast.success("Password reset email sent successfully");

        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.data?.message || "Something went wrong");
        });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setEmailError(validationErrors.email);
      }
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  return (
    <Dialog
      visible={isModalVisible}
      footer={true}
      // header="Verify OTP" // Added a header title here
      closable={true}
      className="custom-dialog"
      onHide={toggleModal}
      breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
      style={{ width: "30vw" }}
    >
      <div className="verify-modal-inputs">
        <img
          src={isDarkTheme ? whiteLogo : logo}
          alt="Wine World Logo"
          className="login-logo"
        />

        <span className="verify-modal-description">
          An OTP will be sent to your email address to ensure the
          <br />
          security of your account.
        </span>

        <div className="forgot-input-group">
          <label className="ml-0 mb-0 mt-1 text-left">Email</label>

          <InputText
            id="Email"
            placeholder="Please enter your email"
            className="login-modal-inputs"
            value={email}
            onChange={handleEmailChange}
          />

          {emailError ? (
            <p className="error-text text-start">{emailError}</p>
          ) : (
            <small className="error-text"></small>
          )}
        </div>
        <div className="modal-footer">
          <Button
            label="Next"
            loading={isLoading}
            className="login-confirm-btn py-2"
            onClick={ResetPasswordHandler}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ResetPassword;
