import { apiSlice } from "./apiSlice";

export const productSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getAllProduct: builder.query({
      query: (query) => {
        const queryString = [
          `page=${query.page || 0}`,
          query.size && `&size=${query.size}`,
        ]
          .filter(Boolean)
          .join("&");
        return {
          url: `products?${queryString}`,
          method: "GET",
        };
      },
    }),

    getAProduct: builder.query({
      query: ({ id, userId }) => {
        const url = `products/singleProduct/${id}${userId !== undefined ? `?userId=${userId}` : ""}`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    getProductHierarchy: builder.query({
      query: ({ cityId }) => {
        const url = `products/hierarchy${cityId !== undefined ? `?cityId=${cityId}` : ""}`;
        return {
          url,
          method: "GET",
          headers: {
            accept: "*/*", // Match the header from the curl command
          },
        };
      },
    }),

    searchProduct: builder.query({
      query: (query) => {

        const queryString = [
          `page=${query.page || 0}`,
          query.size && `size=${query.size}`,
          query.sizeCategories && `sizeCategories=${query.sizeCategories}`,
          query.brands && `brands=${query.brands}`,
          query.categoryId && `categoryId=${query.categoryId}`,
          query.sortBy && `sortBy=${query.sortBy}`,
          query.cityId && `cityId=${query.cityId}`,
          query.departmentId && `departmentId=${query.departmentId}`,
          query.isFeatured !== undefined && `isFeatured=${query.isFeatured}`,
          query.currency && `currency=${query.currency}`,
          query.minPrice && `minPrice=${query.minPrice}`,
          query.maxPrice && `maxPrice=${query.maxPrice}`,
          query.isBestSeller !== undefined && `isBestSeller=${query.isBestSeller}`,
        ]
          .filter(Boolean)
          .join("&");

        return {
          url: `products/search-products?${queryString}`,
          method: "GET",
        };
      },
    }),

    featuredProduct: builder.query({
      query: (query) => {

        const queryString = [
          `page=${query.page || 0}`,
          // query.size && `size=${query.size}`,
          // query.sizeCategories && `sizeCategories=${query.sizeCategories}`,
          // query.brands && `brands=${query.brands}`,
          // query.sortBy && `sortBy=${query.sortBy}`,
          query.cityId && `cityId=${query.cityId}`,
          // query.departmentId && `departmentId=${query.departmentId}`,
          query.isFeatured !== undefined && `isFeatured=${query.isFeatured}`,
          query.isBestSeller !== undefined && `isBestSeller=${query.isBestSeller}`,
          query.isNewArrival !== undefined && `isNewArrival=${query.isNewArrival}`
        ]
          .filter(Boolean)
          .join("&");

        return {
          url: `products/search-products?${queryString}`,
          method: "GET",
        };
      },
    }),


  }),
});

export const { useGetAllProductQuery, useGetProductHierarchyQuery, useGetAProductQuery, useSearchProductQuery, useFeaturedProductQuery } = productSlice;
