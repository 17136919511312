import { apiSlice } from "./apiSlice";



export const citySlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllCity: builder.query({
            query: (query) => ({
                url: "cities?page=0&size=1000",
                method: "GET",
            }),
        }),
        getACity: builder.query({
            query: (id) => ({
                url: `cities/${id}`,
                method: "GET",
            }),
        }),
        getAllProvinceAndCity: builder.query({
            query: (id) => ({
                url: `cities/billable?page=0&size=1000`,
                method: "GET",
            }),
        }),
    })
})

export const { useGetAllCityQuery, useGetACityQuery , useGetAllProvinceAndCityQuery } = citySlice