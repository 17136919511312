import { apiSlice } from "./apiSlice";

export const favouriteSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addfavItem: builder.mutation({
      query: (data) => {
        // const sessionId = localStorage.getItem("sessionId");
        return {
          url: `favorites`,
          method: "POST",
          body: data,
        };
      },
      // invalidatesTags: ["favorites"], // Mark Cart tag as invalidated
    }),

    getFavoriteItems: builder.query({
      query: (userId) => ({
        url: `favorites/${userId}`,
        method: "GET",
        headers: {
          accept: "*/*",
        },
      }),
    }),
    deleteFavoriteItem: builder.mutation({
      query: (data) => ({
        url: `favorites?userId=${data.userId}&productId=${data.productId}`,
        method: "DELETE",
        headers: {
          accept: "*/*",
        },
      }),
    }),


  }),
});

export const {
  useAddfavItemMutation,
  useGetFavoriteItemsQuery,
  useDeleteFavoriteItemMutation
} = favouriteSlice;
