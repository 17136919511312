//  Checkout Page
import React, { useEffect, useState } from "react";

import "./Checkout.css";
import { Link, useNavigate } from "react-router-dom";
import successIcon from "./../../icons/Modal-Success.png";
import paymentFail from "./../../icons/paymentFail.png";
import { ProgressSpinner } from "primereact/progressspinner";

import PayHereLogo from "./../../icons/PayHere.png";
import CheckoutListItem from "../../components/CheckoutListItem";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import ShareModal from "../../components/Modals/ShareModal";
import AddBillingInfo from "../../components/Modals/AddBillingInfo";
import AddDeliveryInfo from "../../components/Modals/AddDeliveryInfo";
import SendAsGift from "../../components/Modals/SendAsGift";
import { BreadCrumb } from "primereact/breadcrumb";
import {
  useCartAddressUpdateMutation,
  useDeleteCartMutation,
  useDeliveryOptionsMutation,
  useGetCartItemsQuery,
  useUpdateGiftDetailsMutation,
} from "../../redux/service/cartSlice";
import { useSelector } from "react-redux";
import {
  useCreateOrderMutation,
  useGetOrderStatusQuery,
  useGetSingleOrderQuery,
} from "../../redux/service/orderSlice";
import { useCreatePaymentIntentMutation } from "../../redux/service/paymentSlice";
import { useGetProfileQuery } from "../../redux/service/profileSlice";
import {
  useGetACityQuery,
  useGetAllCityQuery,
} from "../../redux/service/citySlice";
import AddOns from "../../components/Modals/AddOns";
import { Checkbox } from "primereact/checkbox";
import { toast } from "material-react-toastify";
import { useGetAddOnsQuery } from "../../redux/service/addonsSlice";
import { BASE_URL } from "../../redux/service/apiSlice";
import axios from "axios";
// import { Payhere } from "@payhere-js-sdk/client";
import * as Yup from "yup";
import { Children } from "react";
import { Calendar } from "primereact/calendar";
import { FaSpinner } from "react-icons/fa";

const validationSchema = Yup.object().shape({
  lankaNumber: Yup.string()
    .matches(
      /^(\+94\d{9}|0\d{9})$/,
      "Phone Number must be in the format +94XXXXXXXXX or 0XXXXXXXXX"
    )
    .required("Phone Number is required"),
});

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state?.auth?.userInfo);
  const [isAddOneVisible, setisAddOneVisible] = useState(false);
  const successMessage =
    "your Order has been placed successfully. You can view your order details in the order history and payment invoice.";
  const failedMessage =
    "your Order has been failed. You can view your order details in the order history and payment invoice.";
  const [showAllBilling, setShowAllBilling] = useState(1);
  const [showAllDelivery, setShowAllDelivery] = useState(1);
  const [StatusOrderId, setstatusOrderId] = useState();
  const navigate = useNavigate();
  const { data: cartData, refetch: refetchCart } = useGetCartItemsQuery({
    cartType: "normal",
    userId: userInfo?.id,
  });
  const [deleteCart, { isLoading: deleteCartLoading }] =
    useDeleteCartMutation();



  const [formDataModel, setFormDataModel] = useState(false);
  const cartItems = cartData?.data?.cartItems;
  const currency = useSelector((state) => state?.meta?.currency);
  const [checked, setChecked] = useState(false);
  const deliveryamount =
    currency === "LKR"
      ? cartData?.data?.deliveryChargesInRupee
      : cartData?.data?.deliveryChargesInDollar;
  const additional = 0;
  const [shipping, setShipping] = useState("DELIVERY");
  const [error, setError] = useState({});
  const [promoCode, setPromoCode] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [success, setSuccess] = useState(true);
  const [paymentData, setPaymentData] = useState("");
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isAddBillingModalVisible, setIsAddBillingModalVisible] =
    useState(false);
  const [isAddDeliveryModalVisible, setIsAddDeliveryModalVisible] =
    useState(false);
  const [isGiftModalVisible, setIsGiftModalVisible] = useState(false);
  const [lankaNumberInput, setLankaNumberInput] = useState(false);
  const [lankaNumberError, setLankaNumberError] = useState(false);
  const [lankaNumber, setLankaNumber] = useState("");
  const [lankaNumberErrorMessage, setLankaNumberErrorMessage] = useState("");
  const [address, setAddress] = useState("");
  const [bAddress, setBAddress] = useState("");
  const [sameCityError, setSameCityError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [deliveryDate, setDeliveryDate] = useState("");
  const [localNumber, setLocalNumber] = useState("");
  const [localNumberAddState, setLocalNumberAddState] = useState(false);

  const [addressChange, setAddressChange] = useState();

  const deliveryDateFormatted = deliveryDate
    ? new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(deliveryDate))
    : null;

  useEffect(() => {
    setChecked(false);
    setLankaNumberInput(false);
    setLankaNumberError(false);
    setLankaNumber("");
    setLankaNumberErrorMessage("");
    setSameCityError("");
    setFormErrors({});
    setLocalNumber("");
    setLocalNumberAddState(false);

  }, [addressChange]);

  useEffect(() => {
    setError({

      noBillingAddress: false,
    });
  }, [bAddress]);

  console.log(bAddress)

  // useEffect(() => {
  //   if (cartItems?.length === 0) {
  //     navigate("/");
  //   }
  // }, [cartItems]);

  console.log("cartItems", cartItems?.length === 0);

  useEffect(() => {
    setSameCityError("");
    setLankaNumberInput(false);
    setLankaNumberError(false);
  }, [address]);

  const cityId = localStorage.getItem("city");
  const city = useGetACityQuery(cityId);

  // const { data: guest } = useGetProfileQuery();
  // console.log("shipping", shipping);
  const { data: cities } = useGetAllCityQuery();
  const { data: order, isLoading: isLoadingOrder } = useGetSingleOrderQuery({
    orderId: StatusOrderId,
  });
  const selectedCity = cities?.data?.content?.find((city) => city.id == cityId);

  const totalAmount = cartItems?.reduce((total, item) => {
    let itemPrice;

    if (currency === "LKR") {
      itemPrice = item?.priceInRupee;
    } else if (currency === "USD") {
      itemPrice = item?.priceInDollar;
    }

    const itemTotal = itemPrice;
    return total + itemTotal;
  }, 0);

  const finaltotalAmount =
    shipping === "Delivery"
      ? totalAmount +
      additional +
      (currency === "LKR"
        ? cartData?.data?.deliveryChargesInRupee
        : cartData?.data?.deliveryChargesInDollar)
      : totalAmount + additional;

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const doneClick = () => {
    toggleModal();
    refetchCart();
    navigate("/");
    window.scrollTo(0, 0);
  };

  const toggleAddOneModal = () => {
    setisAddOneVisible((prev) => !prev);
  };



  const orderHistory = () => {
    refetchCart();
    toggleModal();
    navigate("/OrderHistory");
    window.scrollTo(0, 0);
  };

  const [createOrder] = useCreateOrderMutation();
  const [createPaymentIntent] = useCreatePaymentIntentMutation();
  const [createDeliveryOptions] = useDeliveryOptionsMutation();
  const [setCartAddress] = useCartAddressUpdateMutation();
  const [updateGiftDetails, { isLoading: giftLoading }] =
    useUpdateGiftDetailsMutation();

  const user = useSelector((state) => state?.auth?.userInfo);
  const { data: profile } = useGetProfileQuery(user?.id);
  useEffect(() => {
    // console.log("useeffect", cartData?.data?.deliveryOption);
    if (cartData?.data?.deliveryOption) {
      setShipping(cartData?.data?.deliveryOption);
    }
  }, [cartData]);

  const paymentHandler = async () => {
    const sessionId = localStorage.getItem("sessionId")
      ? localStorage.getItem("sessionId")
      : undefined;

    setIsLoading(true);
    // console.log("test");

    if (user) {
      if (!bAddress) {
        setError({ ...error, noBillingAddress: true });
        toast.warning("Please add billing address");
        setIsLoading(false);

        return;
      }
      if (!address && !checked) {
        setError({ ...error, noAddress: true });
        setIsLoading(false);
        toast.warning("Please add delivery address");
        return;
      }
    } else {
      if (!cartData?.data?.billingAddress) {
        setError({ ...error, noBillingAddress: true });
        setIsLoading(false);
        toast.warning("Please add billing address");
        return;
      }

      if (
        (!checked && !cartData?.data?.deliveryAddress) ||
        !cartData?.data?.billingAddress
      ) {
        setError({ ...error, noAddress: true });
        toast.warning("Please add delivery address");
        setIsLoading(false);
        return;
      }
    }
    setError({});

    if (
      city?.data?.data?.cityName !== (checked ? bAddress.city : address.city)
    ) {
      toast.warning("Billing address and delivery city mismatch. Please add a new delivery address.");
      setSameCityError("Billing address and delivery city mismatch. Please add a new delivery address.");
      setIsLoading(false);
      return;
    }

    const billingAddress = user
      ? typeof bAddress === "object"
        ? `${bAddress.addressLine1} ${bAddress.addressLine2} ${bAddress.city} ${bAddress.province} ${bAddress.postalCode} ${bAddress.contactNumber}`
        : bAddress
          ? `${bAddress} ${bAddress.contactNumber}`
          : ""
      : cartData?.data?.billingAddress;

    const dAddress = user
      ? `${address?.addressLine1} ${address?.addressLine2} ${address?.city} ${address?.province} ${address?.postalCode}`
      : cartData?.data?.deliveryAddress;

    const delivery_address = checked ? billingAddress : dAddress;

    try {
      await setCartAddress({
        cartId: cartData?.data?.id,
        formData: {
          billingAddress: billingAddress,
          deliveryAddress: delivery_address,
        },
      }).catch((error) => {
        toast.error(error.data.message || "Something went wrong");
      });

      await createOrder({
        id: cartData?.data?.id,
        userId: user?.id,
        sessionId: sessionId,
        totalPrice: finaltotalAmount,
        paymentMethod: "PayHere",
        currency: currency,
        localContactNumber: localNumber ? localNumber : undefined,
        deliveryDate: deliveryDate ? deliveryDate : undefined,
        cityId: localStorage.getItem("city")
          ? localStorage.getItem("city")
          : undefined,

        orderItems: cartData?.data?.cartItems.map((item) => {
          return {
            ...item,
            productId: item.product.id,
            totalPrice: item.regularPriceInRupee,
          };
        }),
      })
        .unwrap()
        .then(async (res) => {
          await createPaymentIntent({
            amount:
              currency === "LKR"
                ? cartData?.data?.totalPriceInRupee
                : cartData?.data?.totalPriceInDollar,
            currency,
            orderId: res.data.id,
          })
            .unwrap()
            .then((res) => {
              setPaymentData(res.paymentUrl);

              setFormDataModel(true);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
            });
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const removeGift = async () => {
    await updateGiftDetails({
      cartId: cartData?.data?.id,
      formData: { isGift: false },
    })
      .then((res) => {
        if (cartData?.data?.gifterName) {
          toast.success("Gift removed");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  // useEffect(() => {
  //   if (shipping === "STORE_PICKUP") {
  //     removeGift();
  //   }
  // }, [shipping]);

  const toggleShareModal = () => {
    setIsShareModalVisible((prev) => !prev);
  };

  const toggleBillInfoModal = () => {
    setIsAddBillingModalVisible((prev) => !prev);
  };

  const toggleDeliveryInfoModal = () => {
    setIsAddDeliveryModalVisible((prev) => !prev);
  };

  const toggleGiftModal = () => {
    setIsGiftModalVisible((prev) => !prev);
  };

  const deleteAll = async () => {
    try {
      await deleteCart({
        userId: user?.id,
        cartType: "normal",
      }).unwrap();

      // Successfully deleted item
      // refetch(); // Refresh the data
      refetchCart();
      navigate("/");
      toast.success("Successfully cleared the cart.");
    } catch (err) {
      toast.error(err.data?.message || "Failed to delete item");
      console.error(err);
    }
  };

  const PaymentForm2 = ({ url, setFormDataModel }) => {
    const [paymentLoading, setPaymentLoading] = useState(false);

    const [formData, setFormData] = useState({
      sandbox: true,
      merchant_id: "1228617",
      return_url: "https://caprconuat.xyz",
      cancel_url: "https://caprconuat.xyz",
      notify_url: "https://caprconuat.xyz",
      order_id: "",
      items: "",
      currency: "LKR",
      amount: "1000.00",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      country: "Sri Lanka",
      hash: "",
    });

    useEffect(() => {
      if (url) {
        try {
          const queryParams = new URLSearchParams(new URL(url).search);
          const parsedData = {};
          queryParams.forEach((value, key) => {
            parsedData[key] = value;
          });
          setFormData((prev) => ({ ...prev, ...parsedData }));
          // console.log("formData", formData);
        } catch (error) {
          console.error("Invalid URL:", error);
        }
      }
    }, [url]);

    // useEffect(() => {
    //   let retryCount = 0; // Track the number of retries
    //   const maxRetries = 10; // Maximum number of retries
    //   const retryInterval = 6000; // Retry interval in milliseconds (e.g., 6 seconds)
    //   let timeoutId;

    //   const checkPaymentStatus = async (StatusOrderId) => {
    //     try {
    //       const orderId = StatusOrderId;
    //       console.log("orderId", orderId);

    //       const orderData = order;
    //       console.log("orderConform", orderData?.data);
    //       if (orderData?.data?.paymentStatus === "SUCCESS") {
    //         clearTimeout(timeoutId); // Stop further retries
    //         setIsModalVisible(true);
    //         setPaymentLoading(false);
    //         setSuccess(true);
    //       } else if (orderData?.data?.paymentStatus === "PENDING" && retryCount < maxRetries) {
    //         console.log("trying");
    //         retryCount += 1;
    //         timeoutId = setTimeout(() => checkPaymentStatus(StatusOrderId), retryInterval); // Retry after the interval
    //       } else {
    //         clearTimeout(timeoutId); // Stop further retries
    //         setPaymentLoading(false);
    //         setIsModalVisible(true);
    //         setSuccess(false);
    //       }
    //     } catch (error) {
    //       console.error("Error fetching order status:", error);
    //       clearTimeout(timeoutId); // Stop retries on error
    //       // setIsModalVisible(true);
    //       setSuccess(false);
    //     }
    //   };

    //   window.payhere.onCompleted = function (orderId) {
    //     console.error("onCompleted:");

    //     retryCount = 0; // Reset retry count
    //     setIsLoading(true); // Show loader animation
    //     // checkPaymentStatus(StatusOrderId);
    //   };

    //   window.payhere.onDismissed = function () {
    //     console.error("Dismissedscc:", formData);
    //     checkPaymentStatus(StatusOrderId);
    //     setPaymentLoading(true);
    //     // navigate("/");
    //   };

    //   window.payhere.onError = function (error) {
    //     console.error("onError:");

    //     navigate("/");
    //     console.error("Error:", error);
    //   };

    //   return () => {
    //     clearTimeout(timeoutId); // Clean up on component unmount
    //   };
    // }, []);

    useEffect(() => {
      let retryCount = 0; // Track the number of retries
      const maxRetries = 10; // Maximum number of retries
      const retryInterval = 6000; // Retry interval in milliseconds (e.g., 6 seconds)
      let timeoutId;

      const checkPaymentStatus = async (StatusOrderId) => {
        try {
          // const orderId = StatusOrderId;
          // console.log("orderId", orderId);

          // const orderData = order;

          const orderData = await axios.get(
            BASE_URL + `/orders/${StatusOrderId}`
          );
          // return response.data; // Return the data from the API
          console.log("orderConform", orderData?.data?.data?.paymentStatus);

          if (orderData?.data?.data?.paymentStatus === "SUCCESS") {
            clearTimeout(timeoutId); // Stop further retries
            setIsModalVisible(true);
            setPaymentLoading(false);
            setSuccess(true);
          } else if (
            orderData?.data?.data?.paymentStatus === "PENDING" &&
            retryCount < maxRetries
          ) {
            console.log("Trying again... Retry count:", retryCount + 1);
            retryCount += 1;
            timeoutId = setTimeout(
              () => checkPaymentStatus(StatusOrderId),
              retryInterval
            ); // Retry after the interval
          } else {
            clearTimeout(timeoutId); // Stop further retries
            setPaymentLoading(false);
            setIsModalVisible(true);
            setSuccess(false);
          }
        } catch (error) {
          console.error("Error fetching order status:", error);
          clearTimeout(timeoutId); // Stop retries on error
          setSuccess(false);
        }
      };

      window.payhere.onCompleted = function () {
        console.error("onCompleted:");
        retryCount = 0; // Reset retry count
        setPaymentLoading(true);
        checkPaymentStatus(StatusOrderId);
      };

      window.payhere.onDismissed = function () {
        console.error("Dismissed:", formData);

        setPaymentLoading(true);
        setIsModalVisible(true);
        setSuccess(false);
      };

      window.payhere.onError = function (error) {
        console.error("onError:");
        navigate("/");
        console.error("Error:", error);
      };

      return () => {
        clearTimeout(timeoutId); // Clean up on component unmount
      };
    }, []); // Run only once on component mount

    const handlePayment = () => {
      console.log("formData", formData);
      setstatusOrderId(formData?.order_id);
      window.payhere.startPayment(formData);
    };

    return (
      <div style={styles.card} className="">
        <img src={PayHereLogo} alt="PayHere Logo" style={styles.logo} />
        <form>
          <button
            type="button"
            disabled={paymentLoading}
            id="payhere-payment"
            onClick={handlePayment}
            style={styles.button}
          >
            {paymentLoading ? (
              <div
                className="px-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProgressSpinner
                  style={{
                    width: "35px",
                    height: "35px",
                    marginRight: "10px",
                    // stroke: 'white',
                    // filter: 'drop-shadow(2px 2px 3px rgba(255, 255, 255, 0.6))' // Add shadow for a bolder effect
                  }}
                />
                <span>Wait... </span>
              </div>
            ) : (
              "Click to pay"
            )}
          </button>
        </form>
      </div>
    );
  };

  const sameAsBillingHandler = (e) => {
    console.log("eeee", e.checked)
    if (!e.checked) {
      setLankaNumberInput(false);
      setLankaNumberError(false);
      setLankaNumber("");
      setLankaNumberErrorMessage("");
      setSameCityError("");
      setFormErrors({});
      setLocalNumber("");
      setLocalNumberAddState(false);
      setChecked(false);
      return;
    }

    if (city?.data?.data?.cityName !== bAddress.city) {
      setSameCityError("Billing address and delivery city mismatch. Please add a new delivery address.");
      return;
    }

    if (bAddress) {
      if (
        bAddress.contactNumber &&
        (bAddress.contactNumber.startsWith("+94") ||
          bAddress.contactNumber.startsWith("94"))
      ) {
        setChecked(e.checked);
        setLankaNumberInput(false);
        setLankaNumberError(false);
      } else {
        setLankaNumberInput(true);
        setLankaNumberError(true);
        setChecked("");
      }
    } else {
      setChecked("");
      toast.warning("Please add billing address first");
    }
  };

  return (
    <div className="px-2 md:px-[8%] mt-0 pt-0 container">
      <Dialog
        visible={isModalVisible}
        closable={false}
        footer={true}
        header={true}
        className="custom-dialog mx-5 md:mx-0"
        // onHide={toggleModal}
        breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
        style={{ width: "35vw" }}
      >
        <div className="modal-content">
          <div className="success-modal-icon">
            <img src={success === true ? successIcon : paymentFail} alt="" />
          </div>
          <span>
            {success === true
              ? "Thankyou for purchasing!"
              : "Oops. Something went wrong!"}
          </span>
          <h2 className="modal-title">
            Your order is {success === true ? "confirmed" : "failed"}
          </h2>
          <p className="modal-text">
            {success === true ? successMessage : failedMessage}
          </p>

          <div className="flex   gap-3">
            <Button
              label="Order History"
              className="bg-transparent border-[2px] border-[#13382B] p-3 hover:bg-[#e0e0e0] transition-all duration-300 outline-none focus:ring-0"
              onClick={orderHistory}
            />
            <Button
              label="Done"
              className="bg-[#13382B] px-5 text-white border-none shadow-md cursor-pointer hover:bg-[#42866E]"
              onClick={doneClick}
            />
          </div>
        </div>
      </Dialog>

      <AddOns
        isModalVisible={isAddOneVisible}
        toggleModal={toggleAddOneModal}
        data={cartData?.data?.cartAddons}
      />

      <ShareModal
        isModalVisible={isShareModalVisible}
        toggleModal={toggleShareModal}
      />

      <AddBillingInfo
        isModalVisible={isAddBillingModalVisible}
        toggleModal={toggleBillInfoModal}
        cartId={cartData?.data?.id}
      />

      <AddDeliveryInfo
        isModalVisible={isAddDeliveryModalVisible}
        toggleModal={toggleDeliveryInfoModal}
      />

      <SendAsGift
        isModalVisible={isGiftModalVisible}
        toggleModal={toggleGiftModal}
      />

      <div className="parth flex justify-between pt-[50px]  md:mt-0">
        <div className="flex">
          <p className="cursor-pointer text-[var(--color)] text-[12px] md:text-[16px]">
            <Link to="/" className="mr-2">
              {" "}
              Home{" "}
            </Link>{" "}
            {">"} <span className="text-[#DA813D] mx-2"> Checkout </span>
          </p>
        </div>

        {/* <select
            disabled
            className="outline-none bg-[var(--backg-color)]"
            value={localStorage.getItem("city")}
          >
            {cities?.data?.content.map((city) => (
              <option key={city.id} value={city.id}>
                {city.cityName}
              </option>
            ))}
          </select> */}

        <div className="flex flex-row mr-2 text-[12px] md:text-[16px]">
          <label className="mr-2 text-[12px] md:text-[16px]">Selected city : </label>
          {selectedCity ? selectedCity.cityName : "City not found"}
        </div>
      </div>

      <div className="flex flex-col md:flex-row flex-1 justify-between md:gap-4">
        <div className=" flex flex-col">
          <div className="flex flex-col p-6 sm:flex-row border border-gray-500 rounded-lg w-full mb-5">
            {/* Billing Information Section */}
            <div className="md:flex-[0.5]   pl-0 md:pl-5">
              <div className="flex-[0.2] flex text-[var(--color)]">
                <h2 className="leading-none text-sm md:text-md ">
                  Billing Information
                </h2>
              </div>
              {error.noBillingAddress && (
                <span className="text-red-500">
                  Please enter your billing address
                </span>
              )}

              {/* Show one billing address by default, toggle with "See All" */}
              {!user &&
                `${cartData?.data?.billingAddress
                  ? cartData?.data?.billingAddress
                  : ""
                }`}

              {/* {user?.address && (
                <div
                  key={451214654555}
                  className="flex  text-[var(--color)] py-2 px-4 border-none rounded-md w-full gap-4"
                >
                  <div className="flex mt-1">
                    <RadioButton
                      inputId="ingredient1"
                      name="pizza"
                      value="Cheese"
                      onChange={() => {
                        setBAddress(user?.address);
                        setAddressChange(user?.address);
                      }}
                      checked={user?.address === bAddress}
                    />
                  </div>
                  <div className="flex flex-col">
                 
                    <span className="pt-1 text-sm md:text-md">
                      {user?.address}
                    </span>
                  </div>
                </div>
              )} */}

              {profile?.data?.billingAddresses
                ?.slice(0, showAllBilling)
                .map((data, index) => (
                  <div
                    key={index}
                    className="flex  text-[var(--color)] p-4 border-none rounded-md w-full gap-4"
                  >
                    <div className="flex mt-1">
                      <RadioButton
                        inputId="ingredient1"
                        name="pizza"
                        value="Cheese"
                        onChange={() => {
                          setBAddress(data);
                          setAddressChange(data);
                        }}
                        checked={data?.id === bAddress?.id}
                      />
                    </div>
                    <div className="flex flex-col">
                      {/* <span className="font-bold text-lg">{profile?.data?.username}</span> */}
                      <span className="pt-1 text-sm md:text-md">
                        {data?.addressLine1}
                      </span>
                      <span className="text-sm md:text-md">
                        {data?.addressLine2}
                      </span>
                      <span className="text-sm md:text-md">
                        {data?.contactNumber}
                      </span>
                      <span className="text-sm md:text-md">
                        {data?.city}, {data?.country}, {data?.postalCode}
                      </span>
                    </div>
                  </div>
                ))}

              {/* "See All" or "Show Less" for Billing Addresses */}
              {profile?.data?.billingAddresses?.length > 1 && (
                <div className="flex-[0.2] flex text-[var(--color)]">
                  <button
                    onClick={() => {
                      if (showAllBilling === 100) {
                        setShowAllBilling(1);
                      } else {
                        setShowAllBilling(100);
                      }
                    }}
                    className="cursor-pointer text-[var(--conButton)] text-sm md:text-md mt-2"
                  >
                    {showAllBilling === 100 ? `Show Less` : `See All`}
                  </button>
                </div>
              )}

              {/* Add New Billing Address */}
              <div className="flex-[0.2] flex text-[var(--color)]">
                <div
                  onClick={() => setIsAddBillingModalVisible(true)}
                  className="cursor-pointer text-[var(--conButton)] text-sm md:text-md mt-2"
                >
                  {user
                    ? "+ Add new billing address"
                    : `${cartData?.data?.billingAddress
                      ? "Update billing address"
                      : "+ Add new billing address"
                    }`}
                </div>
              </div>
            </div>

            {/* Divider */}
            <div className="md:border-r border-t my-4 border-[var(--border)] m-2.5"></div>

            {/* Delivery Information Section */}
            <div className="md:flex-[0.5]   pl-5">
              <div className="flex-[0.2] flex text-[var(--color)]">
                <h2 className="leading-none text-sm md:text-md">
                  Delivery Information
                </h2>
              </div>
              {bAddress &&
                <div className="flex-[0.6] flex text-[var(--color)]">
                  <span className="pt-2.5 flex gap-2 items-center  text-sm md:text-md">
                    <Checkbox onChange={sameAsBillingHandler} checked={checked} />
                    Same as billing information
                  </span>
                </div>
              }

              {sameCityError && (
                <p className="text-red-500 mt-2">{sameCityError}</p>
              )}
              {lankaNumberInput && (
                <>
                  {lankaNumberError && (
                    <p className="text-red-500 mt-2">
                      Your billing address phone number does not match a Sri
                      Lankan phone number. Please enter a Sri Lankan phone
                      number.
                    </p>
                  )}
                  <div className="flex gap-2 my-3">
                    <InputText
                      placeholder="Please enter sri lankan number"
                      className="login-modal-inputs"
                      value={lankaNumber}
                      disabled={localNumberAddState}
                      onChange={(e) => setLankaNumber(e.target.value)}
                    />
                    {!localNumberAddState ? (
                      <button
                        onClick={() => {
                          try {
                            validationSchema.validateSync(
                              {
                                lankaNumber,
                              },
                              { abortEarly: false }
                            );
                            setFormErrors({});
                            if (
                              lankaNumber &&
                              (lankaNumber?.startsWith("+94") ||
                                lankaNumber?.startsWith("94") ||
                                lankaNumber?.startsWith("0"))
                            ) {
                              setLankaNumberError(false);
                              setChecked(true);
                              setBAddress((prev) => ({
                                ...prev,
                                contactNumber: lankaNumber,
                              }));
                              setLocalNumber(lankaNumber);
                              setLankaNumberErrorMessage("");
                              toast.success("number added successfully");
                              setLocalNumberAddState(true);
                            } else {
                              setLankaNumberErrorMessage(
                                "Please enter sri lankan number"
                              );
                            }
                          } catch (error) {
                            if (error instanceof Yup.ValidationError) {
                              const validationErrors = {};
                              error.inner.forEach((err) => {
                                validationErrors[err.path] = err.message;
                              });
                              setFormErrors(validationErrors);
                              console.log(validationErrors);
                            }
                          }
                        }}
                        className="  py-2 bg-[#13382B] border-none rounded-md  w-[100px] text-white font-bold"
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setLankaNumber("");
                          setLankaNumberError(false);
                          setLocalNumberAddState(false);
                          setChecked(false);
                        }}
                        className="  py-2 bg-[#13382B] border-none rounded-md  w-[100px] text-white font-bold"
                      >
                        Change
                      </button>
                    )}
                  </div>
                  {formErrors.lankaNumber && (
                    <p className="text-red-500">{formErrors.lankaNumber}</p>
                  )}
                  {lankaNumberErrorMessage && (
                    <p className="text-red-500">{lankaNumberErrorMessage}</p>
                  )}
                </>
              )}

              {error.noAddress && (
                <p className="text-red-500">
                  Please enter your delivery address
                </p>
              )}

              {!user && (
                <p>
                  {`${!checked && cartData?.data?.deliveryAddress
                    ? cartData?.data?.deliveryAddress
                    : ""
                    }`}
                </p>
              )}

              {!checked && (
                <>
                  {profile?.data?.deliveryAddresses
                    ?.slice(0, showAllDelivery)
                    .map((data, index) => (
                      <div
                        key={index}
                        className="flex gap-3 text-[var(--color)] p-4 border-none rounded-md w-full  "
                      >
                        <div className="flex mt-1">
                          <RadioButton
                            inputId="ingredient1"
                            name="pizza"
                            value="Cheese"
                            onChange={() => setAddress(data)}
                            checked={data?.id === address?.id}
                          />
                        </div>
                        <div className="flex flex-col">
                          {/* <span className="font-bold text-lg">{profile?.data?.username}</span> */}
                          <span className="pt-1 text-sm md:text-md">
                            {data?.addressLine1}
                          </span>
                          <span className="text-sm md:text-md">
                            {data?.addressLine2}
                          </span>
                          <span className="text-sm md:text-md">
                            {data?.city}, {data?.country}, {data?.postalCode}
                          </span>
                        </div>
                      </div>
                    ))}

                  {profile?.data?.deliveryAddresses?.length > 1 && (
                    <div className="flex-[0.2] flex text-[var(--color)]">
                      <button
                        onClick={() => {
                          if (showAllDelivery === 100) {
                            setShowAllDelivery(1);
                          } else {
                            setShowAllDelivery(100);
                          }
                        }}
                        className="cursor-pointer text-[var(--conButton)] text-sm md:text-md mt-2"
                      >
                        {showAllDelivery === 100 ? `Show Less` : `See All`}
                      </button>
                    </div>
                  )}

                  {/* Add New Delivery Address */}
                  <div className="flex-[0.2] flex text-[var(--color)]">
                    <div
                      onClick={() => setIsAddDeliveryModalVisible(true)}
                      className="cursor-pointer text-[var(--conButton)] text-sm md:text-md mt-2"
                    >
                      {user
                        ? "+ Add new delivery address"
                        : `${cartData?.data?.deliveryAddress
                          ? "Update delivery address"
                          : "+ Add new delivery address"
                        }`}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {cartItems?.map((item, index) => (
            <CheckoutListItem
              key={index}
              item={item}
              name={item?.product?.correctName}
              price={
                currency === "LKR"
                  ? item?.product.regularPriceInRupee
                  : item?.product.regularPriceInDollar
              }
              category={item?.product?.type}
              image={item?.product?.imageUrl}
              quantity={item?.quantity}
              itemId={item?.itemId}
            />
          ))}
          {cartItems?.length > 0 && (
            <div className="item-end flex justify-end mb-5 cursor-pointer">
              <div
                onClick={() => deleteAll()}
                className="p-2 bg-red-800 w-32 text-center text-white font-bold "
              >
                {deleteCartLoading ? "Removing..." : " Remove all"}

              </div>
            </div>
          )}

          {/* Payment method */}
          <div
            className="border-none
           border-gray-500
            flex flex-col rounded-lg  w-full justify-center "
          >
            {/* <div className="text-left mb-5 text-[var(--color)]">
              Payment Method
            </div> */}

            <Card className=" bg-[var(--card)] md:flex hidden rounded-lg text-[var(--color)] p-4">
              <div>*Terms & Conditions :</div>
              <p className="mt-2.5 text-[var(--conditions)]">
                wineworld.lk provides an online platform for purchasing
                products, with clear terms and conditions that outline user
                rights, privacy policies, service limitations, and the website's
                role as a concierge service connecting customers with vendors.
              </p>
            </Card>
          </div>

          {/* {cartItems?.map((item, index) => (
            <CheckoutListItem
              key={index}
              item={item}
              name={item?.product.correctName}
              price={
                currency == "LKR"
                  ? item?.product.regularPriceInRupee
                  : item?.product.regularPriceInDollar
              }
              category={item?.product.type}
              image={item?.product.imageUrl}
              quantity={item?.quantity}
            />
          ))} */}
        </div>
        <div className=" flex flex-col mb-5">
          <div className="border border-gray-500 rounded-lg flex flex-1">
            <div className="text-white p-5 flex flex-col rounded-lg relative">
              <div className="flex justify-between items-top mb-3">
                <h3 className="text-2xl font-bold mt-0">Summary</h3>
                <div className="flex gap-2.5">
                  <Button
                    onClick={() => setIsShareModalVisible(true)}
                    icon="pi pi-share-alt"
                    className="p-button-rounded p-button-text"
                  />
                  {/* <Button
                    icon="pi pi-heart"
                    className="p-button-rounded p-button-text"
                  /> */}
                </div>
              </div>
              <div className="flex justify-between items-center ">
                <span className="font-bold">Total product cost</span>
                <span className="font-bold">
                  {currency}{" "}
                  {totalAmount
                    ?.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </div>
              <div className="text-[var(--color)] mt-2 font-bold">Delivery</div>

              <div>
                <div className="flex flex-col mt-2.5 ml-3 ">
                  <div className="flex items-center gap-2.5 mt-2.5">
                    <RadioButton
                      inputId="pickup"
                      name="shipping"
                      value="STORE_PICKUP"
                      onChange={async (e) => {
                        setShipping(e.value);
                        await createDeliveryOptions({
                          cartId: cartData?.data?.id,
                          deliveryOption: e.value,
                        });
                      }}
                      checked={shipping === "STORE_PICKUP"}
                    />
                    <label htmlFor="pickup">Store Pickup</label>
                  </div>
                  {shipping === "STORE_PICKUP" && (
                    <div className="flex flex-col ml-10 gap-2">
                      <p className="text-[#42866E] m-0 p-0 text-sm">
                        {deliveryDateFormatted
                          ? `Ready for Pickup by ${deliveryDateFormatted}`
                          : "Ready for Pickup within 1-2 working days"}
                      </p>
                      <DeliveryDatePopup setDeliveryDate={setDeliveryDate} title={'Select Pickup Date'}>
                        Change Pickup Date
                      </DeliveryDatePopup>
                    </div>
                  )}

                  <div className="flex items-center gap-2.5 mt-2.5">
                    <RadioButton
                      inputId="delivery"
                      name="shipping"
                      value="DELIVERY"
                      onChange={async (e) => {
                        setShipping(e.value);
                        await createDeliveryOptions({
                          cartId: cartData?.data?.id,
                          deliveryOption: e.value,
                        });
                      }}
                      checked={shipping === "DELIVERY"}
                    />
                    <label>Delivery</label>
                  </div>
                  {shipping === "DELIVERY" && (
                    <div className="flex flex-col ml-10 gap-2">
                      <p className="text-[#42866E] m-0 p-0 text-sm">
                        {deliveryDateFormatted
                          ? `Delivery on ${deliveryDateFormatted}`
                          : "Deliveries within 1-2 working days"}
                      </p>
                      <DeliveryDatePopup setDeliveryDate={setDeliveryDate} title={'Select Delivery Date'}>
                        Change Delivery Date
                      </DeliveryDatePopup>
                    </div>
                  )}
                </div>
              </div>
              {cartData?.data?.cartAddons?.length > 0 && (
                <div className="flex justify-between items-center my-2.5">
                  <span className="font-bold">Additional Customizations</span>
                  {/* <span className="font-bold">
                 LKR {additional.toLocaleString()}
               </span> */}
                </div>
              )}

              <div className="flex gap-3 flex-col mb-5">
                {cartData?.data?.cartAddons?.map((item, index) => (
                  <div className="flex justify-between h-[15px]">
                    <p className="text-[var(--color)] opacity-70">
                      {item.addonName}
                    </p>
                    <p className="text-[var(--color)] font-bold">
                      {currency}{" "}
                      {currency === "LKR"
                        ? item.totalPriceInRupee
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : item.totalPriceInDollar
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                ))}
              </div>
              <button
                onClick={() => setisAddOneVisible(true)}
                className="bg-transparent text-orange-500 border-none cursor-pointer underline self-start"
              >
                + Additional Customizations
              </button>

              <div className="flex justify-between items-center my-2.5">
                {!cartData?.data?.isGift ? (
                  <Button
                    onClick={() => setIsGiftModalVisible(true)}
                    label="Send as Gift"
                    className="gift-btn w-full border-2 border-[var(--color)] text-[var(--color)] font-bold rounded-lg px-3 py-1.5"
                    icon="pi pi-gift"
                  // disabled={shipping === "STORE_PICKUP"}
                  />
                ) : (
                  <Button
                    onClick={removeGift}
                    label="Remove as Gift"
                    className="gift-btn w-full border-2 border-[var(--color)] text-[var(--color)] font-bold rounded-lg px-3 py-1.5"
                    icon="pi pi-gift"
                    loading={giftLoading}
                  />
                )}
              </div>
              <div className="flex justify-between items-center my-2.5">
                <InputText
                  value={promoCode}
                  onChange={(e) => {

                    setError({
                      ...error,
                      promoCodeValid: false,
                      promoCode: false,
                    });

                    setPromoCode(e.target.value);
                  }}
                  placeholder="Enter Promotion Code"
                  className="flex-1 border h-full w-full rounded-md p-2  text-[var(--color)]"
                />
                <Button
                  label="Apply"
                  className="bg-orange-500 border-none font-bold ml-2.5 px-3 py-1.5"
                  onClick={() => {
                    if (promoCode) {
                      setError({
                        ...error,
                        promoCode: false,
                        promoCodeValid: true,
                      });
                    } else {
                      setError({
                        ...error,
                        promoCode: true,
                        promoCodeValid: false,
                      });
                    }
                  }}
                />
              </div>
              {error.promoCode && (
                <p className="text-red-500">Enter promo code</p>
              )}
              {error.promoCodeValid && (
                <p className="text-red-500">Enter valid promo code</p>
              )}

              <div className="mt-12">
                <div className="flex justify-between items-center ">
                  <span className="">Total product cost</span>
                  <span className="font-bold">
                    {currency}{" "}
                    {totalAmount
                      ?.toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
                <div className="flex justify-between items-center my-2.5">
                  <span>Customizations</span>
                  <span className="font-bold">
                    {currency}{" "}
                    {currency === "LKR"
                      ? (cartData?.data?.addonTotalInRupee || 0)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : (cartData?.data?.addonTotalInDollar || 0)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
                <div className="flex justify-between items-center my-2.5">
                  <span>Delivery charges</span>
                  <span className="font-bold">
                    {currency}{" "}
                    {deliveryamount
                      ?.toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                  </span>
                </div>

                {/* <div className="flex justify-between items-center my-2.5">
                  <span>Additional Customizations</span>
                  <span className="font-bold">
                    {currency} {deliveryamount?.toLocaleString() || 0}
                  </span>
                </div> */}
                <div className="flex justify-between items-center my-2.5 border-t border-white pt-2.5 text-xl font-bold">
                  <span>Total</span>
                  <span className="font-bold">
                    {currency}{" "}
                    {currency === "LKR"
                      ? cartData?.data?.totalPriceInRupee
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : cartData?.data?.totalPriceInDollar?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
                <Button
                  label={isLoading ? "Processing..." : "Place Order"}
                  className=" place-order-btn py-2 bg-[#13382B] border-none w-full mt-5 text-white font-bold"
                  onClick={paymentHandler}
                />
                <p className="mt-5 text-[#64748b]">
                  Upon clicking 'Place order', I confirm I have read and
                  acknowledge all {" "}
                  <a
                    href="/terms_and_conditions.pdf"
                    className=" bg-transparent text-orange-500 border-none cursor-pointer self-start underline"
                    download="terms_and_conditions.pdf"
                  >
                    terms and policies
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>

        <Card className=" bg-[var(--card)] md:hidden  rounded-lg text-[var(--color)] p-4">
          <div>*Terms & Conditions :</div>
          <p className="mt-2.5 text-[var(--conditions)]">
            wineworld.lk provides an online platform for purchasing products,
            with clear terms and conditions that outline user rights, privacy
            policies, service limitations, and the website's role as a concierge
            service connecting customers with vendors.
          </p>
        </Card>
        <Dialog
          visible={formDataModel}
          closable={false}
          footer={true}
          header={true}
          className="custom-dialog mx-5 md:mx-0 md:w-1/4" // Add custom class here
          onHide={() => setFormDataModel(false)}
          breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
          style={{}}
        >
          <PaymentForm2 url={paymentData} setFormDataModel={setFormDataModel} />
        </Dialog>
      </div>
    </div>
  );
};

export default Checkout;

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "300px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    // padding: '20px',
    // backgroundColor: 'red',
    textAlign: "center",
  },
  logo: {
    // width: '100px',
    height: "100px",
    marginBottom: "20px",
  },
  button: {
    width: "150px",
    height: "80px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
  },
};

const DeliveryDatePopup = ({ children, setDeliveryDate, title }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState('');

  const today = new Date();
  const todayPlusTwo = new Date();
  todayPlusTwo.setDate(today.getDate() + 2);
  const twoWeeksFromToday = new Date();
  twoWeeksFromToday.setDate(today.getDate() + 10);

  const handleDeliveryDate = () => {
    setDeliveryDate(date);
    setOpen(false);
  };

  return (
    <div>
      <button
        onClick={() => setOpen(true)}
        className="text-[#DA813D] m-0 p-0 text-sm"
      >
        {children}
      </button>
      <Dialog
        visible={open}
        closable={true}
        footer={true}
        header={true}
        className="custom-dialog w-full mx-5 md:mx-0 md:w-1/4" // Add custom class here
        onHide={() => setOpen(false)}
        breakpoints={{
          "2000px": "550px",
          "960px": "550px",
          "640px": "100vw",
          "576px": "90vw",
        }}
        style={{}}
      >
        <div className=" px-10">
          <h2 className="address-modal-title">{title}</h2>
          <div className="modal-divider my-2"></div>
        </div>
        <div className="flex justify-center items-center w-full px-5 py-2">
          <Calendar
            value={date}
            onChange={(e) => setDate(e.value)}
            inline
            className="w-full"
            minDate={todayPlusTwo}
            maxDate={twoWeeksFromToday}
            disabledDays={[0, 6]}
          />
        </div>
        <div className="flex justify-end gap-2 py-4 px-5">
          <button
            onClick={() => {
              setDeliveryDate("");
              setOpen(false);
              setDate("");
            }}
            className="py-2 bg-[#13382B] border-none rounded-md  w-[100px] text-white font-bold"
          >
            Reset
          </button>
          <button
            onClick={handleDeliveryDate}
            className="py-2 bg-[#13382B] border-none rounded-md  w-[100px] text-white font-bold"
          >
            Save
          </button>
        </div>
      </Dialog>
    </div>
  );
};
