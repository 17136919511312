import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import {
  useGetProfileQuery,
  useUpdateProfileAddressMutation,
} from "../../redux/service/profileSlice";
import { useSelector } from "react-redux";
import { useGetAllCityQuery, useGetAllProvinceAndCityQuery } from "../../redux/service/citySlice";
import {
  useCartAddressUpdateMutation,
  useGetCartItemsQuery,
} from "../../redux/service/cartSlice";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const provinces = [
  "Central Province",
  "Eastern Province",
  "North Central Province",
  "Northern Province",
  "North Western Province",
  "Sabaragamuwa Province",
  "Southern Province",
  "Uva Province",
  "Western Province",
];

const AddDeliveryInfo = ({ isModalVisible, toggleModal, addDeliveryInfo }) => {

  const [formData, setFormData] = useState({
    country: "Sri Lanka",
    city: localStorage.getItem("city") || "",
  });
  const [formErrors, setFormErrors] = useState({});
  const { data: cities } = useGetAllCityQuery();
  const [setCartAddress] = useCartAddressUpdateMutation();

  const cityName = cities?.data?.content?.filter(
    (city) => city.id === Number(localStorage.getItem("city"))
  )[0]?.cityName;

  const user = useSelector((state) => state?.auth?.userInfo);

  const { data: cartData } = useGetCartItemsQuery({
    cartType: "normal",
    userId: user?.id,
  });

  const { data: ProvinceAndCity } =  useGetAllProvinceAndCityQuery()

  const provinces = ProvinceAndCity?.data?.content.map((city) => city.province);
  // const Citys = ProvinceAndCity?.data?.content.map((city) => city.cityName);
  const uniqueProvinces = [...new Set(provinces)];
 

  const matchedCity = cities?.data?.content?.find(city => city.cityName === formData.city);

  // const province = cities?.data?.content?.map((city) => city.province);

  


  useEffect(() => {
    if (matchedCity) {
      setFormData((prev) => ({ ...prev, province: matchedCity.province }));
      console.log("matchedCity.province :", matchedCity.province);
    } else {

    }
  }, [matchedCity])


  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      city: cityName || undefined,
    }));
  }, [cityName]);

  useEffect(() => {

    const selectedCity = cities?.data?.content.find(
      (city) => city.cityName === formData.city
    );



    if (selectedCity) {
      setFormData((prev) => ({ ...prev, province: selectedCity.province }));
    }
  }, [formData.city, cities]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      city: cityName || undefined,
    }));
  }, [cityName]);

  const validationSchema = Yup.object(
    Object.assign(
      {},
      !user ? { name: Yup.string().required("Name is required") } : {},
      {
        addressLine1: Yup.string().required("Address Line 1 is required"),
        addressLine2: Yup.string().required("Address Line 2 is required"),
        city: Yup.string().required("City is required"),
        province: Yup.string().required("State is required"),
        postalCode: Yup.string()
          .length(5, "Postal Code must be exactly 5 characters")
          .required("Postal Code is required"),
        country: Yup.string().required("Country is required"),
        number: Yup.string()
          .matches(
            /^(\+94\d{9}|0\d{9})$/,
            "Phone Number must be in the format +94XXXXXXXXX or 0XXXXXXXXX"
          )
          .required("Phone Number is required"),
      }
    )
  );

  const [updateAddress, { isLoading }] = useUpdateProfileAddressMutation();

  const { data: profile } = useGetProfileQuery(user?.id, {
    skip: !user?.id,
  });

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setFormErrors({});
      if (user) {
        const updatedDeliveryAddresses = [
          ...(profile?.data?.deliveryAddresses || []),
          {
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            city: formData.city,
            province: formData.province,
            postalCode: formData.postalCode,
            country: formData.country,
            contactNumber: formData.number,
          },
        ];

        const DeliveryAddressesWithoutId = updatedDeliveryAddresses.map(
          ({ id, ...rest }) => rest
        );

        const billingAddressesWithoutId = profile?.data?.billingAddresses?.map(
          ({ id, ...rest }) => rest
        );

        await updateAddress({
          id: user.id,
          formData: {
            billingAddresses: billingAddressesWithoutId,
            deliveryAddresses: DeliveryAddressesWithoutId,
          },
        })
          .then(() => {
            toast.success("Delivery information saved successfully!")
            setFormData({
              ...formData,
              name: "",
              number: "",
              addressLine1: "",
              addressLine2: "",
              postalCode: "",
              // country: "",
            });
          })
          .catch((error) => {
            toast.error(error.data.message || "Something went wrong");
          });
      } else {
        await setCartAddress({
          cartId: cartData?.data?.id,
          formData: {
            deliveryAddress: `${formData.name}, ${formData.addressLine1}, ${formData.addressLine2}, ${formData.city}, ${formData.province}, ${formData.postalCode}, ${formData.country},  ${formData.number}`,
          },
        })
          .then(() => {
            if (user) {
              setFormData({
                ...formData,
                name: "",
                number: "",
                addressLine1: "",
                addressLine2: "",
                postalCode: "",
                // country: "",
              });
              setFormErrors({});
              toast.success("Delivery information saved successfully!");
            }
          })
          .catch((error) => {
            toast.error(error.data.message || "Something went wrong");
          });
      }

      toggleModal();
    } catch (error) {
      if (error.inner) {
        const errorMessages = {};
        error.inner.forEach((validationError) => {
          errorMessages[validationError.path] = validationError.message;
        });
        setFormErrors(errorMessages);
      }
    }
  };

  console.log(formData.number)

  return (
    <Dialog
      visible={isModalVisible}
      closable={true}
      className="custom-dialog "
      onHide={toggleModal}
      breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
      style={{ width: "30vw" }}
    >
      <div className="address-modal-inputs ">
        <div className=" px-10">
          <h2 className="address-modal-title ">Add New Delivery Information</h2>
          <div className="modal-divider my-2"></div>
          <span className="address-modal-description ">
            All fields are required to complete the delivery information. Please
            ensure all sections are filled out before submitting.
          </span>
        </div>

        {!user && (
          <>
            <div className="address-input-group">
              <label className="modal-label">Name</label>
              <InputText
                value={formData.name}
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value })
                  setFormErrors((prev) => ({
                    ...prev,
                    name: "",
                  }))

                }
                }
                placeholder="Please enter your name"
                className="login-modal-inputs"
              />
              {formErrors.name && (
                <small className="error-text">{formErrors.name}</small>
              )}
            </div>
            <div className="address-input-group">
              <label className="modal-label">Phone Number</label>
              <InputText
                value={formData.number}
                onChange={(e) => {
                  setFormData({ ...formData, number: e.target.value })
                  setFormErrors((prev) => ({
                    ...prev,
                    number: "",
                  }))
                }
                }
                placeholder="Please enter number"
                className="login-modal-inputs"
              />
              {formErrors.number && (
                <small className="error-text">{formErrors.number}</small>
              )}
            </div>
          </>
        )}

        <div className="address-input-group">
          <label className="modal-label">Address Line 1</label>
          <InputText
            value={formData.addressLine1}
            onChange={(e) => {

              setFormData({ ...formData, addressLine1: e.target.value })
              setFormErrors((prev) => ({
                ...prev,
                addressLine1: "",
              }))
            }
            }
            placeholder="Please enter your address line 1"
            className="login-modal-inputs"
          />
          {formErrors.addressLine1 && (
            <small className="error-text">{formErrors.addressLine1}</small>
          )}
        </div>

        <div className="address-input-group">
          <label className="modal-label">Address Line 2</label>
          <InputText
            value={formData.addressLine2}
            onChange={(e) => {

              setFormData({ ...formData, addressLine2: e.target.value })
              setFormErrors((prev) => ({
                ...prev,
                addressLine2: "",
              }))
            }
            }
            placeholder="Please enter your address line 2"
            className="login-modal-inputs"
          />
          {formErrors.addressLine2 && (
            <small className="error-text">{formErrors.addressLine2}</small>
          )}
        </div>

        <div className="address-input-group">
          <label className="modal-label">Phone Number</label>
          <PhoneInput
            id="phone"

            value={formData.number}
            onChange={(e) => {

              setFormData((prev) => ({ ...prev, number: e }));
              setFormErrors((prev) => ({ ...prev, number: "" }));
            }}
            international={false}
            disableCountrySelect
            defaultCountry="LK" // Sets the default country
            country="LK"        // Locks the country to "LK"
            className="border custom-phone-input border-gray-300 rounded-md  p-2 w-full outline-none focus:ring-2 focus:ring-blue-500"

          />
          {formErrors.number && (
            <small className="error-text">{formErrors.number}</small>
          )}
        </div>



        <div className="flex gap-5 w-[78%]">
          <div className="address-input-group">
            <label className="modal-label">Province</label>
            <select
              className=" w-full py-2 px-1 border border-gray-300 bg-transparent rounded-lg outline-none"
              onChange={(e) => {
                setFormData({ ...formData, province: e.target.value });
                setFormErrors((prev) => ({ ...prev, province: "" }));
              }}
              value={formData.province}
              disabled
            >
              <option value="">Select Province</option>

              {uniqueProvinces?.map((province, index) => (
                <option
                  className={`${localStorage.getItem("theme") === "dark"
                      ? "bg-[#1F2D40]"
                      : ""
                    }`}
                  key={index}
                  value={province}
                >
                  {province}
                </option>
              ))}
            </select>
            {formErrors.province && (
              <small className="error-text">{formErrors.province}</small>
            )}
          </div>

          <div className="address-input-group-custom">
            <label className="modal-label">City</label>
            <select
              className="p-2 border w-4/5 md:w-full border-gray-300 rounded-md"
              onChange={(e) => {

                setFormData({ ...formData, city: e.target.value })
                setFormErrors((prev) => ({
                  ...prev,
                  city: "",
                }))
              }
              }
              defaultValue={formData.city}
              disabled
            >
              {cities?.data?.content.map((city) => (
                <option key={city.id} value={city.cityName}>
                  {city.cityName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="address-input-group">
          <label className="modal-label">Country</label>
          <InputText
          type="text"
            value={formData.country}
            onChange={(e) => {

              setFormData({ ...formData, country: e.target.value })
              setFormErrors((prev) => ({
                ...prev,
                country: "",
              }))
            }
            }
            placeholder="Please enter your Country"
            className="login-modal-inputs"
          />
          {formErrors.country && (
            <small className="error-text">{formErrors.country}</small>
          )}
        </div>

        <div className="address-input-group">
          <label className="modal-label">Postal Code</label>
          <InputText
          type="number"
            value={formData.postalCode}
            onChange={(e) => {
              setFormData({ ...formData, postalCode: e.target.value })
              setFormErrors((prev) => ({
                ...prev,
                postalCode: "",
              }))
            }
            }
            placeholder="Please enter your Postal Code"
            className="login-modal-inputs"
          />
          {formErrors.postalCode && (
            <small className="error-text">{formErrors.postalCode}</small>
          )}
        </div>

        

        <div className="add-delivery-modal-footer">
          <div className="add-delivery-modal-footer">
            <Button
              label="Reset"
              className="bill-address-save-btn"
              onClick={() => {
                setFormData({
                  ...formData,
                  name: "",
                  number: "",
                  addressLine1: "",
                  addressLine2: "",
                  postalCode: "",
                  // country: "",
                });
                setFormErrors({});
              }}
            />

            <Button
              label="Save"
              className="bill-address-save-btn py-1"
              onClick={handleSubmit}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddDeliveryInfo;
