// src/components/Header.js
import React, { useRef } from "react";

import scrolldown from "../../src/icons/scrolldown.gif";
import { useNavigate } from "react-router-dom";


const HeroCarosal = ({ product }) => {
  const navigate = useNavigate();

  const targetRef = useRef(null); // Reference to target element
  const handleScrollToBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  };


  return (
    <div className="surface-border border-round m-2 text-center py-5 px-3">

      <div className="mb-3">

        <img
          className="w-full h-auto max-h-[80vh] object-cover rounded-lg"
          src={product.image}
          alt={product.name}
        />



        {product?.visible && (
          <div className="relative text-center max-h-[80vh] text-white bg-cover bg-center flex justify-center items-center">
            <div
              className={
                product?.alignment === "left"
                  ? "absolute left-3 sm:left-[10%] md:left-[20%] lg:left-[5%] transform -translate-y-1/2 text-left md:bottom-2"
                  : "absolute right-3 sm:right-[10%] md:right-[20%] lg:right-[5%] transform -translate-y-1/2 text-right md:bottom-2"
              }
            >
              <h1 className="text-xs sm:text-xl md:text-4xl lg:text-5xl m-0 leading-3 text-white">
                FIND YOUR FAVOURITE
              </h1>
              <h1 className="text-xs sm:text-xl md:text-4xl lg:text-5xl m-0 leading-3 text-white">
                WINES & SPIRITS
              </h1>
              <p className="text-xs sm:text-lg md:text-xl lg:text-2xl mb-1 md:my-2">
                Delivered to your door
              </p>
              <button
                onClick={() => {
                  navigate("product-category/1");
                  window.scrollTo(0, 0);
                }}
                className="bg-white text-gray-800 mb-2 py-1 md:py-2 px-2 sm:px-5 border-none font-semibold rounded cursor-pointer text-sm sm:text-base hover:opacity-80">
                Shop Now
              </button>

            </div>
            {/* <img
              className={"h-10 hidden md:block w-10 absolute right-5 bottom-5"}
              src={scrolldown}
              alt="Scroll down"
              onClick={handleScrollToBottom} // Scroll to bottom on click
            /> */}
          </div>
        )}


      </div>
    </div>
  );
};

export default HeroCarosal;
