import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePasswordResetMutation } from "../../../redux/service/authSlice";
import { toast } from "material-react-toastify";
import * as Yup from "yup";

// Define the validation schema using Yup
const validationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[@$!%*?&#]/, "Password must contain at least one special character"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility
  const [PasswordReset] = usePasswordResetMutation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const navigate = useNavigate(); // Initialize the navigate hook

  const handlePassword = async (e) => {
    try {
      await validationSchema.validate(form, { abortEarly: false });
      setFormErrors({});

      if (form.password === form.confirmPassword) {
        await PasswordReset({
          token: token,
          newPassword: form.password,
        })
          .unwrap()
          .then((res) => {
            toast.success(res?.data?.message);
            navigate("/"); // Redirect after successful password reset
            toast.success("Password reset successfull");
          })
          .catch((err) => {
            toast.error(err?.data?.message || "Something went wrong");
          });
      }
    } catch (err) {
      const errors = err.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      setFormErrors(errors);
    }
  };

  return (
    <div className="p-3 rounded-lg">
      <div className="w-full h-screen">
        <div className="flex justify-center h-[400px]">
          <div className="flex flex-col mt-10 gap-10 w-1/3">
            {/* Password input with eye icon */}
            <div className="relative w-full">
              <InputText
                id="Password"
                type={showPassword ? "text" : "password"} // Toggle input type between "text" and "password"
                placeholder="Please enter your password"
                className="login-modal-inputs w-full" // Add padding-right for space for the icon
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
              />
              {/* Eye icon for show/hide password */}
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer item-center justify-center"
              >
                {!showPassword ? (
                  <i className="pi pi-eye-slash" style={{ fontSize: "1.3rem" }} />
                ) : (
                  <i className="pi pi-eye" style={{ fontSize: "1.3rem" }} />
                )}
              </span>
            </div>
            {formErrors.password && <p className="text-red-500">{formErrors.password}</p>}

            {/* Confirm password input with eye icon */}
            <div className="relative w-full mt-5">
              <InputText
                id="ConfirmPassword"
                type={showConfirmPassword ? "text" : "password"} // Toggle input type between "text" and "password"
                placeholder="Please confirm your password"
                className="login-modal-inputs w-full"
                value={form.confirmPassword}
                onChange={(e) => setForm({ ...form, confirmPassword: e.target.value })}
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer item-center justify-center"
              >
                {!showConfirmPassword ? (
                  <i className="pi pi-eye-slash" style={{ fontSize: "1.3rem" }} />
                ) : (
                  <i className="pi pi-eye" style={{ fontSize: "1.3rem" }} />
                )}
              </span>
            </div>
            {formErrors.confirmPassword && <p className="text-red-500">{formErrors.confirmPassword}</p>}

            <div className="w-full justify-center p-5 flex mt-5">
              <div className="w-1/3 justify-center flex">
                <Button label="Reset Password" className="login-confirm-btn" onClick={handlePassword} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
