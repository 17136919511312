import { configureStore } from '@reduxjs/toolkit';
import HomepageReducer from '../redux/HomepageSlice';
import { apiSlice } from '../redux/service/apiSlice';
import  authReducer  from '../features/authSlice';
import metaSlice from '../features/metaSlice';
import themeReducer from '../features/themeSlice';



export default configureStore({
  reducer: {
    HomePage: HomepageReducer,
    auth: authReducer,
    meta: metaSlice,
    theme: themeReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  
  devTools: true,
})

