import { apiSlice } from "./apiSlice";

export const deliverySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({


    getdeliveryAmount: builder.query({
      query: ({ quantity, cityId }) => {
        // Construct query string parameters
        const queryString = [
          quantity ? `bottleQuantity=${quantity}` : null,
          cityId ? `cityId=${cityId}` : null
        ]
          .filter(Boolean) // Remove any null values
          .join("&"); // Join with & to format as URL query parameters

        return {
          url: `delivery-charges/calculateDeliveryCharge?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Delivery"], // Tag for cache tracking
    }),


  }),
});

export const {
  useGetdeliveryAmountQuery

} = deliverySlice;
