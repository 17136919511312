// src/pages/Profile.js
import React, { useEffect, useRef, useState } from "react";
import "./Profile.css"; // Use a CSS file for styles
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import avatar from "./../../Images/avatar.png";
import { Link, useNavigate } from "react-router-dom";
import AddBillingInfo from "../../components/Modals/AddBillingInfo";
import AddDeliveryInfo from "../../components/Modals/AddDeliveryInfo";
import ProfileInfo from "../../components/Modals/ProfileInfo";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency } from "../../features/metaSlice";
import {
  useGetProfileQuery,
  useUpdateProfileAddressMutation,
  useUpdateProfileImageMutation,
} from "../../redux/service/profileSlice";
import { toast } from "material-react-toastify";
import ChangePasswordModel from "./ChangePasswordModel";
import profilePlashholder from "./../../Images/image-palsholder.jpg";

const initialDeliveryList = [
  {
    email: "johnsmith@gmail.com",
    phone: "+94 734567890",
    address: "NO: Address Line01, Address Line02, City, Country",
    postalCode: "71055",
  },
];

const initialBillList = [
  {
    email: "johnsmith@gmail.com",
    phone: "+94 734567890",
    address: "NO: Address Line01, Address Line02, City, Country",
    postalCode: "71055",
  },
];

const Profile = () => {
  const [isAddBillingModalVisible, setIsAddBillingModalVisible] =
    useState(false);
  const [isAddDeliveryModalVisible, setIsAddDeliveryModalVisible] =
    useState(false);
  const [isProfileInfoModalVisible, setIsProfileInfoModalVisible] =
    useState(false);

  const [imageUpload, { isLoading: imageLoading }] = useUpdateProfileImageMutation();
  const [deliveryList, setDeliveryList] = useState(initialDeliveryList);
  const [billingList, setBillingList] = useState(initialBillList);
  const currency = useSelector((state) => state?.meta?.currency);

  const user = useSelector((state) => state?.auth?.userInfo);
  const [updateAddress] = useUpdateProfileAddressMutation();
  const fileInputRef = useRef(null);

  // const { data: profile } = useGetProfileQuery();

  const { data: profile } = useGetProfileQuery(user?.id);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  const handleDelete = async ({ type, id }) => {
    // console.log(type, id);
    try {
      const updatedBillingAddresses =
        type === "billingAddresses"
          ? profile.data.billingAddresses.filter((address) => address.id !== id)
          : profile.data.billingAddresses;

      const updatedDeliveryAddresses =
        type === "deliveryAddresses"
          ? profile?.data?.deliveryAddresses.filter(
            (address) => address.id !== id
          )
          : profile?.data?.deliveryAddresses;

          const billingAddressesWithoutId = updatedBillingAddresses.map(
            ({ id, ...rest }) => rest
          );

          const updatedDeliveryAddressesWithoutId = updatedDeliveryAddresses.map(
            ({ id, ...rest }) => rest
          );

      await updateAddress({
        id: user.id,
        formData: {
          billingAddresses: billingAddressesWithoutId,
          deliveryAddresses: updatedDeliveryAddressesWithoutId,
        },
      }).catch((error) => {
        toast.error(error.data.message || "Something went wrong");
      });
    } catch (error) {
      toast.error(error.data.message || "Something went wrong");
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {

      const formData = new FormData();
      formData.append("attachment", file);
      await imageUpload({
        id: user.id,
        formData,
      }).unwrap().then((res) => {
        toast.success(res?.message || "Image uploaded successfully");
      }).catch((error) => {
        console.log(error);
        // toast.error(error?.data?.message || "Something went wrong");
      });
    }
  };



  const addDeliveryInfo = (newInfo) => {
    setDeliveryList([...deliveryList, newInfo]);
  };

  const addBillInfo = (newInfo) => {
    setBillingList([...billingList, newInfo]);
  };

  const toggleBillInfoModal = () => {
    setIsAddBillingModalVisible((prev) => !prev); // Toggle visibility
  };

  const toggleDeliveryInfoModal = () => {
    setIsAddDeliveryModalVisible((prev) => !prev);
  };

  const toggleProfileInfoModal = () => {
    setIsProfileInfoModalVisible((prev) => !prev);
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <div className="px-2 md:px-[6%]  flex flex-col justify-center container">
      <AddBillingInfo
        isModalVisible={isAddBillingModalVisible}
        toggleModal={toggleBillInfoModal}
        addBillInfo={addBillInfo}
      />
      <AddDeliveryInfo
        isModalVisible={isAddDeliveryModalVisible}
        toggleModal={toggleDeliveryInfoModal}
        addDeliveryInfo={addDeliveryInfo}
      />
      <ProfileInfo
        isModalVisible={isProfileInfoModalVisible}
        toggleModal={toggleProfileInfoModal}
        profile={profile}
      />

      {/* Profile Header Section */}

      <div className="w-full rounded-xl p-5 flex flex-col mb-7 mt-10 ">
        <p className="cursor-pointer text-[var(--color)] text-[12px] md:text-[16px]">
          <Link to="/" className="mr-2 text-[12px] md:text-[16px]">
            {" "}
            Home{" "}
          </Link>{" "}
          {">"} <span className="text-[#DA813D] mx-2 text-[12px] md:text-[16px]"> Profile </span>
        </p>
        <div className="flex flex-col md:flex-row  items-center justify-between">
          <div className=" flex justify-center w-[210px] h-[210px]">
            <img
              src={profile?.data?.profilePicTemporaryLink || profilePlashholder}
              alt="Profile"
              className="min-w-[210px] h-[210px] rounded-full object-cover"
            />
          </div>

          <div className="block md:hidden ">
            <div className=" profile-actions flex justify-start gap-2.5 mt-5 ">
              <button
                onClick={handleButtonClick}
                className=" w-full bg-[var(--green)] text-[white] border-2 border-[var(--green)] px-3 py-1.5 rounded-md cursor-pointer mt-2"
              >

                {imageLoading ? "Uploading..." : "Update Profile Picture"}
              </button>
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              <ChangePasswordModel>
                <button className="w-full bg-transparent border-2 border-[var(--green)] px-6 py-1.5 rounded-md cursor-pointer mt-2">
                  Change Password
                </button>
              </ChangePasswordModel>

              <button
                onClick={() => setIsProfileInfoModalVisible(true)}
                className="w-full text-[var(--color)] bg-transparent border-2 border-none font-bold border-[var(--green)] px-5 py-1 rounded-md cursor-pointer mt-2"
              >
                <i
                  className="pi pi-pencil text-[var(--color)]"
                  style={{ marginRight: "8px" }}
                ></i>
                Edit Profile
              </button>
            </div>
          </div>

          <div className="flex-[0.65] hidden mt-4 md:block">
            <div className="profile-info flex-1 flex flex-col justify-evenly h-[200px] ml-5 text-[var(--color)]">
              <h2 className="text-2xl font-bold">{profile?.data?.name}</h2>
              <p>
                <i className="pi pi-envelope"></i> {profile?.data?.email}
              </p>
              <p>
                <i className="pi pi-phone"></i>{" "}
                {profile?.data?.contactNumbers
                  ? profile?.data?.contactNumbers
                  : "Not available"}
              </p>
              <p>
                <i className="pi pi-map-marker"></i> {profile?.data?.address}
              </p>
            </div>
          </div>

          <div className="md:hidden mt-5  mb-0 min-h-[200px] w-full">
            <Card
              title="Contact Info"
              className="p-5 border border-gray-300 rounded-lg"
            >
              <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-2">
                  <i className="pi pi-user"></i>
                  <div className="flex flex-col pl-3">
                    <label className="block text-gray-700 m-0">Full Name</label>
                    <InputText
                      value={profile?.data?.name}
                      readOnly
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <i className="pi pi-envelope"></i>
                  <div className="flex flex-col pl-3">
                    <label className="block text-gray-700 m-0">
                      Email Address
                    </label>
                    <InputText
                      value={profile?.data?.email}
                      readOnly
                      className="w-full"
                    />
                  </div>{" "}
                </div>
                <div className="flex items-center space-x-2">
                  <i className="pi pi-phone"></i>
                  <div className="flex flex-col pl-3">
                    <label className="block text-gray-700 m-0">
                      Phone Number
                    </label>
                    <InputText
                      value={profile?.data?.contactNumbers}
                      readOnly
                      className="w-full"
                    />
                  </div>{" "}
                </div>
                <div className="flex items-center space-x-2">
                  <i className="pi pi-map-marker"></i>{" "}
                  <div className="flex flex-col pl-3">
                    <label className="block text-gray-700 m-0">Address</label>
                    <InputText
                      value={profile?.data?.address}
                      readOnly
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className=" items-center hidden md:flex flex-[0.45] h-[200px]  justify-between flex-col">
            <div className="profile-actions flex justify-start gap-2.5 mt-5">
              <button
                onClick={handleButtonClick}
                className=" bg-[var(--green)] text-[white] border-2 border-[var(--green)] px-3 py-1.5 rounded-md cursor-pointer"
              >
                {imageLoading ? "Uploading..." : "Update Profile Picture"}
              </button>
              <ChangePasswordModel>
                <button className="outlined-btn-transparent bg-transparent border-2 border-[var(--green)] px-5 py-1.5 rounded-md cursor-pointer">
                  Change Password
                </button>
              </ChangePasswordModel>
              <button
                onClick={() => setIsProfileInfoModalVisible(true)}
                className="outlined-edit-btn bg-transparent border-0 text-[var(--color)] py-1.5 font-bold cursor-pointer"
              >
                <i className="pi pi-pencil" style={{ marginRight: "8px" }}></i>
                Edit Profile
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-5 md:ml-9 flex items-center gap-5">
        <div>
          <p className="text-[var(--color)]">Details Currency:</p>
        </div>
        <div>
          <select
            name="cars"
            id="cars"
            value={currency}
            className="outline-none m-0 px-2 border border-[#42866E] rounded-md py-1 -translate-y-[9px] text-[#42866E]"
            onChange={(e) => {
              dispatch(setCurrency(e.target.value));
              // console.log("cur", e.target.value);
            }}
          >
            <option value="LKR">LKR</option>
            <option value="USD">USD</option>
          </select>
        </div>
      </div>

      {/* Delivery Information Section */}
      <div className=" flex px-5  mb-7 flex-col md:flex-row items-start justify-between">
        <div className="md:bg-transparent bg-[#EBEEF4] rounded-lg pt-4 md:pt-0 px-4 w-full md:w-1/2">
          <h3 className=" font-3 text-[16px] font-bold">
            {" "}
            Delivery Information{" "}
          </h3>

          {profile?.data?.deliveryAddresses?.map((data, index) => (
            <div
              key={index}
              className="relative bg-[#EBEEF4] rounded-lg px-5 mb-2.5 min-h-[100px] md:min-w-[658px] flex flex-col justify-around"
            >
              <div className="flex justify-between items-center">
                <p className="my-2 flex items-center text-[14px] md:text-[16px]">
                  <i className="pi pi-envelope mr-2"></i> {profile?.data?.email}
                </p>
                <button
                  onClick={() =>
                    handleDelete({ type: "deliveryAddresses", id: data.id })
                  }
                  className="text-red-500 hover:text-red-700"
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>

              <p className="my-2 flex items-center text-[14px] md:text-[16px]">
                <i className="pi pi-map-marker mr-2"></i> {data?.addressLine1} ,{" "}
                {data?.addressLine2} , {data.city} , <br />
                {data?.province} ,<br /> {data?.postalCode} ,<br />{" "}
                {data?.country} ,{" "}
              </p>
            </div>
          ))}
        </div>
        <button
          onClick={() => setIsAddDeliveryModalVisible(true)}
          className=" link-btn mt-4  bg-none border-none text-[#f15e22] cursor-pointer text-[16px] hover:underline"
        >
          + Add new Delivery Information
        </button>
      </div>

      {/* Billing Information Section */}
      <div className=" flex px-5 mb-7  flex-col md:flex-row items-start justify-between">
        <div className="md:bg-transparent bg-[#EBEEF4] rounded-lg px-4 pt-4 md:pt-0  w-full md:w-1/2">
          <h3 className=" font-3 text-[16px] font-bold">Billing Information</h3>
          {profile?.data?.billingAddresses?.map((data, index) => (
            <div
              key={index}
              className="relative bg-[#EBEEF4] rounded-lg px-5 mb-2.5 min-h-[100px] md:min-w-[658px] flex flex-col justify-around "
            >
              <div className="flex justify-between items-center">
                <p className="my-2 flex items-center text-[14px] md:text-[16px]">
                  <i className="pi pi-envelope mr-2"></i> {profile?.data?.email}
                </p>
                <button
                  onClick={() =>
                    handleDelete({ type: "billingAddresses", id: data.id })
                  }
                  className="text-red-500 hover:text-red-700"
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
              <p className="my-2 flex items-center text-[14px] md:text-[16px]">
                <i className="pi pi-map-marker mr-2"></i> {data.addressLine1} ,{" "}
                {data.addressLine2} , {data.city} ,<br /> {data.province} ,
                <br /> {data.postalCode} , <br />
                {data.country} , <br />
                {data.contactNumber}
              </p>
            </div>
          ))}
        </div>
        <button
          onClick={() => setIsAddBillingModalVisible(true)}
          className=" link-btn mt-4  bg-none border-none text-[#f15e22] cursor-pointer text-[16px] hover:underline"
        >
          + Add new Billing Information
        </button>
      </div>
    </div>
  );
};

export default Profile;
