import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const BASE_URL = "https://uuy8atyhe6.execute-api.ap-south-1.amazonaws.com/api";


const baseQuery = fetchBaseQuery({
  // baseUrl: "https://uuy8atyhe6.execute-api.ap-south-1.amazonaws.com/api",

  baseUrl: "https://uuy8atyhe6.execute-api.ap-south-1.amazonaws.com/api",
  // baseUrl: "http://localhost:8080/api",
  // prepareHeaders: (headers) => {
  //   headers.set("Content-Type", "application/json");

  //   headers.set("Access-Control-Allow-Origin", "*");
  //   headers.set("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, PATCH, OPTIONS");
  //   headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization");

  //   return headers;
  // },
  // async responseHandler(response) {
  //   if (response.status === 404) {
  //     console.error("404 Not Found: ", response.url);
  //   }
  //     console.error("404 Not Found: ", response.url);

  //   return response;
  // },
});

export const apiSlice = createApi({
  baseQuery,
  reducerPath: "api",
  tagTypes: ["Cart", "Product", "Order", "User", "Complains"],
  endpoints: () => ({}),
});
