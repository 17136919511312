// src/components/Header.js
import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import OtpInput from "../OtpInput";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import {
  useGetCartItemsQuery,
  useUpdateGiftDetailsMutation,
} from "../../redux/service/cartSlice";
import { useSelector } from "react-redux";

const SendAsGift = ({ isModalVisible, onClick, toggleModal }) => {
  const [formData, setFormData] = useState({
    isGift: true,
  });
  const [formErrors, setFormErrors] = useState({});
  const userInfo = useSelector((state) => state?.auth?.userInfo);

  const { data: cartData } = useGetCartItemsQuery({
    cartType: "normal",
    userId: userInfo?.id,
  });

  const [updateGiftDetails, { isLoading }] = useUpdateGiftDetailsMutation();

  // const validationSchema = Yup.object({
  //   gifterName: Yup.string().required("Name is required"),
  //   gifteeName: Yup.string().required("Name is required"),
  //   gifteeContactNumber: Yup.string().required("Phone is required"),
  //   gifterMessage: Yup.string().required("Message is required"),
  // });

  // const validationSchema = Yup.object({
  //   gifterName: Yup.string().required("Name is required"),
  //   gifteeName: Yup.string().required("Name is required"),
  //   gifteeContactNumber: Yup.string()
  //     .matches(/^[0-9]+$/, "Phone number must contain only numbers")
  //     .required("Phone is required"),
  //   gifterMessage: Yup.string().required("Message is required"),
  // });

  const validationSchema = Yup.object({
    gifterName: Yup.string().required("Name is required"),
    gifteeName: Yup.string().required("Name is required"),
    gifteeContactNumber: Yup.string()
      .matches(
        /^(\+94\d{9}|0\d{9})$/,
        "Phone Number must be in the format +94XXXXXXXXX or 0XXXXXXXXX"
      )
      .required("Phone Number is required"),
    gifterMessage: Yup.string().required("Message is required"),
  });

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      await updateGiftDetails({
        cartId: cartData?.data?.id,
        formData,
      });
      setFormData({
        isGift: true,
        gifterName: "",
        gifteeName: "",
        gifteeContactNumber: "",
        gifterMessage: "",
      });
      toggleModal(false);
    } catch (error) {
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setFormErrors(validationErrors);
      }
    }
  };

  return (
    <Dialog
      visible={isModalVisible}
      footer={true}
      // header="Verify OTP" // Added a header title here
      closable={true}
      className="custom-dialog"
      onHide={toggleModal}
      breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
      style={{ width: "30vw" }}
    >
      <div className="address-modal-inputs">
        <div className="address-topics">
          <h2 className="address-modal-title">Send as Gift</h2>
          <div className="modal-devider"></div>
          {/* <span className='address-modal-description'>All fields are required to complete the delivery information. Please ensure all sections are filled out before submitting.</span> */}
        </div>
        <div className="address-input-group">
          <label className="modal-label">Who’s this gift from?</label>
          <InputText
            id="Name"
            placeholder="Name"
            className="login-modal-inputs"
            value={formData.gifterName}
            onChange={(e) => {
              setFormData({ ...formData, gifterName: e.target.value });
              setFormErrors({ ...formErrors, gifterName: "" });
            }}
          />
          {formErrors.gifterName && (
            <small className="error-text">{formErrors.gifterName}</small>
          )}
        </div>

        {/* Password Input */}
        <div className="address-input-group">
          <label className="modal-label">Who’s this gift for?</label>
          <InputText
            id="Name"
            type="text"
            placeholder="Enter their name or nickname"
            className="login-modal-inputs"
            value={formData.gifteeName}
            onChange={(e) =>{
              setFormData({ ...formData, gifteeName: e.target.value })
              setFormErrors({ ...formErrors, gifteeName: "" })
            }
            }
          />
          {formErrors.gifteeName && (
            <small className="error-text">{formErrors.gifteeName}</small>
          )}
        </div>
        <div className="address-input-group">
          <label className="modal-label">Recipient phone number</label>
          <InputText
            id="Phone"
            type="text"
            placeholder="Phone number"
            className="login-modal-inputs"
            value={formData.gifteeContactNumber}
            onChange={(e) =>{
              setFormData({ ...formData, gifteeContactNumber: e.target.value })
              setFormErrors({ ...formErrors, gifteeContactNumber: "" })
            }
            }
          />
          {formErrors.gifteeContactNumber && (
            <small className="error-text">
              {formErrors.gifteeContactNumber}
            </small>
          )}
        </div>
        <div className="address-input-group">
          <label className="modal-label">Message</label>
          <InputText
            id="message"
            type="text"
            placeholder="Write a message"
            className="login-modal-inputs"
            value={formData.gifterMessage}
            onChange={(e) =>{
              setFormData({ ...formData, gifterMessage: e.target.value })
              setFormErrors({ ...formErrors, gifterMessage: "" })
            }
            }
          />
          {formErrors.gifterMessage && (
            <small className="error-text">{formErrors.gifterMessage}</small>
          )}
        </div>

        <div className="add-delivery-modal-footer">
          <Button
            label="Save"
            loading={isLoading}
            className="bill-address-save-btn py-2"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default SendAsGift;
