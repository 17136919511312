import { useState, useCallback } from "react";

/**
 * Custom hook to toggle a boolean state
 * @param {boolean} initialValue - The initial value of the toggle (default: false)
 * @returns {[boolean, function]} - The current value and a function to toggle it
 */
const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setValue((prev) => !prev);
  }, []);

  return [value, toggle];
};

export default useToggle;
