import { apiSlice } from "./apiSlice";

export const cartSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCartItem: builder.mutation({
      query: (data) => {
        const sessionId = localStorage.getItem("sessionId");
        return {
          url: `carts/item?${data.userId ? `userId=${data.userId}&` : ""
            }cartType=normal&cityId=${data.cityId}${sessionId ? `&sessionId=${sessionId}` : ""
            }`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Cart"], // Mark Cart tag as invalidated
    }),

    getCartItems: builder.query({
      query: (query) => {
        const sessionId = localStorage.getItem("sessionId");
        const queryString = [
          `cartType=${query.cartType || "normal"}`,
          query.userId ? `userId=${query.userId}` : "",
          sessionId ? `sessionId=${sessionId}` : "",
        ]
          .filter(Boolean)
          .join("&");
        return {
          url: `carts/get?${queryString}`,
          method: "GET",
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.status === 404) {
          console.error("404 Error: Item not found", meta.response.url);
        }
        return response;
      },
      providesTags: ["Cart"], // Tag for cache tracking
    }),

    updateCartItem: builder.mutation({

      query: (data) => {
        const sessionId = localStorage.getItem("sessionId");
        const queryString = [
          data.userId ? `userId=${data.userId}` : "", // Add userId only if available
          `cartType=normal`, // cartType is always normal
          `cityId=${data.cityId}`, // cityId is mandatory
          sessionId ? `sessionId=${sessionId}` : "", // Add sessionId only if available
        ]
          .filter(Boolean) // Remove empty strings or falsy values
          .join("&"); // Join parameters with "&" to form a query string

        return {
          url: `carts/item/${data.id}?${queryString}`, // Dynamically constructed query string
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Cart"],
    }),
    deleteCartItem: builder.mutation({
      query: (data) => ({
        url: `carts/item/${data.itemId}?cartType=normal&${data.userId ? `userId=${data.userId}&` : ""
          }cityId=${data.cityId}${localStorage.getItem("sessionId")
            ? `&sessionId=${localStorage.getItem("sessionId")}`
            : ""
          }`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Cart"],
    }),
    deleteCart: builder.mutation({
      query: (data) => ({
        url: `carts?userId=${data.userId}&cartType=${data.cartType}`,
        method: "DELETE",
      }),
    }),
    cartAddressUpdate: builder.mutation({
      query: (data) => ({
        url: `carts/${data.cartId}/update-addresses`,
        method: "PUT",
        body: data.formData,
      }),
      invalidatesTags: ["Cart"],
    }),
    deliveryOptions: builder.mutation({
      query: (data) => {
        const city = localStorage.getItem("city");

        return {
          url: `carts/${data.cartId}/update-delivery-option?cityId=${city}${data.deliveryOption && `&deliveryOption=${data.deliveryOption}`
            }`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Cart"],
    }),
    submitAddons: builder.mutation({
      query: (data) => ({
        url: `carts/${data.cartId}/submitAddons`,
        method: "POST",
        body: data.addons,
      }),
      invalidatesTags: ["Cart"],
    }),
    updateGiftDetails: builder.mutation({
      query: (data) => ({
        url: `carts/${data.cartId}/updateGiftDetails`,
        method: "POST",
        body: data.formData,
      }),
      invalidatesTags: ["Cart"],
    }),
  }),
});

export const {
  useAddCartItemMutation,
  useGetCartItemsQuery,
  useUpdateCartItemMutation,
  useDeleteCartItemMutation,
  useCartAddressUpdateMutation,
  useSubmitAddonsMutation,
  useUpdateGiftDetailsMutation,
  useDeliveryOptionsMutation,
  useDeleteCartMutation,
} = cartSlice;
