//  CorporatesPage
import React, { useEffect, useState } from "react";
import "./ProductView.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import Monkey_ShoulderBottle from "./../../Images/MONKEY-SHOULDER-Large.png";
import ProductLists from "../../components/ProductLists";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useGetAProductQuery, useSearchProductQuery } from "../../redux/service/productSlice";
import { useAddCartItemMutation } from "../../redux/service/cartSlice";
import { toast } from "material-react-toastify";
import { Skeleton } from "primereact/skeleton";
import { useCreateOrderMutation } from "../../redux/service/orderSlice";
import { useCreatePaymentIntentMutation } from "../../redux/service/paymentSlice";
import { useGetdeliveryAmountQuery } from "../../redux/service/deliverySlice";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  useAddfavItemMutation,
  useDeleteFavoriteItemMutation,
  useGetFavoriteItemsQuery,
} from "../../redux/service/favouriteSlice";
import { Galleria } from "primereact/galleria";
// import { Tooltip } from "react-tooltip";
// import "react-tooltip/dist/react-tooltip.css"; // Import the styles
import { Tooltip } from "primereact/tooltip";
const ProductView = () => {
  const { id } = useParams();
  const userId = useSelector((state) => state?.auth?.userInfo?.id);

  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const cityId = localStorage.getItem("city");
  const [formDataModel, setFormDataModel] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [information, setInformation] = useState(false);

  const sessionID = useSelector((state) => state?.auth?.sessionId);
  const userInfo = useSelector((state) => state?.auth?.userInfo);
  const currency = useSelector((state) => state?.meta?.currency);
  const {
    data,
    isLoading: productLoading,
    refetch: refetchProduct,
  } = useGetAProductQuery({ id, userId });
  const {
    favdata,
    isLoading: favproductLoading,
    refetch: refetchfav,
  } = useGetFavoriteItemsQuery(userId, {
    skip: !userId, // Skip the query if userId is not available
  });
  const [addToCart, { isLoading }] = useAddCartItemMutation();
  const [isProcessing, setIsProcessing] = useState();
  const [createOrder] = useCreateOrderMutation();
  const [createPaymentIntent] = useCreatePaymentIntentMutation();
  const user = useSelector((state) => state?.auth?.userInfo);
  const [paymentData, setPaymentData] = useState("");
  const { data: deliveryAmount, isLoading: deliveryLoading } =
    useGetdeliveryAmountQuery({
      cityId,
      quantity,
    });
  const [addfavItem, { isLoading: addFavLoading }] = useAddfavItemMutation();
  const [deletefavItem, { isLoading: deleteFavLoading }] =
    useDeleteFavoriteItemMutation();
  const [images, setImages] = useState([]);

  const bestsellers = [];
  const [isFavourite, setIsFavourite] = React.useState(false);

  const toggleFavourite = async () => {
    if (data?.data?.favorite == true) {
      try {
        // Trigger the mutation to add to favorites
        await deletefavItem({
          userId,
          productId: data?.data?.id,
        }).unwrap();
        toast.success("Item removed from favorites.");
        refetchProduct();
        refetchfav();
      } catch (error) {
        console.error("Error adding to favorites:", error);
      }
    } else {
      try {
        await addfavItem({
          userId,
          productId: data?.data?.id,
        }).unwrap();
        toast.success("Item added to favorites.");

        refetchProduct();
        refetchfav();
      } catch (error) {
        console.error("Error adding to favorites:", error);
      }
    }
  };

  const { data: peopleAlsoTried, isLoading: peopleAlsoTriedLoading } =
    useSearchProductQuery(
      {
        page: 0,
        // size: 1000,
        cityId,
        isFeatured: true,
        isBestSeller: false,
        isNewArrival: false
      },
      { refetchOnMountOrArgChange: true }
    );

  const [imageVisible, setImageVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const itemTemplate = (item) => {
    return (
      <img
        src={item}
        alt="Product"
        style={{ width: "100%", marginBottom: "10px", display: "block" }}
        onClick={() => {
          setActiveIndex(images.indexOf(item)); // Set the clicked image as active
          setImageVisible(true); // Show the image viewer
        }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item}
        alt="Thumbnail"
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  // useEffect(() => {
  //   if (data?.data?.imageUrl) {
  //     // Assuming data.data.imageUrl is an array or a string (in which case, convert it to an array)
  //     const newImages = Array.isArray(data.data.imageUrl)
  //       ? data.data.imageUrl
  //       : [data.data.imageUrl];

  //     // Append the new images to the existing ones
  //     // setImages((prevImages) => [...prevImages, ...newImages]);  // Using the spread operator to add new images
  //     setImages((prevImages) => [newImages]); // Using the spread operator to add new images
  //   }
  //   console.log("test here");
  // }, [data, setImages]);
  useEffect(() => {
    if (data?.data) {
      const { imageUrl, imageUrl2, imageUrl3, imageUrl4, imageUrl5 } = data.data;

      // Filter out any undefined or null values to ensure only valid URLs are added
      const newImages = [imageUrl, imageUrl2, imageUrl3, imageUrl4, imageUrl5].filter(Boolean);

      // Update the images state with only the new images
      setImages(newImages);
    }
    console.log("Images updated");
  }, [data]);

  const paymentHandler = async () => {
    setIsProcessing(true);
    try {
      await addToCart({
        userId: userId || null,
        itemId: data?.data?.id,
        quantity,
        sessionId: sessionID,
        cityId: localStorage.getItem("city"),
      })
        .unwrap()
        .then(async (cartRes) => {
          await createOrder({
            id: cartRes.data.id,
            userId: user.id,
            sessionId: localStorage.getItem("sessionId")
              ? localStorage.getItem("sessionId")
              : undefined,
            cityId: localStorage.getItem("city")
              ? localStorage.getItem("city")
              : undefined,

            orderItems: cartRes.data.cartItems.map((item) => {
              return {
                ...item,
                productId: item.product.id,
                totalPrice: item.regularPriceInRupee,
              };
            }),
          })
            .unwrap()
            .then(async (res) => {
              await createPaymentIntent({
                amount: cartRes.data.cartItems[0].priceInRupee,
                currency,
                orderId: res.data.id,
              })
                .unwrap()
                .then((res) => {
                  setPaymentData(res.paymentUrl);
                  setIsProcessing(false);
                  setFormDataModel(true);
                  // window.location.href = res.paymentUrl;
                })
                .catch((err) => {
                  console.log(err);
                  setIsProcessing(false);
                });
            });
        });
    } catch (error) {
      setIsProcessing(false);
      console.log(error);
    }
  };

  const handleAddToCart = async () => {
    if (!user) {
      return toast.error("Please login to the system before adding to cart");
    }
    try {
      // console.log("working");

      // Pass `null` for `userId` if it's not available
      await addToCart({
        userId: userInfo?.id || null, // Allow `null` for `userId`
        itemId: data?.data?.id,
        quantity: quantity || 1,
        sessionId: sessionID,
        cityId: localStorage.getItem("city"),
      }).unwrap();

      toast.success("Product added to cart");
      setVisible(false);
    } catch (err) {
      if (err.status === 404) {
        console.log(err);
        toast.error(err.data.message || "Error adding product to cart");
      } else {
        toast.error("Error adding product to cart");
      }
    }
  };

  const [position, setPosition] = useState({ x: "50%", y: "50%" });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseMove = (event) => {
    const image = event.target;
    const { left, top, width, height } = image.getBoundingClientRect();

    // Get the mouse position relative to the image
    const x = ((event.clientX - left) / width) * 100;
    const y = ((event.clientY - top) / height) * 100;

    setMousePosition({ x, y });
  };

  const handleBuy = async () => {
    if (!user) {
      return toast.error("Please login to the system before adding to cart");
    }
    setIsBuying(true);
    try {
      // console.log("working");

      // Pass `null` for `userId` if it's not available
      await addToCart({
        userId: userInfo?.id || null, // Allow `null` for `userId`
        itemId: data?.data?.id,
        quantity: quantity || 1,
        sessionId: sessionID,
        cityId: localStorage.getItem("city"),
      }).unwrap();
      setIsBuying(false);
      toast.success("Product added to cart");
      navigate("/cart/");
      window.scrollTo(0, 0);
      setVisible(false);
    } catch (err) {
      if (err.status === 404) {
        console.log(err);
        toast.error(err.data.message || "Error adding product to cart");
        setIsBuying(false);
      } else {
        toast.error("Error adding product to cart");
        setIsBuying(false);
      }
    }
  };

  return (
    <div className="px-2 md:px-[8%] mt-10 ">
      <div className="flex">
        <p className="cursor-pointer text-[var(--color)] text-[12px] md:text-[16px] pt-7 md:pt-0">
          <Link to="/" className="mr-2">
            {" "}
            Home{" "}
          </Link>{" "}
          {">"} <span className="text-[#DA813D] mx-2"> Product Details </span>
        </p>
      </div>
      <Dialog
        visible={formDataModel}
        closable={false}
        footer={true}
        header={true}
        className="custom-dialog mx-5 md:mx-0" // Add custom class here
        // onHide={toggleModal}
        breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
        style={{ width: "35vw" }}
      >
        {/* <PaymentForm url={paymentData} setFormDataModel={setFormDataModel} /> */}
      </Dialog>

      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        header={false}
        className="product-modal"
        draggable={false}
        resizable={false}
      >
        <div className="product-modal-content ">
          {/* Left Section - Product Image and Details */}
          <div className="product-info flex flex-col md:flex-row">
            <div className="cart-modal-left flex-[0.4] md:flex-[0.5] mb-4 md:mb-0">
              <div
                className="relative overflow-hidden group w-64 h-64 mx-auto"
                onMouseMove={handleMouseMove}
              >
                <img
                  src={data?.data.imageUrl}
                  alt="Product"
                  className="transition-transform duration-300 ease-in-out group-hover:scale-150"
                  style={{
                    transformOrigin: `${position.x} ${position.y}`,
                  }}
                />
              </div>
            </div>
            <div className="cart-modal-right flex-[0.6] px-6">
              <h2 className="text-2xl  font-bold text-[var(--color)]">
                {data?.data?.correctName}
              </h2>

              <p>{data?.data?.materialDescription}</p>
              <h3 className="product-price">
                {currency} {data?.data?.regularPriceInRupee.toLocaleString()}
              </h3>
              <p>
                <strong>SKU:</strong> {data?.data?.wwProductRef}
              </p>
              <p>
                <strong>Categories:</strong>
                <div>{data?.data.categoryName}</div>
              </p>
            </div>
          </div>

          {/* Right Section - Product Actions */}
          <div className="product-actions">
            <div className="product-options p-4">
              <h4>Product Details</h4>
              <div className="modal-devider my-4"></div>
              <div>
                <p>Shipping</p>
                <span className="font-light">Calculate shipping</span>
              </div>
              <h4>Order Details</h4>
              <div className="modal-devider my-4"></div>
              <div className="quantity-control flex items-center justify-between">
                <span>Quantity</span>
                <div className="side-item-controls flex items-center border border-gray-300 rounded">
                  <button
                    onClick={() => quantity > 1 && setQuantity(quantity - 1)}
                    className="quantity-btn  p-2 bg-[var(--green)] text-white rounded-l"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={quantity}
                    readOnly
                    className="quantity-input px-2 py-1 border-l border-r border-gray-300 text-center"
                  />

                  <button
                    onClick={() => setQuantity(quantity + 1)}
                    className="quantity-btn  p-2 bg-[var(--green)] text-white rounded-r"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="terms-red text-red-500 mt-4">
                *Terms & Conditions:
              </div>
              <span className="term-description text-gray-600">
                Alcoholic beverages cannot be returned or refunded unless the
                product is defective or damaged upon delivery.Proof of purchase
                and evidence of damage are required for returns.
              </span>
            </div>
            <div className="button-group flex flex-col md:flex-row gap-4 mt-0 ">
              <Button
                label="Buy Now"
                className="p-button-success w-full md:w-auto"
                onClick={() => {
                  navigate("/cart/");
                  window.scrollTo(0, 0);
                }}
              />
              <Button
                label={`${isLoading ? "Loading" : "Add to cart"}`}
                onClick={handleAddToCart}
                className="add-cart-button-secondary w-full md:w-auto "
              />
            </div>
          </div>
        </div>
      </Dialog>

      {productLoading ? (
        <div className="grid md:grid-cols-3 gap-4 pt-10">
          <Skeleton height="500px" className="mb-2 md:col-span-2"></Skeleton>
          <Skeleton height="500px" className="mb-2"></Skeleton>
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row h-auto md:h-[670px] ">
            <div className="flex-[0.7] md:flex-[0.6] m-[10px] border-[1.5px]   border-[#13382B] rounded-lg flex  flex-col  overflow-y-scroll hidden-scrollbar">
              <div className="flex md:px-[35px] md:pb-[30px]   flex-col md:flex-row p-[20px]  box-content md:h-[700px] ">
                <div className="flex-[0.4] mb-4 md:mb-0 flex justify-center items-center  pt-0">

                  <div style={{}} className="relative  items-center flex justify-center items-center  group w-full mt-[10%] mb-[3%] min-w-32 8 mx-auto py-0">
                    <Galleria
                      value={images}
                      numVisible={1}
                      circularcon
                      // className=" galleria-container"

                      style={{ height: "90%", width: "90%", objectFit: 'contain' }}
                      showThumbnails={false}
                      // showItemNavigators
                      showIndicators={images.length > 1}
                      // changeItemOnIndicatorHover
                      // showIndicatorsOnItem={true}
                      item={itemTemplate}
                      // thumbnail={thumbnailTemplate}
                      responsiveOptions={[
                        {
                          breakpoint: "960px",
                          numVisible: 1,
                        },
                      ]}
                      onChange={(e) => setActiveIndex(e.index)} // Update activeIndex when image changes
                    />

                    {imageVisible && (
                      <div
                        className="image-viewer-backdrop "
                        onClick={() => setImageVisible(false)}
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(0, 0, 0, 0.8)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 1000,
                        }}
                      >
                        <img
                          src={images[activeIndex]}
                          alt="Product"
                          className="transition-transform duration-300 ease-in-out"
                          style={{
                            maxWidth: "90%",
                            maxHeight: "90%",
                            transformOrigin: `${mousePosition.x}% ${mousePosition.y}%`, // Dynamic zoom focus based on mouse position
                            transform: isHovered ? "scale(1.8)" : "scale(1)", // Apply zoom on hover
                            transition: "transform 0.3s ease-out", // Smooth zoom transition
                          }}
                          onMouseMove={handleMouseMove} // Track mouse position over the image
                          onMouseEnter={() => setIsHovered(true)} // Start zoom effect on hover
                          onMouseLeave={() => setIsHovered(false)} // Reset zoom effect when hover ends
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-[0.6] flex-col md:p-[40px]">
                  <h2 className="text-[var(--color)]  font-bold font-mulish text-xl md:text-2xl">
                    {data?.data?.correctName}
                  </h2>
                  <span>{data?.data?.materialDescription}</span>
                  <h2 className="text-[var(--green)] text-xl font-bold font-mulish mt-[10%]">
                    {currency}{" "}
                    {currency === "LKR"
                      ? Number(data?.data?.regularPriceInRupee)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : Number(data?.data?.regularPriceInDollar)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </h2>
                  <div className="flex flex-row">
                    <div className="font-bold mr-1.25 text-[var(--color)]">
                      SKU: <span>{data?.data?.wwProductRef}</span>
                    </div>
                  </div>
                  <div className="categoryText flex flex-row mt-[10%]">
                    <div className="font-bold mr-1.25">Categories : </div>
                    <div className="ml-1"> {data?.data.categoryName}</div>
                  </div>
                </div>
              </div>

              <div className=" border bg-[#DEDEDE] border-[#DEDEDE] mx-16 my-0"></div>

              <div className="md:p-10 p-5">
                <div className="flex gap-4">
                  <button
                    onClick={() => setInformation(false)}
                    className={`border-[1px] text-sm ${information && "opacity-50"
                      } border-[#13382B] text-[var(--color)] hover:border-[#DA813D] hover:text-[#DA813D] transition-colors duration-200 px-4 py-2 rounded-md`}
                  >
                    Description
                  </button>
                  <button
                    onClick={() => setInformation(true)}
                    className={`border-[1px] ${!information && "opacity-50"
                      } text-sm border-[#13382B] text-[var(--color)] hover:border-[#DA813D] hover:text-[#DA813D] transition-colors duration-200 px-4 py-2 rounded-md`}
                  >
                    Additional Information
                  </button>
                </div>
              </div>

              <div className="flex-[0.3] md:flex-[0.4]  rounded-lg px-[40px]">
                <div className={` ${!information && "hidden"}`}>
                  <h1 className="text-xl font-bold text-[var(--color)] font-Mulish">
                    Additional Information
                  </h1>
                  <table className="w-full border-collapse border my-5  border-gray-100 md:w-3/4">
                    <tbody>
                      {data?.data?.netWeight && (
                        <tr className=" text-[var(--color)]">
                          <td className="px-4 py-2 w-2/5 font-medium border bg-[var(--tableGray)] border-gray-100">
                            Weight
                          </td>
                          <td className="px-4 py-2 border ">
                            {data?.data?.netWeight} {data?.data?.weightUnit}
                          </td>
                        </tr>
                      )}
                      {data?.data?.alcoholStrength && (
                        <tr className=" text-[var(--color)]">
                          <td className="px-4 py-2 w-2/5 font-medium border bg-[var(--tableGray)] border-gray-100">
                            Strength
                          </td>
                          <td className="px-4 py-2 border ">
                            {data?.data?.alcoholStrength}
                          </td>
                        </tr>
                      )}
                      {data?.data?.volume && (
                        <tr className=" text-[var(--color)]">
                          <td className="px-4 py-2 w-2/5 font-medium border bg-[var(--tableGray)] border-gray-100">
                            Size
                          </td>
                          <td className="px-4 py-2  border">
                            {data?.data?.volume} L
                          </td>
                        </tr>
                      )}
                      {data?.data?.categoryName && (
                        <tr className=" text-[var(--color)]">
                          <td className="px-4 py-2 w-2/5 font-medium border bg-[var(--tableGray)] border-gray-100">
                            WW Category
                          </td>
                          <td className="px-4 py-2  border">
                            {data?.data?.categoryName}
                          </td>
                        </tr>
                      )}
                      {data?.data?.countryOfOrigin && (
                        <tr className=" text-[var(--color)]">
                          <td className="px-4 py-2 w-2/5 font-medium border bg-[var(--tableGray)] border-gray-100">
                            Country
                          </td>
                          <td className="px-4 py-2  border">
                            {data?.data?.countryOfOrigin}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className={` ${information && "hidden"}`}>
                  <h1 className="text-xl font-bold text-[var(--color)] font-Mulish">
                    Description
                  </h1>
                  <div className="py-5 ">
                    <span>{data?.data?.materialDescription}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-[0.3] md:flex-[0.4] m-[10px] border-[1.5px] border-[#13382B] rounded-lg p-[40px] ">
              {/* Product Details Header */}
              <div className="flex justify-between items-center mb-3.75">
                <h3 className="text-[var(--green)] m-0 font-bold">
                  Product Details
                </h3>
                <div className="flex">
                  {/* Icons (Share and Favorite) */}
                  <div className="mr-3 mt-1.5" style={{ cursor: "pointer" }}>
                    <FaShareAlt size={18} />
                  </div>
                  {user && (
                    <div
                      onClick={toggleFavourite}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <>
                        <FaHeart color={data?.data?.favorite ? 'red' : 'gray'} size={20} />
                        <Tooltip
                          anchorId="favorite-icon"
                          content={data?.data?.favorite ? "Remove from favorites" : "Add to favorites"}
                          place="top"
                        />
                      </> */}
                      <>
                        {favproductLoading == true ? (
                          <div>Wait..</div>
                        ) : (
                          <>
                            <Button
                              tooltip={
                                data?.data?.favorite
                                  ? "Remove from favorites"
                                  : "Add to favorites"
                              }
                              tooltipOptions={{ autoHide: false }}
                            >
                              <FaHeart
                                id="favorite-icon"
                                onClick={(e) => {
                                  toggleFavourite();
                                  e.stopPropagation();
                                }}
                                color={data?.data?.favorite ? "red" : "gray"}
                                size={20}
                              />
                            </Button>

                            {/* <Tooltip
                              anchorId="favorite-icon"
                              content={data?.data?.favorite ? "Remove from favorites" : "Add to favorites"}
                              place="top"
                            /> */}
                          </>
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>
              <div className="border-b border-gray-300 mt-5 mb-5"></div>
              {/* Delivery Info */}

              <div className="direction-row flex justify-between items-center mt-5">
                <p className="text-[var(--softText)] mt-[2%] font-bold items-center">
                  Delivery Charges
                </p>
                <p className="items-center text-[var(--color)]">
                  {currency}{" "}
                  {deliveryAmount?.data &&
                    !isNaN(
                      currency === "LKR"
                        ? deliveryAmount?.data?.totalDeliveryChargeInRupee
                        : deliveryAmount?.data?.totalDeliveryChargeInDollar
                    )
                    ? new Intl.NumberFormat(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      currency === "LKR"
                        ? deliveryAmount?.data?.totalDeliveryChargeInRupee
                        : deliveryAmount?.data?.totalDeliveryChargeInDollar
                    )
                    : "No delivery amount available"}
                </p>
              </div>
              {/* Order Details Header */}
              <h4 className="text-[var(--softText)] mt-[15%] mb-2.5 font-bold">
                Order Details
              </h4>
              <div className="border-b border-gray-300 mt-5 mb-5"></div>
              {/* Quantity Selector */}
              <div className="flex items-center mb-5">
                <p className="text-[var(--softText)] mt-[2.5%] mb-5">
                  Quantity
                </p>
                <div className="flex ml-auto items-center border border-gray-300 rounded">
                  <button
                    onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}
                    className="p-2 bg-[var(--green)] text-white border-none rounded-l cursor-pointer"
                  >
                    -
                  </button>
                  <span className="px-2.5 py-1.25 border-l border-r border-gray-300">
                    {quantity}
                  </span>
                  <button
                    onClick={() => setQuantity(quantity + 1)}
                    className="p-2 bg-[var(--green)] text-white border-none rounded-r cursor-pointer"
                  >
                    +
                  </button>
                </div>
              </div>
              {/* Terms and Conditions */}
              <div className="mb-5">
                <p className="text-red-500 m-0">*Terms & Conditions:</p>
                <p className="text-gray-600 m-0">
                  Alcoholic beverages cannot be returned or refunded unless the
                  product is defective or damaged upon delivery.Proof of
                  purchase and evidence of damage are required for returns.
                </p>
              </div>

              <button
                className={`w-full p-2.5  bg-[var(--green)] text-white border-none rounded mb-2.5 cursor-pointer flex items-center justify-center ${isBuying ? "opacity-75 cursor-not-allowed" : ""
                  }`}
                onClick={handleBuy}
                disabled={isBuying} // Disable the button when processing
              >
                {isBuying ? (
                  <div className="flex items-center gap-2">
                    <ProgressSpinner
                      style={{ width: "1rem", height: "1rem" }}
                      strokeWidth="8"
                    />
                    <span>Adding to cart...</span>
                  </div>
                ) : (
                  "Buy Now"
                )}
              </button>
              {/* <button
                onClick={() => {
                  if (window.innerWidth < 768) {
                    {
                      navigate("/cart/");
                      window.scrollTo(0, 0);
                    }
                  } else {
                    // setVisible(true);
                    handleAddToCart()
                  }
                }}
                className="w-full p-2.5 bg-white text-[#13382B] border border-[#13382B] rounded cursor-pointer"
              >
                {" "}
                Add to cart{" "}
              </button> */}
              <button
                onClick={() => handleAddToCart()}
                className={`w-full   p-2.5 flex items-center justify-center bg-white text-[#13382B] border border-[#13382B] rounded cursor-pointer ${isLoading ? "opacity-75 cursor-not-allowed" : ""
                  }`}
                disabled={isLoading} // Disable the button while loading
              >
                {isLoading ? (
                  <div className="flex items-center gap-2">
                    <ProgressSpinner
                      style={{ width: "1rem", height: "1rem" }}
                      strokeWidth="8"
                    />
                    <span>Adding</span>
                  </div>
                ) : (
                  "Add to Cart"
                )}
              </button>
            </div>
          </div>
        </>
      )}

      {peopleAlsoTried?.content?.length > 0 && (
        <div>
          <h2 className="my-10 text-[var(--color)] text-2xl text-center md:text-left  md:text-3xl font-bold">
            PEOPLE ALSO TRIED
          </h2>
          <div className="mt-12">
            <ProductLists productData={peopleAlsoTried} visibleCount={4} />
          </div>
        </div>
      )}
    </div>
  );
};
export default ProductView;
