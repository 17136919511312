import React, { useEffect, useState } from 'react';
import './TypeOneEventCard.css';

const TypeOneEventCard = ({ Title, Description, onClick, image }) => {


    return (
        <div className="event-card-container-left">
            <div className="event-card-content-left">
                <h3 className="event-card-title">{Title}</h3>
                <span className="event-card-description">{Description}</span>
                <button className="event-card-button-left text-[var(--color)]" onClick={onClick}>Contact us</button>
            </div>

            <img
                src={image}
                className="event-card-image-left object-cover"
                alt=''
            />
        </div>


    );
};

export default TypeOneEventCard;
