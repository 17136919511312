import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import logo from "./../icons/logo.png";
import whiteLogo from "./../icons/whiteLogo.png";
import OtpInput from "./OtpInput";
import {
  useReSendOtpMutation,
  useVerifyOtpMutation,
} from "../redux/service/authSlice";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../features/authSlice";

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required")
    .matches(/^\d+$/, "OTP must be numbers only") // Numbers only
    .length(5, "OTP must be exactly 5 digits"), // Exactly 5 digits
});

const VerifyOtp = ({
  isModalVisible,
  isDarkTheme,
  email,
  number,
  setIsVerifyVisible,
}) => {
  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();
  const [reSendOtp] = useReSendOtpMutation();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [timer, setTimer] = useState(300); // 2 minutes in seconds
  const dispatch = useDispatch();

  useEffect(() => {
    if (isModalVisible) {
      setTimer(300);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const reSendOtpHandler = async () => {
    await reSendOtp({ email, contact: number })
      .unwrap()
      .then((res) => {
        setTimer(300);
        toast.success("OTP sent successfully");
        setFormData("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message || "Something went wrong");
      });
  };

  const handleSubmit = async () => {
    if (timer <= 0) return; // Prevent submission if timer has expired

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setFormErrors({});

      // await verifyOtp({
      //   email,
      //   otp: formData.otp,
      //   contact: number.replace("+", ""),
      // })
      await verifyOtp({
        email,
        otp: formData.otp,
        contact: number ? number.replace("+", "") : null,
      })
        .unwrap()
        .then((res) => {
          console.log(res);
          dispatch(setUser(res.data));
          setIsVerifyVisible(false);
          toast.success("OTP verified successfully");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.data?.message || "Something went wrong");
        });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setFormErrors(validationErrors);
      }
    }
  };

  return (
    <Dialog
      visible={isModalVisible}
      footer={true}
      closable={false}
      className="custom-dialog"
      onHide={() => setIsVerifyVisible(false)}
      breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
      style={{ width: "30vw" }}
    >
      <div className="verify-modal-inputs">
        <img
          src={isDarkTheme ? whiteLogo : logo}
          alt="Wine World Logo"
          className="login-logo"
        />

        <span className="verify-modal-description">
          An OTP will be sent to the provided email address
          <br />
          to ensure the security of your account.
        </span>

        <span
          className={`verify-modal-description ${
            timer === 0 ? "text-red-500" : ""
          }`}
        >
          {formatTime(timer)}
        </span>

        <div className="verify-modal-description">
          <OtpInput
            length={5}
            onChange={(otp) => {
              setFormData({ ...formData, otp });
              setFormErrors({ ...formErrors, otp: "" });
            }}
          />
        </div>

        {formErrors.otp && (
          <span className="text-red-500">{formErrors.otp}</span>
        )}

        <span className="verify-modal-description group/otp">
          Didn't receive OTP?{" "}
          <button
            disabled={timer > 0}
            // className="group-hover/otp:underline text-[#ED7242] cursor-pointer "
            className={`group-hover/otp:underline ${
              timer > 0
                ? "text-gray-400 cursor-not-allowed"
                : "text-[#ED7242] cursor-pointer"
            }`}
            onClick={reSendOtpHandler}
          >
            Resend
          </button>
        </span>

        <div className="modal-footer">
          <Button
            label="Submit"
            className="login-confirm-btn py-2"
            onClick={handleSubmit}
            loading={isLoading}
            disabled={
              !formData?.otp ||
              formData?.otp?.length < 5 ||
              isLoading ||
              timer === 0
            }
          />
        </div>
      </div>
    </Dialog>
  );
};

export default VerifyOtp;
