import { apiSlice } from "./apiSlice";

export const addOnsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddOns: builder.query({
      query: () => ({
        url: "addons/get",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAddOnsQuery } = addOnsSlice;
