// src/pages/AboutPage.js
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container">
            <div style={{backgroundColor: "transparent"}} className="p-4 md:mx-12  ">
                <Card title={<span
                    className="text-3xl uppercase text-[var(--color)] bg-transparent">Privacy Policy</span>}
                      className="mb-4 md:mx-12 md:px-12 font-10 border-none shadow-none">
                    <p className="text-justify"> What Personal Information About Customers does Wineworld gather? The information we learn from
                        customers helps us personalize and continually improve your experience. Here are the types of
                        information we gather. </p>
                    <p className="text-justify"> Information You Give Us: We receive and store any information you enter on our Web site or give
                        us in any other way. You can choose not to provide certain information, but then you might not
                        be able to take advantage of many of our features. We use the information that you provide for
                        such purposes as responding to your requests, customizing future shopping for you, improving our
                        stores, and communicating with you. </p>

                    <p className="text-justify"> Automatic Information: We receive and store certain types of information whenever you interact
                        with us. For example, like many Web sites, we use “cookies,” and we obtain certain types of
                        information when your Web browser accesses any of our websites or advertisements and other
                        content served by or on behalf of us on other Web sites.</p>
                    <p className="text-justify">Mobile: When you download or use apps created by us or by our subsidiaries, we may receive
                        information about your location and your mobile device, including a unique identifier for your
                        device. We may use this information to provide you with location-based services, such as
                        advertising, search results, and other personalized content. Most mobile devices allow you to
                        turn off location services.</p>

                    <p className="text-justify">E-mail Communications: To help us make e-mails more useful and interesting, we often receive a
                        confirmation when you open e-mail from us if your computer supports such capabilities. If you do
                        not want to receive e-mails or other mails from us you could use the unsubscribe option
                        available in every mail we send.</p>
                    <p className="text-justify">What About Cookies? Cookies are unique identifiers that we transfer to your device to enable our
                        systems to recognize your device and to provide features such as personalized advertisements on
                        other Web sites and storage of items in your Shopping Cart between visits.</p>
                    <p className="text-justify">The Help feature on most browsers will tell you how to prevent your browser from accepting new
                        cookies, how to have the browser notify you when you receive a new cookie, or how to disable
                        cookies altogether. Additionally, you can disable or delete similar data used by browser
                        add-ons, such as Flash cookies, by changing the add-on’s settings or visiting the Web site of
                        its manufacturer. We recommend that you leave them turned on. For instance, if you block or
                        otherwise reject our cookies, you will not be able to add items to your Shopping Cart, proceed
                        to Checkout, or use products and services that require you to Sign in.</p>
                    <p className="text-justify">Does Wineworld Share the Information It Receives? Information about our customers is an important
                        part of our business, and we are not in the business of selling it to others. We share customer
                        information only as described below and with subsidiaries we control that either are subject to
                        this or follow practices at least as protective as those described.</p>

                    <p className="text-justify">Affiliated Businesses We Do Not Control: We work closely with affiliated businesses. In other
                        cases, we sell product lines jointly with these businesses. You can tell when a third party is
                        involved in your transactions, and we share customer information related to those transactions
                        with that third party.</p>
                    <p className="text-justify">Third-Party Service Providers: We employ other companies and individuals to perform functions on
                        our behalf. Examples include fulfilling orders, delivering packages, sending postal mail and
                        e-mail, removing repetitive information from customer lists, analysing data, providing marketing
                        assistance, providing search results and links (including paid listings and links), processing
                        credit card payments, and providing customer service. They have access to personal information
                        needed to perform their functions, but may not use it for other purposes.</p>

                    <p className="text-justify">Business Transfers: As we continue to develop our business, we might sell or buy stores,
                        subsidiaries, or business units. In such transactions, customer information generally is one of
                        the transferred business assets but remains subject to the promises made in any pre-existing
                        Privacy Notice (unless, of course, the customer consents otherwise).</p>
                    <p className="text-justify">With Your Consent: Other than as set out above, you will receive notice when information about
                        you might go to third parties, and you will have an opportunity to choose not to share the
                        information.</p>
                    <p className="text-justify">How Secure Is Information About Me? We work to protect the security of your information during
                        transmission by using Secure Sockets Layer (SSL) software, which encrypts information you
                        input.</p>

                    <p className="text-justify">Bank / Payment details are directly typed on respective secured servers and details are not kept
                        with us.</p>

                    <p className="text-justify">It is important for you to protect against unauthorized access to your password and to your
                        computer. Be sure to sign off when finished using a shared computer.</p>
                </Card>
            </div>
        </div>
            );
            };

            export default PrivacyPolicy;
