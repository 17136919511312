import React, { useState } from "react";
import "./OrderDetails.css";
import { useParams } from "react-router-dom";

import { Skeleton } from "primereact/skeleton";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // If using FontAwesome React library

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Details from "./../../icons/Details.png";
import Location from "./../../icons/Location.png";
import CartIcon from "./../../icons/Cart.png";
import BottleImage from "./../../Images/MONKEY-SHOULDER-Large.png";
import {
  useGetAOrderQuery,
  useGetSingleOrderQuery,
} from "../../redux/service/orderSlice";
import { useSelector } from "react-redux";
import { useAddCartItemMutation } from "../../redux/service/cartSlice";
import { toast } from "material-react-toastify";
import { useNavigate } from "react-router-dom";

const OrderDetails = () => {
  const [selectedOrderType, setSelectedOrderType] = useState("Previous Orders");
  const sessionID = useSelector((state) => state?.auth?.sessionId);
  const userId = useSelector((state) => state?.auth?.userInfo?.id);
  const { id } = useParams();
  const [addToCart, { isLoading }] = useAddCartItemMutation();
  const [expandedAddons, setExpandedAddons] = useState(false);
  const [expandedPrices, setExpandedPrices] = useState(false);
  const currency = useSelector((state) => state?.meta?.currency);
  const navigate = useNavigate();

  // const {data : order } =useGetAOrderQuery(id)

  const { data: order, isLoading: isLoadingOrder } = useGetSingleOrderQuery({
    orderId: id,
  });


  const handleNavigation = () => {
    navigate('/Complaint', { state: { ReportorderId: order?.data?.id } });
  };

  const handleAddToCart = async (productId) => {
    try {
      await addToCart({
        userId: userId || null,
        itemId: productId,
        quantity: 1,
        sessionId: sessionID,
        cityId: localStorage.getItem("city"),
      }).unwrap();
      toast.success("Product added to cart");
    } catch (err) {
      if (err.status === 404) {
        console.log(err);
        toast.error(err.data.message || "Error adding product to cart");
      } else {
        toast.error("Error adding product to cart");
      }
    }
  };

  const totalAmount = order?.data?.orderItems.reduce((total, item) => {
    let itemPrice;

    if (currency === "LKR") {
      itemPrice = item?.totalPriceInRupee;
    } else if (currency === "USD") {
      itemPrice = item?.totalPriceInDollar;
    }

    const itemTotal = itemPrice;
    return total + itemTotal;
  }, 0);

  // console.log("order", order?.data);

  return (
    <div className="px-3 md:px-8 mt-10 container">
      {/* <span className='parth'>Home {">"} Product Details {">"} Checkout {">"} Order Details</span> */}

      {/* Details cards */}

      {isLoadingOrder ? (
        <div className="gap-5 flex flex-col">
          <Skeleton width="100%" height="150px"></Skeleton>
          <Skeleton width="100%" height="250px"></Skeleton>
          <Skeleton width="100%" height="250px"></Skeleton>
        </div>
      ) : (
        <>

          <div className="flex items-center my-2 items-end justify-end  text-green-900">
            <div
              className="space-x-2 flex items-center cursor-pointer"
              onClick={handleNavigation}
            >
              <i className="pi pi-exclamation-circle text-xl"></i>
              <span className="font-medium">Report Order</span>
            </div>

          </div>
          <div className="detail-card-wrap flex  flex-col gap-5 md:gap-0 md:flex-row">
            {/* Order Details */}

            <div className="detail-card-item">
              <div className="detail-card-icon">
                <img src={Details} alt="" />
              </div>

              <div className="detail-card-items">
                <div className="detail-card-h3">Order Details</div>

                <div className="detail-card-div">
                  <strong>Order ID : </strong>
                  {order?.data?.id}
                </div>

                <div className="detail-card-div mb-1">
                  <strong>Placed on: </strong>
                  {new Date(order?.data?.orderDate).toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </div>
                <div
                  className={`font-semibold ${order?.data?.status === "COMPLETED"
                    ? " text-green-500" :
                    order?.data?.status === "PROCESSING"
                      ? "text-blue-600"
                      : order?.data?.status === "CANCELLED"
                        ? "text-red-500"
                        : order?.data?.status === "RETURNED"
                          ? "text-orange-500"
                          : "text-yellow-600" // SUCCESS
                    }`}
                >
                  <strong className="mr-1 text-[var(--color)]">
                    Order Status :
                  </strong>
                  {order?.data?.status}
                </div>
                <div
                  className={`font-semibold ${order?.data?.status === "PROCESSING"
                    ? "text-yellow-600" :
                    order?.data?.paymentStatus === "PENDING"
                      ? "text-blue-600"
                      : order?.data?.paymentStatus === "CANCELLED"
                        ? "text-red-500"
                        : order?.data?.paymentStatus === "FAILED"
                          ? "text-orange-500"
                          : "text-green-500" // SUCCESS
                    }`}
                >
                  <strong className="mr-1 text-[var(--color)]">
                    Payment Status :
                  </strong>
                  {order?.data?.paymentStatus}
                </div>
                <div className="detail-card-div">
                  <strong>Billing Address: </strong>
                  {order?.data?.billingAddress}
                </div>

                <div className="detail-botle-addons">
                  <button
                    className="expand-button font-bold text-[#0d77a8]"
                    onClick={() =>
                      setExpandedPrices(!expandedPrices)
                    }
                  >
                    {expandedPrices ? "Hide Price List" : "Show Price List"}
                  </button>

                </div>

                {expandedPrices &&
                  <div>

                    <div className="addon-item" >
                      <div className="addon-name">Total product cost</div>
                      <div className="addon-details">

                        <span>
                          {order?.data?.currency}{" "}
                          {order?.data?.currency === "LKR"
                            ? totalAmount
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : totalAmount
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      </div>
                    </div>
                    <div className="addon-item" >
                      <div className="addon-name">Delivery charges</div>
                      <div className="addon-details">

                        <span>
                          {order?.data?.currency}{" "}
                          {order?.data?.currency === "LKR"
                            ? order?.data?.deliveryFeeInRupee
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
                            : order?.data?.deliveryFeeInDollar
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}

                        </span>
                      </div>
                    </div>


                    {order?.data?.orderAddons.length > 0 && (
                      <div className="addon-item" >
                        <div className="addon-name flex flex-row">Customizations
                          <button
                            className="expand-button text-[#0d77a8] font-semibold flex flex-row ml-2 items-center"
                            onClick={() => setExpandedAddons(!expandedAddons)}
                          >
                            {/* Conditional button text */}
                            {expandedAddons ? "hide" : "show"}

                            {/* Conditional icons */}
                            {expandedAddons ? (
                              <FaChevronUp className="ml-2 text-sm" />  // Up icon for collapse
                            ) : (
                              <FaChevronDown className="ml-2 text-sm" /> // Down icon for expand
                            )}
                          </button>

                        </div>
                        <div className="addon-details">

                          <span>
                            {order?.data?.currency}{" "}
                            {order?.data?.currency === "LKR"
                              ? order?.data?.addonTotalInRupee
                                ?.toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
                              : order?.data?.addonTotalInDollar
                                ?.toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                          </span>
                        </div>
                      </div>

                    )}



                    {expandedAddons && (
                      <div className="addon-list">
                        {order?.data?.orderAddons?.map((addon, addonIndex) => (
                          <div className="addon-item" key={addonIndex}>
                            <div className="addon-name">{addon?.addonName} X {addon?.quantity}</div>
                            <div className="addon-details">

                              <span>
                                {order?.data?.currency}{" "}
                                {order?.data?.currency === "LKR"
                                  ? addon?.totalPriceInRupee
                                    ?.toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : addon?.totalPriceInDollar
                                    ?.toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}


                    <div className="addon-item" >
                      <div className=" font-bold">Total</div>
                      <div className="font-bold">

                        <span>
                          {order?.data?.currency}{" "}
                          {order?.data?.currency === "LKR"
                            ? order?.data?.totalPriceInRupee
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : order?.data?.totalPriceInDollar
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      </div>
                    </div>

                  </div>
                }










              </div>

            </div>

            {/* Delivery details */}

            <div className="detail-card-item">
              <div className="detail-card-icon">
                <img src={Location}></img>
              </div>

              <div className="detail-card-items">
                <div className="detail-card-h3">Delivery Details</div>

                <div className="detail-card-div">
                  <strong>Name : </strong>
                  {order?.data?.customerName}
                </div>
                {/* <div className='detail-card-div'><strong>Email Address : </strong>{order?.data?.customerName}</div> */}
                <div className="detail-card-div">
                  <strong>Loction : </strong>
                  {order?.data?.deliveryAddress}
                </div>
                {order?.data?.deliveryDate && (

                  <div className="detail-card-div">
                    <strong>Scheduled Delivery: </strong>
                    {order?.data?.deliveryDate?.split("T")[0]}
                  </div>
                )}

              </div>
            </div>
          </div>

          {/* Bottle card */}
          {order?.data?.orderItems?.map((item) => (
            <div className="detail-bottle-card">
              <div className="detail-botle-start ">
                <img className="object-contain" src={item?.imageUrl}></img>
              </div>

              <div className="detail-botle-middle mt-4">
                <h4 className="detail-card-bottleTopic">{item?.productName}</h4>

                <p className="detail-card-price ">
                  {order?.data?.currency}{" "}
                  {order?.data?.currency == "LKR"
                    ? item?.unitPriceInRupee?.toLocaleString()
                    : order?.data?.currency == "USD"
                      ? item?.unitPriceInRupee?.toLocaleString()
                      : 0}{" "}
                  x {item?.quantity}
                </p>

                {/* <span className="detail-card-category">Blended</span> */}

                <div className="detail-card-devider my-5"></div>

                <div className="detail-card-bill mt-2">
                  <div>
                    <div className="detail-card-bill-items">Quantity: </div>
                    <div className="detail-card-bill-items">Unit Price: </div>
                    {/* <div className="detail-card-bill-items">Delivery Fee: </div> */}

                    <div className="detail-card-bill-items-total">
                      Sub Total :
                    </div>
                  </div>

                  <div className="detail-card-bill-right">
                    <div className="detail-card-bill-items">
                      {item?.quantity}
                    </div>

                    <div className="detail-card-bill-items">
                      {order?.data?.currency}{" "}
                      {order?.data?.currency === "LKR" || order?.data?.currency === "USD"
                        ? (item?.unitPriceInRupee
                          ? item.unitPriceInRupee
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "0.00")
                        : "0.00"}{" "}
                    </div>
                    {/* Delivery charge */}
                    {/* <div className="detail-card-bill-items">
                      {order?.data?.currency} 0
                    </div> */}
                    <div className="detail-card-bill-items-total">
                      {order?.data?.currency}{" "}
                      {order?.data?.currency === "LKR"
                        ? (item?.totalPriceInRupee
                          ? item.totalPriceInRupee
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "0.00")
                        : order?.data?.currency === "USD"
                          ? (item?.totalPriceInDollar
                            ? item.totalPriceInDollar
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : "0.00")
                          : "0.00"}
                    </div>
                  </div>
                </div>

              </div>










              <div className="detail-botle-end">
                <img
                  className="cursor-pointer"
                  alt="add to cart"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event from reaching the parent div
                    handleAddToCart(item?.productId);
                  }}
                  src={CartIcon}
                ></img>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default OrderDetails;
