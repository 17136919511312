// src/components/Header.js
import React, { useEffect, useState } from "react";
import ProductsCard from "./ProductsCard";
import { Button } from "primereact/button";

const ProductLists = ({ productData, visibleCount, onClick }) => {
  const product = productData?.content || [];


  const [visibleProducts, setVisibleProducts] = useState(visibleCount); // Show initial number of products
  const [showSeeMore, setShowSeeMore] = useState(true); // Toggle the 'See More' button

  useEffect(() => {
    if (visibleProducts >= product.length) {
      setShowSeeMore(false); // Hide the 'See More' button when all products are shown
    } else {
      setShowSeeMore(true);
    }
  }, [visibleProducts, product]);

  const loadMoreProducts = () => {
    if (visibleProducts + 1 >= product.length) {
      setVisibleProducts(product.length); // Load all products if we reach the end
      setShowSeeMore(false);
    } else {
      setVisibleProducts(visibleProducts + 9); // Load the next set of 8 products
    }
  };


  return (
    <div className="featured-products">
      <div className="product-content">
        <div className="product-grid">
          {product?.slice(0, visibleProducts).map((product) => (
            <ProductsCard
              key={product.id}
              productData={product}
              onClick={onClick}
            />
          ))}
        </div>
        {/* <div className="see-more">
          <Button className="see-more-button" >
            See More
          </Button>
        </div> */}
        {showSeeMore && (
          <div className="see-more">
            <div className="see-more-button" onClick={loadMoreProducts}>
              See More
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductLists;
