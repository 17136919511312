import { Card } from "primereact/card";
import React from "react";

const PlaceAnOrder = () => {
  return (
    <div className="mt-6 container">
      <Card
        title={
          <span className="text-3xl uppercase text-[var(--color)] bg-transparent">
            Place an Order
          </span>
        }
        className="mb-4 md:mx-12 md:px-12 font-10 border-none shadow-none"
      >
        <p>To place an order online with Wine World,</p>
        <p>
          1. Register with a user account on our website. This can be done by
          clicking the “My Account” button, and then proceeding to “Create an
          Account”.
        </p>
        <p>
          2. By logging onto the site you are acknowledging that you are 21
          years and above, and therefore of legal age to shop for alcohol as per
          the Excise Law in Sri Lanka. ID verification will be requested at
          point of receipt.
        </p>
        <p>
          3. Browse the site and add items to your cart. You can view your cart
          by clicking on the “basket icon”
        </p>
        <p>4. Once the shopping is complete you can then proceed to checkout.</p>
        <p>
          5. As per the excise laws of Sri Lanka one order/ purchase cannot exceed
          below mentioned quotas for local or imported alcoholic beverages:
        </p>
        <p>
          6. Please note, that completing an order on wineworld.lk means that
          you have only engaged a ‘concierge service’ to procure for you certain
          products.
        </p>
        <div className="p-5 ">
          <ul className="list-disc list-inside">
            <li className="">
              Local Spirits – Order needs to be below 7.5 liters per order
            </li>
            <li className=" mt-2">
              Imported Wines & Spirits – Order needs to be below 80 liters per
              order
            </li>
          </ul>
        </div>
        <p>
          ***Wineworld.lk at no point has ownership of the goods and is
          exclusively performing concierge services . ***
        </p>
      </Card>
    </div>
  );
};

export default PlaceAnOrder;
