import React from 'react';

const CheckoutForm = () => {
  const servicePoster = 'path/to/your/image.jpg';

  return (
    <div className="overlay-content">
      {/* <div className="people">
        <img src={servicePoster} alt="Events" className="background-image" />
        <div className="content-overlay">
          <form method="post" action="https://sandbox.payhere.lk/pay/checkout">
            <input type="hidden" name="merchant_id" value="1228617" />
            <input type="hidden" name="return_url" value="https://caprconuat.xyz" />
            <input type="hidden" name="cancel_url" value="https://caprconuat.xyz" />
            <input type="hidden" name="notify_url" value="https://caprconuat.xyz" />

            <div className="form-section">
              <h3>Item Details</h3>
     
              <input type="text" name="currency" value="" />
              <input type="text" name="amount" value="" />
            </div>

            <div className="form-section">
              <h3>Customer Details</h3>
              <input type="text" name="first_name" value="" />
              <input type="text" name="last_name" value="" />
              <input type="text" name="email" value="" />
              <input type="text" name="phone" value="" />
              <input type="text" name="address" value="" />
              <input type="text" name="city" value="" />
              <input type="hidden" name="country" value="" />
              <input type="hidden" name="hash" value="" />
            </div>

            <input type="submit" value="Buy Now" className="submit-button" />
          </form>
        </div>
      </div> */}
    </div>
  );
};

export default CheckoutForm;