import React, { useEffect, useRef, useState } from 'react';
import './WeddingPage.css'; // Use a CSS file for styles
import { Button } from 'primereact/button';
import weddingImg1 from './../../Images/weddingImg1.png';
import weddingImg2 from './../../Images/weddingImg2.png';
import weddingImg3 from './../../Images/weddingImg3.png';
import seperaatorGold from './../../icons/seperatorGold.png'
import pinkcards1 from './../../Images/pink-cards1.png';
import pinkcards2 from './../../Images/pink-cards2.png';
import pinkcards3 from './../../Images/pink-cards3.png';
import BottleCalculator from '../../components/BottleCalculator';
import ContactForm from '../../components/ContactForm';
import { useNavigate } from 'react-router-dom';
import marbleBackground from './../../Images/marble_background.png';
import VerticalDemo from "../../components/Carousel/CoustomCarousel";

const WeddingPage = () => {

    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const slider = sliderRef.current;
        let animationFrame;
        // return;
        const startAnimation = () => {
            slider.scrollLeft += 0.5; // Adjust scroll increment for slower speed
            if (slider.scrollLeft >= slider.scrollWidth / 2) {
                slider.scrollLeft = 0; // Reset scroll to create seamless effect
            }
            animationFrame = requestAnimationFrame(startAnimation);
        };

        startAnimation();

        return () => cancelAnimationFrame(animationFrame);
    }, []);

    const sliderRef = useRef(null);

    const [images, setImages] = useState([
        { id: 1, src: weddingImg1 },
        { id: 2, src: weddingImg2 },
        { id: 3, src: weddingImg3 },
    ]);

    const imageList = [...images, ...images];

    return (

        <div className="px-1 md:px-[8%]  overflow-hidden mt-10 container">
            <div className="mx-[1.5%] ">
                <div className="px-5 flex flex-col items-center">
                    {/* <div className="mb-8 rounded-lg flex flex-row">

                        <div className="p-col-12 p-md-4 rounded-l-lg">
                            <img
                                src={weddingImg1}
                                alt="Bottle and glasses"
                                className="w-[500px] h-auto rounded-l-lg"
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <img
                                src={weddingImg2} // Replace with actual image link
                                alt="Table with wine"
                                className="w-[500px] h-auto"
                            />
                        </div>
                        <div className="p-col-12 p-md-4 rounded-r-lg">
                            <img
                                src={weddingImg3} // Replace with actual image link
                                alt="Cheers with bottles"
                                className="w-[500px] h-auto rounded-r-lg"
                            />
                        </div>
                    </div> */}

                    <div className="slider-container mt-0 mb-5 md:mx-[1%]" ref={sliderRef}>
                        <div className="slider-track ">
                            {imageList.map((image) => (
                                <img
                                    key={image.id + Math.random()}
                                    src={image.src}
                                    alt={`Slide ${image.id}`}
                                    className="w-[150px] h-auto flex-shrink-0 sm:w-[200px] md:w-[300px] lg:w-[436px]"
                                />
                            ))}
                            {imageList.map((image) => (
                                <img
                                    key={image.id + Math.random()}
                                    src={image.src}
                                    alt={`Slide ${image.id}`}
                                    className="w-[150px] h-auto flex-shrink-0 sm:w-[200px] md:w-[300px] lg:w-[436px]"
                                />
                            ))}
                            {imageList.map((image) => (
                                <img
                                    key={image.id + Math.random()}
                                    src={image.src}
                                    alt={`Slide ${image.id}`}
                                    className="w-[150px] h-auto flex-shrink-0 sm:w-[200px] md:w-[300px] lg:w-[436px]"
                                />
                            ))}
                        </div>
                    </div>

                    <div className="text-center">
                        <h2 className="section-heading text-[2rem] md:text-[2.5rem] text-[#b39c6a]  mb-4">Toast to
                            Unforgettable Memories</h2>
                        <h3 className="text-[1.1rem] md:text-[1.5rem] mx-12 md:mx-0 text-[#00897b] text-center mb-8">Elevate
                            Your Wedding With Luxurious Drinks</h3>
                    </div>

                    <img className="w-[290px]" src={seperaatorGold}></img>
                    <div className="p-text-center separator">
                        <i className=""></i>
                    </div>

                    <div className="description">
                        <p className={`mx-auto text-lg text-[var(--softText)] text-[1rem] md:text-[1.2rem] text-center leading-7 px-[6%] ${isExpanded ? 'max-h-full' : 'max-h-40'} overflow-hidden transition-all duration-500 ease-in-out`}>
                            Indulge in a wedding celebration filled with sophistication and elegance,
                            where every sip becomes a cherished memory. We take great pleasure in curating a
                            magnificent range of champagnes, wines, and exquisite spirits to elevate your wedding day.
                            With meticulous care, our dedicated team of experts ensures that each libation surpasses
                            expectations, leaving an indelible mark on your guests. From prestigious champagnes that
                            effervesce with opulence to refined wines and spirits that captivate the senses, our curated
                            selection sets the stage for unforgettable toasts and treasured moments. Raise a glass,
                            celebrate love, and embark on a journey of unparalleled taste as you create memories
                            that will be forever cherished.
                        </p>
                        <button className="block md:hidden mx-auto mt-4 text-blue-500"
                            onClick={handleToggle}> {isExpanded ? 'See Less' : 'See More'} </button>
                    </div>
                </div>

                {/* Bottle Calculator */}

                <BottleCalculator />

                <div className='hidden sm:flex flex-row justify-between'>
                    <div className='imageBox w-1/2'>
                        <img src={pinkcards1} className='imagerow_image' alt='' />
                    </div>
                    <div className='imageBox w-1/2'>
                        <img src={pinkcards2} className='imagerow_image' alt='' />
                    </div>
                    <div className='imageBox w-1/2'>
                        <img src={pinkcards3} className='imagerow_image' alt='' />
                    </div>
                </div>
                <div className="">
                    <VerticalDemo className="flex sm:hidden" Images={[pinkcards1, pinkcards2, pinkcards3]} />
                </div>
                <div className="mobile-margin mobile-padding">
                    {/* Contact form */}
                    <ContactForm type="wedding" />
                </div>
                <div className="mobile-margin">
                    <div
                        className="flex justify-center items-center mt-11 min-h-[60vh] mx-5 md:mx-0 bg-cover bg-center md:p-10 p-1 rounded-lg"
                        style={{ backgroundImage: `url(${marbleBackground})` }}>
                        <div className="p-5 text-center rounded-lg max-w-5xl">
                            <h1 className="text-[1.8rem] md:text-[2.2rem] text-[#b08d57] mb-5 font-semibold">FILL IN
                                YOUR
                                DETAILS AND WE WILL<br />REACH OUT TO YOU!</h1>
                            <p className="md:text-lg text-[1rem] text-gray-800 leading-loose mb-5 ">
                                Indulge in a wedding celebration filled with sophistication and elegance,
                                where every sip becomes a cherished memory. We take great pleasure in curating
                                a magnificent range of champagnes, wines, and exquisite spirits to elevate your
                                wedding day. With meticulous care, our dedicated team of experts ensures that
                                each libation surpasses expectations, leaving an indelible mark on your guests.
                                From prestigious champagnes that effervesce with opulence to refined wines and
                                spirits that captivate the senses, our curated selection sets the stage for
                                unforgettable toasts and treasured moments. Raise a glass, celebrate love,
                                and embark on a journey of unparalleled taste as you create memories that will
                                be forever cherished.
                            </p>
                            <Button onClick={() => navigate('/contact-us/')}
                                className="text-base py-2 px-5 rounded bg-transparent border border-[var(--green)]">
                                <span className="font-bold text-[var(--green)]">Contact Us</span> </Button></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeddingPage;