import React, { useEffect, useState } from "react";

import { PrimeReactProvider } from "primereact/api";
import "./App.css";
import Header from "./components/Header";

import Footer from "./components/Footer";
import { ScrollTop } from 'primereact/scrolltop';

import "primeicons/primeicons.css";
import { ProgressSpinner } from 'primereact/progressspinner';

import "primereact/resources/primereact.min.css"; // PrimeReact core CSS
import "primeicons/primeicons.css"; // PrimeIcons

import { ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import AllRoutes from "./routes/Routes";
import { useDispatch } from "react-redux";
import { setTheme } from "./features/themeSlice";
import { useLocation } from "react-router-dom";






const App = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    return localStorage.getItem("theme") === "dark";
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const themeLink = document.getElementById("theme-link");
    themeLink.href = isDarkTheme
      ? "https://unpkg.com/primereact/resources/themes/vela-orange/theme.css"
      : "https://unpkg.com/primereact/resources/themes/saga-orange/theme.css";

    localStorage.setItem("theme", isDarkTheme ? "dark" : "light");
  }, [isDarkTheme]);

  const toggleTheme = () => {
    setIsDarkTheme((prevTheme) => !prevTheme);
    dispatch(setTheme(isDarkTheme ? "light" : "dark"));
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);



  return (
    <>
      <ToastContainer />
      <PrimeReactProvider>

        <div className={isDarkTheme ? "dark-theme" : "light-theme"}
        // style={{ zoom: 0.9 }}
        >
          <Header isDarkTheme={isDarkTheme} toggleTheme={toggleTheme} />
          <ScrollTop className="bg-[var(--contactBtn)]" />
          <div className="md:h-[230px] h-[70px]"></div>

          <AllRoutes />


          <Footer />
        </div>

        <link
          id="theme-link"
          rel="stylesheet"
          href="https://unpkg.com/primereact/resources/themes/saga-orange/theme.css"
        />
      </PrimeReactProvider>
    </>
  );
};

export default App;
