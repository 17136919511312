import { apiSlice } from "./apiSlice";



export const eventOrderSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createEventOrder: builder.mutation({
            query: (formData) => ({
                url: "events",
                method: "POST",
                body: formData
            }),
        }),
       
    })
})

export const { useCreateEventOrderMutation } = eventOrderSlice