import { createSlice } from '@reduxjs/toolkit';
import CeylonArrack from './../icons/Ceylon-arrack.png';
import ColomboNo7 from './../icons/Colombo-No-7.png';
import Glenfiddich from './../icons/Glenfiddich.png';
import Monkey_shoulder from './../icons/Monkey-shoulder.png';
import Remy_martin from './../icons/Remy-martin.png';
import Rockland from './../icons/Rockland.png';
import BalvenieWhisky from './../icons/BalvenieWhisky.png';
import ClanMacGregor from './../icons/ClanMacGregor.png';
import Grants from './../icons/Grants.png';
import Spirits from './../Images/Spirits.png';
import Wine from './../Images/Wine.png';
import Sparkling from './../Images/Sparkling.png';
import Beer from './../Images/Beer.png';
import Backgr1 from './../Images/background1.png';
import Backgr2 from './../Images/background2.png';
import Backgr3 from './../Images/background3.png';
import Monkey_ShoulderBottle from './../Images/Monkey_Shoulder.png';
import GlenfiddichBottle from './../Images/Glenfiddich.png';
import JimBottle from './../Images/jim.png';
import ClanBottle from './../Images/clan.png';


const initialState = {
      UserDetails: "lakith",
      BrandImages: [
            { src: CeylonArrack, alt: 'Ceylon Arrack' },
            { src: ColomboNo7, alt: 'Colombo No 7' },
            { src: Rockland, alt: 'Colombo No 7' },
            { src: Glenfiddich, alt: 'Rockland' },
            { src: Monkey_shoulder, alt: 'Glenfiddich' },
            { src: Remy_martin, alt: 'Monkey Shoulder' },
            { src: CeylonArrack, alt: 'Ceylon Arrack' },
            { src: ColomboNo7, alt: 'Colombo No 7' },
            { src: Rockland, alt: 'Colombo No 7' },
            { src: Glenfiddich, alt: 'Rockland' },
            { src: Monkey_shoulder, alt: 'Glenfiddich' },
            { src: Remy_martin, alt: 'Monkey Shoulder' },
            { src: BalvenieWhisky, alt: 'Monkey Shoulder' },
            { src: ClanMacGregor, alt: 'Monkey Shoulder' },
            { src: Grants, alt: 'Monkey Shoulder' },
      ],
      CollectionList: [
            { src: Spirits, alt: 'Spirits', navigation: 'product-category/2' },
            { src: Wine, alt: 'Wine', navigation: 'product-category/1' },
            { src: Sparkling, alt: 'Sparkling', navigation: 'product-category/1' },
            { src: Beer, alt: 'Beer', navigation: 'product-category/3' },
      ],
      HeroImageList: [
            {
                  image: Backgr1,
                  alignment: "right",
                  visible: true,
            },
            {
                  image: Backgr2,
                  alignment: "right",
                  visible: false,
            },
            {
                  image: Backgr3,
                  alignment: "left",
                  visible: true,
            },
      ],
      Bestsellers: [
            {
                  src: Monkey_ShoulderBottle,
                  name: 'Monkey Shoulder 700ml',
                  category: "Blended",
                  newArrival: true,
                  price: 21900
            },
            {
                  src: GlenfiddichBottle,
                  name: 'Glenfiddich IPA 700ml',
                  category: "Single Malt",
                  newArrival: false,
                  price: 44700
            },
            {
                  src: JimBottle,
                  name: 'Jim Beam White Label 700ml',
                  category: "Whisky",
                  newArrival: false,
                  price: 15500
            },
            {
                  src: ClanBottle,
                  name: 'Clan Macgregor 750ml',
                  category: "Whisky",
                  newArrival: true,
                  price: 12800
            },
            {
                  src: GlenfiddichBottle,
                  name: 'Glenfiddich IPA 700ml',
                  category: "Single Malt",
                  newArrival: false,
                  price: 44700
            },
            {
                  src: JimBottle,
                  name: 'Jim Beam White Label 700ml',
                  category: "Whisky",
                  newArrival: false,
                  price: 15500
            },
            {
                  src: ClanBottle,
                  name: 'Clan Macgregor 750ml',
                  category: "Whisky",
                  newArrival: false,
                  price: 12800
            },
            {
                  src: Monkey_ShoulderBottle,
                  name: 'Monkey Shoulder 700ml',
                  category: "Blended",
                  newArrival: false,
                  price: 21900
            },
            {
                  src: GlenfiddichBottle,
                  name: 'Glenfiddich IPA 700ml',
                  category: "Single Malt",
                  newArrival: false,
                  price: 44700
            },
            {
                  src: JimBottle,
                  name: 'Jim Beam White Label 700ml',
                  category: "Whisky",
                  newArrival: true,
                  price: 15500
            },
            {
                  src: ClanBottle,
                  name: 'Clan Macgregor 750ml',
                  category: "Whisky",
                  newArrival: false,
                  price: 12800
            },
            {
                  src: GlenfiddichBottle,
                  name: 'Glenfiddich IPA 700ml',
                  category: "Single Malt",
                  newArrival: false,
                  price: 44700
            },
            {
                  src: JimBottle,
                  name: 'Jim Beam White Label 700ml',
                  category: "Whisky",
                  newArrival: false,
                  price: 15500
            },
            {
                  src: ClanBottle,
                  name: 'Clan Macgregor 750ml',
                  category: "Whisky",
                  price: 12800
            },
      ],
      RecommendedProducts: [
            {
                  src: GlenfiddichBottle,
                  name: 'Glenfiddich IPA 700ml',
                  category: "Single Malt",
                  price: 44700
            },
            {
                  src: JimBottle,
                  name: 'Jim Beam White Label 700ml',
                  category: "Whisky",
                  price: 15500
            }
      ],
      NewAdditions: [
            {
                  src: Monkey_ShoulderBottle,
                  name: 'Monkey Shoulder 700ml',
                  category: "Blended",
                  price: 21900
            },
            {
                  src: ClanBottle,
                  name: 'Clan Macgregor 750ml',
                  category: "Whisky",
                  price: 12800
            },
      ]

}

export const HomepageSlice = createSlice({
      name: 'HomepageSlice',
      initialState,
      reducers: {
            SetUserDetails: (state, action) => {
                  state.UserDetails = action.payload
            },
            GetBrandImages: (state, action) => {
                  state.BrandImages = action.payload
            },
            GetCollectionList: (state, action) => {
                  state.CollectionList = action.payload
            },
            GetHeroImageList: (state, action) => {
                  state.HeroImageList = action.payload
            },
            GetBestsellers: (state, action) => {
                  state.Bestsellers = action.payload
            },
            GetRecommendedProducts: (state, action) => {
                  state.RecommendedProducts = action.payload
            },
            GetNewAdditions: (state, action) => {
                  state.NewAdditions = action.payload
            },

      }
});

export const { SetUserDetails, GetBestsellers, GetRecommendedProducts, GetNewAdditions, GetBrandImages, GetCollectionList, GetHeroImageList } = HomepageSlice.actions

export default HomepageSlice.reducer