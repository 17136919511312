
import { Card } from "primereact/card";
import { Link } from "react-router-dom";

const ConciergePolicy = () => {
  return (
    <div className="mt-6 container">
      <Card
        title={
          <span className="text-3xl uppercase text-[var(--color)] bg-transparent">
            Concierge Policy
          </span>
        }
        className="mb-4 md:mx-12 md:px-12 font-10 border-none shadow-none"
      >
        <p>To place an order online with Wine World,</p>
        <p>
          1. Wineworld.lk concierge service ships to your doorstep and <span className="font-semibold">our services are strictly for those of age 21 years/ above.</span>
        </p>
        <p>
          2. Concierge charges are calculated based on the location and the weight of order.
        </p>
        <p>
          3. Colombo 1-15 & Colombo Suburbs, concierge services will be completed within 2 to 4 working days
          (excluding Sunday and excise holidays). And Colombo other within 5-7 working days (excluding Sunday and
          excise holidays)
        </p>
        <p>
          4. Please do stay alert and answer the phone calls by the concierge during the expected dates of delivery
          for successful & timely completion. In the instance the concierge’s calls are missed, the completetion of
          the service will be moved on to the next availble slot. (Extra charges may apply)
        </p>
        <p>
          5. Concierge services shall only be performed within excise working hours 09.00 a.m. to 09.00 p.m. every
          working day. Orders will not be released on Sri Lankan excise holidays.
        </p>
        <p>
          6. As per the excise laws of Sri Lanka one order/ purchase cannot exceed below mentioned quotas for local or
          imported alcoholic beverages:
        </p>
        <div className="p-5 ">
          <ul className="list-disc list-inside">
            <li className="">
              Local Spirits – Order needs to be below 7.5 liters per order
            </li>
            <li className=" mt-2">
              Imported Wines & Spirits – Order needs to be below 80 liters per order
            </li>
          </ul>
        </div>
        <p>
          7. ID verification must be produced at the point of recepit.
        </p>
        <p>
          8. Please note that above mentioned timelines can vary in the situation of an emergency/crisis that maybe
          outside the control of WineWorld.lk.
        </p>
        <p>
          9. Always check that the received items are as per your order requirement at the point of recepit, for more
          information on the importance of this step read our <Link to={"/returns-and-refunds"}
            className="font-semibold">Returns & Refunds
            Policy.</Link>
        </p>

      </Card>
    </div>
  )
}

export default ConciergePolicy