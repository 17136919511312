import React, { useEffect, useState } from "react";
import "./CheckoutListItem.css";
import { Button } from "primereact/button";
import Monkey_ShoulderBottle from "./../Images/Monkey_Shoulder.png";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { useSelector } from "react-redux";
import {
  useDeleteCartItemMutation,
  useGetCartItemsQuery,
} from "../redux/service/cartSlice";
import { toast } from "material-react-toastify";

const CheckoutListItem = ({
  name,
  price,
  category,
  image,
  quantity,
  itemId,
}) => {
  const navigate = useNavigate(); // Initialize the navigate hook
  const currency = useSelector((state) => state?.meta?.currency);
  const userInfo = useSelector((state) => state?.auth?.userInfo);

  const [deleteItem, { isLoading: deleteItemLoading }] =
    useDeleteCartItemMutation();
  const cityId = localStorage.getItem("city");
  const { data: cartData, refetch } = useGetCartItemsQuery({
    userId: userInfo?.id,
    cartType: "normal",
  });

  const DeleteItems = async ({ itemId }) => {
    // console.log("Attempting to delete item", itemId);

    await deleteItem({ itemId, cityId, userId: userInfo?.id })
      .unwrap()
      .then(async (res) => {
        // console.log("Successfully deleted item");
        await refetch();
        cartData?.data?.cartItems.length === 1 && navigate("/");
      })
      .catch((err) => {
        toast.error(`${err.data.message}`);
        console.log(err);
      });
  };

  return (
    // <div className="border border-gray-500 rounded-lg p-4 mb-3 md:flex-row md:flex md:justify-between">
    //   <div className="grid grid-cols-4">
    //     {/* Product Image */}
    //     <div className="image-container md:w-32 items-center   ">

    //       <img
    //         src={image}
    //         alt="Monkey Shoulder"
    //         className="h-24"
    //       />
    //     </div>

    //     {/* Product Details */}
    //     <div className="col-span-3 bg-red-200">
    //       <span className="bottle-name block">{name}</span>
    //       <div className="product-price">{currency} {price} X {quantity}</div>
    //       <div className="product-type">{category}</div>
    //     </div>
    //   </div>

    //   <div className="card-right">
    //     <button
    //       onClick={() => DeleteItems({ itemId: itemId })}
    //       className="side-delete-btn"
    //     >
    //       &#128465;
    //     </button>
    //   </div>
    // </div>
    <div className=" border border-gray-500 rounded-lg px-4 mb-3 md:py-2  flex  items-center justify-evenly">
      {/* Product Image */}
      <div className="image-container  mb-4 md:mb-0 ">
        <img src={image} alt="Image" className="h-28 w-32 object-contain" />
      </div>

      {/* Product Details */}
      <div className="flex-1  md:mx-3 ml-2 md:py-2 ">
        <span className="bottle-name block my-0">{name}</span>
        <div className="product-price my-0">
          {currency} {price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} X{" "}
          {quantity}
        </div>
        <div className="product-type my-0">{category}</div>
      </div>

      {/* Delete Button */}
      <div className="mt-4 md:mt-0 md:mx-3">
        <button
          onClick={() => DeleteItems({ itemId: itemId })}
          className={`text-red-500 text-3xl  ${
            deleteItemLoading && "cursor-wait"
          }`}
        >
          &#128465;
        </button>
      </div>
    </div>
  );
};

export default CheckoutListItem;
