import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { useGetProductHierarchyQuery } from "../redux/service/productSlice";
import { useNavigate } from "react-router-dom";

const Search = ({ isModalVisible, setIsSearchVisible, isDarkTheme }) => {
  const dispatch = useDispatch();
  const cityId = localStorage.getItem("city");
  const navigate = useNavigate(); // Initialize the navigate hook

  const { data: hierarchyData, refetch: hierarchyReFetch, isLoading, isError } = useGetProductHierarchyQuery({
    cityId: cityId, // Assuming `userInfo?.cityId` is the cityId parameter
  });

  // Initialize search query and filtered items
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  function navigator(prod, dep, cat) {
    if (prod) {
      console.log("item?.prod", prod);
      setIsSearchVisible(false);
      navigate(`/product/${prod}`);
    }
    else if (cat) {
      setIsSearchVisible(false);
      navigate(`/product-category/${dep}/${cat}`);
    }

    else if (dep) {
      setIsSearchVisible(false);
      navigate("/product-category/" + dep);
    } else {
      console.error("No valid navigation target provided.");
    }
  }


  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = hierarchyData
      ?.filter((item) => item.srch.toLowerCase().includes(query))
      ?.sort((a, b) => {
        if (!a.prod && b.prod) return -1; // a goes first
        if (a.prod && !b.prod) return 1; // b goes first
        return 0; // maintain order otherwise
      });
    setFilteredItems(filtered);
  };

  useEffect(() => {
    if (hierarchyData) {
      // Sort items based on the presence of dep, cat, and prod
      const sorted = [...hierarchyData].sort((a, b) => {
        const getPriority = (item) => {
          if (item.dep && !item.cat && !item.prod) return 0; // Only dep
          if (item.dep && item.cat && !item.prod) return 1; // dep and cat
          if (item.dep && item.cat && item.prod) return 2; // dep, cat, and prod
          return 3; // Fallback for items with missing `dep`
        };

        return getPriority(a) - getPriority(b); // Sort by priority
      });

      setFilteredItems(sorted); // Set sorted items
    }
  }, [hierarchyData]);


  if (isLoading) {
    return (
      <Dialog
        visible={isModalVisible}
        footer={null}
        closable={true}
        position="top"
        className="custom-Searchdialog"
        onHide={() => setIsSearchVisible(false)}
        breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
        style={{
          width: "40vw",
          height: "60vh", // Set fixed height
          maxHeight: "60vh", // Prevent modal from growing beyond this
        }}
        header={
          <h2 className={'text-3xl pb-0 mt-5 mb-0 font-bold text-green-900'}>
            Search for Items
          </h2>
        }
      >
        <div className="p-5 h-full">Loading...</div>
      </Dialog>
    );
  }

  if (isError) {
    return (
      <Dialog
        visible={isModalVisible}
        footer={null}
        closable={true}
        position="top"
        className="custom-Searchdialog"
        onHide={() => setIsSearchVisible(false)}
        breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
        style={{
          width: "40vw",
          height: "60vh", // Set fixed height
          maxHeight: "60vh", // Prevent modal from growing beyond this
        }}
        header={
          <h2 className={'text-3xl pb-0 mt-5 mb-0 font-bold text-green-900'}>
            Search for Items
          </h2>
        }
      >
        <div className="p-5 h-full">Error loading data.</div>
      </Dialog>
    );
  }

  return (
    <Dialog
      visible={isModalVisible}
      footer={null}
      closable={true}
      position="bottom-right"
      className="custom-Searchdialog "
      onHide={() => { setIsSearchVisible(false); setSearchQuery(""); }}
      breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
      style={{
        width: "40vw",
        height: "100vh", // Set fixed height
        // maxHeight: "80vh", // Prevent modal from growing beyond this
      }}
      header={
        <h2 className={'text-3xl pb-0 mt-5 mb-0 font-bold text-green-900'}>
          Search for Items
        </h2>
      }
    >
      <div className="p-5 h-full overflow-hidden">
        {/* Search Input */}
        <div
          className="mb-4 sticky top-0 z-10 bg-white py-4"
          style={{
            backgroundColor: isDarkTheme ? "#1f2937" : "#ffffff", // Adjust for dark/light themes
          }}
        >
          <InputText
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search items..."
            className="w-full p-inputtext-sm border-2 border-[var(-color)] p-3"
          />
        </div>

        {/* Filtered List */}
        <div className="overflow-y-auto h-[calc(100%-60px)]  gap-4 pr-2">
          {filteredItems?.length > 0 ? (
            filteredItems?.map((item, index) => (
              <div
                onClick={() => navigator(item?.prod, item?.dep, item?.cat)}
                key={index}
                className={`p-3 pl-3 max-h-28 mb-2 border border-transparent rounded-xl hover:border-green-950 border-2 cursor-pointer ${isDarkTheme
                  ? "bg-gray-800 text-white hover:bg-gray-700"
                  : "text-gray-800"
                  }`}
              >
                <div className="flex items-center ">
                  {/* Display the image if available */}
                  {item.imageLink && (
                    <img
                      src={item.imageLink}
                      alt={item.str}
                      className="w-12 h-20 mr-4 object-  rounded"
                    />
                  )}
                  <h4 className="font-semibold text-lg">{item.str}</h4>
                </div>
              </div>
            ))
          ) : (
            <div
              className={`p-4 rounded shadow-md text-center ${isDarkTheme ? "bg-gray-800 text-white" : "bg-gray-100 text-gray-500"
                }`}
            >
              No items found.
            </div>
          )}
        </div>
      </div>
    </Dialog >
  );
};

export default Search;
