import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { useSearchProductQuery } from "../redux/service/productSlice";
import { toast } from "material-react-toastify";
import { useAddCartItemMutation } from "../redux/service/cartSlice";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useCreateEventOrderMutation } from "../redux/service/eventOrder";
import { Dialog } from "primereact/dialog";

const ContactForm = ({ type }) => {
  const [productAndQty, setProductAndQty] = useState([]);
  const cityId = localStorage.getItem("city");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [product, setProduct] = useState([]);
  const currency = useSelector((state) => state?.meta?.currency);

  const [createEventOrder, { isLoading: createEventOrderLoading }] =
    useCreateEventOrderMutation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces")
      .max(50, "Name must not exceed 50 characters")
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .max(254, "Email must not exceed 254 characters")
      .min(3, "Email must be at least 3 characters")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
    date: Yup.string().required("Date is required"),
    phone: Yup.string()
      .required("Phone Number is required").min(10, "Phone Number must be at least 10 characters")
      .test("is-valid-length", "Invalid phone number", (value) => {
        if (!value) return false; // Required field check
        const countryCode = value.slice(0, 3); // First 3 characters for country code
        if (countryCode === "+94") {
          // Sri Lanka (+94) numbers should be 12 characters
          return value.length === 12;
        }
        // Other countries (default to max 15 characters)
        return value.length <= 15;
      }),
  });

  const {
    data,
    isLoading: productsLoading,
    refetch,
  } = useSearchProductQuery(
    {
      page: 0,
      size: 1000,
      cityId,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (data?.content) {
      // console.log("info.regularPriceInDollar", data?.content);
      const transformedProducts = data?.content.map((info) => ({
        id: info.id,
        name: info.correctName,
        price: info.regularPriceInRupee,
        priceUSD: info.regularPriceInDollar,
        // price: info.regularPriceInRupee,
        img: info.imageUrl,
      }));
      setProduct(transformedProducts);
    }
    // console.log("info.regularPriceInDollar", info.regularPriceInDollar);
  }, [data]);

  const sessionID = useSelector((state) => state?.auth?.sessionId);
  const userId = useSelector((state) => state?.auth?.userInfo?.id);

  const [addToCart, { isLoading }] = useAddCartItemMutation();

  // const handleAddToCart = async (id) => {
  //   try {
  //     await addToCart({
  //       userId: userId || null,
  //       itemId: id,
  //       cartType: "wedding",
  //       quantity: 1,
  //       sessionId: sessionID,
  //       cityId: localStorage.getItem("city"),
  //     }).unwrap();
  //     toast.success("Product added to cart");
  //   } catch (err) {
  //     if (err.status === 404) {
  //       console.log(err);
  //       toast.error(err.data.message || "Error adding product to cart");
  //     } else {
  //       toast.error("Error adding product to cart");
  //     }
  //   }
  // };

  const handleProductChange = (e) => {
    const selectedProduct = e.value;
    const isProductExists = productAndQty.some(
      (item) => item.id === selectedProduct.id
    );

    // handleAddToCart(selectedProduct.id);

    if (!isProductExists) {
      setProductAndQty((prevState) => [
        ...prevState,
        {
          id: selectedProduct.id,
          name: selectedProduct.name,
          price: selectedProduct.price,
          priceUSD: selectedProduct.priceUSD,
          img: selectedProduct.img,
          quantity: 1, // Default quantity as 1
        },
      ]);
    } else {
      toast.warning("Product already added");
    }
  };

  const handleQuantityChange = (id, increment) => {
    setProductAndQty((prevState) =>
      prevState.map((item) =>
        item.id === id
          ? { ...item, quantity: Math.max(0, (item.quantity || 0) + increment) }
          : item
      )
    );
  };

  const handleRemoveProduct = (id) => {
    setProductAndQty((prevState) => prevState.filter((item) => item.id !== id));
  };

  const handleSubmit = async (e) => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      await createEventOrder({
        ...formData,
        eventOrderType: type,
        items: productAndQty.map((item) => ({
          productId: item.id,
          quantity: item.quantity,
          productName: item.name,
        })),
      })
        .then((res) => {
          console.log(res);
          toast.success("message sent successfully");
          setFormData({
            name: "",
            email: "",
            message: "",
            date: "",
            phone: "",
          });
          setProductAndQty([]);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.data.message || "Something went wrong");
        });
      setFormErrors({});

      const transformedProducts = productAndQty.map((item) => ({
        productId: item.id,
        quantity: item.quantity,
        productName: item.name,
      }));

      // console.log({ ...formData, products: transformedProducts });
    } catch (error) {
      if (error.inner) {
        const errorMessages = {};
        error.inner.forEach((validationError) => {
          errorMessages[validationError.path] = validationError.message;
        });
        setFormErrors(errorMessages);
      }
    }
  };

  const truncateOptions = (options) => {
    return options.map((option) => ({
      ...option,
      name:
        option.name.length > 25
          ? `${option.name.slice(0, 20)}...`
          : option.name,
    }));
  };

  const truncatedProducts = truncateOptions(product);

  return (
    <div className="md:p-10 p-3 mt-10 rounded-lg border border-gray-300">
      <h2 className="mt-0 text-[var(--color)]">Contact Form</h2>

      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex-[0.45] mb-6 md:mb-0">
          <div className="p-fluid">
            <div className="mb-2.5">
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                onChange={async (e) => {
                  setFormData({ ...formData, name: e.target.value });
                  try {
                    await validationSchema.validateAt("name", { name: e.target.value });
                    setFormErrors((prev) => ({ ...prev, name: "" }));
                  } catch (error) {
                    setFormErrors((prev) => ({ ...prev, name: error.message }));
                  }
                }}
                placeholder="Name"
                value={formData.name}
                className="border border-gray-300 rounded h-12 pl-3 text-[var(--softText)]"
              />
              {formErrors.name && (
                <small className="error-text">{formErrors.name}</small>
              )}
            </div>
            <div className="mb-2.5">
              <label htmlFor="email">Email Address</label>
              <InputText
                id="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={async (e) => {
                  setFormData({ ...formData, email: e.target.value });
                  try {
                    await validationSchema.validateAt("email", { email: e.target.value });
                    setFormErrors((prev) => ({ ...prev, email: "" }));

                  } catch (error) {
                    setFormErrors((prev) => ({ ...prev, email: error.message }));
                  }
                }}
                className="border border-gray-300 rounded h-12 pl-3 text-[var(--softText)]"
              />
              {formErrors.email && (
                <small className="error-text">{formErrors.email}</small>
              )}
            </div>
            <div className="mb-2.5">
              <label htmlFor="phone">Phone Number</label>
              <PhoneInput
                id="phone"
                value={formData.phone}
                onChange={async (e) => {
                  setFormData({ ...formData, phone: e });
                  try {
                    await validationSchema.validateAt("phone", { phone: e });
                    setFormErrors((prev) => ({ ...prev, phone: "" }));

                  } catch (error) {
                    setFormErrors((prev) => ({ ...prev, phone: error.message }));
                  }
                }}
                defaultCountry="LK"
                className="border bg-red-300 border-gray-300 rounded-md p-2 w-full outline-none focus:ring-2 focus:ring-blue-500"
              />
              {formErrors.phone && (
                <small className="error-text">{formErrors.phone}</small>
              )}
            </div>

            <div className="mb-2.5">
              <label htmlFor="date">Date</label>
              <Calendar
                id="date"
                dateFormat="dd/mm/yy"
                value={formData.date}
                style={{ outline: "none" }}
                placeholder="DD/MM/YYYY"
                minDate={new Date()}
                onChange={async (e) => {
                  setFormData({ ...formData, date: e.target.value });
                  try {
                    await validationSchema.validateAt("date", { date: e.target.value });
                    setFormErrors((prev) => ({ ...prev, date: "" }));

                  } catch (error) {
                    setFormErrors((prev) => ({ ...prev, date: error.message }));
                  }
                }}
                className="border border-gray-300 rounded h-12 pl-3 text-[var(--softText)] outline-none"
              />
              {formErrors.date && (
                <small className="error-text">{formErrors.date}</small>
              )}
            </div>

            <div className="mb-2.5">
              <label htmlFor="message">Message</label>
              <InputTextarea
                id="message"
                value={formData.message}
                rows={4}
                onChange={async (e) => {
                  setFormData({ ...formData, message: e.target.value });
                  setFormErrors((prev) => ({ ...prev, message: "" }));
                }}
                placeholder="Write a message"
                className="border border-gray-300 rounded pl-3 pt-2 text-[var(--softText)]"
              />
              {formErrors.message && (
                <small className="error-text">{formErrors.message}</small>
              )}
            </div>
          </div>
        </div>
        <div className="flex-[0.45]">
          <div className="mb-2.5 text-[var(--softText)]">
            <label>Select Products</label>
            <Dropdown
              value={null}
              options={truncatedProducts}
              onChange={handleProductChange}
              optionLabel="name"
              placeholder="Select products"
              className="border border-gray-300 rounded h-12 text-[var(--softText)]"
              filter
            />
          </div>
          <div className="mt-4 max-h-[300px] overflow-y-auto">
            {productAndQty.map((item) => (
              <div
                key={item.id}
                className="flex items-center justify-between p-3 border border-gray-300 rounded-lg mb-2"
              >
                <img
                  src={item.img}
                  alt="Product"
                  className="w-12 h-auto object-cover mr-4"
                />
                <div className="product-details text-[var(--softText)]">
                  <h4 className="m-0 text-lg">{item.name}</h4>
                  {/* <p>{item?.price * item?.quantity}</p> */}
                  {/* <p>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: currency || "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(item?.price * item?.quantity)}
                  </p> */}
                  <p>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: currency || "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      ((currency === "LKR" ? item?.price : item?.priceUSD) ||
                        0) * (item?.quantity || 0)
                    )}
                  </p>
                </div>
                <div className="flex items-center">
                  <Button
                    icon="pi pi-minus"
                    className="p-button-rounded p-button-text"
                    onClick={() => {
                      if (item.quantity > 1) {
                        handleQuantityChange(item.id, -1);
                      }
                    }}
                    disabled={item.quantity <= 0}
                  />
                  <span className="mx-4 text-base">{item.quantity}</span>
                  <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-text"
                    onClick={() => handleQuantityChange(item.id, 1)}
                  />
                </div>
                <Button
                  icon="pi pi-trash"
                  className="p-button-danger p-button-text"
                  onClick={() => handleRemoveProduct(item.id)}
                />
              </div>
            ))}
          </div>

          <ConfirmModal
            handleSubmit={handleSubmit}
            formData={formData}
            validationSchema={validationSchema}
            createEventOrderLoading={createEventOrderLoading}
            setFormErrors={setFormErrors}
          >
            <Button
              label="Submit"
              loading={createEventOrderLoading}
              className="mt-3 bg-green-600 border-none text-white py-2 px-6 text-base rounded cursor-pointer hover:bg-green-700"
            />
          </ConfirmModal>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

const ConfirmModal = ({
  children,
  handleSubmit,
  createEventOrderLoading,
  formData,
  validationSchema,
  setFormErrors,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <button
        onClick={async () => {
          try {
            await validationSchema.validate(formData, { abortEarly: false });

            setVisible(true);
          } catch (error) {
            const errorMessages = {};
            error.inner.forEach((validationError) => {
              errorMessages[validationError.path] = validationError.message;
            });
            setFormErrors(errorMessages);
          }
        }}
      >
        {children}
      </button>
      <Dialog
        header="Confirmation"
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        className="md:min-w-[500px] "
      >
        <div className="px-6 pb-4">
          <p>Are you sure you want to submit the form?</p>
          <div className="flex justify-end gap-3">
            <Button
              label="Cancel"
              onClick={() => {
                setVisible(false);
              }}
              className="mr-2"
            />
            <Button
              label="Submit"
              isLoading={createEventOrderLoading}
              onClick={() => {
                handleSubmit();
                setVisible(false);
              }}
              className="bg-green-600 border-none text-white py-2 px-6 text-base rounded cursor-pointer hover:bg-green-700"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
