//  CorporatesPage

// src/pages/HomePage.js
import React, { useEffect, useState } from "react";
import "./ServicesPage.css"; // Use a CSS file for styles
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { Button } from "primereact/button";
// import 'primeflex/primeflex.css';
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import TypeOneEventCard from "../../components/TypeOneEventCard";
import TypeTwoEventCard from "../../components/TypeTwoEventCard";

import servicePoster from "./../../Images/ServicePoster.png";
import service1 from "./../../Images/services/Concierge.png";
import service2 from "./../../Images/services/Bar-service.png";
import service3 from "./../../Images/services/Warehouse.png";
import service4 from "./../../Images/services/RetailStrength.png";
import service5 from "./../../Images/services/OtherService.png";
import service6 from "./../../Images/services/Hampers.png";

const ServicesPage = () => {
  const navigate = useNavigate(); // Initialize the navigate hook

  const services = [
    {
      id: 1,
      title: "Concierge Services",
      description:
        "Flexibility is at the core of our service, ensuring that all your organization’s procurement and purchasing requirements are met with meticulous precision. Your corporate event’s success is our priority, and we will go above and beyond to prioritize your convenience and exceed your expectations.",
      image: service1,
      buttonLabel: "Contact Us",
    },
    {
      id: 2,
      title: "Bar Services",
      description:
        "In addition to our concierge services, we provide a full bar service for  corporate and social functions, offering expert advice and creative  solutions to diverse requirements. Over the years we have been  privileged to partner with events hosted by RedBull, HSBC, Colombo  Fashion Week, and many more.",
      image: service2,
      buttonLabel: "Contact Us",
    },
    {
      id: 3,
      title: "Warehouse Facilities",
      description:
        "Wine World is amongst the few companies to operate a fully licensed  bonded warehouse; enabling the company to supply our duty paid  customers/channels. Our temperature- controlled rooms for storage of  wine and other temperature sensitive brands enable us to maintain its  quality.",
      image: service3,
      buttonLabel: "Contact Us",
    },
    {
      id: 4,
      title: "Retail Strength",
      description:
        "Flexibility is at the core of our service, ensuring that all your  organization's procurement and purchasing requirements are met with  meticulous precision. Your corporate event's success is our priority,  and we will go above and beyond to prioritize your convenience and  exceed your expectations.",
      image: service4,
      buttonLabel: "Contact Us",
    },
    {
      id: 5,
      title: "Other Services",
      description:
        "We curate premium spirits and wine evenings conducted by local and international sommeliers.",
      image: service5,
      buttonLabel: "Contact Us",
    },
    {
      id: 6,
      title: "Hampers & Gifting Solutions",
      description:
        "Leave it to our team to customize your order to suit your requirement.  We provide tailor made corporate hampers for all occasions with no  hidden charges.",
      image: service6,
      buttonLabel: "Contact Us",
    },
  ];

  return (
      <div className="px-3  md:px-8 mt-10 container">
          {/* <div className="overlay-content ">
                <div className="people">
                    <img
                        src={servicePoster}
                        alt="Events"
                        className="background-image"
                    />
                    <div className="content-overlay ">
                        <div style={{
                            bottom: '10px',
                            alignItems: 'center',
                            width: '79.8%',
                            textAlign: 'center' // Add this to center the content
                        }}>
                            <div className="arrow-icon ">
                                <div className="arrow-icon circle-container " >
                                    <i className="pi pi-arrow-down icon-theme" ></i>
                                </div>
                                <h3 className="service-title" style={{ marginTop: '10px' }}>EVENTS AND SERVICES</h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}

          <div>
              <div className="hidden md:block ">
                  <img
                      src={servicePoster}
                      alt="Events"
                      className="background-image-web rounded-lg rounded-r-xl  w-11/12 h-[calc(100vh-50px)] md:max-h-96 object-cover"
                  />
                  <div className="image-overlay absolute inset-0 flex justify-center items-center">
                      <div className="arrow-icon">
                          <div
                              className="arrow-icon circle-container cursor-pointer"
                              onClick={() => {
                                  const servicesSection = document.getElementById("services");
                                  if (servicesSection) {
                                      servicesSection.scrollIntoView({behavior: "smooth"});
                                  }
                              }}
                          >
                              <i className="pi pi-arrow-down icon-theme"></i>
                          </div>
                          <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl mt-5 text-center text-on-image-color">
                              EVENTS AND SERVICES
                          </h3>
                      </div>
                  </div>
              </div>
          </div>


          <div id="services" className="hidden md:block" style={{}}>
              {/* Type 1 */}
              <TypeOneEventCard
                  Title={"Concierge Services"}
                  Description={
                      "Flexibility is at the core of our service, ensuring that all your  organization's procurement and purchasing requirements are met with  meticulous precision. Your corporate event's success is our priority,  and we will go above and beyond to prioritize your convenience and  exceed your expectations."
                  }
                  onClick={() => {
                      navigate("/contact-us/");
                      window.scrollTo(0, 0);
                  }}
                  image={service1}
              />

              {/* Type 2 */}

              <TypeTwoEventCard
                  Title={"Bar Services"}
                  Description={
                      "In addition to our concierge services, we provide a full bar service for  corporate and social functions, offering expert advice and creative  solutions to diverse requirements. Over the years we have been  privileged to partner with events hosted by RedBull, HSBC, Colombo  Fashion Week, and many more."
                  }
                  onClick={() => {
                      navigate("/contact-us/");
                      window.scrollTo(0, 0);
                  }}
                  image={service2}
              />

              {/* Type 1 */}
              <TypeOneEventCard
                  Title={"Warehouse Facilities"}
                  Description={
                      "Wine World is amongst the few companies to operate a fully licensed  bonded warehouse; enabling the company to supply our duty paid  customers/channels. Our temperature- controlled rooms for storage of  wine and other temperature sensitive brands enable us to maintain its  quality."
                  }
                  onClick={() => {
                      navigate("/contact-us/");
                      window.scrollTo(0, 0);
                  }}
                  image={service3}
              />

              {/* Type 2 */}

              <TypeTwoEventCard
                  Title={"Retail Strength"}
                  Description={
                      "Flexibility is at the core of our service, ensuring that all your  organization's procurement and purchasing requirements are met with  meticulous precision. Your corporate event's success is our priority,  and we will go above and beyond to prioritize your convenience and  exceed your expectations."
                  }
                  onClick={() => {
                      navigate("/contact-us/");
                      window.scrollTo(0, 0);
                  }}
                  image={service4}
              />

              {/* Type 1 */}
              <TypeOneEventCard
                  Title={"Other Services"}
                  Description={
                      "We curate premium spirits and wine evenings conducted by local and international sommeliers."
                  }
                  onClick={() => {
                      navigate("/contact-us/");
                      window.scrollTo(0, 0);
                  }}
                  image={service5}
              />

              {/* Type 2 */}

              <TypeTwoEventCard
                  Title={"Hampers & Gifting Solutions"}
                  Description={
                      "Leave it to our team to customize your order to suit your requirement.  We provide tailor made corporate hampers for all occasions with no  hidden charges."
                  }
                  onClick={() => {
                      navigate("/contact-us/");
                      window.scrollTo(0, 0);
                  }}
                  image={service6}
              />
          </div>

          {/* ///////////////////////////////////////////////// */}

          <div className=" w-full overflow-hidden relative px-3 mt-10 mx-auto py-8 px-4 border-none  block md:hidden">
              {/* Header Image */}
              <div className="header-image-container">
                  <img
                      src={servicePoster}
                      alt="Header"
                      className="header-image"
                  />
              </div>

              <div className="overlay-container">
                  <div className="overlay-content">
                      <div className="arrow-container">
                          <i className="pi pi-arrow-down arrow-icon"></i>
                      </div>

                      <h3 className="service-title-mobile">
                          EVENTS AND SERVICES
                      </h3>
                  </div>
              </div>

              <div className="mt-10">
                  {services.map((service) => (
                      <div key={service.id} className=" rounded-lg shadow-md p-4 mb-6">
                          <div className="flex justify-center mb-4">
                              <img
                                  src={service.image}
                                  alt={service.title}
                                  className="w-full rounded-lg image-card"
                              />
                          </div>
                          <h3 className="mobile-service-title text-center  text-md font-semibold text-gray-800 mb-2">
                              {service.title}
                          </h3>
                          <p className="text-sm text-[var(--softText)] mb-4 text-center">
                              {service.description}
                          </p>
                          {service.buttonLabel && (
                              <div className="flex justify-center">
                                  <button
                                      onClick={() => {
                                          navigate("/contact-us/");
                                          window.scrollTo(0, 0);
                                      }}
                                      className="event-card-button-mobile font-bold"
                                  >
                                      Contact us
                                  </button>
                              </div>
                          )}
                      </div>
                  ))}
              </div>
          </div>
      </div>
  );
};

export default ServicesPage;
