import { apiSlice } from "./apiSlice";


export const paymentSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createPaymentIntent: builder.mutation({
            query: (data) => ({
                url: "payment/initiate",
                method: "POST",
                body: data,
            }),
        }),
        notifyPaymentIntent: builder.mutation({
            query: (data) => ({
                url: `payment/notify?paymentId=${data.id}&status=${data.status}`,
                method: "POST",
                body: data,
            }),
        }),
        // cancelPaymentIntent: builder.mutation({
        //     query: (data) => ({
        //         url: `payment/cancel?paymentId=${data.id}`,
        //         method: "POST",
        //         body: data,
        //     }),
        // }),
        // returnPaymentIntent: builder.mutation({
        //     query: (data) => ({
        //         url: `payment/return?paymentId=${data.id}`,
        //         method: "POST",
        //         body: data,
        //     }),
        // }),
    }),
})



export const { useCreatePaymentIntentMutation, useNotifyPaymentIntentMutation, } = paymentSlice