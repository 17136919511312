import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    theme : localStorage.getItem("theme") || "light"
}


export const themeSlice = createSlice({
    name: 'themeSlice',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload
            localStorage.setItem("theme", action.payload)
        }
    }
})

export const { setTheme } = themeSlice.actions

export default themeSlice.reducer