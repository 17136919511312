// src/components/Header.js
import React, { useState } from 'react';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import OtpInput from '../OtpInput';
import { Divider } from 'primereact/divider';

const ShareModal = ({ isModalVisible, onClick, toggleModal }) => {

    const handleOtpChange = (otp) => {
        // console.log("Entered OTP:", otp);
    };

    return (
        <Dialog
            visible={isModalVisible}
            footer={true}
            // header="Verify OTP" // Added a header title here
            closable={true}
            className="custom-dialog"
            onHide={toggleModal}
            breakpoints={{ '960px': '70vw', '640px': '100vw', '576px': '90vw' }}
            style={{ width: '30vw' }}
        >
            <div className="verify-modal-inputs">

                <h2 className='verify-modal-description'>Share</h2>

                <span className='verify-modal-description'>Let someone else pay!<br />
                    Share this link to allow others to view your items.</span>


                <Button icon="pi pi-share-alt" label='Copy Link' className="p-button-rounded p-button-text share-color" />

                <div className='share-on'>
                    <div className='share-on-line'></div>
                    <div>Or share on </div>
                    <div className='share-on-line'></div>

                </div>


                <div className="share-icons-container">
                    <div className="icon-circle">
                        <i className="pi pi-whatsapp"></i>
                    </div>
                    <div className="icon-circle">
                        <i className="pi pi-google"></i>
                    </div>
                    {/* <Divider layout="vertical" className="dashed-line" /> */}
                    <div className="icon-circle">
                        <i className="pi pi-comments"></i>
                    </div>
                </div>

            </div>
        </Dialog>
    );
};

export default ShareModal;
