import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useConvertOrderMutation, useGetAllOrderQuery } from "../../redux/service/orderSlice";
import { useSelector } from "react-redux";
import { ProgressSpinner } from 'primereact/progressspinner'
import { FaClipboard } from "react-icons/fa"; // Import Font Awesome clipboard icon

import "primeicons/primeicons.css"; // Ensure this is imported in your project
import { toast } from "material-react-toastify";

const OrderHistory = () => {
  const [selectedOrderType, setSelectedOrderType] = useState("Ongoing Orders");
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state?.auth?.userInfo);
  const sessionId = useSelector((state) => state?.auth?.sessionId);
  const { data: order, isLoading: ordersLoading } = useGetAllOrderQuery({
    userId: userInfo?.id,
    sessionId,
  });

  const handleOrderTypeChange = (type) => {
    setSelectedOrderType(type);
  };



  // const filteredOrders =
  //   order?.data?.filter((item) =>
  //     selectedOrderType === "Ongoing Orders"
  //       ? ["PROCESSING", "NEW", "PAYMENT_PENDING", "SHIPPED"].includes(item.status)
  //       : !["PROCESSING", "NEW", "PAYMENT_PENDING", "SHIPPED"].includes(item.status)
  //   ) || [];
  const filteredOrders =
    (order?.data?.filter((item) =>
      selectedOrderType === "Ongoing Orders"
        ? ["PROCESSING"].includes(item.status)
        : !["PROCESSING"].includes(item.status)
    ) || []).reverse();

  // const filteredOrders =
  // (order?.data?.filter((item) =>
  //   selectedOrderType === "Ongoing Orders"
  //     ? ["PROCESSING", "NEW", "PAYMENT_PENDING", "SHIPPED"].includes(item.status)
  //     : !["PROCESSING", "NEW", "PAYMENT_PENDING", "SHIPPED"].includes(item.status)
  // ) || []).reverse();


  //   const OrderItem = () => {

  //     const [convertOrder, { isLoading: convertLoading }] = useConvertOrderMutation();


  //     const handleConvertOrder = async (id) => {
  //       const orderId = id;
  //       try {
  //         const response = await convertOrder(orderId); // Replace 1 with the desired orderId
  //         console.log(response);
  //         toast.success("Successfully added all the items to the cart");
  //       } catch (error) {
  //         console.error("Error converting order:", error);
  //       }
  //     };

  //     return (



  //       <div className="order-list-container  ">
  //         {filteredOrders.map((item) => (
  //           <div
  //             key={item.id}
  //             className="flex flex-col md:flex-row border border-gray-300 p-2 rounded-lg w-full mb-2"
  //           >
  //             <div className="flex flex-col sm:flex-row flex-1 items-start rounded">
  //               <div className="text-left sm:ml-3">
  //                 <div className="mt-2 text-[var(--color)]">
  //                   <p className="mb-0">
  //                     <strong>Order ID:</strong> {item.id}{" "}
  //                     <i
  //                       onClick={() => {
  //                         navigator.clipboard.writeText(item.id);
  //                         alert("Copied!");
  //                       }}
  //                       className="ml-2.5 cursor-pointer"
  //                     >
  //                       📋
  //                     </i>
  //                   </p>
  //                   <p className="mb-0">
  //                     <strong>Order Status:</strong>{" "}
  //                     <span
  //                       className={`font-bold ${item.status === "PROCESSING"
  //                         ? "text-yellow-500"
  //                         : item.status === "CANCELLED"
  //                           ? "text-red-500"
  //                           : item.status === "FAILED"
  //                             ? "text-orange-500"
  //                             : "text-green-500" // SUCCESS
  //                         }`}
  //                     >
  //                       {item.status}
  //                     </span>
  //                   </p>
  //                   <p className="mb-1">
  //                     <strong>Order Date:</strong>{" "}
  //                     <span className="text-slate-500">
  //                       {/* {new Date(item.orderDate).toLocaleDateString()} */}
  //                       {new Date(item?.orderDate).toLocaleString('en-GB', {
  //                         day: '2-digit',
  //                         month: '2-digit',
  //                         year: 'numeric',
  //                         hour: '2-digit',
  //                         minute: '2-digit',
  //                         hour12: false,
  //                       })}
  //                     </span>
  //                   </p>
  //                 </div>
  //                 <div className="text-base text-[var(--color)] mb-1 leading-[15px]">
  //                   <strong className="font-bold text-xl">


  //                     {item?.currency} {item?.currency === "LKR" ? item?.totalPriceInRupee?.toLocaleString() : item?.currency === "USD" ? item?.totalPriceInDollar?.toLocaleString() : 0}
  //                   </strong>
  //                 </div>
  //                 <span className="text-orange-500 text-sm">
  //                   {item.orderItems.length} Items
  //                 </span>
  //               </div>
  //             </div>

  //             <div className="flex flex-row items-center gap-2.5 mt-4 sm:mt-0">
  //               <button
  //                 onClick={() => {
  //                   navigate(`/OrderDetails/${item.id}`);
  //                   window.scrollTo(0, 0);
  //                 }}
  //                 className="bg-[var(--conButton)] text-white border-2 border-[var(--conButton)] py-2 px-4 text-base cursor-pointer rounded transition duration-300 hover:bg-[#f0f0f0]"
  //               >
  //                 Order Details
  //               </button>
  //               <button onClick={() => handleConvertOrder(item?.id)} className="border-2 mr-3 border-[var(--green)] text-[var(--color)] bg-transparent py-2 px-4 text-base cursor-pointer rounded transition duration-300 hover:bg-[#f0f0f0]">
  //                 {convertLoading ? (
  //                   <>
  //                     <span className="spinner-border animate-spin mr-2" role="status" />
  //                     Processing...
  //                   </>
  //                 ) : (
  //                   "Reorder"
  //                 )}
  //               </button>
  //               {/* <button className="border-2 border-[var(--green)] bg-transparent p-1.5 flex justify-center items-center text-2xl rounded-full transition duration-300 hover:bg-[#f0f0f0]">
  //  🛒
  // </button> */}
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     )
  //   }


  const OrderItem = () => {
    const [loadingOrderId, setLoadingOrderId] = useState(null);
    const [convertOrder] = useConvertOrderMutation();

    const handleConvertOrder = async (id) => {
      setLoadingOrderId(id); // Set the loading order ID
      try {
        const response = await convertOrder(id);
        console.log(response);
        toast.success("Successfully added all the items to the cart");
      } catch (error) {
        console.error("Error converting order:", error);
        toast.error("Failed to add items to the cart");
      } finally {
        setLoadingOrderId(null); // Reset the loading order ID
      }
    };

    return (
      <div className="order-list-container">
        {filteredOrders?.map((item) => (
          <div
            key={item.id}
            className="flex flex-col md:flex-row border border-gray-300 p-2 rounded-lg w-full mb-2"
          >
            <div className="flex flex-col sm:flex-row flex-1 items-start rounded">
              <div className="text-left sm:ml-3">
                <div className="mt-2 text-[var(--color)]">
                  <p className="mb-0 flex-row flex">
                    <strong>Order ID:</strong> {item.id}{" "}
                    {/* <i
                      onClick={() => {
                        navigator.clipboard.writeText(item.id);
                        toast.success("Copied.!");
                      }}
                      className="ml-2.5 cursor-pointer"
                    >
                      📋
                    </i> */}
                    <FaClipboard
                      onClick={() => {
                        navigator.clipboard.writeText(item.id);
                        toast.success("Copied.!");
                      }}
                      className="ml-2.5 cursor-pointer"
                      style={{ fontSize: "1.2rem", color: "#000" }}
                    />
                  </p>
                  <p className="mb-0">
                    <strong>Order Status:</strong>{" "}
                    <span

                      className={`font-semibold ${item.status === "COMPLETED"
                        ? " text-green-500" :
                        item.status === "PROCESSING"
                          ? "text-blue-600"
                          : item.status === "CANCELLED"
                            ? "text-red-500"
                            : item.status === "RETURNED"
                              ? "text-orange-500"
                              : "text-yellow-600" // SUCCESS
                        }`}
                    >
                      {item.status}
                    </span>

                  </p>

                  <p className="mb-0">
                    <strong>Payment Status:</strong>{" "}
                    <span

                      className={`font-semibold ${item.paymentStatus === "PROCESSING"
                        ? "text-yellow-600" :
                        item.paymentStatus === "PENDING"
                          ? "text-blue-600"
                          : item.paymentStatus === "CANCELLED"
                            ? "text-red-500"
                            : item.paymentStatus === "FAILED"
                              ? "text-orange-500"
                              : "text-green-500" // SUCCESS
                        }`}
                    >
                      {item.paymentStatus}
                    </span>
                  </p>

                  <p className="mb-1">
                    <strong>Order Date:</strong>{" "}
                    <span className="text-slate-500">
                      {new Date(item?.orderDate).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </span>
                  </p>
                </div>
                <div className="text-base text-[var(--color)] mb-1 leading-[15px]">
                  <strong className="font-bold text-xl">
                    {item?.currency}{" "}
                    {item?.currency === "LKR"
                      ? item?.totalPriceInRupee?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : item?.currency === "USD"
                        ? item?.totalPriceInDollar?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : 0}
                  </strong>
                </div>
                <span className="text-orange-500 text-sm">
                  {item.orderItems.length} Items
                </span>
              </div>
            </div>

            <div className="flex flex-row items-center gap-2.5 mt-4 sm:mt-0">
              <button
                onClick={() => {
                  navigate(`/OrderDetails/${item.id}`);
                  window.scrollTo(0, 0);
                }}
                className="bg-[var(--conButton)] text-white border-2 border-[var(--conButton)] py-2 px-4 text-base cursor-pointer rounded transition duration-300 hover:bg-[#f0f0f0]"
              >
                Order Details
              </button>

              {item.paymentStatus === "PENDING" ? (
                <button
                  // onClick={() => handleConvertOrder(item?.id)}
                  className="border-2 mr-3 w-28 border-[var(--green)] text-[var(--color)] bg-transparent py-2 px-4 text-base cursor-pointer rounded transition duration-300 hover:bg-[#f0f0f0]"
                >
                  {loadingOrderId === item.id ? (
                    <>
                      <span className="spinner-border animate-spin mr-2" role="status" />
                      Processing...
                    </>
                  ) : (
                    "Repay"
                  )}
                </button>
              ) : (


                <button
                  onClick={() => handleConvertOrder(item?.id)}
                  className="border-2 mr-3 w-28 border-[var(--green)] text-[var(--color)] bg-transparent py-2 px-4 text-base cursor-pointer rounded transition duration-300 hover:bg-[#f0f0f0]"
                >
                  {loadingOrderId === item.id ? (
                    <>
                      <span className="spinner-border animate-spin mr-2" role="status" />
                      Processing
                    </>
                  ) : (
                    "Reorder"
                  )}
                </button>
              )}




            </div>
          </div>
        ))}
      </div>
    );
  };





  return (
    <div className="px-2 md:px-[8%] mt-12 container">
      <div className="flex">
        <p className="cursor-pointer text-[var(--color)]">
          <Link to="/" className="mr-2"> Home </Link>  {">"}<span className="text-[#DA813D] mx-2"> Order History </span>
        </p>
      </div>
      <div className="w-full font-sans">
        {/* Order Type Selection */}
        <div className="flex gap-2.5 mb-5">

          <button
            className={`${selectedOrderType === "Ongoing Orders"
              ? "bg-black text-white border-black"
              : "bg-white text-black"
              } py-2 px-4 text-base cursor-pointer border border-gray-300 rounded transition duration-300`}
            onClick={() => handleOrderTypeChange("Ongoing Orders")}
          >
            Ongoing Orders
          </button>
          <button
            className={`${selectedOrderType === "Previous Orders"
              ? "bg-black text-white border-black"
              : "bg-white text-black"
              } py-2 px-4 text-base cursor-pointer border border-gray-300 rounded transition duration-300`}
            onClick={() => handleOrderTypeChange("Previous Orders")}
          >
            Previous Orders
          </button>
        </div>

        {filteredOrders?.length > 0 ? (
          //  {/* Render Filtered Orders */}
          <div className="order-list-container">
            {/* {filteredOrders.map((item) => ( */}
            <OrderItem
            // key={item.id}
            // item={item}
            // // handleConvertOrder={handleConvertOrder}
            // // convertLoading={convertLoading}
            // navigate={navigate}
            />
            {/* ))} */}
          </div>

        ) :
          (
            <div>
              {ordersLoading == true ? (
                <div className="my-32 font-bold text-center w-full flex flex-col items-center">
                  <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                  <span className="mt-4">Please wait.!</span>
                </div>

              ) :
                (
                  <div className="my-32 font-bold text-center w-full"> No items in {selectedOrderType} list yet.! </div>

                )

              }


            </div>
          )
        }


      </div>
    </div>
  );
};

export default OrderHistory;





{/* <div className=' md:block'>
                    <hr />
                </div> */}

{/* Order Details */ }
{/* <div className="mt-5 text-[var(--color)]  md:border-t md:pt-4">
          <p>
            <strong>Order Status:</strong>{" "}
            <span className="text-green-500">{orderStatus}</span>
          </p>
          <p>
            <strong>Order ID:</strong> {orderId}{" "}
            <i onClick={handleCopy} className="ml-2.5 cursor-pointer">
              📋
            </i>
            {copied && (
              <span className="ml-2.5 font-semibold text-[var(--conButton)]">
                Copied!
              </span>
            )}
          </p>
        </div> */}