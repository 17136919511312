import { createSlice } from "@reduxjs/toolkit";

const generateUUID = () => {
  return crypto.randomUUID();
};

const initialState = {
  sessionId: localStorage.getItem("sessionId") || null,
  userInfo: localStorage.getItem("userInfo") 
    ? JSON.parse(localStorage.getItem("userInfo")) 
    : (sessionStorage.getItem("userInfo") 
    ? JSON.parse(sessionStorage.getItem("userInfo")) 
    : null),
  email: localStorage.getItem("email") || "",
  password: localStorage.getItem("password") || "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
      localStorage.setItem("sessionId", action.payload);
    },
    setUser: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    },

    setUserSession: (state, action) => {
      state.userInfo = action.payload;
      sessionStorage.setItem("userInfo", JSON.stringify(action.payload));
    },

    setCredentials: (state, action) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
      localStorage.setItem("email", action.payload.email);
      localStorage.setItem("password", action.payload.password);
    },

    removeCredentials: (state) => {
      state.email = "";
      state.password = "";
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    },

    logout: (state) => {
      state.userInfo = "";
      state.email = "";
      state.password = "";
      localStorage.removeItem("userInfo");
      const newSessionId = generateUUID();
      state.sessionId = newSessionId;
      localStorage.setItem("sessionId", newSessionId);
      sessionStorage.removeItem("userInfo");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      // localStorage.removeItem("city");
    },
  },
});

if (!localStorage.getItem("sessionId")) {
  const randomSessionId = generateUUID();
  localStorage.setItem("sessionId", randomSessionId);
}

export const { setSessionId, setUser, logout, setUserSession , setCredentials, removeCredentials } = authSlice.actions;

export default authSlice.reducer;
