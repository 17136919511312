// src/components/Header.js
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";
import { useUpdateProfileMutation } from "../../redux/service/profileSlice";
import { toast } from "material-react-toastify";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces")
    .max(50, "Name must not exceed 50 characters")
    .min(3, "Name must be at least 3 characters")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .max(254, "Email must not exceed 254 characters")
    .min(3, "Email must be at least 3 characters")
    .required("Email is required"),
  contactNumbers: Yup.string()
    .required("Phone Number is required")
    .min(10, "Phone Number must be at least 10 characters")
    .test("is-valid-length", "Invalid phone number", (value) => {
      if (!value) return false;
      const countryCode = value.slice(0, 3);
      if (countryCode === "+94") {
        return value.length === 12;
      }

      return value.length <= 15;
    }),
  address: Yup.string().required("Address is required"),
});

const ProfileInfo = ({ isModalVisible, onClick, toggleModal, profile }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const user = useSelector((state) => state?.auth?.userInfo);

  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  useEffect(() => {
    const formattedContactNumbers = profile?.data?.contactNumbers
    ? profile.data.contactNumbers.startsWith("+")
      ? profile.data.contactNumbers // Already starts with +
      : `+${profile.data.contactNumbers}` // Add + if missing
    : ""; // Default empty string if no contactNumbers

    setFormData({
      ...formData,
      contactNumbers: formattedContactNumbers,
      email: profile?.data?.email,
      name: profile?.data?.name,
      address: profile?.data?.address,
    });
    setErrors({});

    // console.log("formData", formData);
  }, [profile, isModalVisible]);

  const updateProfileHandler = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      await updateProfile({
        id: user?.id,
        formData: {
          name: formData?.name,
          address: formData?.address,
          email: formData?.email,
          contactNumbers: formData?.contactNumbers,
        },
      })
        .unwrap()
        .then((res) => {
          toggleModal();
          toast.success(res?.message || "Profile updated successfully");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.data?.message || "Something went wrong");
        });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  return (
    <Dialog
      visible={isModalVisible}
      footer={true}
      closable={true}
      className="custom-dialog mx-6 md:mx-0"
      onHide={toggleModal}
      breakpoints={{ "960px": "70vw", "640px": "100vw", "576px": "90vw" }}
      style={{ width: "30vw" }}
    >
      <div className="address-modal-inputs">
        <div className="address-topics">
          <h2 className="address-modal-title">Profile Information</h2>
          <div className="modal-devider"></div>
          <span className="address-modal-description">
            Please ensure all sections are filled out before submitting.
          </span>
        </div>

        <div className="address-input-group">
          <label className="modal-label">Name</label>
          <InputText
            id="Name"
            defaultValue={profile?.data?.name}
            placeholder="Please enter your Name"
            className="login-modal-inputs"
            value={formData.name}
            onChange={async (e) => {
              setFormData({ ...formData, name: e.target.value });
              try {
                await validationSchema.validateAt("name", {
                  ...formData,
                  name: e.target.value,
                });
                setErrors((prev) => ({ ...prev, name: "" }));
              } catch (error) {
                setErrors((prev) => ({ ...prev, name: error.message }));
              }
            }}
          />
          {errors.name && <small className="error-text">{errors.name}</small>}
        </div>

        <div className="address-input-group">
          <label className="modal-label">Address</label>
          <InputText
            placeholder="Please enter your Address"
            className="login-modal-inputs"
            defaultValue={profile?.data?.address}
            value={formData.address}
            onChange={(e) => {
              setFormData({ ...formData, address: e.target.value });
              setErrors((prev) => ({ ...prev, address: "" }));
            }}
          />

          {errors.address && (
            <small className="error-text">{errors.address}</small>
          )}
        </div>

        <div className="address-input-group">
          <label className="modal-label">Email</label>
          <InputText
            defaultValue={profile?.data?.email}
            placeholder="Enter your phone email"
            className="login-modal-inputs"
            disabled
            value={formData.email}
            // onChange={(e) => {
            //   setFormData({ ...formData, email: e.target.value });
            //   setErrors((prev) => ({ ...prev, email: "" }));
            // }}
          />
          {errors.email && <small className="error-text">{errors.email}</small>}
        </div>

        <div className="address-input-group">
          <label className="modal-label">Phone Number</label>
          <PhoneInput
            id="phone"
            value={formData.contactNumbers}
            onChange={async (e) => {
              setFormData({ ...formData, contactNumbers: e });
              try {
                await validationSchema.validateAt("contactNumbers", {
                  ...formData,
                  contactNumbers: e,
                });
                setErrors((prev) => ({ ...prev, contactNumbers: "" }));
              } catch (error) {
                setErrors((prev) => ({
                  ...prev,
                  contactNumbers: error.message,
                }));
              }
            }}
            defaultCountry="LK"
            country="LK"
            className="border  border-gray-300 rounded-md  p-2 w-full outline-none focus:ring-2 focus:ring-blue-500"
          />
          {errors.contactNumbers && (
            <small className="error-text">{errors.contactNumbers}</small>
          )}
        </div>

        {/* <div className="address-input-group">
                    <label className='modal-label'>Postal code</label>
                    <InputText
                        id="Password"
                        type="password"
                        placeholder="Please enter your Postal Code"
                        className="login-modal-inputs"
                        value={formData.postalCode}
                        onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })}
                    />
                    {address && <small className="error-text">{passwordError}</small>}
                </div> */}

        <div className="add-delivery-modal-footer">
          {/* <Button label="Order History" className="cancel-btn" onClick={orderHistory} /> */}
          <Button
            label="Cancel"
            onClick={toggleModal}
            className="bill-address-save-btn py-2"
          />
          <Button
            label={`${isLoading ? "Saving..." : "Save"}`}
            className="bill-address-save-btn py-2"
            onClick={updateProfileHandler}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ProfileInfo;
