import { Card } from "primereact/card";
import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="mt-6 container">
      <Card
        title={
          <span className="text-3xl uppercase text-[var(--color)] bg-transparent">
            Terms and Conditions
          </span>
        }
        className="mb-4 md:mx-12 md:px-12 font-10 border-none shadow-none"
      >
        <div className="grid gap-3">
          <p>
            This website provides the content and services available on the site
            to you subject to the following terms and conditions, our privacy
            policy and other terms and conditions and policies which you may
            find throughout our site in connection with certain functionality,
            features or promotions as well as customer service, all of which are
            deemed a part of and included within these terms and conditions
            (collectively “terms and conditions”). By accessing or using the
            Site, you are acknowledging that you have read, understand and
            agree, without limitation or qualification, to be bound by these
            terms and conditions.
          </p>

          <h1 className="text-2xl text-black font-sans font-semibold">
            CONCIERGE SERVICE
          </h1>

          <p>
            WineWorld.lk does not in any way have legal ownership of the items
            sold on its digital platforms and all payments made through online
            purchases are directly paid to the vendor of the stocks.
            WineWorld.lk is a mere concierge service and the only income
            recognized by WineWorld.lk is the charge for concierge services.
            <br /> <br /> By placing an online order on WineWorld.lk, the
            customer/user authorizes WineWorld.lk to collect the items in
            concern and deliver it on behalf of the customer.
          </p>

          <h1 className="text-2xl text-black font-sans font-semibold">
            PRIVACY
          </h1>
          <p>
            What Personal Information About Customers does Wineworld gather? The
            information we learn from customers helps us personalize and
            continually improve your experience. Here are the types of
            information we gather. <br /> <br />
            Information You Give Us: We receive and store any information you
            enter on our Web site or give us in any other way. You can choose
            not to provide certain information, but then you might not be able
            to take advantage of many of our features. We use the information
            that you provide for such purposes as responding to your requests,
            customizing future shopping for you, improving our stores, and
            communicating with you.
            <br /> <br />
            Automatic Information: We receive and store certain types of
            information whenever you interact with us. For example, like many
            Web sites, we use “cookies,” and we obtain certain types of
            information when your Web browser accesses any of our websites or
            advertisements and other content served by or on behalf of us on
            other Web sites.
            <br /> <br />
            Mobile: When you download or use apps created by us or by our
            subsidiaries, we may receive information about your location and
            your mobile device, including a unique identifier for your device.
            We may use this information to provide you with location-based
            services, such as advertising, search results, and other
            personalized content. Most mobile devices allow you to turn off
            location services.
            <br /> <br />
            E-mail Communications: To help us make e-mails more useful and
            interesting, we often receive a confirmation when you open e-mail
            from us if your computer supports such capabilities. If you do not
            want to receive e-mails or other mails from us you could use the
            unsubscribe option available in every mail we send. <br /> <br />
            What About Cookies? Cookies are unique identifiers that we transfer
            to your device to enable our systems to recognize your device and to
            provide features such as personalized advertisements on other Web
            sites and storage of items in your Shopping Cart between visits.{" "}
            <br />
            <br />
            The Help feature on most browsers will tell you how to prevent your
            browser from accepting new cookies, how to have the browser notify
            you when you receive a new cookie, or how to disable cookies
            altogether. Additionally, you can disable or delete similar data
            used by browser add-ons, such as Flash cookies, by changing the
            add-on’s settings or visiting the Web site of its manufacturer. We
            recommend that you leave them turned on. For instance, if you block
            or otherwise reject our cookies, you will not be able to add items
            to your Shopping Cart, proceed to Checkout, or use products and
            services that require you to Sign in.
            <br />
            <br />
            Does Wineworld Share the Information It Receives? Information about
            our customers is an important part of our business, and we are not
            in the business of selling it to others. We share customer
            information only as described below and with subsidiaries we control
            that either are subject to this or follow practices at least as
            protective as those described.
            <br />
            <br />
            Affiliated Businesses We Do Not Control: We work closely with
            affiliated businesses. In other cases, we sell product lines jointly
            with these businesses. You can tell when a third party is involved
            in your transactions, and we share customer information related to
            those transactions with that third party.
            <br />
            <br />
            Third-Party Service Providers: We employ other companies and
            individuals to perform functions on our behalf. Examples include
            fulfilling orders, delivering packages, sending postal mail and
            e-mail, removing repetitive information from customer lists,
            analysing data, providing marketing assistance, providing search
            results and links (including paid listings and links), processing
            credit card payments, and providing customer service. They have
            access to personal information needed to perform their functions but
            may not use it for other purposes.
            <br />
            <br />
            Business Transfers: As we continue to develop our business, we might
            sell or buy stores, subsidiaries, or business units. In such
            transactions, customer information generally is one of the
            transferred business assets but remains subject to the promises made
            in any pre-existing Privacy Notice (unless, of course, the customer
            consents otherwise).
            <br />
            <br />
            With Your Consent: Other than as set out above, you will receive
            notice when information about you might go to third parties, and you
            will have an opportunity to choose not to share the information.
            <br />
            <br />
            How Secure Is Information About Me? We work to protect the security
            of your information during transmission by using Secure Sockets
            Layer (SSL) software, which encrypts information you input.
            <br />
            <br />
            Bank / Payment details are directly typed on respective secured
            servers and details are not kept with us.
            <br />
            <br />
            It is important for you to protect against unauthorized access to
            your password and to your computer. Be sure to sign off when
            finished using a shared computer.
          </p>
          <h1 className="text-2xl text-black font-sans font-semibold">
            ELECTRONIC COMMUNICATIONS
          </h1>
          <p>
            When you use any of our websites, or send e-mails to us, you are
            communicating with us electronically. You consent to receive
            communications from us electronically. We will communicate with you
            by e-mail or by posting notices on this site or through other
            websites. You agree that all agreements, notices, disclosures and
            other communications that we provide to you electronically satisfy
            any legal requirement that such communications be in writing.
          </p>
          <h1 className="text-2xl text-black font-sans font-semibold">
            INTELLECTUAL PROPERTY
          </h1>
          <p>
            All information and content included in or made available on the
            site, but not limited to trade marks , such as text, graphics,
            logos, button icons, images, audio clips, digital downloads, and
            data compilations and the compilation and organization thereof
            (collectively, the “Content”) is the property of Wineworld brand
            owner or its content suppliers and protected by international
            copyright laws. The compilation of all content included in or made
            available through Wineworld is the exclusive property of Wineworld
            brand owner and protected by international copyright laws
            <br />
            <br />
            You shall not frame or utilize framing techniques to enclose any
            trademark, logo, or other proprietary information (including images,
            text, page layout, or form) of Wineworld without express written
            consent. You may not use any meta tags or any other “hidden text”
            utilizing Wineworld’s name or trademarks without the express written
            consent having been first obtained from us.
          </p>
          <h1 className="text-2xl text-black font-sans font-semibold">
            LICENSE AND ACCESS
          </h1>
          <p>
            Subject to your compliance with these Conditions of Use and your
            payment of any applicable fees, Wineworld or its content providers
            grant you a limited, non-exclusive, non-transferable,
            non-sublicensable license to access and make personal and
            non-commercial use of this website to purchase Wineworld Products
            and Services. This license does not include any resale or commercial
            use of any Wineworld Products and Service, or its contents; any
            collection and use of any product listings, descriptions, or prices;
            any derivative use of any Wineworld Products and Service or its
            contents; any downloading, copying, or other use of account
            information for the benefit of any third party; or any use of data
            mining, robots, or similar data gathering and extraction tools. All
            rights not expressly granted to you in these Conditions of Use or
            any Service Terms are reserved and retained by Wineworld or its
            licensors, suppliers, publishers, rights holders, or other content
            providers.
          </p>
        </div>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          LIMITATION OF LIABILITY
        </h1>
        <p>
          The site is presented “as is”. We make no representations or
          warranties of any kind whatsoever, expressed or implied, in connection
          with these terms and conditions or the site, including but not limited
          to warranties of Merchantability, Non-infringement or fitness for a
          particular purpose, except to the extent such representations and
          warranties are not legally excludable. You agree that, to the fullest
          extent permitted by applicable law, we will not be responsible or
          liable (whether in contract, tort (including negligence) or otherwise)
          under any circumstances, for any interruption of business, access
          delays or access interruptions to the site, data non delivery,
          corruption destruction or other modification, loss or damages of any
          sort incurred as a result of dealings with or the presence of off
          website links on the site, computer viruses, system failures or
          malfunctions which may occur in connection with your use of the site,
          any inaccuracies or omissions in content or events beyond our
          reasonable control.
          <br />
          <br />
          WineWorld.lk is a facility that connects users/customers with vendors
          that they wish to purchase products from, and cannot be held liable
          for the quality standards of the delivered products provided by said
          vendors.
          <br />
          <br />
          You agree that no claims or action arising out of, or related to, the
          use of the site or these terms and conditions may be brought by you
          more than one (1) year after the cause of action relating to such
          claim or action arose.
        </p>

        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          INDEMNIFICATION
        </h1>
        <p>
          You agree to defend, indemnify and hold us harmless for any loss,
          damages or costs, including reasonable attorneys’ fees, resulting from
          any third-party claim, action, or demand resulting from your use of
          the Site or breach of these Terms and Conditions.
        </p>
        {/* <h1 className="text-2xl text-black font-sans font-semibold">
          YOUR ACCOUNT
        </h1> */}
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          YOUR ACCOUNT
        </h1>
        <p>
          If you use any Wineworld Website you are responsible for maintaining
          the confidentiality of your account and password and for restricting
          access to your computer, and you agree to accept responsibility for
          all activities that occur under your account or password. Wineworld
          does sell products for children, but it sells them to adults, who can
          purchase with a credit card or other permitted payment method.
          Wineworld reserves the right to refuse service, terminate accounts,
          remove or edit content, or cancel orders in its sole discretion.
        </p>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT
        </h1>
        <p>
          Visitors may post reviews, comments, photos, and other content; send
          e-cards and other communications; and submit suggestions, ideas,
          comments, questions, or other information, so long as the content is
          not illegal, obscene, threatening, defamatory, invasive of privacy,
          infringing of intellectual property rights, or otherwise injurious to
          third parties or objectionable and does not consist of or contain
          software viruses, political campaigning, commercial solicitation,
          chain letters, mass mailings, or any form of “spam.” You may not use a
          false e-mail address, impersonate any person or entity, or otherwise
          mislead as to the origin of a card or other content. Wineworld
          reserves the right (but not the obligation) to remove or edit such
          content, but does not regularly review posted content. If you do post
          content or submit material, and unless we indicate otherwise, you
          grant Wineworld a nonexclusive, royalty-free, perpetual, irrevocable,
          and fully sublicensable right to use, reproduce, modify, adapt,
          publish, translate, create derivative works from, distribute, and
          display such content throughout the world in any media. You grant
          Wineworld and sublicensees the right to use the name that you submit
          in connection with such content, if they choose. You represent and
          warrant that you own or otherwise control all of the rights to the
          content that you post; that the content is accurate; that use of the
          content you supply does not violate this policy and will not cause
          injury to any person or entity; and that you will indemnify Wineworld
          for all claims resulting from content you supply. Wineworld has the
          right but not the obligation to monitor and edit or remove any
          activity or content. Wineworld takes no responsibility and assumes no
          liability for any content posted by you or any third party.
        </p>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          COPYRIGHT COMPLAINTS
        </h1>
        <p>
          Wineworld respects the intellectual property of others. If you believe
          that your work has been copied in a way that constitutes copyright
          infringement, please refer to our legal team (wwinfo@wineworld.lk)
          with the subject line which includes the phrase “Legal Complaint”.
        </p>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          RISK OF LOSS
        </h1>
        <p>
          All items purchased from Wineworld are made pursuant to a shipment
          contract. This means that the risk of loss and title for such items
          pass to you upon our delivery to the carrier.
        </p>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          PRODUCT DESCRIPTIONS
        </h1>
        <p>
          Wineworld attempts to be as accurate as possible. However, Wineworld
          does not warrant that product descriptions or other content of any
          product is accurate, complete, reliable, current, or error-free. If a
          product offered by Wineworld itself is not as described, your sole
          remedy is to return it in an unused condition.
        </p>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">PRICING</h1>
        <p>
          Except where noted otherwise, the List Price or Suggested Price
          displayed for products does not include shipping charges unless
          otherwise stated and excludes taxes payable to regulatory institutions
          and customs offices.
          <br />
          <br />
          List Price or Suggested Price displayed in currencies other than LKR,
          is to be considered for reference only. Exchange rates at the time of
          the purchase will be applied by the payment gateway and transacting
          banks.
        </p>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          TAXATION
        </h1>
        <p>
          Each customer is responsible for the taxes owed if there is a tax on
          those items in their state/country upon delivery. If items are
          returned due to customs duty/taxations, the resulting fee will be
          deducted before refunding payments.
        </p>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          SITE POLICIES, MODIFICATION, AND SEVERABILITY
        </h1>
        <p>
          Please review our policies posted on this site. We reserve the right
          to make changes to our site, policies, Service Terms, and these
          Conditions of Use at any time. If any of these conditions shall be
          deemed invalid, void, or for any reason unenforceable, that condition
          shall be deemed severable and shall not affect the validity and
          enforceability of any remaining condition.
        </p>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">
          DISPUTES
        </h1>
        <p>
          With respect to any dispute regarding the site, all rights and
          obligations and all actions contemplated by these terms and conditions
          shall be governed by the laws of Sri Lanka as if the Terms and
          Conditions were a contract wholly entered into and wholly performed
          within Sri Lanka. Any dispute relating in any way to your visit to the
          site shall be referred to arbitration in accordance with the rules of
          Sri Lanka, by sole arbitrator, and the Arbitration proceedings shall
          be held in the English Language. Any payment related disputes should
          be mailed to wwinfo@wineworld.lk
        </p>
        <h1 className="text-2xl text-black mb-3 font-sans font-semibold">GENERAL</h1>
        <p>
          You acknowledge and agree that these Terms and Conditions constitute
          the complete and exclusive agreement between us concerning your use of
          the Site, and supersede and govern all prior proposals, agreements, or
          other communications.
          <br />
          <br />
          We reserve the right, at our sole discretion, to change these Terms
          and Conditions at any time by posting the changes on the Site and
          providing notice of such change. Any changes are effective immediately
          upon posting to the Site and release of notice of such change. Your
          continued use of the Site thereafter constitutes your agreement to all
          such changed Terms and Conditions. We may, with or without prior
          notice, terminate any of the rights granted by these Terms and
          Conditions. You shall comply immediately with any termination or other
          notice, including, as applicable, by ceasing all use of the Site.
          <br />
          <br />
          Nothing contained in these Terms and Conditions shall be construed as
          creating any agency, partnership, or other form of joint enterprise
          between us. Our failure to require your performance of any provision
          hereof shall not affect our full right to require such performance at
          any time thereafter, nor shall our waiver of a breach of any provision
          hereof be taken or held to be a waiver of the provision itself. In the
          event that any provision of these Terms and Conditions shall be
          unenforceable or invalid under any applicable law or be so held by any
          applicable arbitral award or court decision, such unenforceability or
          invalidity shall not render these Terms and Conditions unenforceable
          or invalid as a whole but these Terms and Conditions shall be
          modified, to the extent possible, by the adjudicating entity to most
          fully reflect the original intent of the parties as reflected in the
          original provision.
        </p>
      </Card>
    </div>
  );
};

export default TermsAndConditions;
