import React from 'react';
import { Carousel } from 'primereact/carousel';

export default function VerticalDemo({ Images, className }) {
    // Define the template for each image
    const productTemplate = (imageSrc) => {
        return (
            <div className="border-1 surface-border border-round m-2 text-center py-5 px-3 mobile-padding mobile-margin">
                <div className="mb-3 imageBox">
                    <img src={imageSrc} alt="Product" className="shadow-2 rounded-lg" />
                </div>
            </div>
        );
    };

    return (
        <div className={`card flex justify-content-center ${className}`}>
            <Carousel
                value={Images}
                numVisible={1}
                numScroll={1}
                orientation="horizontal"
                itemTemplate={productTemplate}
            />
        </div>
    );
}
